export const pl = {
    "How Others Can Find You": "Jak inni mogą cię znaleźć",
    "Schedules": "Rozkłady",
    "Login": "Logowanie",
    "Start Your InstaPay Journey Today!": "Rozpocznij swoją podróż z InstaPay już dziś!",
    "Phone Number or Email": "Numer telefonu lub email",
    "Login Using Social Media": "Logowanie przez media społecznościowe",
    "Continue": "Kontynuuj",
    "Don't Have an Account? Sign Up": "Nie masz konta? Zarejestruj się",
    "Sign Up": "Zarejestruj się",
    "Edit": "Szczegóły",
    "Enter Your Password": "Wprowadź swoje hasło",
    "Forgot Password?": "Zapomniałeś hasła?",
    "Account Type": "Typ konta",
    "Individual": "Indywidualne",
    "Business": "Biznesowe",
    "Already Have an Account? Sign In": "Masz już konto? Zaloguj się",
    "Sing In": "Zaloguj się",
    "Enter Your Personal Details": "Wprowadź swoje dane osobiste",
    "Add Photo or Logo": "Dodaj zdjęcie lub logo",
    "Enter Your Name": "Wprowadź swoje imię",
    "Back": "Powrót",
    "Phone Number": "Numer telefonu",
    "Email Address": "Adres Email",
    "Create a Strong Password": "Utwórz silne hasło",
    "Password Requirements": "Wymagania dotyczące hasła",
    "Must be between 9 and 64 characters": "Muszą mieć od 9 do 64 znaków",
    "Include at least two of the following:": "Zawierać przynajmniej dwa z poniższych:",
    "Uppercase character": "Wielka litera",
    "Lowercase character": "Mała litera",
    "Number": "Cyfra",
    "Special character": "Znak specjalny",
    "Confirm Password": "Potwierdź hasło",
    "Finalize Your Process": "Dokończ proces",
    "Referral Code (Optional)": "Kod polecający (opcjonalnie)",
    "It will autofill if you're signing up with an invitation link.": "Wypełni się automatycznie, jeśli rejestrujesz się przez link zaproszenia.",
    "I agree with": "Zgadzam się z",
    "Terms & Conditions": "Regulaminem",
    "Privacy Policy": "Polityką prywatności",
    "Create Your Account": "Utwórz swoje konto",
    "Enter Code": "Wprowadź kod",
    "A one-time verification code has been sent to your email address  and phone number.": "Jednorazowy kod weryfikacyjny został wysłany na twój adres email i numer telefonu.",
    "Resend available in ": "Ponowne wysłanie dostępne za",
    "seconds": "sekund",
    "Verify": "Zweryfikuj",
    "Verification Code Verified": "Kod weryfikacyjny zweryfikowany",
    "Please continue to the next step.": "Proszę kontynuować do następnego kroku.",
    "Finish": "Zakończ",
    "Finishing": "Kończenie",
    "Company Name": "Nazwa firmy",
    "Enter Your Business Details": "Wprowadź dane swojej firmy",
    "Country ": "Kraj",
    "You cannot change the country once your account is verified.": "Nie można zmienić kraju po zweryfikowaniu konta.",
    "Learn more": "Dowiedz się więcej",
    "Optional": "Opcjonalne",
    "Continuing": "Kontynuowanie",
    "Didn't receive the verification code?": "Nie otrzymałeś kodu weryfikacyjnego?",
    "Resend Now": "Wyślij ponownie teraz",
    "Search Here": "Szukaj tutaj",
    "Search for users, services, and more on InstaPay": "Szukaj użytkowników, usług i więcej na InstaPay",
    "Search users here": "Szukaj użytkowników tutaj",
    "Earn money with our affiliate program!": "Zarabiaj pieniądze z naszego programu partnerskiego!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Generuj pasywny dochód, udostępniając swój kod partnerski InstaPay swoim obserwującym. Za każdą transakcję, którą wykonają,\notrzymujesz prowizję, maksymalizując zyski z Twojego wpływu online.",
    "Learn More": "Dowiedz się więcej",
    "Balance Amount": "Saldo",
    "You spent less compared to last month.": "Wydałeś mniej w porównaniu do zeszłego miesiąca.",
    "Referral Rewards": "Nagrody za polecenie",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Szybki transfer do Twoich najnowszych kontaktów:",
    "Number of Transactions": "Liczba transakcji",
    "Total Transaction Amount": "Całkowita kwota transakcji",
    "Total Sent": "Łącznie wysłane",
    "Total Received": "Łącznie otrzymane",
    "Add Funds": "Dodaj środki",
    "Send Money": "Wyślij Pieniądze",
    "Request Money": "Poproś o Pieniądze",
    "Send a Quote": "Wyślij ofertę",
    "Pay With Crypto": "Płać kryptowalutami",
    "Payment Insights": "Wgląd w płatności",
    "Recent Transactions ": "Ostatnie transakcje",
    "Today": "Dziś",
    "My Wallets": "Moje portfele",
    "Balance": "Saldo",
    "Wallet ID": "ID portfela",
    "Monthly limit": "Miesięczny limit",
    "Top Up": "Doładuj",
    "Summary": "Podsumowanie",
    "Settings": "Ustawienia",
    "More": "Więcej",
    "Upgrade to Business Account": "Przejdź na konto biznesowe",
    "Conversion": "Konwersja",
    "Enter Amount": "Wprowadź kwotę",
    "Exchanged Amount": "Przeliczona kwota",
    "Convert": "Konwertuj",
    "You Are Converting": "Przeliczasz",
    "From": "Od",
    "To": "Na",
    "Exchange Rate": "Kurs wymiany",
    "Fees": "Opłaty",
    "You Will Receive": "Otrzymasz",
    "Slide To Confirm": "Przesuń, aby potwierdzić",
    "Verification Code Confirmed - You'll be redirected to the next step": "Kod weryfikacyjny potwierdzony - zostaniesz przekierowany do następnego kroku",
    "Moving to next step in": "Przechodzenie do następnego kroku za",
    "Thanks for using InstaPay": "Dziękujemy za korzystanie z InstaPay",
    "Your funds have been credited to your wallet.": "Twoje środki zostały zaksięgowane na twoim portfelu.",
    "Dashboard": "Panel sterowania",
    "Accounts": "Konta",
    "Transactions": "Transakcje",
    "Other Transactions": "Inne transakcje",
    "Payments": "Płatności",
    "Beneficiaries": "Beneficjenci",
    "Referrals": "Polecenia",
    "Quotations": "Oferty",
    "Pending": "Oczekujące",
    "My QR Code Sticker": "Mój naklejka kodu QR",
    "My Portfolio": "Moje portfolio",
    "My Payment Address": "Mój adres płatności",
    "Analytics": "Analizy",
    "Profile": "Profil",
    "Dark Mode": "Tryb ciemny",
    "Support": "Pomoc",
    "Logout": "Wyloguj się",
    "Powered By": "Napędzane przez",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Szwajcarska firma fintech",
    "MAIN MENU": "GŁÓWNE MENU",
    "OTHERS": "INNE",
    "Wallet": "Wallet",
    "Default": "Domyślny",
    "Breakdown": "Rozkład",
    "Credit": "Kredyt",
    "Debit": "Debet",
    "Wallet Management": "Zarządzanie portfelem",
    "Top-up Your Wallet": "Doładuj swój portfel",
    "Download Statement": "Pobierz wyciąg",
    "Block the Wallet": "Zablokuj portfel",
    "Wallet Status": "Status portfela",
    "-Select-File-Type-": "-Wybierz-typ-pliku-",
    "Download Now": "Pobierz teraz",
    "Downloading": "Pobieranie",
    "All": "Wszystkie",
    "Sent": "Wysłane",
    "Received": "Otrzymane",
    "Requested": "Żądane",
    "Quoted": "Ofertowane",
    "Search Transactions": "Szukaj transakcji",
    "Date & Time": "Data i czas",
    "Type": "Typ",
    "Transaction ID": "ID transakcji",
    "Recipient": "Odbiorca",
    "Amount": "Kwota",
    "Status": "Status",
    "Payment Type": "Typ płatności",
    "Sent Payments": "Wysłane płatności",
    "Received Payments": "Otrzymane płatności",
    "Date": "Data",
    "Cycles/Time": "Cykle/Czas",
    "Payment As": "Płatność jako",
    "Name": "Nazwa",
    "Cancel": "Anuluj",
    "Subscriptions & Scheduled": "Subskrypcje i zaplanowane",
    "Select the option that matches your needs from the list below:": "Wybierz opcję, która odpowiada Twoim potrzebom z poniższej listy:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Łatwo wysyłaj pieniądze do przyjaciół, rodziny lub firm lokalnie lub międzynarodowo. Wybierz spośród wielu opcji transferu, w tym wpłat na konto \nbankowe, portfele mobilne,odbieranie gotówki, karty płatnicze oraz przelewy z portfela do portfela. Ciesz się wygodą i bezpieczeństwem korzystania \nz InstaPay dla płynnych i natychmiastowych przelewów pieniężnych.",
    "International Transfer": "Przelew Międzynarodowy\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Wyślij pieniądze za granicę z łatwością. Przelej środki międzynarodowo do przyjaciół, rodziny lub firm za pomocą kilku kliknięć. Wybierz spośród\nszerokiej gamy kanałów płatności, w tym konta bankowego, pieniędzy mobilnych/portfela mobilnego, punktu odbioru gotówki lub karty płatniczej\ndla wygodnych i bezpiecznych transakcji.",
    "Select the country where you want to send the money!": "Wybierz kraj, do którego chcesz wysłać pieniądze!",
    "Search Country": "Szukaj Kraju",
    "Trouble Sending Money?": "Problem z wysyłaniem pieniędzy?",
    "Confirm": "Potwierdź",
    "Select Payout Channel": "Wybierz Kanał Wypłaty",
    "Select your most convenient option to send the money.": "Wybierz najwygodniejszą opcję wysłania pieniędzy.",
    "Select The Bank": "Wybierz Bank",
    "Search Bank": "Szukaj Banku",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Wyślij pieniądze bezpośrednio na konto bankowe odbiorcy. Ciesz się wygodą i bezpieczeństwem płynnego przesyłania środków do dowolnego banku \nna świecie.",
    "Bank Transfer": "Przelew Bankowy",
    "Enter the amount": "Wprowadź kwotę",
    "Tax": "Podatek",
    "Amount the recipient will receive": "Kwota, którą otrzyma odbiorca",
    "Select Remarks / Reason": "Wybierz Uwagi / Powód",
    "Comments": "Komentarze",
    "Attach Files": "Załącz Pliki",
    "Groups": "Grupy",
    "Manage Groups": "Zarządzaj Grupami",
    "Add New Group": "Dodaj Nową Grupę",
    "Invite": "Zaproś Osobę",
    "Manage Beneficiaries": "Zarządzaj Beneficjentami",
    "Recipients List": "Lista Odbiorców",
    "Search Contacts": "Szukaj Kontaktów",
    "Confirm Your Payment": "Potwierdź Płatność",
    "Select Country": "Wybierz Kraj",
    "Select the Recipient's Payment Channel": "Wybierz Kanał Płatności Odbiorcy",
    "Change Selection": "Zmień Wybór",
    "You Are Transferring ": "Przesyłasz",
    "Oops! Something Went Wrong.": "Ups! Coś poszło nie tak.",
    "We're Sorry About That. ": "Przepraszamy za to.",
    "Go back to Payments Page.": "Wróć do strony płatności.",
    "Wallet to Wallet Transfer": "Przelew z Portfela do Portfela",
    "Request a customized price quote from service providers or vendors.": "Zamów spersonalizowaną wycenę od dostawców usług lub sprzedawców.",
    "Enter the Wallet ID": "Wprowadź ID Portfela",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Wprowadź ID Portfela, Email lub Numer Telefonu odbiorcy.",
    "Recipient not on InstaPay?": "Czy odbiorca nie jest na InstaPay? Zaproś ich.",
    "Invite Them": "Zaproś Ich",
    "My Beneficiaries": "Moi Beneficjenci",
    "Enter the Amount You Wish to Transfer.": "Wprowadź kwotę, którą chcesz przelać.",
    "Sending to ": "Wysyłasz do",
    "You Have Selected Wallet to Wallet Transfer": "Wybrałeś Przelew z Portfela do Portfela",
    "Authenticate Your Payment.": "Uwierzytelnij swoją płatność.",
    "You are transferring": "Przesyłasz",
    "Total ": "Łącznie",
    "Where Is My Payment?": "Gdzie jest moja płatność?",
    "Share": "Udostępnij",
    "Your Payment Is Confirmed": "Twoja płatność została potwierdzona",
    "QR Code Payment": "Płatność Kodem QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Natychmiastowo przelej pieniądze do innych użytkowników InstaPay za pomocą naszego bezpiecznego serwisu portfel-do-portfela. Wyślij i odbieraj \nśrodki bezproblemowo w ciągu kilku sekund.",
    "Scan QR with Your Camera or Upload from Your Device.": "Zeskanuj QR swoją kamerą lub załaduj z urządzenia.",
    "Upload from Your Device.": "Załaduj z urządzenia.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternatywnie, użyj Unikalnego Adresu Płatności InstaPay (UPA)",
    "Enter InstaPay UPA": "Wprowadź InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Odbiorca nie jest na InstaPay? Zaproś ich",
    "Verify QR Code": "Zweryfikuj Kod QR",
    "Scan/Upload QR Code of the Recipient": "Zeskanuj/Załaduj Kod QR Odbiorcy",
    "Payment Confirmed": "Płatność Potwierdzona",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Poproś o spersonalizowane wyceny od dostawców usług lub sprzedawców, aby otrzymać dokładne oszacowanie kosztów usług lub produktów, \nktórych potrzebujesz.",
    "Choose The Beneficiary or Group": "Wybierz Beneficjenta lub Grupę",
    "Enter the Amount You Wish to Request.": "Wprowadź kwotę, którą chcesz zażądać.",
    "You Are Requesting Money": "Żądasz Pieniędzy",
    "You are requesting": "Żądasz",
    "As": "Jako",
    "Conversion of Your Cryptocurrencies": "Konwersja Twoich Kryptowalut",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Doświadcz naszego użytkownika, bezpiecznego i efektywnego procesu Konwersji Crypto na Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Wybierz Kryptowalutę, którą zamierzasz zlikwidować.",
    "Next": "Dalej",
    "Enter the Crypto Wallet Address of the Recipient.": "Wprowadź Adres Portfela Krypto Odbiorcy.",
    "Minimum Transactional Value: $100.": "Minimalna Wartość Transakcji: 100$.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Uwaga: Ceny krypto często się zmieniają. Odniesienie do ostatecznej wyceny na stronie potwierdzenia zamówienia.",
    "Select Network": "Wybierz Sieć",
    "Send A Quote": "Wyślij Ofertę",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Wyślij szczegółową ofertę cenową swoim klientom lub klientom. Przedstaw swoje usługi, produkty,",
    "Choose the Beneficiary": "lub oferty profesjonalnie za pomocą spersonalizowanej wyceny.",
    "Allow Receiver to Bargain": "Wybierz Beneficjenta",
    "Enter the Title for Your Quotation.": "Pozwól Odbiorcy Targować się",
    "Enter the Description for Your Quotation": "Wprowadź Tytuł dla Twojej Wyceny.",
    "You are Quoting": "Wprowadź Opis dla Twojej Wyceny",
    "Title": "Wyceniasz",
    "Description": "Opis",
    "Attachments": "Opis",
    "Confirm Your Quotation": "Załączniki",
    "Verify The Quotation": "Potwierdź swoją Wycenę",
    "Your quotation is confirmed": "Zweryfikuj Wycenę",
    "Request A Quote": "Poproś o wycenę",
    "Coming Soon": "Poproś o Wycenę",
    "Add Beneficiary": "Już wkrótce",
    "Search for Beneficiaries": "Dodaj Beneficjenta",
    "Connected Accounts": "Szukaj Beneficjentów",
    "Details": "Połączone Konta",
    "First Name": "Edytuj",
    "Last Name": "Imię",
    "Country": "Nazwisko",
    "City": "Kraj",
    "Address Line": "Miasto",
    "Mobile Number": "Numer Telefonu Komórkowego",
    "Relationship With Beneficiary": "Relacja z Beneficjentem",
    "Bank Account": "Konto Bankowe",
    "Mobile Money/Mobile Wallet": "Pieniądze Mobilne/Portfel Mobilny",
    "Payment Card": "Karta Płatnicza",
    "Crypto Wallet": "Portfel Krypto",
    "InstaPay Account": "Konto InstaPay",
    "Cash Pickup": "Odbiór Gotówki",
    "IBAN / SWIFT Code": "Kod IBAN lub Swift/BIC",
    "Swift Code": "Kod Swift",
    "Account Number": "Numer Konta",
    "Bank Name": "Nazwa Banku",
    "Branch Name": "Nazwa Oddziału",
    "Branch Street": "Ulica Oddziału",
    "Province": "Województwo",
    "Postal Code": "Kod Pocztowy",
    "Mobile Money Provider": "Dostawca Pieniędzy Mobilnych",
    "Wallet Name": "Nazwa Portfela",
    "Wallet Number": "Numer Portfela",
    "Card Holder Name": "Nazwa Właściciela Karty",
    "Card Number": "Numer Karty",
    "Expiry Date": "Data Ważności",
    "Crypto Currency": "Kryptowaluta",
    "Wallet Address": "Adres Portfela",
    "Wallet Holder Name": "Nazwa Właściciela Portfela",
    "Wallet Currency": "Waluta Portfela",
    "Select Document Type": "Wybierz Typ Dokumentu",
    "Enter Document Number": "Wprowadź Numer Dokumentu",
    "Add Individual Account": "Dodaj Indywidualne Konto",
    "Add Business Account": "Dodaj Konto Firmowe",
    "Company Address": "Adres Firmy",
    "Company Email": "Email Firmy",
    "Company Phone No": "Numer Telefonu Firmy",
    "Total Referrals": "Łączna Liczba Poleceń",
    "My Earnings": "Moje Zarobki",
    "Your Referral ID": "Twój ID Polecającego",
    "Share Your Unique Referral Link": "Udostępnij Swój Unikalny Link Polecający",
    "How do I Refer a Friend to InstaPay?": "Jak Polecić Przyjaciela do InstaPay?",
    "Step 01": "Krok 01",
    "Share your InstaPay unique referral link with your friends.": "Udostępnij swój unikalny link polecający InstaPay swoim znajomym.",
    "Step 02": "Krok 02",
    "Earn rewards when they transact $100 or more": "Zarabiaj nagrody, gdy dokonają transakcji na kwotę 100$ lub więcej",
    "Step 03": "Krok 03",
    "Earn up to $0.06 for every transaction your followers make.": "Zarabiaj do 0.06$ za każdą transakcję wykonaną przez Twoich obserwatorów.",
    "My Referrals": "Moje Polecenia",
    "Referral Complete": "Polecenie Zakończone",
    "Received Quotations": "Otrzymane Oferty",
    "Sent Quotations": "Wysłane Oferty",
    "Search Pending Items": "Szukaj Oczekujących Przedmiotów",
    "Actions": "Akcje",
    "Received Requests": "Otrzymane Prośby",
    "Sent Requests": "Wysłane Prośby",
    "No Actions": "Brak Akcji",
    "You Requested": "Poprosiłeś",
    "Via": "Przez",
    "How was your experience?": "Jakie było Twoje doświadczenie?",
    "Leave a Review": "Zostaw Recenzję",
    "Overall Ratings": "Ogólna Ocena",
    "Visit Profile": "Odwiedź Profil",
    "Ratings": "Oceny",
    "Reviews": "Recenzje",
    "No reviews available": "Brak dostępnych recenzji",
    "Select Your Currency": "Wybierz Swoją Walutę",
    "Proceed": "Kontynuuj",
    "Commision": "Prowizja",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Odblokuj Bezproblemowe Transakcje z Naklejką Kodu QR InstaPay",
    "Effortless and Swift:": "Bezproblemowe i Szybkie:",
    "Instantly download your unique InstaPay QR code sticker.": "Natychmiastowo pobierz swoją unikalną naklejkę kodu QR InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Doświadcz bezproblemowych, bezgotówkowych płatności bez potrzeby korzystania ze skomplikowanej infrastruktury, jak terminale płatnicze.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Szybko zbieraj płatności - bez uciążliwego wprowadzania numerów kart kredytowych lub telefonów.",
    "Each payment includes customer information for your convenience.": "Każda płatność zawiera informacje o kliencie dla Twojej wygody.",
    "Economically Smart:": "Ekonomicznie Mądre:",
    "Zero activation fees – start immediately without upfront costs.": "Brak opłat aktywacyjnych - zacznij od razu bez kosztów wstępnych.",
    "Forget about fixed monthly charges; pay as you transact.": "Zapomnij o stałych opłatach miesięcznych; płać, jak transakcjonujesz.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Odrzuć sprzęt; brak kosztów terminalu oznacza większe oszczędności dla Ciebie.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Ciesz się niskimi opłatami transakcyjnymi, wynoszącymi tylko 0,75% za płatność kodem QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "Nie narzucamy minimalnych prowizji; oszczędzasz więcej przy każdej transakcji.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Doświadcz radości z natychmiastowych płatności, poprawiających Twój przepływ gotówki.",
    "Make Every Transaction Count with InstaPay": "Spraw, by Każda Transakcja Liczyła się z InstaPay",
    "Wallet QR": "QR Portfela",
    "Download QR": "Pobierz QR",
    "QR Code Status": "Status Kodu QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Udostępnij swój kod QR lub link do adresu płatności, aby natychmiastowo otrzymywać płatności na swoim koncie InstaPay.",
    "QR Code Title": "Tytuł Kodu QR",
    "View Transactions": "Zobacz Transakcje",
    "My QR Codes:": "Moje Kody QR:",
    "Load More": "Załaduj Więcej",
    "Benefits Of Portfolio": "Korzyści z Portfolio",
    "Benefits:": "Korzyści:",
    "Enhanced Visibility:": "Zwiększona Widoczność:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Podnieś swój publiczny profil. Twoje portfolio działa jako dynamiczna wystawa, dostępna bezpośrednio z Twojego Adresu Płatności.",
    "Engage Your Audience:": "Angażuj Swoją Publiczność:\n",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Łącz się z odwiedzającymi przez Twoją twórczą lub zawodową podróż. Pozwól, by Twoja praca\nmówiła za Ciebie, tworząc głębsze zaangażowanie potencjalnych klientów lub zwolenników.",
    "Integrated Social Proof:": "Zintegrowany Dowód Społeczny:\n",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Prezentuj swój wpływ w mediach społecznościowych. Odwiedzający mogą zobaczyć Twój zasięg w mediach społecznościowych, dodając wiarygodność \ni kontekst do Twojego portfolio.",
    "Seamless Transactions:": "Bezproblemowe Transakcje:\n",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Umożliwiaj odwiedzającym żądanie lub inicjowanie płatności bezpośrednio ze strony publicznej. Twoje portfolio nie tylko prezentuje Twoją pracę, ale \ntakże ułatwia łatwe interakcje finansowe.",
    "Personalized Storytelling:": "Personalized Storytelling:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Użyj sekcji 'O mnie', aby podzielić się swoją historią, misją lub wizją, tworząc osobistą więź z Twoją publicznością.\n",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Moje Portfolio' to coś więcej niż tylko galeria; to narzędzie do budowania Twojej marki, angażowania publiczności i usprawniania Twoich finansowych \ninterakcji na InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Wystaw swoje prace, zwiększ zasięg. 'Moje Portfolio' to Twoje cyfrowe płótno na InstaPay. Prześlij i pokaż różnorodne treści – od zachwycających \nobrazów i filmów po pouczające pliki PDF. Niezależnie od tego, czy jesteś artystą, freelancerem czy przedsiębiorcą, ta funkcja pozwala Ci prezentować \nswoje prace lub projekty w atrakcyjny wizualnie sposób.\n",
    "Add/Manage Portfolio": "Dodaj/Zarządzaj Portfolio",
    "Add New": "Dodaj Nowe",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maksymalizuj swoje zarobki z mediów społecznościowych z Adresem Płatności InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Ułatw swojej publiczności wspieranie Ciebie:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Łatwo zintegruj swój Adres Płatności InstaPay z postami w mediach społecznościowych.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Łatwo skopiuj i wklej link swojego adresu płatności do każdego ze swoich postów, historii lub na widocznym miejscu w profilu.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Idealne dla influencerów, artystów, przedsiębiorców lub każdego, kto ma społeczność chętną do okazania wsparcia.",
    "Instant Support, Endless Possibilities:": "Natychmiastowe wsparcie, nieograniczone możliwości:",
    "Transform the way you monetize your social media content.": "Zmieniaj sposób monetyzacji treści w mediach społecznościowych.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Pozwól swoim obserwatorom wysyłać Ci fundusze natychmiast jednym kliknięciem, bezpośrednio z ich ulubionych sieci społecznościowych.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Idealne do wsparcia w czasie rzeczywistym podczas transmisji na żywo, jako system napiwków dla Twoich kreatywnych treści lub do finansowania \nTwojego kolejnego dużego projektu.\n",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Gdzie umieścić swój Adres Płatności InstaPay, aby osiągnąć maksymalny wpływ:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Profile w mediach społecznościowych: Przypnij go do swojej biografii lub sekcji o sobie dla stałej widoczności",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Posty i ogłoszenia: Włącz go do swoich indywidualnych postów, szczególnie tych podkreślających Twoją pracę lub konkretne wezwania do wsparcia.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Historie i Reelsy: Zintegruj go ze swoimi interaktywnymi historiami lub fascynującymi filmikami, aby natychmiastowo zbierać wkłady.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Transmisje na żywo: Udostępniaj go podczas swoich sesji NA ŻY",
    "Simple, Fast, and Secure:": "Proste, szybkie i bezpieczne:",
    "No complex setup – get started in moments.": "Bez skomplikowanej konfiguracji – zacznij w kilka chwil.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Ciesz się bezpieczeństwem i szybkością zaufanego procesu płatności InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Odbieraj płatności od każdego, wszędzie, bez konieczności posiadania konta w InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Podnieś swoje możliwości w mediach społecznościowych dzięki Adresowi Płatności InstaPay.",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Wirtualny Adres Płatności)",
    "Payment Address Title": "Tytuł Adresu Płatności",
    "Currency": "Waluta",
    "Payment Address Description": "Opis Adresu Płatności",
    "Update": "Aktualizuj",
    "Updating": "Aktualizowanie",
    "Total Transaction": "Łączna transakcja",
    "Last 30 days": "Ostatnie 30 dni",
    "Last 1 month": "Ostatni 1 miesiąc",
    "Last 1 year": "Ostatni 1 rok",
    "Custom Date": "Niestandardowa data",
    "Payment Methods": "Metody płatności",
    "Payment Types": "Typy płatności",
    "Requested Amount": "Żądana kwota",
    "Quoted Amount": "Podana kwota",
    "About Me": "O mnie",
    "Followers": "Obserwujący",
    "InstaPay ID": "ID InstaPay",
    "Download QR Code": "Pobierz kod QR",
    "Initiate A Payment": "Zainicjuj płatność",
    "Report this person": "Zgłoś tę osobę",
    "Add now": "Dodaj teraz",
    "Recent Reviews": "Najnowsze recenzje",
    "Reviews As Seller": "Recenzje jako sprzedający",
    "Reviews As Buyer": "Recenzje jako kupujący",
    "Select Language": "Wybierz język",
    "Profile Completion": "Zakończenie profilu",
    "Profile Completed": "Profil ukończony",
    "Basic Info": "Podstawowe informacje",
    "Username": "Nazwa użytkownika",
    "Gender": "Płeć",
    "Male": "Mężczyzna",
    "Female": "Kobieta",
    "-Select-Gender-": "-Wybierz płeć-",
    "Date Of Birth": "Data urodzenia",
    "Address": "Adres",
    "Edit Profile": "Edytuj profil",
    "Update Profile": "Zaktualizuj profil",
    "Updating Profile": "Aktualizowanie profilu",
    "Complete Now": "Zakończ teraz",
    "Password": "Hasło",
    "Change Password": "Zmień hasło",
    "New Password": "Nowe hasło",
    "Re-enter New Password": "Wprowadź ponownie nowe hasło",
    "Please follow this guide for a strong password": "Proszę stosować się do tej instrukcji, aby stworzyć silne hasło",
    "Include at least one special character.": "Zawierać co najmniej jeden znak specjalny.",
    "Minimum of 8 characters.": "Minimum 8 znaków.",
    "Include at least one number": "Zawierać co najmniej jedną cyfrę",
    "Change it often for enhanced security.": "Często zmieniaj dla zwiększenia bezpieczeństwa.",
    "Security Questions": "Pytania bezpieczeństwa",
    "Security Question": "Pytanie zabezpieczające",
    "Select your question": "Wybierz swoje pytanie",
    "Answer To The Question": "Odpowiedź na pytanie",
    "Nominee": "Nominowany",
    "Relationship with Nominee": "Relacja z nominowanym",
    "Nominee Contact Number": "Numer kontaktowy nominowanego",
    "Nominee Address": "Adres nominowanego",
    "Delete Nominee": "Usuń nominowanego",
    "Update Nominee": "Zaktualizuj nominowanego",
    "Details Updated Successfully!": "Szczegóły zaktualizowane pomyślnie!",
    "Success": "Sukces",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Możesz wybrać osobę nominowaną według własnego uznania, pod warunkiem, że nie jest to osoba niepełnoletnia, za pośrednictwem platformy InstaPay. \nPotwierdzasz, że nie jest wymagany mokry podpis do mianowania osoby nominowanej, a rejestracja online będzie uważana za ostateczną i wiążącą. \nW przypadku śmierci posiadacza portfela InstaPay, saldo w portfelu zostanie przekazane zarejestrowanej osobie nominowanej. \nKEMIT KINGDOM zostanie zwolnione ze wszystkich swoich zobowiązań po przekazaniu kwoty osobie nominowanej. Nominowany dostarczy wszystkie \ndokumenty wymagane przez InstaPay, w tym te do identyfikacji i potwierdzenia śmierci.",
    "Add Nominee": "Dodaj Nominowanego",
    "Two Factor Authentication": "Uwierzytelnianie Dwuskładnikowe",
    "SMS Number": "Numer SMS",
    "Updated Successfully!": "Pomyślnie zaktualizowano!",
    "Social Network Accounts": "Konta w Sieciach Społecznościowych",
    "Here, you can set up and manage your integration settings.": "Tutaj możesz skonfigurować i zarządzać ustawieniami integracji.",
    "Social Network Account": "Konto w Sieci Społecznościowej",
    "Activate Account": "Aktywuj Konto",
    "Enter the code below in the InstaPay Chatbot.": "Wprowadź poniższy kod w Chatbocie InstaPay.",
    "The code will expire in ": "Kod wygaśnie za",
    "Notifications": "Powiadomienia",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Dostosuj sposób otrzymywania powiadomień. Te ustawienia dotyczą monitorowanych przez Ciebie działań",
    "Activity": "Aktywność",
    "Email": "Email",
    "Push": "Powiadomienia Push",
    "SMS": "SMS",
    "Source": "Źródło",
    "Payment Requests": "Zapytania o płatność",
    "Bulk Payments": "Płatności masowe",
    "Identity Verification": "Weryfikacja Tożsamości",
    "Verify Your Identity": "Zweryfikuj swoją tożsamość",
    "Identity Verification Status:": "Status Weryfikacji Tożsamości:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Krótko opisz siebie i wyjaśnij swoje powody korzystania z InstaPay.",
    "Enter Your Message": "Wprowadź swoją wiadomość",
    "Select Occupation": "Wybierz zawód",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Musisz mieć dokument tożsamości wydany przez rząd z zdjęciem. Wszystkie dokumenty tożsamości muszą być napisane w alfabecie łacińskim. \nW przeciwnym razie wymagane jest uwierzytelnione tłumaczenie.",
    "Accepted Documents": "Akceptowane dokumenty",
    "A national identity card": "Krajowa karta tożsamości",
    "A valid passport": "Ważny paszport",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Ważne pozwolenie na pobyt (akceptowane tylko, jeśli wydane przez kraj europejski lub w Szwajcarii)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometryczne rozpoznawanie twarzy: porównanie wideo z fotografią w paszporcie",
    "Address Verification:": "Weryfikacja Adresu:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Dostarcz wyciąg bankowy lub rachunek za media (młodszy niż 3 miesiące) jako dowód adresu",
    "Additional Documents:": "Dodatkowe dokumenty:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom zastrzega sobie prawo do żądania dodatkowych dokumentów (Zwiększona Staranność - EDD) lub dalszych informacji niezbędnych \ndo przeprowadzenia dokładnych weryfikacji, zgodnie z obowiązującymi przepisami prawnymi.",
    "Start Verification": "Rozpocznij weryfikację",
    "Sessions": "Sesje",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "To jest lista urządzeń, które zalogowały się na twoje konto. Usuń te, które nie rozpoznajesz.",
    "Your Withdrawal Options": "Twoje Opcje Wypłaty",
    "Choose your preferred method for withdrawing payments.": "Wybierz preferowaną metodę wypłaty płatności.",
    "No withdrawal options currently available in your country.": "Obecnie brak opcji wypłaty w twoim kraju.",
    "Download Your Data": "Pobierz swoje dane",
    "Request a Copy of Your Data": "Poproś o kopię swoich danych",
    "Submit a request to download a copy of your data": "Złóż wniosek o pobranie kopii swoich danych",
    "Which Data Should Be Included in the Download?": "Jakie dane powinny być zawarte w pobraniu?",
    "Select all applicable options.": "Wybierz wszystkie odpowiednie opcje.",
    "Personal Information": "Dane osobiste",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Te informacje służą do zabezpieczenia twojego konta, dostosowania doświadczenia i kontaktowania się z tobą w razie potrzeby.",
    "Name and Date Of Birth": "Imię i nazwisko oraz data urodzenia",
    "Phone number": "Numer telefonu",
    "Financial Information": "Informacje finansowe",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Korzystamy z tych informacji, aby umożliwić szybsze płatności i wysyłać lub odbierać pieniądze w kilka kliknięć.",
    "Bank accounts": "Konta bankowe",
    "Debit or credit cards": "Karty debetowe lub kredytowe",
    "Credit Information": "Informacje o kredycie",
    "Includes your credit application information": "Zawiera informacje o wniosku kredytowym",
    "Other Information (not included in file)": "Inne informacje (nie zawarte w pliku)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Zawiera informacje o urządzeniu, dane techniczne użytkowania, informacje geolokalizacyjne, preferencje marketingowe, historię zgód,\noraz dane używane do innych usług, takich jak kredyt, weryfikacja tożsamości, komunikacja z PayPal i procesory stron trzecich.",
    "Choose file type:": "Wybierz typ pliku:",
    "Select file type": "Wybierz typ pliku",
    "PDF file can be easily opened on any computer": "Plik PDF można łatwo otworzyć na każdym komputerze",
    "Submit Request": "Wyślij wniosek",
    "Delete Your Account": "Usuń swoje konto",
    "Ask us to delete your data and close this account": "Poproś nas o usunięcie twoich danych i zamknięcie tego konta",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Korzystamy z twoich danych do świadczenia usług, więc jeśli usuniemy twoje dane, musimy również zamknąć twoje konto.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Jeśli twoje konto jest ograniczone, przejdź do Centrum Rozwiązań, wybierz 'Przejdź do Ograniczeń Konta', a następnie kliknij 'Rozwiąż'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Upewnij się, że wszystkie nierozliczone płatności zostały rozliczone. Nie będziemy mogli zamknąć twojego konta, dopóki to się nie stanie.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Jeśli masz ujemne saldo, kliknij link Rozwiąż obok twojego salda.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Gdy twoje konto będzie czyste i jeśli będziesz spełniać warunki, zamkniemy twoje konto i usuniemy twoje dane. Musimy zachować niektóre twoje dane \nz powodów prawnych i regulacyjnych. Aby dowiedzieć się więcej, zobacz nasze ",
    "privacy statement": "oświadczenie o prywatności",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Wyślemy ci email, aby poinformować cię o statusie i ewentualnych kolejnych krokach, które musisz podjąć.",
    "Have more questions about data deletion?": "Masz więcej pytań dotyczących usuwania danych?",
    "Contact us": "Skontaktuj się z nami",
    "I understand that account deletion is permanent and can't be reversed.": "Rozumiem, że usunięcie konta jest nieodwracalne.",
    "Are you sure you want to close your account?": "Czy na pewno chcesz zamknąć swoje konto?",
    "Yes, Continue": "Tak, kontynuuj",
    "Logging In": "Logowanie",
    "Complete your profile to streamline your transaction process.": "Uzupełnij swój profil, aby usprawnić proces transakcji.",
    "Skip": "Pomiń",
    "Complete Your Profile": "Uzupełnij swój profil",
    "API Key": "Klucz API",
    "Get API Key": "Uzyskaj Klucz API",
    "Generating New API Key": "Generowanie Nowego Klucza API",
    "Generate API Key": "Wygeneruj Klucz API",
    "Regenerate API Key": "Wygeneruj ponownie Klucz API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Aby w pełni wykorzystać płynne transakcje InstaPay, wykonaj te szybkie kroki, aby wygenerować swój klucz API. Najpierw zarejestruj się na koncie pod \n[twoim adresem URL rejestracji] i uzyskaj dostęp do Panelu Dewelopera. Przejdź do sekcji 'Klucze API', wygeneruj nowy klucz i upewnij się, że jest \nbezpiecznie przechowywany.",
    "Delete API Key": "Usuń Klucz API",
    "Webhook URL": "URL Webhook",
    "KYB Verification": "Weryfikacja KYB",
    "Additional Files": "Dodatkowe Pliki",
    "Business Verification": "Weryfikacja Biznesu",
    "--Select-Your-Company-Type--": "--Wybierz-Typ-Twojej-Firmy--",
    "Documents Required:": "Wymagane Dokumenty:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Oficjalny Wyciąg z Rejestru Firm (Dokument Rejestracji/Inkorporacji Podmiotu)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Ten dokument służy jako oficjalna 'karta tożsamości' firmy i powinien być uzyskany z lokalnego rejestru biznesowego. \nOdbija obecny status firmy (aktywna lub zamknięta).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Dokument powinien być aktualny, z datą wydania nie przekraczającą 3 miesięcy.",
    "The document must include the following details:": "Dokument musi zawierać następujące szczegóły:\n",
    "Legal name of the company": "Prawna nazwa firmy",
    "Full registered address": "Pełny zarejestrowany adres\n",
    "Company registration number": "Numer rejestracji firmy",
    "Date of incorporation": "Data inkorporacji",
    "Description of the company's activities/purpose": "Opis działalności/purpose firmy",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Informacje o przedstawicielu prawnym: pełne imię i nazwisko, data i miejsce urodzenia, adres zamieszkania\n(minimalny wymóg: kraj zamieszkania) oraz narodowość",
    "If applicable, the document should also include:": "Jeśli to ma zastosowanie, dokument powinien również zawierać:\n",
    "Brand or trading names": "Nazwy handlowe lub marki",
    "Value Added Tax (VAT) number": "Numer podatku VAT",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Lista Ostatecznych Beneficjentów (UBOs) - jest to obowiązkowe w niektórych krajach, w przeciwnym razie",
    "Proof of Legal Representative's Authority": "Dowód Upoważnienia Przedstawiciela Prawnego",
    "This document validates an individual's authority to represent the company": "Ten dokument potwierdza uprawnienia osoby do reprezentowania firmy",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Zazwyczaj przedstawiciel prawny jest wymieniony w wyciągu z rejestru firmy. Jednak, jeśli tak nie jest,\nnależy zabezpieczyć dokument, taki jak Pełnomocnictwo lub Delegacja Upoważnień.",
    "Power of Attorney/Delegation of Powers:": "Pełnomocnictwo/Delegacja Upoważnień:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Osoba udzielająca upoważnienia musi być do tego prawnie uprawniona. Ta osoba powinna być wymieniona jako przedstawiciel prawny\nw wyciągu z rejestru firmy.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Dokumentacja Struktury Własności i Ostatecznych Beneficjentów (UBOs)\n",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Powinien zostać dostarczony dowód na notowanie firmy na odpowiedniej giełdzie papierów wartościowych. Może to być strona z witryny \ngiełdy wyświetlająca szczegóły firmy.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Jeśli mniej niż 75% akcji firmy jest publicznie posiadanych, wszyscy akcjonariusze posiadający lub kontrolujący więcej niż 25%\nakcji będą podlegać wymogom weryfikacji Ostatecznego Beneficjenta (UBO).",
    "Collection of Identification Documents": "Zbiór Dokumentów Tożsamości",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Dokument tożsamości musi być wydany przez rząd, zawierający zdjęcie osoby. Wszystkie dokumenty tożsamości muszą być napisane \nw alfabecie łacińskim. W przeciwnym razie wymagane jest uwierzytelnione tłumaczenie.",
    "Accepted documents:": "Akceptowane dokumenty:",
    "Valid National Identity Card": "Ważna Karta Tożsamości Narodowej",
    "Valid Passport": "Ważny Paszport",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Ważne Pozwolenie na Pobyt - Akceptowane tylko, jeśli wydane przez kraj europejski.",
    "Only legal representatives are subject to the identification document collection process.": "Tylko przedstawiciele prawni podlegają procesowi zbierania dokumentów tożsamości.",
    "Additional Requirement:": "Dodatkowe Wymagania:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "W przypadkach związanych z Pełnomocnictwem lub Delegacją Upoważnień, obie strony\n(osoba udzielająca upoważnienia i odbierająca je) muszą dostarczyć kopię swoich dokumentów tożsamości.",
    "Additional Documents": "Dodatkowe Dokumenty\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Rachunek za media (taki jak gaz, prąd, telefon stacjonarny lub komórkowy); lub dokument wydany przez departament rządowy\npokazujący adres i nazwisko użytkownika końcowego (np. Certyfikat Mieszkalny); lub",
    "Bank Statement; or": "Wyciąg Bankowy; lub\n",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "dokument wydany przez departament rządowy pokazujący adres i nazwisko użytkownika końcowego\n(np. Certyfikat Mieszkalny).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom zastrzega sobie prawo do żądania dodatkowych dokumentów lub dalszych informacji niezbędnych do przeprowadzenia\ndokładnych weryfikacji, zgodnie z obowiązującymi przepisami prawnymi.",
    "Note:": "Uwaga:",
    "Partners": "Partnerzy",
    "Add New Partner": "Dodaj Nowego Partnera",
    "Designation": "Nazwa",
    "Partner Name": "Nazwa Partnera",
    "Partner Email": "Email Partnera\n",
    "Partner Designation": "Oznaczenie Partnera",
    "Add Now": "Dodaj Teraz\n",
    "Adding": "Dodawanie",
    "Delete Partner": "Usuń Partnera\n",
    "Delete": "Usuń",
    "Deleting": "Usuwanie",
    "Are you sure, you want to remove sxz as partner?": "Czy na pewno chcesz usunąć sxz jako partnera?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Wyciąg z Rejestru Podmiotu (Dokument Rejestracji/Tworzenia Podmiotu)",
    "This document should be either:": "Ten dokument powinien być jednym z następujących:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Dowód utworzenia podmiotu, zawierający szczegóły dotyczące podmiotu, datę utworzenia i cel.",
    "A local registry extract:": "Wyciąg z rejestru lokalnego:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Wymaganym zgodnie z przepisami kraju, służącym jako aktualna 'karta tożsamości' podmiotu, wskazującym jego status\n(aktywny lub zamknięty).",
    "If a registry extract exists, it must not be older than 3 months.": "Jeśli istnieje wyciąg z rejestru, nie może być starszy niż 3 miesiące.\n",
    "Entity name": "Nazwa podmiotu\n",
    "Registration number (if applicable, based on the country's requirements)": "Numer rejestracji (jeśli dotyczy, w zależności od wymagań kraju)",
    "Date of creation": "Data utworzenia\n",
    "Description of the entity's activities or purpose": "Opis działalności lub celu podmiotu\n",
    "Trading names": "Nazwy handlowe\n",
    "Articles of Association": "Statut Spółki\n",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Statut Spółki pełni rolę dokumentu konstytucyjnego podmiotu, określając jego zasady działania.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Nie ma ograniczenia czasowego dotyczącego tego dokumentu, co oznacza, że wymagana jest najnowsza wersja. Zazwyczaj tego typu\npodmioty wymagają co najmniej jednego zebrania rocznego, które może skutkować zaktualizowaną wersją Statutu Spółki.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Przykłady zmian, które mogą nastąpić, obejmują aktualizacje nazwy podmiotu, adresu, celu, zarządu,\nról, obowiązków itd.",
    "The document should contain information about:": "Dokument powinien zawierać informacje o:",
    "The entity itself, including its legal name, registered address, and purpose": "Sam podmiot, w tym jego prawna nazwa, zarejestrowany adres i cel",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Wewnętrznych zasadach działania, takich jak proces powoływania członków zarządu, ich role i uprawnienia, wśród innych szczegółów. Ta lista nie jest \nwyczerpująca.",
    "How the entity is funded": "Jak jest finansowany podmiot",
    "Appointment/Removal/Changes on Board of Management Members:": "Powołanie/Usunięcie/Zmiany w Zarządzie:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Te aktualizacje zazwyczaj mają miejsce w oparciu o potrzeby podmiotu lub zgodnie ze Statutem Spółki. Zmiany są dokumentowane poprzez \nprotokoły z posiedzeń zarządu, które są rejestrowane i certyfikowane, aby odzwierciedlać zaktualizowaną listę członków, takich jak prezes, wiceprezes, \nskarbnik i sekretarz generalny.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Najnowsze protokoły z posiedzeń zarządu, w tym aktualna lista członków, będą wymagane wraz ze Statutem Spółki, jeśli jest dostępny.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Uwaga: Jeśli aktualna lista członków zarządu nie jest zawarta w najnowszym Statucie Spółki i nie ma dostępnych protokołów z posiedzeń zarządu, \nta lista musi zostać dostarczona. Powinna być datowana i podpisana przez najnowszego prawnie mianowanego przedstawiciela, którego powołanie \nmożna zweryfikować za pomocą odpowiedniej dokumentacji.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Ten dokument potwierdza uprawnienia osoby do reprezentowania podmiotu. Może to być:",
    "The latest Articles of Association": "Najnowszy Statut Spółki",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Aktualna lista członków zarządu\n(która może być zawarta w protokołach z posiedzeń zarządu)",
    "The document should specify:": "Dokument powinien określać:",
    "Full name of the individual(s)": "Pełne imię i nazwisko osoby/osób",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Funkcja osoby/osób. Te informacje będą weryfikowane krzyżowo ze Statutem Spółki, aby zapewnić, że funkcja osoby pozwala jej zobowiązywać \npodmiot. Jest to szczególnie ważne, gdy wnioskodawca nie jest prezesem, a inne funkcje mają ograniczone uprawnienia reprezentacji, \njak stwierdzono w Statucie Spółki.",
    "Additional information about the legal representative that will be collected includes:": "Dodatkowe informacje o przedstawicielu prawnym, które będą zbierane, obejmują:",
    "Date and place of birth": "Datę i miejsce urodzenia",
    "Residential address (at least the country of residence should be stated)": "Adres zamieszkania (przynajmniej kraj zamieszkania powinien być podany)",
    "Nationality": "Obywatelstwo",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Struktura Właśności & Ostateczni Beneficjenci (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Zazwyczaj tego typu podmioty, biorąc pod uwagę ich strukturę i cel, nie mają beneficjentów. Jednakże, członkowie zarządu będą identyfikowani \ni traktowani jako tacy do celów przesiewowych, zbierania i weryfikacji tożsamości itp.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Niewielka liczba stowarzyszeń może mieć beneficjentów, szczególnie te generujące zyski. W takich przypadkach ten szczegół zostanie wymieniony \nw Statucie Spółki. Ci ludzie będą łatwo identyfikowani i traktowani jako tacy, obok członków zarządu.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Dodatkowe Wymaganie: W przypadkach związanych z Pełnomocnictwem lub Delegacją Upoważnień, \nobie strony (osoba udzielająca upoważnienia i odbierająca je) muszą dostarczyć kopię swoich dokumentów tożsamości.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Wyciąg z Rejestru Jednoosobowej Działalności Gospodarczej (Dokument Rejestracji/Tworzenia Podmiotu)\n",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Ten dokument służy jako lokalny rejestr dla przedsiębiorców indywidualnych, wymagany w krajach, gdzie taka rejestracja jest obowiązkowa.\nPotwierdza, że osoba prowadzi działalność zawodową jako jedyny przedsiębiorca. Nazwa tego dokumentu różni się w zależności od kraju.",
    "Where applicable, the document should not be older than 3 months.": "Jeśli to możliwe, dokument nie powinien być starszy niż 3 miesiące.",
    "The document must contain:": "Dokument powinien zawierać:",
    "Individual's name": "Imię i nazwisko osoby",
    "Residential address": "Adres zamieszkania",
    "Registration number": "Numer rejestracyjny",
    "The sole trader is the only individual subject to the identification document collection process.": "Jedyny przedsiębiorca jest jedyną osobą podlegającą procesowi zbierania dokumentów tożsamości.",
    "No Transactions": "Brak Transakcji",
    "No Pending Items": "Brak Oczekujących Przedmiotów",
    "No Beneficiaries": "Brak Beneficjentów",
    "Send Money To": "Wyślij Pieniądze",
    "Confirmation": "Potwierdzenie",
    "You are setting": "Ustawiasz",
    "as default.": "jako domyślne.",
    "Previously": "Wcześniej",
    "was default QR.": "był domyślnym kodem QR.",
    "Category": "Kategoria",
    "-Select-From-The-List-": "-Wybierz-Z-Listy-",
    "Add a note": "Dodaj notatkę",
    "Categories Added Successfully!": "Kategorie Dodane Pomyślnie!",
    "Subscription starting date": "Data rozpoczęcia subskrypcji",
    "Until": "Do",
    "Next Transaction": "Następna Transakcja",
    "Transaction Method": "Metoda Transakcji",
    "Accept the quotation": "Zaakceptuj wycenę",
    "Choose Currency": "Wybierz Walutę",
    "Accept": "Zaakceptuj",
    "Bargain the quotation": "Targuj wycenę",
    "Enter Counter Amount:": "Wpisz Kwotę Przeciwną:",
    "Bargain": "Targuj",
    "Decline the quotation": "Anuluj wycenę",
    "Are you sure, you want to decline this quotation?": "Czy jesteś pewien, że chcesz odrzucić tę wycenę?",
    "Decline": "Odrzuć",
    "Personal Account": "Konto Osobiste",
    "Jan": "styć",
    "Feb": "lut",
    "Mar": "mar",
    "Apr": "kwie",
    "May": "maj",
    "Jun": "cze",
    "Jul": "lip",
    "Aug": "sierp",
    "Sep": "wrz",
    "Oct": "paź",
    "Nov": "lis",
    "Dec": "gru",
    "Today": "Dziś",
    "Spent In": "Spędzone w",
    "Revised Amount": "Skorygowana kwota",
    "You spent more comparing to last month": "Wydałeś więcej w porównaniu z poprzednim miesiącem",
    "Last": "Ostatni",
    "days": "Dni",
    "Year": "Lata",
    "Instant Mobile Top-up Across 150+ Countries": "Natychmiastowe doładowanie telefonu w ponad 150 krajach",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Ciesz swoich bliskich lub doładuj własny telefon, niezależnie od tego, gdzie jesteś! Usługa doładowań komórkowych InstaPay pozwala na natychmiastowe doładowanie telefonów w ponad 150 krajach. Idealne na prezent lub aby zawsze być w kontakcie, nasza usługa jest zaprojektowana dla wygody, szybkości i bezpieczeństwa.",
    "How It Works - Step-by-Step Guide:": "Jak to działa - Przewodnik krok po kroku:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Wybierz 'Doładowanie telefonu': Uzyskaj dostęp do tej funkcji bezpośrednio w aplikacji InstaPay na platformach takich jak WhatsApp, Twitter, Telegram czy Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Wybierz walutę i kwotę: Wybierz spośród wielu walut (USD, GBP, EUR itp.) i określ, ile chcesz doładować.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Wprowadź numer telefonu: Wpisz numer telefonu, który chcesz doładować—twój lub bliskiej osoby. Nasz system zapewnia bezpieczny i szybki proces.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Potwierdź i zapłać: Sprawdź szczegóły doładowania, potwierdź i dokonaj płatności wybraną metodą. Transakcja przebiega natychmiastowo i bezpiecznie.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Otrzymaj natychmiastowe potwierdzenie: Zarówno ty, jak i odbiorca (jeśli dotyczy) otrzymacie powiadomienie o potwierdzeniu, gdy doładowanie się powiedzie.",
    "Benefits:": "Korzyści:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Zasięg globalny: Łatwo wysyłaj doładowania do ponad 150 krajów.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Natychmiastowy efekt: Zobacz korzyści natychmiast — idealne na prezenty na ostatnią chwilę lub pilne komunikacje.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Pełne bezpieczeństwo: Ciesz się spokojem dzięki naszemu bezpiecznemu systemowi płatności.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Wszechstronne opcje płatności: Wybierz spośród kart kredytowych, PayPal lub portfela InstaPay do płatności.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Gotowy, aby rozprowadzać radość lub pozostać w kontakcie? Doładuj teraz i uszczęśliw dnia kogoś — lub swój własny!",
    "Pick a language": "Wybierz język",
    "French": "Francuski",
    "Spanish": "Hiszpański",
    "Portuguese": "Portugalski",
    "German": "Niemiecki",
    "Ukrainian": "Ukraiński",
    "Italian": "Włoski",
    "Russian": "Rosyjski",
    "Arabic": "Arabski",
    "Polish": "Polski",
    "Dutch (Nederlands)": "Holenderski",
    "Yoruba": "Joruba",
    "Indonesian": "Indonezyjski",
    "Turkish": "Turecki",
    "Swahili": "Suahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chiński",
    "Select Your Preferred Language": "Wybierz preferowany język",
    "Please select the language you would \nlike to use for the eKYC process.": "Wybierz język, którego chcesz używać do procesu eKYC.",
    "Select Your Nationality": "Wybierz swoją narodowość",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Wybierz swoją narodowość, aby zobaczyć obsługiwane dokumenty tożsamości dla twojego kraju.",
    "Grant permission to use the camera.": "Zezwól na użycie kamery.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Dotknij lub kliknij \"Zezwól\", gdy pojawi się monit o przyznanie dostępu do kamery w celu zrobienia zdjęcia dokumentu.",
    "Upload Your Documents from \nGallery/Photos": "Prześlij swoje dokumenty z galerii/zdjęć",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Jeśli masz problemy z kamerą lub jeśli twój system jej nie ma, możesz ręcznie przesłać zdjęcia dokumentu z lokalnej pamięci.",
    "Upload Your ID.": "Prześlij swój dowód tożsamości.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Upewnij się, że twój dowód tożsamości jest wyraźny i nie rozmazany, aby zapewnić szybką weryfikację.",
    "Valid Proof of Address": "Ważny Dowód Adresu",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Upewnij się, że twój dowód adresu jest aktualny i że data wyciągu jest w ciągu ostatnich trzech miesięcy od daty rozpoczęcia twojej weryfikacji eKYC.\n\n2. Upewnij się, że twój dowód adresu jest wyraźny i nie rozmazany, aby zapewnić szybką weryfikację.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Ważna informacja\n\nProszę dokładnie zapoznać się ze wszystkimi krokami tego przewodnika przed przystąpieniem do weryfikacji eKYC.\n\nUnikaj częstych błędów:\n\n* Upewnij się, że wszystkie twoje dokumenty są czytelne, wyraźne i aktualne.\n* Sprawdź, czy podane przez ciebie informacje są zgodne z danymi na twoich oficjalnych dokumentach.\n* Postępuj zgodnie z instrukcjami, aby uniknąć opóźnień lub odrzuceń.\n\nUwaga: \nJeśli twoja weryfikacja eKYC nie powiedzie się i będziesz musiał ją ponownie złożyć, zostaną naliczone dodatkowe opłaty za ponowne złożenie.\n\nPrzestrzegając tych wytycznych, możesz zapewnić płynne i udane przeprowadzenie weryfikacji eKYC.\n\nW przypadku pytań lub potrzebnej pomocy skontaktuj się z naszym zespołem wsparcia pod adresem support@insta-pay.ch",
    "Personal Transactions": "Transakcje osobiste",
    "Business Transactions": "Transakcje biznesowe",
    "Shopping & Purchases": "Zakupy i zakupy",
    "Bills & Utilities": "Rachunki i usługi",
    "Entertainment & Leisure": "Rozrywka i wypoczynek",
    "Investments & Savings": "Inwestycje i oszczędności",
    "Health & Wellness": "Zdrowie i dobre samopoczucie",
    "Transportation": "Transport",
    "Education": "Edukacja",
    "Miscellaneous": "Różne",
    "Family Support": "Wsparcie rodziny",
    "WALLET": "WALLET",
    "Add a currency": "Dodaj walutę",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Kiedy dodajesz walutę, wszystkie płatności, które otrzymasz w tej walucie, zostaną zaksięgowane na twoim saldzie InstaPay. Twoja główna waluta będzie używana do wysyłania lub żądania płatności, chyba że określono inaczej.",

    "balance amount ": "kwota salda",
    "referral ": "polecenie",
    "crypto wallet balance": "saldo portfela kryptowalut",
    "money in": "pieniądze przychodzące",
    "money out": "pieniądze wychodzące",
    "analytics": "analizy",
    "see more": "zobacz więcej",
    "manage my Wallets and see the transaction details": "zarządzać moimi Wallets i zobaczyć szczegóły moich transakcji",
    "set as default Wallet": "ustawić jako domyślny Wallet",
    "add currency": "dodaj walutę",
    "convert funds ": "konwertuj środki",
    "withdraw balance": "wypłać saldo",
    "chart names": "nazwy wykresów",
    "withdraw balance from PKR wallet": "wypłać saldo z portfela PKR",
    "select a payout channel": "wybierz kanał wypłaty",
    "you have entered an amount below the minimum payment range": "wpisałeś kwotę poniżej minimalnego zakresu płatności",
    "change": "zmienić",
    "edit": "edytować",
    "moving to next step in ...": "przejście do następnego kroku za ...",
    "you are withdrawing ...": "wypłacasz ...",
    "where is my payment": "gdzie jest moja płatność",
    "download": "pobierz",
    "IBAN / SWIFT Code": "IBAN lub kod SWIFT/BIC",
    "swift code": "kod SWIFT",
    "account number": "numer konta",
    "branch street": "ulica oddziału",
    "city": "miasto",
    "province": "województwo",
    "postal code": "kod pocztowy",
    "all payout channels": "wszystkie kanały wypłat",
    "bussiness beneficiary": "beneficjent biznesowy",
    "no quotation": "brak oferty",
    "no pending items": "brak oczekujących elementów",
    "QR status": "Status QR",
    "Portfolio": "Portfel",
    "edit whole portfolio page": "edytuj całą stronę portfela",
    "personal account": "konto osobiste",
    "Chart names": "Nazwy wykresów",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "więcej",
    "not connected": "nie połączony",
    "code": "kod",
    "ratings": "oceny",
    "current password": "obecne hasło",
    "include at least one uppercase and lowercase": "zawierać co najmniej jedną wielką i małą literę",
    "my social network accounts": "moje konta w sieciach społecznościowych",
    "SMS ": "SMS",
    "view identity verfication guide": "zobacz przewodnik weryfikacji tożsamości",
    "boimetric face recognition": "biometryczne rozpoznawanie twarzy",
    "please select a payer (withdrawl options)": "proszę wybrać płatnika (opcje wypłaty)",
    "IBAN number ": "numer IBAN",
    "account holder name": "nazwa posiadacza konta",
    "download data": "pobierz dane",
    "bank transfer": "przelew bankowy",
    "mobile wallet": "portfel mobilny",
    "all bank names": "wszystkie nazwy banków",
    "pay via card": "zapłać kartą",
    "you have entered an amount that is below the payer range": "wprowadziłeś kwotę poniżej zakresu płatnika",
    "trouble sending money": "problem z wysyłaniem pieniędzy",
    "please follow these example": "proszę postępować zgodnie z tymi przykładami",
    "wallet ID ": "ID Wallet",
    "username": "nazwa użytkownika",
    "email": "email",
    "phone number": "numer telefonu",
    "no user found": "nie znaleziono użytkownika",
    "search": "szukaj",
    "scan/upload QR code of the recipient": "zeskanuj/prześlij kod QR odbiorcy",
    "invite them": "zaprosić ich",
    "trouble quoting money": "problem z wyceną pieniędzy",
    "select a country": "wybierz kraj",
    "airtime details": "szczegóły doładowania",
    "enter the phone number": "wpisz numer telefonu",
    "service type": "typ usługi",
    "select a service": "wybierz usługę",
    "service list": "lista usług",
    "amount entered is less than the ....": "wprowadzona kwota jest mniejsza niż ....",
    "total": "całkowity",
    "total credit the receiver gets": "całkowity kredyt, który otrzymuje odbiorca",
    "take ": "weź",
    "OTP has been verified , You'll be redirected......": "OTP został zweryfikowany, zostaniesz przekierowany......",
    "Link Your Instagram Account to InstaPay": "Połącz swoje konto Instagram z InstaPay",
    "Step 01 – From Instagram App": "Krok 01 – Z aplikacji Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Otwórz aplikację Instagram, znajdź stronę profilu \"InstaPay\", wybierz \"Wiadomość\", aby otworzyć okno czatu, wpisz/powiedz \"Cześć\" i kliknij wyślij, aby rozpocząć rozmowę z chatbotem InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Krok 02 – Ze strony profilu Instagram InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot InstaPay wyświetli dwa przyciski akcji: \"Zarejestruj się\" i \"Połącz\". Wybierz \"Połącz\" i chatbot poprosi Cię o wprowadzenie nazwy użytkownika InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Krok 03 – Ze strony profilu Instagram InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot InstaPay poprosi o kod weryfikacyjny InstaPay. Wprowadź kod, który otrzymałeś w ustawieniach InstaPay w sekcji \"Moje konta w mediach społecznościowych\" i kliknij wyślij.",
    "Step 04 – From InstaPay Instagram Profile Page": "Krok 04 – Ze strony profilu Instagram InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Otrzymasz wiadomość z gratulacjami potwierdzającą, że Twoje konto Instagram zostało teraz zsynchronizowane z InstaPay. Kliknij \"Menu główne\", aby rozpocząć korzystanie z usług InstaPay.",
    "Additional Tips": "Dodatkowe wskazówki",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Zabezpiecz swoje konto: Upewnij się, że Twoje konto Instagram jest zabezpieczone poprzez włączenie uwierzytelniania dwuskładnikowego (2FA), aby chronić powiązane usługi InstaPay.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Odkryj więcej: Po połączeniu odkryj szeroką gamę usług oferowanych przez InstaPay, od bezproblemowych transakcji po natychmiastowe wypłaty.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Bądź na bieżąco:Śledź nasze aktualizacje, ponieważ wkrótce będziemy wspierać łączenie innych kont w mediach społecznościowych, aby uczynić Twoje doświadczenie jeszcze bardziej zintegrowanym.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Pomoc i wsparcie:Jeśli napotkasz jakiekolwiek problemy podczas procesu łączenia, skontaktuj się z naszym zespołem wsparcia, aby uzyskać pomoc.",
    "Future Enhancements": "Przyszłe ulepszenia",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "W niedalekiej przyszłości InstaPay pozwoli na połączenie większej liczby kont w mediach społecznościowych, zapewniając jeszcze większą elastyczność i wygodę. Śledź ekscytujące aktualizacje!",
    "Personal Transactions": "Transakcje osobiste",
    "Business Transactions": "Transakcje biznesowe",
    "Shopping & Purchases": "Zakupy i zakupy",
    "Bills & Utilities": "Rachunki i usługi",
    "Entertainment & Leisure": "Rozrywka i wypoczynek",
    "Investments & Savings": "Inwestycje i oszczędności",
    "Health & Wellness": "Zdrowie i dobre samopoczucie",
    "Transportation": "Transport",
    "Education": "Edukacja",
    "Miscellaneous": "Różne",
    "Family Support": "Wsparcie rodziny",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Rozpocznij swoją przygodę z InstaPay już dziś!",
    "Enter your phone number": "Wprowadź swój numer telefonu",
    "Login with email": "Zaloguj się za pomocą e-maila",
    "Don't have an account?": "Nie masz konta?",
    "Sign Up": "Zarejestruj się",
    "Continue": "Kontynuuj",
    "We recommend you download and install the Google Authenticator app": "Zalecamy pobranie i zainstalowanie aplikacji Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Uwierzytelnianie dwuskładnikowe (2FA) to najlepszy sposób na ochronę w Internecie.",
    "Strong security with Google Authenticator": "Silne zabezpieczenie z Google Authenticator",
    "Enter your password": "Wprowadź swoje hasło",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Zalecamy pobranie i zainstalowanie aplikacji Google Authenticator, aby zapewnić natychmiastowe otrzymywanie kodów weryfikacyjnych, unikając opóźnień lub problemów z SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Kod uwierzytelniania dwuskładnikowego",
    "Problem with the token?": "Problem z tokenem?",
    "Verify using SMS": "Użyj SMS do weryfikacji",
    "Verify": "Zweryfikować",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Weryfikacja SMS",
    "Enter the SMS code sent to +41********4053": "Wprowadź kod SMS wysłany na +41********4053",
    "Verify Using Authenticator App": "Użyj aplikacji Authenticator do weryfikacji",
    "Resend code in 120 seconds": "Wyślij kod ponownie za 120 sekund",
    "Verify ": "Zweryfikować",
    "SMS - Web Mobile": null,
    "My own account": "Moje własne konto",
    "Friends & Family": "Przyjaciele i Rodzina",
    "Aunt": "Ciotka",
    "Brother-in-law": "Szwagier",
    "Cousin": "Kuzyn",
    "Daughter": "Córka",
    "Father": "Ojciec",
    "Father-in-law": "Teść",
    "Friend": "Przyjaciel",
    "Grandfather": "Dziadek",
    "Grandmother": "Babcia",
    "Husband": "Mąż",
    "Mother": "Matka",
    "Mother-in-law": "Teściowa",
    "Nephew": "Siostrzeniec",
    "Niece": "Siostrzenica",
    "Self (i.e. the sender, himself)": "Ja sam (czyli nadawca)",
    "Sister": "Siostra",
    "Sister-in-law": "Szwagierka",
    "Son": "Syn",
    "Uncle": "Wujek",
    "Wife": "Żona",
    "Others not listed": "Inne niewymienione",
    "Merchant": "Sprzedawca",
    "No relationship": "Brak relacji",
    "Add Funds": "Dodaj środki",
    "Enter the Amount": "Wprowadź kwotę:",
    "Fees": "Opłaty:",
    "You Will Get": "Otrzymasz:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Wartość transakcji powinna wynosić od [Currency ISO code & Amount] do [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Nieprawidłowa kwota: Twój obecny poziom weryfikacji tożsamości ogranicza maksymalną kwotę, jaką możesz dodać. Proszę zaktualizować weryfikację tożsamości, aby skorzystać z wyższych limitów.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Cena waluty często zmienia się w zależności od warunków rynkowych. Proszę odnieść się do ceny na stronie potwierdzenia zamówienia jako ostatecznej wyceny.",
    "Next": "Dalej",
    "Upgrade Identity Verification": "Zaktualizuj weryfikację tożsamości",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Poproś o pieniądze od kogokolwiek, gdziekolwiek, w tym na platformach społecznościowych. Dzięki InstaPay twoje prośby o płatność są natychmiast dostępne po ich zaakceptowaniu, a środki są przesyłane na twoje konta odbiorcze. Uprość swoje transakcje i zarządzaj płatnościami z łatwością i bezpieczeństwem.",
    "You’ve been logged out.": "Zostałeś wylogowany.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Dla twojego bezpieczeństwa zostałeś wylogowany po 15 minutach bezczynności. Zaloguj się ponownie, jeśli chcesz korzystać z InstaPay.",
    "LOGIN AGAIN": "ZALOGUJ SIĘ PONOWNIE",
    "Brother": "Brat",
    "Current Password": "Obecne hasło",
    "The new password cannot be the same as the old one": "Nowe hasło nie może być takie samo jak stare.",
    "Minimum password length: [X] characters ": "Minimalna długość hasła: [X] znaków",
    "Include at least one uppercase and one lowercase letter.": "Uwzględnij co najmniej jedną wielką i jedną małą literę.",
    "Nominee Information": "Informacje o nominowanym",
    "Verification Code Preferences": "Preferencje kodu weryfikacyjnego",
    "How Others Can Find You": "Jak inni mogą Cię znaleźć",
    "My Social Network Accounts": "Moje konta w sieciach społecznościowych",
    "Login Activity": "Aktywność logowania",
    "Receiving Accounts": "Konta odbiorcze",
    "Change Your Password To Continue": "Zmień hasło, aby kontynuować",
    "Continue with": "Kontynuuj z",
    "Back to Login": "Wróć do logowania",
    "Send OTP": "Wyślij OTP",
    "A Verification code has been sent to your email and number.": "Kod weryfikacyjny został wysłany na Twój email i numer.",
    "Resend available in": "Ponowne wysłanie dostępne za",
    "Create a strong Password": "Stwórz silne hasło",
    "Password must:": "Hasło musi:",
    "Be between 9 and 64 characters": "Mieć od 9 do 64 znaków",
    "Include at least two of the following:": "Zawierać co najmniej dwa z następujących:",
    "Lowercase Character": "Mała litera",
    "Special Character": "Znak specjalny",
    "Password Updated Successfully!": "Hasło zostało zaktualizowane pomyślnie!",
    "Click below to login": "Kliknij poniżej, aby się zalogować",

    "Send Money": "Wyślij pieniądze",
    "Enter the amount": null,
    "Recent Transfer": "Ostatnie przelewy",
    "See all": "Zobacz wszystko",
    "Send again": "Wyślij ponownie",
    "You Send": "Wysyłasz",
    "They Receive": "Oni otrzymują",
    "Receive Method": "Metoda odbioru",
    "Select method": "Wybierz metodę",
    "Next": "Dalej",
    "Trouble sending money?": "Problemy z wysyłaniem pieniędzy?",
    "Where do you want to send money ?": "Gdzie chcesz wysłać pieniądze?",
    "Search ": "Szukaj",
    "Confirm": "Potwierdź",
    "Bank Deposit": "Depozyt Bankowy",
    "Cash Pick-up": "Odbiór Gotówki",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Karta Bankowa",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Jaki jest Wallet mobilny odbiorcy?",
    "Transfer fees": "Opłaty za przelew",
    "Total to pay": "Łączna kwota do zapłaty",
    "Choose the beneficiary!": "Wybierz beneficjenta!",
    "Country": "Kraj",
    "Receive method": "Metoda odbioru",
    "You send": "Wysyłasz",
    "change": "zmień",
    "edit": "dodaj nowy",
    "add new": "Beneficjenci",
    "Beneficiaries": "Zarządzaj beneficjentami",
    "Manage Beneficiaries": "Szukaj",
    "Search": "Dalej",
    "CASH PICK-UP": "ODBIÓR GOTÓWKI",
    "Where will your receiver collect the money": "Gdzie odbiorca odbierze pieniądze?",
    "Cash is available to collect within minutes from any branch of [name] ": "Gotówka jest dostępna do odbioru w ciągu kilku minut w dowolnym oddziale [nazwa]",
    "BANK ACCOUNT": "KONTO BANKOWE",
    "Transfer to recipient’s bank account": "Przelew na konto bankowe odbiorcy",
    "BANK CARD": "KARTA BANKOWA",
    "Fast transfer directly to recipient’s bank card": "Szybki przelew bezpośrednio na kartę bankową odbiorcy",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Szybki przelew bezpośrednio na Wallet crypto",
    "Review transfer": "Przegląd przelewu",
    "Receiver's Details": "Dane odbiorcy",
    "Edit": "Edytuj",
    "Name": "Imię",
    "Reason for sending": "Powód wysyłki",
    "Sending": "Wysyłka",
    "Receiver gets": "Odbiorca otrzymuje",
    "Exchange rate": "Kurs wymiany",
    "Estimated time": "Szacowany czas",
    "Select Payment Type": "Wybierz rodzaj płatności",
    "Credit cards": "Karty kredytowe",
    "Domestic cards": "Karty krajowe",
    "Mobile Wallets": "Wallet mobilne",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KARTA KREDYTOWA",
    "Credit Card": "Karta kredytowa",
    "Select card": "Wybierz kartę",
    "Card Number": "Numer karty",
    "Enter the 16-digit card number on the card": "Wprowadź 16-cyfrowy numer karty",
    "Expiry Date": "Data wygaśnięcia",
    "Enter the expiration date of the card": "Wprowadź datę wygaśnięcia karty",
    "CVV Number": "Numer CVV",
    "Enter the 3 or 4 digit number on the card": "Wprowadź 3- lub 4-cyfrowy numer na karcie",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wybierz Wallet",
    "My Wallet": "Mój Wallet",
    "Conversion": "Konwersja",
    "Convert": "Konwertuj",
    "Your payment is confirmed": "Twoja płatność została potwierdzona",
    "Thanks for using InstaPay": "Dziękujemy za korzystanie z InstaPay",
    "Status": "Status",
    "Share": "Udostępnij",
    "Transfer created": "Przelew utworzony",
    "Payment received": "Płatność otrzymana",
    "Payment processed": "Płatność przetworzona",
    "Transfer successful": "Przelew udany",
    "It may take few hours for the funds to appear in [user name] account": "Może minąć kilka godzin, zanim środki pojawią się na koncie [nazwa użytkownika]",
    "Transfer failed": "Przelew nieudany",
    "Transaction Details": "Szczegóły transakcji",
    "Need help?": "Potrzebujesz pomocy?",
    "Service Details": "Szczegóły Usługi",
    "Funds Deposit Speed": "Dostępność Środków",
    "Cut-off Time": "Czas Zamknięcia",
    "Bank holiday list": "Lista Świąt Bankowych",
    "Threshold limits": "Limity Transakcyjne",
    "null": null,
    "Instant, Real Time": "Natychmiastowy, w czasie rzeczywistym",
    "Same day, T+1": "Tego samego dnia, T+1",
    "Non-instant, T+1": "Nienatychmiastowy, T+1",
    "Instant Payment, Real Time": "Natychmiastowa płatność, w czasie rzeczywistym",
    "Transaction processed before 3:00 PM Local time": "Transakcja przetworzona przed 15:00 czasu lokalnego",
    "Delivered the same day": "Dostarczone tego samego dnia",
    "Transactions processed post 3:00 PM": "Transakcje przetworzone po 15:00",
    "T+1 business days": "T+1 dni robocze",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Płatności dokonane w dni robocze przed 15:00 zostaną zaksięgowane na koncie bankowym odbiorcy do północy tego samego dnia.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Płatności dokonane w dni robocze po 15:00 zostaną zaksięgowane do północy następnego dnia.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Płatności dokonane w sobotę, niedzielę lub święto zostaną zaksięgowane na koncie do północy następnego dnia roboczego.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 czasu lokalnego",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Minimalny limit na transakcję:",
    "Maximum per transaction limit:": "Maksymalny limit na transakcję:",
    "Daily threshold limit:": "Dzienne limity:",
    "Weekly threshold limit:": "Tygodniowe limity:",
    "Monthly threshold limit:": "Miesięczne limity:",
    "Yearly threshold limit:": "Roczne limity:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Podstawowe konto (Niezweryfikowane)",
    "Identity Verified": "Tożsamość zweryfikowana",

    "Good Evening!": "Dobry wieczór!",
    "Total Balance": "Całkowity stan konta",
    "Quick Transfer": "Szybki przelew środków",
    "No transactions": "Nie znaleziono transakcji",
    "Blocked balance": "Zablokowane środki",
    "Available balance": "Dostępny stan konta",
    "Insufficient Balance in PKR": "Niewystarczający stan konta w PKR",
    "Chat with us": "Czat z nami",
    "Add Currency": "Dodaj walutę",
    "Education": "Edukacja",
    "Divers": "Różne",
    "Family support": "Wsparcie rodziny",
    "Others": "Inne wydatki",
    "Transport": "Transport",
    "schedule payment": "Płatność zaplanowana",
    "subscription payment": "Płatność cykliczna",
    "No payment": "Nie znaleziono płatności",
    "No Beneficiaries": "Brak dostępnych beneficjentów",
    "Code Postal": "Kod pocztowy",
    "Additional Information": "Dodatkowe informacje",
    "Gender": "Płeć",
    "Date Of Birth": "Data urodzenia",
    "Nationality": "Narodowość",
    "Occupation": "Zawód (wszystkie opcje również)",
    "ID Type": "Typ dokumentu tożsamości (wszystkie opcje również)",
    "ID Number": "Numer dokumentu tożsamości",
    "Select Beneficiary Payment Channels": "Wybierz kanały płatnicze beneficjenta",
    "wallet crypto": "Portfel kryptowalutowy",
    "Add another wallet": "Dodaj inny portfel",
    "Enterprise": "Biznes",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "\"Beneficjenci biznesowi już wkrótce! Będziesz mógł przeprowadzać transakcje biznesowe na całym świecie, rozszerzając swoje zasięgi poza granice.\"",
    "Joined At": "Dołączył w dniu",
    "Profile Link": "Link do profilu",
    "comission": "Prowizja",
    "my withdrawls": "Moje wypłaty",
    "My Referrals": "Moje polecenia",
    "search quotation": "Szukaj ofert",
    "no quotation": "Nie znaleziono ofert",
    "actions": "Działania",
    "search request": "Wyszukaj zapytanie",
    "no pending items ": "Nie znaleziono oczekujących elementów",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "Udostępnij swój kod QR lub link do płatności, aby natychmiast otrzymać płatności do portfela InstaPay",
    "Description": "Opis",
    "save": "Zapisz",
    "Share your Insta-Pay unique payment link": "Udostępnij swój unikalny link do płatności InstaPay",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Podnieś swój publiczny profil. Twój portfel działa jako dynamiczna witryna dostępna bezpośrednio z twojego adresu płatniczego",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "\"Połącz się ze swoją publicznością poprzez swoją profesjonalną lub twórczą podróż. Niech twoja praca mówi sama za siebie i zaangażuje potencjalnych klientów lub sympatyków bardziej.\"",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "\"Wyświetl swój zasięg w mediach społecznościowych. Odwiedzający mogą zobaczyć twój wpływ w mediach społecznościowych, co dodaje wiarygodności twojemu portfelowi.\"",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "\"Pozwól odwiedzającym żądać lub inicjować płatności bezpośrednio ze swojej strony publicznej. Twój portfel to nie tylko galeria, ale również sposób na ułatwienie łatwych transakcji finansowych.\"",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Użyj sekcji \"O mnie\", aby podzielić się swoją historią, misją lub wizją, budując osobiste połączenie ze swoją publicznością.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"Mój portfel\" to coś więcej niż tylko galeria; to narzędzie do budowania marki, angażowania publiczności i upraszczania interakcji finansowych na InstaPay.",
    "No Portfolio Items": "Nie znaleziono elementów portfela",
    "Add New Portfolio Item": "Dodaj nowy element portfela",
    "Title": "Tytuł",
    "drag/drop or upload a file(photo/video/pdf)": "Przeciągnij/upuść lub załaduj plik (zdjęcie/wideo/pdf)",
    "discard": "Odrzuć",
    "save and post ": "Zapisz i opublikuj",
    "My Payment Address": "Mój adres płatniczy",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Idealny dla influencerów, artystów, przedsiębiorców lub każdego, kto chce, aby ich społeczność łatwo okazywała wsparcie.",
    "Personal Account": "Status konta osobistego",
    "spent in total": "Całkowite wydatki",
    "not connected": "Niepołączone",
    "more": "Więcej",
    "less": "Mniej",
    "profile reports": "Raporty profilu",
    "All notifications are in English langauge": "Wszystkie powiadomienia są w języku angielskim",
    "timezone": "Strefa czasowa",
    "nominee information": "Informacje o nominowanym",
    "full name": "Pełne imię i nazwisko",
    "Relation with Nominee": "Relacja z nominowanym",
    "source": "Źródło",
    "Google Authenticator": "Google Authenticator",
    "notifications": "Powiadomienia",
    "login": "Zaloguj się",
    "View Identity Verification Guide": "Zobacz przewodnik weryfikacji tożsamości",
    "verified": "Zweryfikowany",
    "select ": "Wybierz",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Musi to być dokument tożsamości wydany przez rząd ze zdjęciem. Wszystkie dokumenty muszą być zapisane w alfabecie łacińskim. W przeciwnym razie wymagana jest certyfikowana tłumaczenie.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom zastrzega sobie prawo do żądania dodatkowych dokumentów (Wzmożona Staranność - EDD) lub wszelkich informacji niezbędnych do przeprowadzenia pełnej weryfikacji, zgodnie ze swoimi zobowiązaniami prawnymi.",
    "sessions": "Sesje",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Odblokuj natychmiastowe wypłaty: Ustaw teraz swoje konta odbiorcze!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Łatwo zarządzaj, gdzie chcesz otrzymywać płatności, dodając szczegóły swoich kont bankowych, portfeli mobilnych, portfeli kryptowalutowych, kart płatniczych lub punktów odbioru gotówki. Postępuj zgodnie z poniższymi krokami, aby zapewnić sobie natychmiastowy dostęp do środków w razie potrzeby.",
    "Select the receiving channel below and setup the details.": "Wybierz poniżej kanał odbioru i skonfiguruj szczegóły.",
    "Please Select A Payer": "Proszę wybrać płatnika",
    "Number": "Numer",
    "Account Holder Name": "Imię i nazwisko posiadacza konta",
    "Province": "Prowincja",
    "postal": "Adres pocztowy",
    "delete channel": "Usuń kanał",
    "set as default": "Ustaw jako domyślny",
    "edit": "Edytuj",
    "all channels are in english language in recieving account page": "Wszystkie kanały są w języku angielskim na stronie kont odbiorczych",
    "select file type": "Wybierz typ pliku",
    "download data": "Pobierz dane",
    "INTERNATIONAL TRANSFER": "MIĘDZYNARODOWY PRZELEW",
    "enter the amount": "Wprowadź kwotę",
    "they recieve ": "Odbiorca otrzymuje",
    "trouble sending money": "Problemy z przesłaniem pieniędzy?",
    "select destination country": "Wybierz kraj docelowy",
    "bank transfer": "Przelew bankowy",
    "recieving method": "Metoda odbioru",
    "payer": "Płatnik",
    "The fee and the received amount may vary depending on the chosen Payment Method": "Opłaty i otrzymana kwota mogą się różnić w zależności od wybranej metody płatności.",
    "search ": "Szukaj",
    "show more": "Pokaż więcej",
    "select remark reasons": "Wybierz powody uwag",
    "credit and debit card": "Karty kredytowe i debetowe",
    "Transfer Fees": "Opłaty za przelew",
    "Estimated Time": "Szacowany czas:",
    "within minutes": "W ciągu kilku minut",
    "total ": "Suma",
    "OTP verifications code": "Kod weryfikacyjny OTP",
    "verify using sms/email": "Zweryfikuj za pomocą SMS/e-mail",
    "sms/email verification code": "Kod weryfikacyjny SMS/e-mail",
    "Verify Using Authenticator": "Zweryfikuj za pomocą Authenticatora",
    "WALLET TO WALLET TRANSFER": "PRZELEW Z PORTFELA DO PORTFELA",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Wprowadź ID portfela, nazwę użytkownika, adres e-mail lub numer telefonu odbiorcy.",
    "search": "Szukaj",
    "lookup": "Wyszukaj",
    "Recipient Not on InstaPay?": "Odbiorca nie ma InstaPay?",
    "invite them": "Zaproś ich",
    "Please follow these examples": "Postępuj zgodnie z poniższymi przykładami:",
    "Wallet ID: GY68J782": "ID portfela: GY68J782",
    "InstaPay/Instagram Username": "Nazwa użytkownika InstaPay/Instagram: instauser",
    "Email: user@email.com": "Adres e-mail: user@email.com",
    "With Country code": "Numer telefonu (z kodem kraju)",
    "invite friend": "Zaproś znajomego",
    "invite via phone number": "Zaproś przez numer telefonu",
    "invite via email address": "Zaproś przez adres e-mail",
    "next": "Dalej",
    "back": "Wstecz",
    "Personalized Message": "Wiadomość personalizowana",
    "available currency": "Dostępna waluta",
    "attach files": "Dołącz pliki",
    "total amount": "Całkowita kwota",
    "instant payment": "Płatność natychmiastowa",
    "starting from": "Początek od",
    "recurring cycle": "Cykl cykliczny",
    "until I stop": "Aż do zatrzymania",
    "start from": "Początek od",
    "cycle": "Cykl",
    "total": "Suma",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "Odbiorca może otrzymać nieco dostosowaną kwotę z powodu kursów wymiany.",
    "schedule to": "Zaplanowane na",
    "schedule at": "Zaplanowane o",
    "Scheduled Date": "Data zaplanowana",
    "Scheduled time": "Godzina zaplanowana",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "Odbiorca może otrzymać nieco dostosowaną kwotę z powodu kursów wymiany.",
    "QR CODE PAYMENT ": "PŁATNOŚĆ KODEM QR",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Alternatywnie, użyj alfanumerycznego kodu QR InstaPay.",
    "PYAYMENT REQUEST": "ŻĄDANIE PŁATNOŚCI",
    "choose the beneficiary": "Wybierz beneficjenta",
    "trouble requesting money": "Problemy z żądaniem pieniędzy?",
    "Standard Request": "Żądanie standardowe",
    "Subscription Request": "Żądanie cykliczne",
    "Schedule Request": "Żądanie zaplanowane",
    "Security Request": "Żądanie depozytu zabezpieczającego",
    "Instalment Request": "Plan ratalny",
    "Split Request": "Podzielona płatność",
    "You recieved": "Otrzymałeś",
    "is this your location?": "Czy to jest twoja lokalizacja?",
    "yes": "Tak",
    "Authenticator Code": "Kod Authenticatora",
    "Enter a code shown in the app to make sure everything works fine.": "Wprowadź kod pokazany w aplikacji, aby upewnić się, że wszystko jest poprawne.",
    "SEND A QUOTE": "WYŚLIJ OFERTĘ",
    "Trouble Quoting Money?": "Problemy z wysłaniem oferty?",
    "Please follow these examples:": "Postępuj zgodnie z poniższymi przykładami:",
    "Full Name: Muhammad Imtiaz": "Pełne imię i nazwisko: Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "Nazwa użytkownika InstaPay/Instagram: instauser",
    "Phone Number: 44795396600 (With Country Code)": "Numer telefonu: 44795396600 (z kodem kraju)",
    "SEND TOP-UP AND E-SIM": "WYŚLIJ DOŁADOWANIE I E-SIM",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Pozostań w kontakcie na całym świecie! Usługa doładowania InstaPay umożliwia natychmiastowe doładowanie telefonów, zakup eSIM, voucherów, kart podarunkowych oraz płatność rachunków za usługi publiczne w ponad 150 krajach. Idealna na prezent lub aby być przygotowanym, nasza usługa stawia na łatwość, szybkość i bezpieczeństwo.",
    "MOBILE AIRTIME AND E-SIM": "DOŁADOWANIE TELEFONICZNE I E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Wybierz kraj doładowania telefonu!",
    "Other Operator": "Inni operatorzy",
    "airtime": "Czas rozmów",
    "bundle": "Pakiet",
    "data": "Dane",
    "Enter custom amount": "Wprowadź niestandardową kwotę",
    "Credit & Debit Card": "Karta kredytowa i debetowa",
    "Bank Transfer": "Przelew bankowy",
    "Mobile Wallet": "Portfel mobilny",
    "InstaPay Wallet": "Portfel InstaPay",
    "PayPal": "PayPal",
    "add card payment": "Dodaj płatność kartą",
    "save details to use next time.": "Zapisz szczegóły do przyszłego użytku",
    "Recipient Mobile Number": "Numer telefonu odbiorcy",
    "Amount": "Kwota",
    "Service": "Usługa",
    "Destination": "Cel",
    "Provider": "Dostawca",
    "Airtime": "Czas rozmów",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Within minutes": "W ciągu kilku minut",
    "top-up summary": "Podsumowanie doładowania",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR nie jest obsługiwany przez PayPal, domyślnie używany będzie USD.",
    "Amount in USD": "Kwota w USD",
    "Send Top-up": "Wyślij doładowanie",
    "available sunservices": "Dostępne usługi",
    "login with email": "Zaloguj się za pomocą e-maila",
    "Already Have an Account?": "Masz już konto? Zaloguj się",
    "profile picture": "Zdjęcie profilowe",
    "add photo/logo": "Dodaj zdjęcie/logo",
    "first name": "Imię",
    "enter your first name": "Wprowadź swoje imię",
    "last name": "Nazwisko",
    "enter your last name": "Wprowadź swoje nazwisko",
    "email(optional)": "E-mail (opcjonalnie)",
    "enter your email address": "Wprowadź swój adres e-mail",
    "password must": "Hasło musi",
    "It will be autofill, if you're signing up with invitation link": "Autouzupełnienie dla rejestracji na zaproszenie",
    "change number": "Zmień numer",
    "signup error": "Błąd rejestracji",
    "Something went wrong while sending sms!": "Coś poszło nie tak podczas wysyłania SMS-a! Spróbuj ponownie później.",
    "Errors": "Wystąpiły błędy. Odnieś się do poniższej listy, aby uzyskać szczegóły.",
    "Generic error": "Błąd ogólny",
    "Trust Payments API requires the 'requests' library": "API Trust Payments wymaga prawidłowej funkcji 'requests' do działania.",
    "Send error": "Błąd podczas procesu wysyłania. Spróbuj ponownie.",
    "Receive error": "Błąd podczas procesu odbioru. Spróbuj ponownie.",
    "Invalid credentials provided": "Nieprawidłowe dane logowania. Zweryfikuj i spróbuj ponownie.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Wystąpił problem podczas łączenia z serwerami Trust Payments. Sprawdź swoje połączenie i spróbuj ponownie.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Nieoczekiwany błąd podczas łączenia z serwerami Trust Payments. Jeśli problem będzie się powtarzał, skontaktuj się z support@trustpayments.com.",
    "Unknown error. If this persists please contact Trust Payments": "Nieznany błąd. Skontaktuj się z obsługą Trust Payments, jeśli problem będzie się utrzymywał.",
    "Incorrect usage of the Trust Payments API": "Nieprawidłowe użycie API Trust Payments. Zweryfikuj żądanie i spróbuj ponownie.",
    "Invalid card details": "Nieprawidłowe dane karty. Sprawdź i wprowadź poprawne dane.",
    "Invalid date": "Nieprawidłowa data.",
    "Invalid date/time": "Nieprawidłowy format daty/czasu. Podaj prawidłowy format.",
    "Invalid details": "Nieprawidłowe dane. Zweryfikuj i wprowadź poprawne informacje.",
    "Card number does not match card type": "Numer karty nie pasuje do określonego typu karty.",
    "Malformed XML": "Błąd formatowania XML. Zweryfikuj i popraw XML.",
    "XML does not match schema": "XML nie pasuje do oczekiwanego schematu.",
    "Invalid file format": "Nieprawidłowy format pliku. Użyj obsługiwanego formatu.",
    "Empty file contents": "Zawartość pliku pusta. Podaj poprawną zawartość.",
    "Invalid file contents": "Nieprawidłowa zawartość pliku. Zweryfikuj plik i spróbuj ponownie.",
    "Malformed JSON": "Nieprawidłowy format JSON. Popraw format JSON.",
    "StApi Error": "Błąd StApi",
    "Invalid fields specified in request": "Nieprawidłowe pola określone w żądaniu. Proszę zweryfikować i poprawić.",
    "Missing parent": "Brak informacji o nadrzędnym.",
    "Refund requires settled parent or parent that's due to settle today": "Zwrot wymaga transakcji nadrzędnej rozliczonej lub tej, która ma być rozliczona dzisiaj.",
    "Refund requires authorisation parent": "Zwrot wymaga transakcji autoryzacyjnej nadrzędnej.",
    "Refund amount too great": "Kwota zwrotu przekracza dopuszczalny limit.",
    "No acquirer specified": "Brak informacji o akwizytorze.",
    "Repeat amount too great": "Powtarzalna kwota przekracza dopuszczalny limit.",
    "Split amount too great": "Kwota podziału przekracza dopuszczalny limit.",
    "Cannot refund a declined transaction": "Nie można zwrócić odrzuconej transakcji.",
    "Refund requires a settled parent": "Zwrot wymaga transakcji nadrzędnej rozliczonej.",
    "Reversal requires a cancelled auth parent": "Anulowanie wymaga anulowanej transakcji autoryzacyjnej.",
    "Cannot override amount in child transaction": "Nie można nadpisać kwoty w transakcji podrzędnej.",
    "Cannot override currency in child transaction": "Nie można nadpisać waluty w transakcji podrzędnej.",
    "Subscription requires RECUR account": "Subskrypcja wymaga ustawienia konta cyklicznego.",
    "Subscription requires successful parent": "Subskrypcja wymaga udanej transakcji nadrzędnej.",
    "Risk Decisions must have AUTH as parent": "Decyzje ryzyka muszą być powiązane z transakcją autoryzacyjną.",
    "Chargebacks must have AUTH/REFUND as parent": "Obciążenia zwrotne muszą być powiązane z transakcją autoryzacyjną lub zwrotem.",
    "Refund amount less than Minimum allowed": "Kwota zwrotu jest mniejsza niż dozwolona minimalna kwota.",
    "Refund requires paypaltransactionid": "Zwrot wymaga ID transakcji PayPal.",
    "Invalid split transaction": "Nieprawidłowa transakcja podziału.",
    "Cannot reverse AUTH processed more than 48 hours ago": "Nie można cofnąć autoryzacji przetworzonej ponad 48 godzin temu.",
    "Reversal requires acquirerreferencedata": "Anulowanie wymaga danych referencyjnych akwizytora.",
    "Cannot reverse AUTH processed by a different acquirer": "Nie można cofnąć autoryzacji przetworzonej przez innego akwizytora.",
    "Payment type does not support repeats": "Typ płatności nie obsługuje powtarzających się transakcji.",
    "Reversal missing required data": "Anulowanie brakuje wymaganych danych.",
    "Missing token": "Brak informacji o tokenie.",
    "Subscription with an accountcheck parent not supported on current acquirer": "Subskrypcja z kontem nadrzędnym nie jest obsługiwana przez obecnego akwizytora.",
    "Subscription cannot be used as a parent": "Subskrypcja nie może być używana jako transakcja nadrzędna.",
    "Invalid parent": "Nieprawidłowy typ transakcji nadrzędnej.",
    "Payment type does not support refunds": "Typ płatności nie obsługuje zwrotów.",
    "Invalid incremental transaction": "Nieprawidłowa transakcja przyrostowa.",
    "Partial reversals not supported": "Częściowe cofnięcia nie są obsługiwane.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Transakcje nadrzędne i podrzędne muszą mieć ten sam typ płatności dla zapytań 3D secure.",
    "Payment type does not support card scheme updates": "Typ płatności nie obsługuje aktualizacji schematu kart.",
    "Cannot reverse AUTH at this time, please try again": "Nie można cofnąć autoryzacji w tej chwili. Spróbuj ponownie później.",
    "Cannot determine token": "Nie można ustalić tokena. Proszę zweryfikować i spróbować ponownie.",
    "Service Temporarily Disabled": "Usługa jest tymczasowo wyłączona. Spróbuj ponownie później.",
    "Login first request": "Wymagane zalogowanie przed wysłaniem żądania.",
    "Invalid username/password": "Nieprawidłowa nazwa użytkownika lub hasło. Spróbuj ponownie.",
    "Invalid session": "Nieprawidłowa sesja. Zaloguj się ponownie.",
    "Session has expired": "Sesja wygasła. Zaloguj się ponownie.",
    "Password expired": "Hasło wygasło. Zresetuj hasło.",
    "Password has been previously used": "Hasło zostało już użyte. Wybierz nowe hasło.",
    "MyST user account has been locked": "Konto użytkownika MyST zostało zablokowane. Skontaktuj się z pomocą techniczną.",
    "New password does not match confirmed password": "Nowe hasło nie pasuje do potwierdzonego hasła.",
    "Incorrect current password": "Nieprawidłowe bieżące hasło. Spróbuj ponownie.",
    "Invalid selection": "Nieprawidłowy wybór. Wybierz poprawną opcję.",
    "User already exists": "Użytkownik już istnieje. Zaloguj się lub zresetuj hasło, jeśli to konieczne.",
    "No transaction found": "Nie znaleziono transakcji.",
    "Invalid selected transactions": "Nieprawidłowe wybrane transakcje.",
    "Data supplied has not been saved": "Dane nie zostały zapisane. Spróbuj ponownie.",
    "Invalid request type": "Nieprawidłowy typ żądania.",
    "Missing request type, at least one request type must be selected": "Brak wybranego typu żądania. Musi być wybrany co najmniej jeden.",
    "Invalid payment type": "Nieprawidłowy typ płatności.",
    "Missing payment type, at least one payment type must be selected": "Brak wybranego typu płatności. Musi być wybrany co najmniej jeden.",
    "Invalid error code": "Nieprawidłowy kod błędu.",
    "Missing error code, at least one error code must be selected": "Brak kodu błędu. Musi być wybrany co najmniej jeden.",
    "Invalid filter description": "Nieprawidłowy opis filtra.",
    "Invalid destination description": "Nieprawidłowy opis celu.",
    "Invalid notification type": "Nieprawidłowy typ powiadomienia.",
    "Invalid destination": "Nieprawidłowy cel.",
    "Invalid field selected": "Nieprawidłowe pole wybrane.",
    "Invalid email from address": "Nieprawidłowy adres e-mail nadawcy.",
    "Invalid email subject": "Nieprawidłowy temat e-maila.",
    "Invalid email type": "Nieprawidłowy typ e-maila.",
    "Unable to process request": "Nie można przetworzyć żądania. Spróbuj ponownie później.",
    "No file selected for upload": "Nie wybrano pliku do przesłania.",
    "Invalid file size": "Nieprawidłowy rozmiar pliku. Podaj plik o dopuszczalnym rozmiarze.",
    "Invalid filename": "Nieprawidłowa nazwa pliku.",
    "Invalid extension": "Nieprawidłowe rozszerzenie pliku.",
    "User requires at least one site reference": "Użytkownik wymaga co najmniej jednego odniesienia do witryny.",
    "Only ST-level users can have '*' access": "Tylko użytkownicy poziomu ST mogą mieć dostęp z prawami '*'.",
    "Request failed": "Żądanie nie powiodło się. Spróbuj ponownie później.",
    "Invalid File Contents": "Nieprawidłowa zawartość pliku.",
    "Maximum number of files uploaded": "Przekroczona maksymalna liczba przesłanych plików. Proszę usunąć niektóre pliki i spróbować ponownie.",
    "Insufficient gateway access privileges": "Niewystarczające uprawnienia dostępu do bramki.",
    "Maximum file size limit reached": "Przekroczony maksymalny limit rozmiaru pliku.",
    "Username(s) must be a valid user(s)": "Nazwy użytkowników muszą być poprawnymi użytkownikami.",
    "Site reference(s) must be a valid site(s)": "Odniesienia do witryn muszą być poprawnymi witrynami.",
    "Unable to send email, please verify the details and try again": "Nie można wysłać e-maila. Proszę zweryfikować szczegóły i spróbować ponownie.",
    "Negative already exists": "Ujemna wartość już istnieje. Proszę zweryfikować i dokonać korekty.",
    "Cannot delete a search owned by another user": "Nie można usunąć wyszukiwania należącego do innego użytkownika.",
    "Invalid search": "Nieprawidłowe wyszukiwanie. Spróbuj ponownie z poprawnymi kryteriami.",
    "Cannot delete the specified search, the search name cannot be found": "Nie można usunąć określonego wyszukiwania. Nie znaleziono nazwy wyszukiwania.",
    "Search parameter is too short": "Parametr wyszukiwania jest zbyt krótki. Wprowadź bardziej szczegółowe terminy wyszukiwania.",
    "Duplicate custom fields defined": "Zdefiniowano zduplikowane niestandardowe pola. Proszę rozwiązać duplikaty.",
    "Cannot allocate selected users, insufficient privileges": "Nie można przydzielić wybranych użytkowników. Niewystarczające uprawnienia.",
    "Allocated users have access to additional sites": "Przydzieleni użytkownicy mają dostęp do dodatkowych witryn.",
    "Allocated users have access to additional users": "Przydzieleni użytkownicy mają dostęp do dodatkowych użytkowników.",
    "User with current role cannot be allocated users": "Użytkownicy z aktualną rolą nie mogą przydzielać innych użytkowników.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Ta strona wymaga, aby Twoja przeglądarka akceptowała pliki cookie, aby się zalogować. Pliki cookie można zaakceptować, klikając 'Akceptuję' poniżej.",
    "User requires at least one site reference or site group": "Użytkownik wymaga co najmniej jednego odniesienia do witryny lub grupy witryn.",
    "Allocated users have access to additional site groups": "Przydzieleni użytkownicy mają dostęp do dodatkowych grup witryn.",
    "No statement found": "Nie znaleziono oświadczenia.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "Dostarczone dane nie zostały zaktualizowane w usłudze trzeciej strony MobilePay.",
    "Bypass": "Bypass",
    "Insufficient access privileges": "Niewystarczające uprawnienia dostępu.",
    "Coding error": "Wykryto błąd kodowania.",
    "Insufficient privileges": "Niewystarczające uprawnienia do wykonania tej akcji.",
    "Invalid request": "Nieprawidłowe żądanie. Proszę zweryfikować i spróbować ponownie.",
    "Invalid field": "Nieprawidłowe pole wprowadzone. Proszę zweryfikować i poprawić wpis.",
    "Unknown site": "Nieznana strona.",
    "Banned card": "Karta zablokowana.",
    "XML element parse error": "Błąd parsowania elementu XML. Proszę zweryfikować strukturę XML.",
    "Maestro must use SecureCode": "Transakcje Maestro muszą używać SecureCode do weryfikacji.",
    "Multiple email addresses must be separated with , or ;": "Wiele adresów e-mail musi być oddzielonych przecinkami (,) lub średnikami (;).",
    "Invalid site reference for alias": "Nieprawidłowe odniesienie do witryny dla aliasu.",
    "Invalid version number": "Nieprawidłowy numer wersji. Proszę wprowadzić prawidłowy numer wersji.",
    "Unknown user": "Nieznany użytkownik. Proszę zweryfikować i spróbować ponownie.",
    "Cannot determine account": "Nie można ustalić konta.",
    "JSON element parse error": "Błąd parsowania elementu JSON. Proszę zweryfikować strukturę JSON.",
    "Wallet type configuration error": "Błąd konfiguracji typu portfela.",
    "Wallet type not supported on this request": "Typ portfela nie jest obsługiwany dla tego żądania.",
    "The card number you have provided is incorrect, please verify your details and try again": "Podany numer karty jest nieprawidłowy. Proszę zweryfikować dane i spróbować ponownie.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "Podany kod bezpieczeństwa (CVV2) jest nieprawidłowy. Proszę zweryfikować i spróbować ponownie.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "Podana data ważności jest nieprawidłowa. Proszę zweryfikować dane i spróbować ponownie.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "Podany miesiąc ważności jest nieprawidłowy. Proszę zweryfikować i spróbować ponownie.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "Podany rok ważności jest nieprawidłowy. Proszę zweryfikować i spróbować ponownie.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Nie można przetworzyć płatności z powodu błędów połączenia. Nieprawidłowy identyfikator żądania. Proszę spróbować ponownie.",
    "The issue number you have provided is incorrect, please verify your details and try again": "Podany numer wydania jest nieprawidłowy. Proszę zweryfikować i spróbować ponownie.",
    "The payment type you have provided is incorrect, please verify your details and try again": "Podany typ płatności jest nieprawidłowy. Proszę zweryfikować i spróbować ponownie.",
    "Unable to process your payment, please contact the website": "Nie można przetworzyć płatności. Proszę skontaktować się z witryną w celu uzyskania pomocy.",
    "There are errors with these fields: {0}": "Wystąpiły błędy w tych polach: {0}",
    "No account found": "Nie znaleziono konta.",
    "Refund cannot be processed": "Nie można przetworzyć zwrotu.",
    "Transaction de-activated": "Transakcja zdezaktywowana.",
    "Socket receive error": "Błąd odbierania gniazda.",
    "Socket connection error": "Błąd połączenia gniazda.",
    "Socket closed": "Gniazdo zamknięte.",
    "Invalid data received": "Otrzymano nieprawidłowe dane.",
    "Invalid SQL": "Nieprawidłowe zapytanie SQL.",
    "Timeout": "Wystąpił czas oczekiwania.",
    "Invalid acquirer": "Nieprawidłowe dane akwizytora.",
    "Unable to connect to acquirer": "Nie można połączyć się z akwizytorem.",
    "Invalid response from acquirer": "Nieprawidłowa odpowiedź od akwizytora.",
    "No available transport": "Brak dostępnego transportu.",
    "File size too large": "Zbyt duży rozmiar pliku.",
    "Socket send error": "Błąd wysyłania gniazda.",
    "Communication error": "Błąd komunikacji.",
    "Proxy error": "Błąd serwera proxy.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Nie można przetworzyć płatności z powodu błędów połączenia. Proszę zweryfikować dane i spróbować ponownie ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Nie można przetworzyć płatności z powodu błędów połączenia (status odpowiedzi HTTP {0}). Proszę zweryfikować dane i spróbować ponownie ({1}).",
    "Wrong number of emails": "Podano nieprawidłową liczbę adresów e-mail.",
    "Bank System Error": "Błąd systemu bankowego.",
    "Wrong number of transactions": "Podano nieprawidłową liczbę transakcji.",
    "Invalid transport configuration": "Nieprawidłowa konfiguracja transportu.",
    "No valid updates specified": "Nie podano prawidłowych aktualizacji.",
    "Transaction reference not found": "Nie znaleziono odniesienia do transakcji.",
    "Settlebaseamount too large": "Kwota bazowa do rozliczenia jest zbyt duża.",
    "Transaction not updatable": "Transakcja nie do zaktualizowania.",
    "No searchable filter specified": "Brak podanego filtra do wyszukania.",
    "Timeout Error": "Błąd czasu oczekiwania. Proszę spróbować ponownie.",
    "3-D Secure Transport Error": "Błąd transportu 3-D Secure.",
    "Unauthenticated": "Niezautoryzowane żądanie.",
    "Site Suspended": "Strona zawieszona.",
    "No updates performed": "Nie wykonano żadnych aktualizacji.",
    "Invalid Request": "Nieprawidłowe żądanie.",
    "Invalid Response": "Nieprawidłowa odpowiedź.",
    "Invalid Acquirer": "Nieprawidłowe dane akwizytora.",
    "Invalid account data": "Nieprawidłowe dane konta.",
    "Missing": "Brakujące informacje.",
    "Payment Error": "Błąd płatności.",
    "Invalid acquirer for 3-D Secure": "Nieprawidłowy akwizytor dla 3-D Secure.",
    "Invalid payment type for 3-D Secure": "Nieprawidłowy typ płatności dla 3-D Secure.",
    "Invalid updates specified": "Nieprawidłowe aktualizacje.",
    "Manual investigation required": "Wymagana ręczna weryfikacja.",
    "Invalid headers": "Nieprawidłowe nagłówki.",
    "Max fraudscore exceeded": "Przekroczono maksymalny wynik oszustwa.",
    "Invalid filters": "Podano nieprawidłowe filtry.",
    "Merchant System Error": "Błąd systemu handlowca.",
    "Your payment is being processed. Please wait...": "Twoja płatność jest przetwarzana. Proszę czekać...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Nie można określić zarówno 'opis typu żądania', jak i 'opisy typu żądania' w jednym żądaniu.",
    "Acquirer missing original transaction data": "Brak danych oryginalnej transakcji od akwizytora.",
    "Insufficient funds": "Niewystarczające środki.",
    "Unable to process due to scheme restrictions": "Nie można przetworzyć z powodu ograniczeń schematu.",
    "Failed Screening": "Nieudana weryfikacja.",
    "Unable to process due to restrictions": "Nie można przetworzyć z powodu ograniczeń.",
    "Invalid process": "Nieprawidłowy proces.",
    "Risk Referral": "Wymagana ocena ryzyka.",
    "Name Pick required": "Wymagane imię i nazwisko dla wyboru.",
    "Address Pick required": "Wymagany adres dla wyboru.",
    "IP not in range": "Adres IP poza zasięgiem.",
    "Invalid button configuration": "Nieprawidłowa konfiguracja przycisku.",
    "Unrecognised response from acquirer": "Nierozpoznana odpowiedź od akwizytora.",
    "Decline": "Odrzucenie.",
    "Uncertain result": "Niepewny wynik.",
    "Soft Decline": "Miękkie odrzucenie.",
    "Refer to Issuer": "Skontaktuj się z wydawcą, aby uzyskać więcej informacji.",
    "Request is queued please check the transaction later for the status": "Żądanie jest w kolejce. Proszę sprawdzić status transakcji później.",
    "Generic Retry": "Ponowienie ogólne.",
    "Soft Decline retry": "Ponowienie miękkiego odrzucenia.",
    "There has been a problem with your payment, please verify your details and try again": "Wystąpił problem z Twoją płatnością. Proszę zweryfikować dane i spróbować ponownie.",
    "Unknown error": "Nieznany błąd.",

    "Login": "Zaloguj się",
    "Enter Your Email": "Wpisz swój e-mail",
    "Login With Phone Number": "Zaloguj się numerem telefonu",
    "Verify Using Sms/Email": "Zweryfikuj za pomocą SMS/email",
    "Resend Code In 100 Seconds": "Wyślij ponownie kod za 100 sekund",
    "Verify": "Zweryfikuj",
    "Verifying": "Weryfikowanie",
    "Last Week": "W zeszłym tygodniu",
    "Older Transaction": "Starsza transakcja",
    "Total": "Całość",
    "Conversion Confirmed": "Przeliczanie potwierdzone",
    "User Account Limit": "Limit konta użytkownika",
    "Monthly Balance Limit": "Miesięczny limit salda",
    "Total Limit": "Całkowity limit",
    "Used": "Wykorzystane",
    "Remaining": "Pozostało",
    "Allowed Minimum Topup Amount": "Minimalna dozwolona kwota doładowania",
    "Allowed Maximum Topup Amount": "Maksymalna dozwolona kwota doładowania",
    "Incoming Limit": "Limit przychodzący",
    "Daily limit": "Limit dzienny",
    "Monthly limit": "Limit miesięczny",
    "Yearly limit": "Limit roczny",
    "Sending Limit": "Limit wysyłania",
    "Allowed Number Of Transactions": "Dozwolona liczba transakcji",
    "Total Amount": "Całkowita kwota",
    "Amount To Send": "Kwota do wysłania",
    "Destination Amount": "Kwota docelowa",
    "Note": "Notatka",
    "Revised Amount": "Zmieniona kwota",
    "Save": "Zapisz",
    "Date": "Data",
    "Action": "Działanie",
    "Payment Scheduled At": "Płatność zaplanowana na",
    "Transaction Fee": "Opłata transakcyjna",
    "Number Of Cycle": "Liczba cykli",
    "Subscription Starting Date": "Data rozpoczęcia subskrypcji",
    "Until": "Do",
    "Next Transaction": "Następna transakcja",
    "Pay Now": "Zapłać teraz",
    "Transaction Method": "Metoda płatności",
    "Mobile Money Provider": "Dostawca pieniędzy mobilnych",
    "Wallet Name": "Nazwa portfela",
    "Wallet Number": "Numer portfela",
    "Crypto Currency": "Kryptowaluta",
    "Wallet Address": "Adres portfela",
    "Select Document Type": "Wybierz typ dokumentu",
    "Edit Document Number": "Edytuj numer dokumentu",
    "My Withdrawals": "Moje wypłaty",
    "Bargainable": "Możliwy do negocjacji",
    "Description": "Opis",
    "Attached Files": "Dołączone pliki",
    "Sent": "Wysłano",
    "Accepted": "Zaakceptowano",
    "Decline": "Odrzucono",
    "Pending": "Oczekujące",
    "Overall Rating": "Ogólna ocena",
    "No Review": "Brak recenzji",
    "0 Rating": "Ocena 0",
    "Transaction Map": "Mapa transakcji",
    "Send Money": "Wyślij pieniądze",
    "Sending Method": "Metoda wysyłki",
    "Trouble Sending Money": "Problemy z wysyłaniem pieniędzy",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Opłata i otrzymana kwota mogą się różnić w zależności od wybranej metody płatności.",
    "Select Method": "Wybierz metodę",
    "Completed": "Zakończono",
    "How Was Your Experience?": "Jakie było Twoje doświadczenie?",
    "Leave A Review": "Zostaw recenzję",
    "Review History": "Historia recenzji",
    "Write A Review": "Napisz recenzję",
    "Send And Post": "Wyślij i opublikuj",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Konwersja kryptowalut na fiat to solidne i bezpieczne rozwiązanie, które płynnie przekształca kryptowaluty w waluty fiducjarne. Zaspokaja różnorodne potrzeby użytkowników, oferując szereg kanałów płatności dopasowanych do indywidualnych preferencji i lokalizacji.\"",
    "Review Created": "Przegląd utworzony",
    "Transactions": "Transakcje",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Przeciągnij/Upuść lub Prześlij Plik (Zdjęcie/Wideo/Pdf)",
    "Max Size 1GB": "Maksymalny rozmiar 1GB",
    "Title Description": "Opis tytułu",
    "Add New": "Dodaj nowy",
    "Discard": "Odrzuć",
    "Save And Post": "Zapisz i opublikuj",
    "Personal Account": "Konto osobiste",
    "Conversion": "Konwersja",
    "Spent In Total": "Wydane łącznie",
    "International": "Międzynarodowy",
    "Recharge": "Doładuj",
    "Top Up Wallet To Wallet": "Doładuj portfel do portfela",
    "PayPal": "PayPal",
    "Instapay Wallet": "Portfel Instapay",
    "Not Connected": "Nie połączono",
    "More": "Więcej",
    "Less": "Mniej",
    "Profile Reports": "Raporty profilu",
    "Timezone": "Strefa czasowa",
    "Cancel": "Anuluj",
    "Full Name": "Pełne imię",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Dodatkowe dokumenty",
    "Profile Complete": "Profil zakończony",
    "Verified": "Zweryfikowane",
    "Bank Account": "Konto bankowe",
    "Mobile Wallet": "Portfel mobilny",
    "Cash Pickup": "Odbiór gotówki",
    "Add": "Dodaj",
    "Configured": "Skonfigurowano",
    "Email": "E-mail",
    "Download Data": "Pobierz dane",
    "Enter The Amount": "Wprowadź kwotę",
    "They Receive": "Otrzymują",
    "Where Do You Want To Send Money": "Gdzie chcesz wysłać pieniądze",
    "Bank Transfer": "Przelew bankowy",
    "Receiving Method": "Metoda odbioru",
    "Payer": "Płatnik",
    "Minimum Amount": "Minimalna kwota",
    "Maximum Amount": "Maksymalna kwota",
    "Search": "Szukać",
    "Show More": "Pokaż więcej",
    "Select Remark / Reasons (All)": "Wybierz uwagi / powody (wszystkie)",
    "Attach Files": "Dołącz pliki",
    "Holiday List": "Lista wakacyjna",
    "No Data To Show": "Brak danych do wyświetlenia",
    "Threshold Limits": "Limity progowe",
    "Amount Limits": "Limity kwoty",
    "OTP Verification Code": "Kod weryfikacyjny OTP",
    "Verify Using Authenticator": "Zweryfikuj za pomocą Authenticator",
    "Sms/Email Verification Code": "Kod weryfikacyjny SMS/email",
    "Invite Them": "Zaproś ich",
    "Lookup": "Szukaj",
    "Invite Friend": "Zaproś znajomego",
    "Invite Via Phone Number": "Zaproś przez numer telefonu",
    "Invite Via Email Address": "Zaproś przez adres e-mail",
    "Next": "Następny",
    "Back": "Wstecz",
    "Invite Via Email": "Zaproś przez e-mail",
    "Available Currency": "Dostępna waluta",
    "Add Remarks And Comments": "Dodaj uwagi i komentarze",
    "Recipient Details": "Szczegóły odbiorcy",
    "Go Back": "Wróć",
    "Trouble Requesting Money": "Problem z żądaniem pieniędzy",
    "Instant": "Natychmiastowy",
    "Subscription": "Subskrypcja",
    "Schedule": "Harmonogram",
    "Choose Request Type": "Wybierz typ żądania",
    "Mobile Airtime": "Czas na telefonie",
    "Incorrect Phone Number": "Niepoprawny numer telefonu",
    "Make Sure You Have Entered A Correct Phone Number.": "Upewnij się, że wprowadziłeś poprawny numer telefonu.",
    "Close": "Zamknij",
    "Operator": "Operator",
    "Recipient Mobile Number": "Numer telefonu odbiorcy",
    "Amount": "Kwota",
    "Service": "Usługa",
    "Destination": "Cel",
    "Top Up Summary": "Podsumowanie doładowania",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Przekroczono dzienny limit wysyłania",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Wyślij szczegółową wycenę do swojego klienta lub nabywcy. Przedstaw swoją usługę, produkty lub oferty profesjonalnie z spersonalizowaną wyceną.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Płatności dokonane w dni robocze przed 15:00 zostaną zakredytowane na konto odbiorcy przed północą tego samego dnia.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Skonfiguruj swoje konta odbiorcze w trzech krajach i natychmiast wypłać środki z zatwierdzonych wniosków płatności do aktywowanych kanałów wypłat.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Królestwo Kemit zastrzega sobie prawo do żądania dodatkowych dokumentów (Wzmocniona Weryfikacja - EDD) lub dodatkowych informacji niezbędnych do przeprowadzenia dokładnych weryfikacji, zgodnie z obowiązkami prawnymi.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Musi to być dokument tożsamości wydany przez rząd z fotografią. Wszystkie dokumenty tożsamości muszą być napisane alfabetem łacińskim. Jeśli nie, wymagane jest tłumaczenie uwierzytelnione.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Zaprezentuj swoją pracę, zwiększ swój zasięg. 'Moje portfolio' to Twoje cyfrowe płótno na Instapay. Prześlij i wyświetl szeroką gamę treści – od przyciągających uwagę obrazów i filmów po informacyjne pliki PDF. Niezależnie od tego, czy jesteś artystą, freelancerem, czy przedsiębiorcą, ta funkcja pozwala Ci prezentować swoją pracę lub projekty w sposób wizualnie angażujący.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Podziel się swoim kodem QR lub linkiem do płatności, aby natychmiast otrzymać pieniądze na swoje Insta Wallet.",
    "Afghanistan": "Afganistan",
    "Aland Islands": "Wyspy Alandzkie",
    "Albania": "Albania",
    "Algeria": "Algieria",
    "American Samoa": "Samoa Amerykańskie",
    "Andorra": "Andora",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua i Barbuda",
    "Argentina": "Argentyna",
    "Armenia": "Armenia",
    "Aruba": "Aruba",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaijan": "Azerbejdżan",
    "Bahamas": "Bahamy",
    "Bahrain": "Bahrajn",
    "Bangladesh": "Bangladesz",
    "Barbados": "Barbados",
    "Belarus": "Białoruś",
    "Belgium": "Belgia",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermudy",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Boliwia",
    "Bonaire - Sint Eustatius and Saba": "Bonaire - Sint Eustatius i Saba",
    "Bosnia and Herzegovina": "Bośnia i Hercegowina",
    "Botswana": "Botswana",
    "Brazil": "Brazylia",
    "British Indian Ocean Territory": "Brytyjskie Terytorium Oceanu Indyjskiego",
    "Brunei Darussalam": "Brunei",
    "Bulgaria": "Bułgaria",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Zielony Przylądek",
    "Cambodia": "Kambodża",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cayman Islands": "Wyspy Kajmańskie",
    "Central African Republic": "Republika Środkowoafrykańska",
    "Chad": "Czad",
    "Chile": "Chile",
    "China": "Chiny",
    "Christmas Island": "Wyspa Bożego Narodzenia",
    "Cocos (Keeling) Islands": "Wyspy Kokosowe (Keeling)",
    "Colombia": "Kolumbia",
    "Comoros": "Komory",
    "Congo": "Kongo",
    "Congo - Democratic Republic of the": "Kongo - Demokratyczna Republika",
    "Cook Islands": "Wyspy Cooka",
    "Costa Rica": "Kostaryka",
    "Cote d'Ivoire": "Wybrzeże Kości Słoniowej",
    "Croatia": "Chorwacja",
    "Cuba": "Kuba",
    "Curacao": "Curaçao",
    "Cyprus": "Cypr",
    "Czech Republic": "Czechy",
    "Denmark": "Dania",
    "Djibouti": "Dżibuti",
    "Dominica": "Dominika",
    "Dominican Republic": "Republika Dominikany",
    "Ecuador": "Ekwador",
    "Egypt": "Egipt",
    "El Salvador": "Salwador",
    "Equatorial Guinea": "Gwinea Równikowa",
    "Eritrea": "Erytrea",
    "Estonia": "Estonia",
    "Eswatini": "Eswatini",
    "Ethiopia": "Etiopia",
    "Falkland Islands (Malvinas)": "Falklandy (Malwiny)",
    "Faroe Islands": "Wyspy Owcze",
    "Fiji": "Fidżi",
    "Finland": "Finlandia",
    "France": "Francja",
    "French Polynesia": "Polinezja Francuska",
    "French Southern Territories": "Francuskie Terytoria Południowe i Antarktyczne",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Gruzja",
    "Germany": "Niemcy",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grecja",
    "Greenland": "Grenlandia",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Gwatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Gwinea (Konkakry)",
    "Guinea-Bissau": "Gwinea-Bisau",
    "Guyana": "Gujana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Wyspy Heard i Wyspy McDonalda",
    "Holy See": "Watykan",
    "Honduras": "Honduras",
    "Hong Kong": "Hongkong",
    "Hungary": "Węgry",
    "Iceland": "Islandia",
    "India": "Indie",
    "Indonesia": "Indonezja",
    "Iran (Islamic Republic of)": "Iran (Islamska Republika)",
    "Iraq": "Irak",
    "Ireland": "Irlandia",
    "Isle of Man": "Wyspa Man",
    "Israel": "Izrael",
    "Italy": "Włochy",
    "Jamaica": "Jamajka",
    "Japan": "Japonia",
    "Jersey": "Jersey",
    "Jordan": "Jordania",
    "Kazakhstan": "Kazachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Korea (Ludowa Koreańska Republika Demokratyczna)",
    "Korea - Republic of": "Korea - Republika Korei",
    "Kuwait": "Kuwejt",
    "Kyrgyzstan": "Kirgistan",
    "Lao People's Democratic Republic": "Laońska Ludowa Republika Demokratyczna",
    "Latvia": "Łotwa",
    "Lebanon": "Liban",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libia",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litwa",
    "Luxembourg": "Luksemburg",
    "Macao": "Makau",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malezja",
    "Maldives": "Malediwy",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Wyspy Marshalla",
    "Mauritania": "Mauretania",
    "Mauritius": "Mauritius",
    "Mexico": "Meksyk",
    "Micronesia (Federated States of)": "Mikronezja (Stany Zjednoczone)",
    "Moldova - Republic of": "Mołdawia - Republika",
    "Monaco": "Monako",
    "Mongolia": "Mongolia",
    "Montenegro": "Czarnogóra",
    "Montserrat": "Montserrat",
    "Morocco": "Maroko",
    "Mozambique": "Mozambik",
    "Myanmar": "Birma",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Holandia",
    "New Caledonia": "Nowa Kaledonia",
    "New Zealand": "Nowa Zelandia",
    "Nicaragua": "Nikaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Wyspa Norfolk",
    "North Macedonia": "Północna Macedonia",
    "Northern Mariana Islands": "Wyspy Północnomarianne",
    "Norway": "Norwegia",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Palestyna",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Nowa Gwinea",
    "Paraguay": "Paragwaj",
    "Peru": "Peru",
    "Philippines": "Filipiny",
    "Pitcairn": "Pitcairn",
    "Poland": "Polska",
    "Portugal": "Portugalia",
    "Puerto Rico": "Portoryko",
    "Qatar": "Katar",
    "Romania": "Rumunia",
    "Russian Federation": "Federacja Rosyjska",
    "Rwanda": "Rwanda",
    "Saint Barth\u00e9lemy": "Saint Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Święta Helena - Wyspy Wniebowstąpienia i Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts i Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (część francuska)",
    "Saint Vincent and the Grenadines": "Saint Vincent i Grenadyny",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tome i Principe",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Sint Maarten (Dutch part)": "Sint Maarten (część holenderska)",
    "Slovakia": "Słowacja",
    "Slovenia": "Słowenia",
    "Solomon Islands": "Wyspy Salomona",
    "Somalia": "Somalia",
    "South Africa": "Republika Południowej Afryki",
    "South Georgia and the South Sandwich Islands": "Georgia Południowa i Sandwich Południowy",
    "South Sudan": "Sudan Południowy",
    "Spain": "Hiszpania",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard i Jan Mayen",
    "Sweden": "Szwecja",
    "Switzerland": "Szwajcaria",
    "Syrian Arab Republic": "Syria",
    "Tajikistan": "Tadżykistan",
    "United Republic of Tanzania": "Zjednoczona Republika Tanzanii",
    "Thailand": "Tajlandia",
    "Timor-Leste": "Timor Wschodni",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trynidad i Tobago",
    "Tunisia": "Tunezja",
    "Turkey": "Turcja",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks i Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraina",
    "United Arab Emirates": "Zjednoczone Emiraty Arabskie",
    "United Kingdom of Great Britain and Northern Ireland": "Zjednoczone Królestwo Wielkiej Brytanii i Irlandii Północnej",
    "United States of America": "Stany Zjednoczone Ameryki",
    "United States Minor Outlying Islands": "Mniejsze Wyspy Okręgowe Stanów Zjednoczonych",
    "Uruguay": "Urugwaj",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Wenezuela (Republika Boliwariańska)",
    "Viet Nam": "Wietnam",
    "Virgin Islands (British)": "Brytyjskie Wyspy Dziewicze",
    "Virgin Islands (U.S.)": "Wszystkich Wysp Dziewiczych (USA)",
    "Wallis and Futuna": "Wallis i Futuna",
    "Yemen": "Jemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Taiwan": "Tajwan",
    "Palestine": "Palestyna",
    "Western Sahara": "Zachodnia Sahara",
    "Kosovo": "Kosowo",
    "South Ossetia": "Południowa Osetia",
    "Abkhazia": "Abchazja",
    "Nagorno-Karabakh (Artsakh)": "Górski Karabach (Armenia)",
    "Transnistria": "Naddniestrze",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Północny Cypr",
    "Saudi Arabia": "Arabia Saudyjska",
    "Yugoslavia": "Jugosławia",

    "Login": "Zaloguj się",
    "Enter Your Email": "Wprowadź swój email",
    "Login With Phone Number": "Zaloguj się numerem telefonu",
    "Verify Using Sms/Email": "Zweryfikuj za pomocą SMS/email",
    "Resend Code In 100 Seconds": "Wyślij ponownie kod za 100 sekund",
    "Verify": "Zweryfikuj",
    "Verifying": "Weryfikowanie",
    "Last Week": "W zeszłym tygodniu",
    "Older Transaction": "Starsza transakcja",
    "Total": "Całość",
    "Conversion Confirmed": "Przeliczanie potwierdzone",
    "User Account Limit": "Limit konta użytkownika",
    "Monthly Balance Limit": "Miesięczny limit salda",
    "Total Limit": "Całkowity limit",
    "Used": "Wykorzystane",
    "Remaining": "Pozostało",
    "Allowed Minimum Topup Amount": "Minimalna dozwolona kwota doładowania",
    "Allowed Maximum Topup Amount": "Maksymalna dozwolona kwota doładowania",
    "Incoming Limit": "Limit przychodzący",
    "Daily Limit": "Limit dzienny",
    "Monthly Limit": "Limit miesięczny",
    "Yearly Limit": "Limit roczny",
    "Sending Limit": "Limit wysyłania",
    "Allowed Number Of Transactions": "Dozwolona liczba transakcji",
    "Total Amount": "Całkowita kwota",
    "Amount To Send": "Kwota do wysłania",
    "Destination Amount": "Kwota docelowa",
    "Note": "Notatka",
    "Revised Amount": "Zmieniona kwota",
    "Save": "Zapisz",
    "Date": "Data",
    "Action": "Działanie",
    "Payment Scheduled At": "Płatność zaplanowana na",
    "Transaction Fee": "Opłata transakcyjna",
    "Number Of Cycle": "Liczba cykli",
    "Subscription Starting Date": "Data rozpoczęcia subskrypcji",
    "Until": "Do",
    "Next Transaction": "Następna transakcja",
    "Pay Now": "Zapłać teraz",
    "Transaction Method": "Metoda płatności",
    "Mobile Money Provider": "Dostawca pieniędzy mobilnych",
    "Wallet Name": "Nazwa portfela",
    "Wallet Number": "Numer portfela",
    "Crypto Currency": "Kryptowaluta",
    "Wallet Address": "Adres portfela",
    "Select Document Type": "Wybierz typ dokumentu",
    "Edit Document Number": "Edytuj numer dokumentu",
    "My Withdrawals": "Moje wypłaty",
    "Bargainable": "Możliwy do negocjacji",
    "Description": "Opis",
    "Attached Files": "Dołączone pliki",
    "Sent": "Wysłano",
    "Accepted": "Zaakceptowano",
    "Decline": "Odrzucono",
    "Pending": "Oczekujące",
    "Overall Rating": "Ogólna ocena",
    "No Review": "Brak recenzji",
    "0 Rating": "Ocena 0",
    "Transaction Map": "Mapa transakcji",
    "Send Money": "Wyślij pieniądze",
    "Sending Method": "Metoda wysyłki",
    "Trouble Sending Money": "Problemy z wysyłaniem pieniędzy",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Opłata i otrzymana kwota mogą się różnić w zależności od wybranej metody płatności.",
    "Select Method": "Wybierz metodę",
    "Completed": "Zakończono",
    "How Was Your Experience?": "Jakie było Twoje doświadczenie?",
    "Leave A Review": "Zostaw recenzję",
    "Review History": "Historia recenzji",
    "Write A Review": "Napisz recenzję",
    "Send And Post": "Wyślij i opublikuj",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Konwersja kryptowalut na fiat to solidne i bezpieczne rozwiązanie, które płynnie przekształca kryptowaluty w waluty fiducjarne. Zaspokaja różnorodne potrzeby użytkowników, oferując szereg kanałów płatności dopasowanych do indywidualnych preferencji i lokalizacji.\"",
    "Review Created": "Przegląd utworzony",
    "Transactions": "Transakcje",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Przeciągnij/Upuść lub Prześlij Plik (Zdjęcie/Wideo/Pdf)",
    "Max Size 1GB": "Maksymalny rozmiar 1GB",
    "Title Description": "Opis tytułu",
    "Add New": "Dodaj nowy",
    "Discard": "Odrzuć",
    "Save And Post": "Zapisz i opublikuj",
    "Personal Account": "Konto osobiste",
    "Conversion": "Konwersja",
    "Spent In Total": "Wydane łącznie",
    "International": "Międzynarodowy",
    "Recharge": "Doładuj",
    "Top Up Wallet To Wallet": "Doładuj portfel do portfela",
    "PayPal": "PayPal",
    "Instapay Wallet": "Portfel Instapay",
    "Not Connected": "Nie połączono",
    "More": "Więcej",
    "Less": "Mniej",
    "Profile Reports": "Raporty profilu",
    "Timezone": "Strefa czasowa",
    "Cancel": "Anuluj",
    "Full Name": "Pełne imię",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Dodatkowe dokumenty",
    "Profile Complete": "Profil zakończony",
    "Verified": "Zweryfikowane",
    "Bank Account": "Konto bankowe",
    "Mobile Wallet": "Portfel mobilny",
    "Cash Pickup": "Odbiór gotówki",
    "Add": "Dodaj",
    "Configured": "Skonfigurowano",
    "Email": "E-mail",
    "Download Data": "Pobierz dane",
    "Enter The Amount": "Wprowadź kwotę",
    "They Receive": "Otrzymują",
    "Where Do You Want To Send Money": "Gdzie chcesz wysłać pieniądze",
    "Bank Transfer": "Przelew bankowy",
    "Receiving Method": "Metoda odbioru",
    "Payer": "Płatnik",
    "Minimum Amount": "Minimalna kwota",
    "Maximum Amount": "Maksymalna kwota",
    "Search": "Szukać",
    "Show More": "Pokaż więcej",
    "Select Remark / Reasons (All)": "Wybierz uwagi / powody (wszystkie)",
    "Attach Files": "Dołącz pliki",
    "Holiday List": "Lista wakacyjna",
    "No Data To Show": "Brak danych do wyświetlenia",
    "Threshold Limits": "Limity progowe",
    "Amount Limits": "Limity kwoty",
    "OTP Verification Code": "Kod weryfikacyjny OTP",
    "Verify Using Authenticator": "Zweryfikuj za pomocą Authenticator",
    "Sms/Email Verification Code": "Kod weryfikacyjny SMS/email",
    "Invite Them": "Zaproś ich",
    "Lookup": "Szukaj",
    "Invite Friend": "Zaproś znajomego",
    "Invite Via Phone Number": "Zaproś przez numer telefonu",
    "Invite Via Email Address": "Zaproś przez adres e-mail",
    "Next": "Następny",
    "Back": "Wstecz",
    "Invite Via Email": "Zaproś przez e-mail",
    "Available Currency": "Dostępna waluta",
    "Add Remarks And Comments": "Dodaj uwagi i komentarze",
    "Recipient Details": "Szczegóły odbiorcy",
    "Go Back": "Wróć",
    "Trouble Requesting Money": "Problem z żądaniem pieniędzy",
    "Instant": "Natychmiastowy",
    "Subscription": "Subskrypcja",
    "Schedule": "Harmonogram",
    "Choose Request Type": "Wybierz typ żądania",
    "Mobile Airtime": "Czas na telefonie",
    "Incorrect Phone Number": "Niepoprawny numer telefonu",
    "Make Sure You Have Entered A Correct Phone Number.": "Upewnij się, że wprowadziłeś poprawny numer telefonu.",
    "Close": "Zamknij",
    "Operator": "Operator",
    "Recipient Mobile Number": "Numer telefonu odbiorcy",
    "Amount": "Kwota",
    "Service": "Usługa",
    "Destination": "Cel",
    "Top Up Summary": "Podsumowanie doładowania",
    "Daily Sending Limit Exceeded": "Przekroczono dzienny limit wysyłania",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Wyślij szczegółową wycenę do swojego klienta lub nabywcy. Przedstaw swoją usługę, produkty lub oferty profesjonalnie z spersonalizowaną wyceną.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Płatności dokonane w dni robocze przed 15:00 zostaną zakredytowane na konto odbiorcy przed północą tego samego dnia.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Skonfiguruj swoje konta odbiorcze w trzech krajach i natychmiast wypłać środki z zatwierdzonych wniosków płatności do aktywowanych kanałów wypłat.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Królestwo Kemit zastrzega sobie prawo do żądania dodatkowych dokumentów (Wzmocniona Weryfikacja - EDD) lub dodatkowych informacji niezbędnych do przeprowadzenia dokładnych weryfikacji, zgodnie z obowiązkami prawnymi.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Musi to być dokument tożsamości wydany przez rząd z fotografią. Wszystkie dokumenty tożsamości muszą być napisane alfabetem łacińskim. Jeśli nie, wymagane jest tłumaczenie uwierzytelnione.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Zaprezentuj swoją pracę, zwiększ swój zasięg. 'Moje portfolio' to Twoje cyfrowe płótno na Instapay. Prześlij i wyświetl szeroką gamę treści – od przyciągających uwagę obrazów i filmów po informacyjne pliki PDF. Niezależnie od tego, czy jesteś artystą, freelancerem, czy przedsiębiorcą, ta funkcja pozwala Ci prezentować swoją pracę lub projekty w sposób wizualnie angażujący.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Podziel się swoim kodem QR lub linkiem do płatności, aby natychmiast otrzymać pieniądze na swoje Insta Wallet.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Masz 15 minut na zakończenie transakcji.",
    "Time Remaining": "Proszę zakończyć w wyznaczonym czasie, aby uniknąć wygaśnięcia adresu URL.",
    "Please Process The Payment Via Your Selected Payment Type": "Pozostały czas",
    "Payment Type": "Proszę przetworzyć płatność za pomocą wybranego rodzaju płatności.",
    "Top-Up Wallet": "Rodzaj płatności",
    "Confirm Your Payment": "DOŁADUJ PORTFEL",
    "You Will Get": "Potwierdź swoją płatność",
    "Amount To Pay": "Otrzymasz",
    "Proceed To Payment": "Kwota do zapłaty",
    "Load/Deposit Money To The Wallet": "Przejdź do płatności",
    "Withdraw": "Załaduj/zgromadź środki w portfelu",
    "Elevate Your Business With InstaPay": "Wypłata",
    "Higher Balances & Transaction Limits": "Rozwiń swój biznes z InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Wyższe salda i limity transakcji",
    "Full Feature Access": "Odkryj wolność zarządzania większymi saldami i przetwarzania większych ilości transakcji, z niezawodnością, jakiej oczekujesz od InstaPay.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Pełny dostęp do funkcji",
    "Global Reach": "Globalny Zasięg",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Rozszerz swój biznes bez granic. InstaPay łączy Cię z klientami i partnerami na całym świecie, czyniąc globalne transakcje łatwiejszymi niż kiedykolwiek.",
    "Advanced Security": "Zaawansowane Bezpieczeństwo",
    "Business Registration Available Soon\n": "Rejestracja działalności będzie wkrótce dostępna.",
    "Amount After Fee": "Kwota po Opłacie",
    "Allowed Maximum Amount Per Transaction": "Dopuszczalna Maksymalna Kwota na Transakcję",
    "Transfer": "Przelew",
    "Uncategorized": "Nieskategoryzowane",
    "Currency ISO Code & Amount": "Kod waluty ISO i kwota",
    "Choose The Top-Up Channel": "Wybierz kanał doładowania",
    "You Selected": "który wybrałeś",
    "Payout Channel": "kanał wypłaty",
    "Your Details": "twoje dane",
    "Detailed Confirmed": "szczegóły potwierdzone",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Zwiększ swój potencjał finansowy, przechodząc na konto InstaPay Business. Wykorzystaj pełne możliwości InstaPay dzięki ulepszonym funkcjom zaprojektowanym specjalnie dla rozwoju biznesu.",
    "Profile Picture": "Zdjęcie profilowe",
    "Add Photo Or Logo": "Dodaj zdjęcie lub logo",
    "First Name": "Imię",
    "Last Name": "Nazwisko",
    "Enter Your First Name": "Wprowadź swoje imię",
    "Enter Your Last Name": "Wprowadź swoje nazwisko",
    "Email (Optional)": "Email (opcjonalnie)",
    "Password Must": "Hasło musi",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Zostanie wypełnione automatycznie, jeśli rejestrujesz się za pomocą linku zaproszenia.",
    "Change Number": "Zmień numer",
    "Search Country": "Wyszukaj kraj",
    "Select From The List": "Wybierz z listy",
    "Received Amount": "Kwota otrzymana",

    "null": null,
    "Processing": "Przetwarzanie",
    "Verify Using Sms/Email": "Zweryfikuj za pomocą SMS/Email",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Już istnieje",
    "You Can Have Only 8 Wallets": "Możesz mieć tylko 8 portfeli",
    "Save": "Zapisz",
    "0 File": "0 plików",
    "Search From The List": "Wyszukaj na liście",
    "Amount To Send": "Kwota do wysłania",
    "Wallet To Wallet": "Portfel do Portfela",
    "TOP-UP WALLET ": "Doładuj portfel",
    "Enter The Amount": "Wprowadź kwotę",
    "Load/Deposit Money To The Wallet": "Załaduj/Wpłać pieniądze do portfela",
    "Choose The Top-Up Channel": "Wybierz kanał doładowania",
    "Paypal": "Paypal",
    "You Will Get": "Otrzymasz",
    "Amount To Pay": "Kwota do zapłaty",
    "Card Holder Name ": "Imię i nazwisko posiadacza karty",
    "Card Number": "Numer karty",
    "CVC": "CVC",
    "MM/YY": "MM/RR",
    "Your Details ": "Twoje dane",
    "You Selected": "Wybrano",
    "Detailed Confirmed": "Szczegółowo potwierdzone",
    "Transaction Failed": "Transakcja nieudana",
    "Something Went Wrong. Try Again.": "Coś poszło nie tak. Spróbuj ponownie.",
    "Convert Funds": "Przekształć środki",
    "How Would You Like To Cashout?:": "Jak chcesz wypłacić środki?:",
    "Default Receiving Account": "Domyślne konto odbiorcze",
    "Specify Receiving Account": "Określ konto odbiorcze",
    "Which Receiving Account Would You Want Use?:": "Które konto odbiorcze chcesz użyć?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Bank Mobilny",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Brak",
    "Request": "Żądanie",
    "Subscription Payment": "Płatność subskrypcyjna",
    "Is not supported by Paypal, USD will be used as the default currency.": "Nieobsługiwane przez PayPal, USD zostanie użyty jako domyślna waluta.",
    "Review Payment": "Przeglądaj płatność",
    "Google Authenticator": "google authenticator",
    "Scheduled Has Been Cancelled": "Zaplanowanie zostało anulowane",
    "Select Country First": "Wybierz kraj jako pierwszy",
    "Select Gender": "Wybierz płeć",
    "Select Occupation": "Wybierz zawód",
    "IBAN and SWIFT/BIC": "IBAN i SWIFT/BIC",
    "Mobile Money Provider": "Dostawca pieniędzy mobilnych",
    "Wallet Name": "Nazwa portfela",
    "Wallet Number ": "Numer portfela",
    "Crypto Currency": "Kryptowaluta",
    "Wallet Address ": "Adres portfela",
    "Select Document Type ": "Wybierz typ dokumentu",
    "Enter Document Number": "Wprowadź numer dokumentu",
    "Msisdn": "Msisdn",
    "Edit ": "Edytuj",
    "My Withdrawls": "Moje wypłaty",
    "Declined": "Odrzucone",
    "Accepted": "Zaakceptowane",
    "Overall Ratings": "Ogólna ocena",
    "0 Ratings": "0 ocen",
    "No Reviews": "Brak recenzji",
    "Send Money": "Wyślij pieniądze",
    "Trouble Sending Money?": "Problemy z wysyłaniem pieniędzy?",
    "Payment Request": "Prośba o płatność",
    "How Was Your Experience?": "Jakie były Twoje wrażenia?",
    "Leave A Review": "Zostaw recenzję",
    "Write Review Message": "Napisz wiadomość recenzji",
    "Send And Post": "Wyślij i opublikuj",
    "You Rated": "Oceniłeś",
    "Review History": "Historia recenzji",
    "Max Size 1GB": "Maksymalny rozmiar 1 GB",
    "Title": "Tytuł",
    "Description": "Opis",
    "Add New": "Dodaj nowe",
    "My Payment Address": "Mój adres płatności",
    "Personal Account": "Konto osobiste",
    "Updated Successfully": "Zaktualizowano pomyślnie",
    "Spent In": "Wydano na",
    "TOP UP": "Doładuj",
    "Quotation": "Oferta",
    "Amount": "Kwota",
    "Not Connected": "Niepołączono",
    "More": "Więcej",
    "Less": "Mniej",
    "Reports": "Raporty",
    "User Profile": "Profil użytkownika",
    "Cancel": "Anuluj",
    "Saving": "Zapisz",
    "Confirm ": "potwierdź",
    "Verify ": "Zweryfikuj",
    "Delete Country": "Usuń kraj",
    "Bank Account": "Konto bankowe",
    "Cash Pickup": "Odbiór gotówki",
    "Mobile Wallet ": "Portfel mobilny",
    "Delete Country Withdrawals": "Usuń wypłaty kraju",
    "Yes , Delete It": "Tak, usuń to",
    "E-sim": "E-sim",
    "Utilities": "Usługi",
    "Searching": "Wyszukiwanie",
    "Recipient Mobile Number": "Numer telefonu odbiorcy",
    "TOP-UP SUMMARY": "Podsumowanie doładowania",
    "Trouble Requesting Money?": "Problemy z wysyłaniem pieniędzy?",
    "Choose The Beneficiary": "Wybierz beneficjenta",
    "Enter The amount": "Wprowadź kwotę",
    "You selected": "Wybrałeś",
    "Timezone": "Strefa czasowa",
    "Starting From": "Począwszy od",
    "Recurring Cycle": "Cykl powtarzający się",
    "Until I stop": "Dopóki nie przestanę",
    "Schedule To": "Zaplanuj na",
    "Schedule at": "Zaplanuj na",
    "Selected time must be at least 5 minutes from now.": "Wybrany czas musi wynosić co najmniej 5 minut od teraz.",
    "Is this your Location?": "Czy to jest twoja lokalizacja?",
    "Yes ": "Tak",
    "Select Remark Reason": "Wybierz powód uwagi",
    "Attach File": "Załącz plik",
    "Invite via Phone Number": "Zaproś przez numer telefonu",
    "Invite Friend": "Zaproś znajomego",
    "Invite via Email Address": "Zaproś przez adres e-mail",
    "Invite via": "Zaproś przez",
    "Phone": "Telefon:",
    "Message": "Wiadomość:",
    "Send": "Wyślij",
    "Invited Succesfully!": "Zaproszono pomyślnie!",
    "Email": "E-mail",
    "Bank Transfer": "Przelew bankowy",
    "Mobile Wallet": "Portfel mobilny",
    "Select The Bank": "Wybierz bank",
    "Select The Payer": "Wybierz płatnika",
    "Min Amount:": "Minimalna kwota:",
    "Max Amount": "Maksymalna kwota",
    "Other Beneficiaries:": "Inni beneficjenci:",
    "Back ": "Wstecz",
    "Next": "Dalej",
    "Lookup": "Wyszukaj",
    "Invite Them": "Zaproś ich",
    "Delete Channel ": "Usuń kanał",
    "Set As Default": "Ustaw jako domyślny",
    "See More": "Zobacz więcej",
    "Session Details": "Szczegóły sesji",
    "Platform": "Platforma",
    "Windows": "Windows",
    "Browser Name": "Nazwa przeglądarki",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Wersja przeglądarki",
    "IP Address": "Adres IP",
    "Karachi": "Karaczi",
    "State": "Stan",
    "Sindh": "Sindh",
    "Active": "Aktywny",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Ten portfel walutowy jest obecnie zablokowany/dezaktywowany i nie można dodać środków.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Ten portfel walutowy jest obecnie zablokowany/dezaktywowany i nie można przekształcać środków.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Ten portfel walutowy jest obecnie zablokowany/dezaktywowany i nie możesz wypłacić salda.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Ten portfel walutowy jest obecnie zablokowany/dezaktywowany i nie możesz pobrać wyciągu.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Ten portfel walutowy jest obecnie ustawiony jako domyślny i nie może zostać zablokowany. Aby kontynuować, proszę najpierw ustawić inny portfel walutowy jako domyślny.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Ten portfel walutowy jest obecnie ustawiony jako domyślny i nie może zostać dezaktywowany. Aby kontynuować, proszę najpierw ustawić inny portfel walutowy jako domyślny.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Czy na pewno chcesz usunąć szczegóły wypłat tego kraju?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Skonfiguruj swoje konta odbiorcze w maksymalnie trzech krajach i natychmiast wypłać środki z zatwierdzonych próśb o płatności na aktywowane kanały wypłat.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Nie znaleziono beneficjenta w Pakistanie z danymi Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "Wiadomość zapraszająca została pomyślnie wysłana do",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Opłata i otrzymana kwota mogą się różnić w zależności od wybranej metody płatności.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Podziel się swoim kodem QR lub linkiem płatności, aby natychmiast otrzymać płatność w swoim portfelu Insta.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Przeciągnij/Upuść lub prześlij plik (Zdjęcie/Wideo/PDF)",
    "Login Again": "zaloguj się ponownie",
    "Unblock The Wallet": "odblokuj portfel",
    "Invalid Amount": "nieprawidłowa kwota",
    "Add Payment Card": "dodaj kartę płatniczą",
    "Save Details To Use Next Time": "zapisz dane do użycia następnym razem",
    "Paid": "zapłacono",
    "Started On": "rozpoczęto dnia",
    "Num Of Cycles": "liczba cykli",
    "Ends On": "kończy się dnia",
    "Next Payment At": "następna płatność o",
    "Beneficiary": "beneficjent",
    "Recieved Quotation": "otrzymana oferta",
    "Sent Quotation": "wysłana oferta",
    "Decline Offer": "odrzucić ofertę",
    "Are You Sure That You Want To Decline The Offer?": "czy na pewno chcesz odrzucić ofertę?",
    "Decline The Offer": "odrzucić ofertę",
    "Bargain Offer": "oferta negocjacyjna",
    "Enter Counter Amount": "wprowadź kwotę kontr-oferty",
    "Custom Amount": "niestandardowa kwota",
    "Send The Offer": "wyślij ofertę",
    "The Offer Is Sent": "oferta została wysłana",
    "Countered Offer": "oferta kontr-oferty",
    "Applied": "zastosowane",
    "Accept Quotation": "zaakceptuj ofertę",
    "Quotation Not Found Or Already Exist": "oferta nie znaleziona lub już istnieje",
    "Recieved Request": "otrzymane zapytanie",
    "Sent Request": "wysłane zapytanie",
    "Search Request": "wyszukaj zapytanie",
    "Search Quotation": "wyszukaj ofertę",
    "QR Status": "status QR",
    "QR Code Description": "opis kodu QR",
    "Download Your QR Code": "Pobierz swój kod QR",
    "Your QR Code": "Twój kod QR",
    "Your QR Code Poster": "Twój plakat z kodem QR",
    "Download  ": "pobierz",
    "Select Relation": "wybierz relację",
    "Nominee Permanent Address": "stały adres nominowanego",
    "Enter Your Message": "wprowadź wiadomość",
    "Select": "wybierz",
    "Select File Type": "wybierz typ pliku",
    "Send TOP-UP And E-Sim": "Wyślij doładowanie i E-sim",
    "Search Users": "wyszukaj użytkowników",
    "Select Payment Request Type": "wybierz typ żądania płatności",
    "Wallet To Wallet Transfer": "przelew z portfela do portfela",
    "Holiday List": "lista dni wolnych",
    "No Data To Show": "brak danych do wyświetlenia",
    "They Recieve": "oni otrzymują",
    "You Will Be Logged Out In 60 Seconds": "Zostaniesz wylogowany za 60 sekund",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Przesuń myszkę lub naciśnij dowolny klawisz na klawiaturze, aby zresetować licznik do 15 minut",
    "You've Been Logged Out": "Zostałeś wylogowany",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Dla Twojego bezpieczeństwa zostałeś wylogowany po 15 minutach braku aktywności. Zaloguj się ponownie, jeśli chcesz używać InstaPay.",
    "Cash": "Gotówka",
    "Business": "Biznes",
    "Gift": "Prezent",
    "Salary": "Pensja",
    "Lottery": "Loteria",
    "Savings": "Oszczędności",
    "Other": "Inne",
    "Select Source Of Fund": "Wybierz źródło funduszy",
    "Select your source of funds": "Wybierz swoje źródło funduszy",
    "Request Times Out. Please Try Again!": "Przekroczono limit czasu żądania. Spróbuj ponownie!",
    "Resend Code In 100 Seconds": "Wyślij ponownie kod za 100 sekund",
    "Computer Service": "Usługa komputerowa",
    "Family Support": "Wsparcie rodziny",
    "Education": "Edukacja",
    "Gift And Other Donations": "Prezenty i inne darowizny",
    "Medical Treatment": "Leczenie medyczne",
    "Maintenance Or Other Expenses": "Utrzymanie lub inne wydatki",
    "Travel": "Podróże",
    "Small Value Remittance": "Przelew o małej wartości",
    "Liberalized Remittance": "Zliberalizowany przelew",
    "Construction Expenses": "Wydatki na budowę",
    "Hotel Accommodation": "Zakwaterowanie w hotelu",
    "Advertising And/Or Public Relations Related Expenses": "Wydatki związane z reklamą i/lub public relations",
    "Fees For Advisory Or Consulting Service": "Opłaty za usługi doradcze lub konsultingowe",
    "Business Related Insurance Payment": "Opłata za ubezpieczenie związane z działalnością gospodarczą",
    "Insurance Claims Payment": "Wypłata odszkodowań z ubezpieczenia",
    "Delivery Fees": "Opłaty za dostawę",
    "Payments For Exported Goods": "Płatności za eksportowane towary",
    "Payment For Services": "Płatności za usługi",
    "Payment Of Loans": "Spłata pożyczek",
    "Office Expenses": "Wydatki biurowe",
    "Residential Property Purchase": "Zakup nieruchomości mieszkalnej",
    "Property Rental Payment": "Opłata za wynajem nieruchomości",
    "Royalty, Trademark, Patent And Copyright Fees": "Opłaty za prawa autorskie, znaki towarowe, patenty i opłaty licencyjne",
    "Investment In Shares": "Inwestycja w akcje",
    "Fund Investment": "Inwestowanie w fundusze",
    "Tax Payment": "Opłata podatkowa",
    "Transportation Fees": "Opłaty za transport",
    "Utility Bills": "Rachunki za media",
    "Personal Transfer": "Przelew osobisty",
    "Payment Of Salary": "Wypłata pensji",
    "Payment Of Rewards": "Wypłata nagród",
    "Payment Of Influencer": "Płatność dla influencerów",
    "Broker, Commitment, Guarantee And Other Fees": "Opłaty brokerskie, zobowiązania, gwarancje i inne opłaty",
    "Other Purposes": "Inne cele",
    "Aunt": "Ciotka",
    "Brother": "Brat",
    "Brother-In-Law": "Szef",
    "Cousin": "Kuzyn",
    "Daughter": "Córka",
    "Father": "Ojciec",
    "Father-In-Law": "Teść",
    "Friend": "Przyjaciel",
    "Grandfather": "Dziadek",
    "Grandmother": "Babcia",
    "Husband": "Mąż",
    "Mother": "Matka",
    "Mother-In-Law": "Teściowa",
    "Nephew": "Siostrzeniec",
    "Niece": "Siostrzenica",
    "Self (i.e. The Sender, Himself)": "Nadawca (czyli nadawca)",
    "Sister": "Siostra",
    "Sister-In-Law": "Bratowa",
    "Son": "Syn",
    "Uncle": "Wujek",
    "Wife": "Żona",
    "Others Not Listed": "Inni niewymienieni",
    "Passport": "Paszport",
    "National Identification Card": "Karta identyfikacyjna narodowa",
    "Driving License": "Prawo jazdy",
    "Social Security Card/Number": "Karta/ numer ubezpieczenia społecznego",
    "Tax Payer Identification Card/Number": "Karta/ numer identyfikacji podatnika",
    "Senior Citizen Identification Card": "Karta identyfikacyjna seniora",
    "Birth Certificate": "Akt urodzenia",
    "Village Elder Identification Card": "Karta identyfikacyjna starszego wioski",
    "Permanent Residency Identification Card": "Karta identyfikacyjna stałego pobytu",
    "Alien Registration Certificate/Card": "Certyfikat/Karta rejestracji obcokrajowca",
    "PAN Card": "Karta PAN",
    "Voter’s Identification Card": "Karta identyfikacyjna wyborcy",
    "Health Insurance Card/Number": "Karta/Numer ubezpieczenia zdrowotnego",
    "Employer Identification Card": "Karta identyfikacyjna pracodawcy",
    "Others Not Listed": "Inne, nie wymienione",
    "Bank Account": "Konto bankowe",
    "Cash Pickup": "Odbiór gotówki",
    "Card": "Karta",
    "Arts & Entertainment": "Sztuka i rozrywka",
    "Banking & Finance": "Bankowość i finanse",
    "Education & Research": "Edukacja i badania",
    "Engineering & Construction": "Inżynieria i budownictwo",
    "Healthcare & Medicine": "Opieka zdrowotna i medycyna",
    "Information Technology & Services": "Technologie informacyjne i usługi",
    "Legal & Compliance": "Prawo i zgodność",
    "Manufacturing & Production": "Produkcja i produkcja",
    "Marketing & Sales": "Marketing i sprzedaż",
    "Non-profit & Charity": "Organizacje non-profit i charytatywne",
    "Real Estate & Property": "Nieruchomości i własność",
    "Retail & Wholesale": "Handel detaliczny i hurtowy",
    "Science & Pharmaceuticals": "Nauka i farmaceutyki",
    "Transport & Logistics": "Transport i logistyka",
    "Travel & Tourism": "Podróże i turystyka",
    "Unemployed": "Bezrobotny",
    "Other": "Inne",
    "Cash": "Gotówka",
    "Business": "Biznes",
    "Gift": "Prezent",
    "Salary": "Wynagrodzenie",
    "Lottery": "Loteria",
    "Savings": "Oszczędności",
    "Good Morning": "Dzień dobry",
    "Good Evening": "Dobry wieczór",
    "Completed": "Zakończono",
    "Load/Deposit Money To The Wallet": "Przejdź do płatności",
    "Enter The Amount": "Wprowadź kwotę",
    "Choose The Top-Up Channel": "Wybierz kanał doładowania",
    "Good Night!": "Dobranoc!",
    "You Will Get": "Potwierdź swoją płatność",
    "Amount To Pay": "Otrzymasz",
    "Top-Up Wallet": "Rodzaj płatności",
    "Card Holder Name ": "Imię i nazwisko posiadacza karty",
    "Card Number": "Numer karty",
    "CVC": "CVC",
    "MM/YY": "MM/RR",
    "Save Details To Use Next Time": "zapisz dane do użycia następnym razem",
    "Select Or Add Payment Card": "Wybierz lub dodaj kartę płatniczą",
    "Your Details ": "Twoje dane",
    "You Selected": "Wybrano",
    "Detailed Confirmed": "Szczegółowo potwierdzone",
    "Transaction Failed": "Transakcja nieudana",
    "Something Went Wrong. Try Again.": "Coś poszło nie tak. Spróbuj ponownie.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Niepowodzenie",
    "Initiated": "Rozpoczęto",
    "Revise": "Przejrzyj",
    "Declined": "Odrzucono",
    "Bargain-Accepted": "Oferta zaakceptowana",
    "Accepted": "Zaakceptowano",
    "Decline The Offer": "Odrzuć ofertę",
    "Pending": "Oczekujące",
    "Cancelled": "Anulowano",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Wyślij szczegółową wycenę do swojego klienta lub nabywcy. Przedstaw swoją usługę, produkty lub oferty profesjonalnie z spersonalizowaną wyceną.",
    "Wallet to wallet": "Portfel do portfela",
    "Please follow these examples:": "Proszę postępować zgodnie z tymi przykładami:",
    "👤 InstaPay/Instagram Username: instauser": "👤 Nazwa użytkownika InstaPay/Instagram: instauser",
    "📧 Email: user@email.com": "📧 E-mail: user@email.com",
    "Full Name": "Pełne imię",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Witamy w InstaPay! Aby zapewnić sobie możliwość natychmiastowego wypłacania środków, które otrzymujesz, musisz skonfigurować swoje konta odbiorcze w ustawieniach. Pozwoli ci to na bezproblemowe przesyłanie pieniędzy na preferowane konta bankowe, portfele mobilne, portfele kryptowalutowe lub miejsca odbioru gotówki.",
    "You Have No Beneficiary": "Nie masz beneficjenta",
    "Add Now": "Dodaj teraz",
    "Wallet-Status": "Status portfela",
    "Select Payment Card": "Wybierz kartę płatniczą",
    "Your funds have been credited to your wallet.": "Twoje środki zostały przelane na Twój portfel.",
    "Thanks for using InstaPay": "Dziękujemy za korzystanie z InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Otrzymuj pieniądze natychmiast: Skonfiguruj swoje konta odbiorcze teraz!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Postępuj zgodnie z tymi prostymi krokami, aby skonfigurować swoje konta odbiorcze i zawsze mieć dostęp do swoich środków:",
    "STEP 01": "KROK 01",
    "Go to Settings": "Przejdź do Ustawień",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Otwórz aplikację InstaPay i przejdź do sekcji \"Ustawienia\".",
    "STEP 02": "KROK 02",
    "Select Receiving Accounts": "Wybierz Konta Odbiorcze",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Stuknij \"Konta Odbiorcze\", aby rozpocząć dodawanie swoich danych.",
    "Start Adding Accounts": "Zacznij Dodawać Konta",
    "Phone Number: 44795396600 (With Country Code)": "Numer Telefonu: 44795396600 (Z Kodem Kraju)",
    "Select Country First": "Wybierz kraj jako pierwszy",
    "First Name": "Imię",
    "Last Name": "Nazwisko",
    "Address Line": "Linia adresowa",
    "Email Address": "Adres e-mail",
    "Select City": "Wybierz miasto",
    "Select Gender": "Wybierz płeć",
    "Select Occupation": "Wybierz zawód",
    "Male": "Mężczyzna",
    "Female": "Kobieta",
    "Non-Binary": "Niebinarny",
    "Prefer Not To Say'": "Wolę nie mówić",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Nie znaleziono użytkownika",
    "Email Address ": "Adres e-mail",
    "Select Document": "Wybierz dokument",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Zaprezentuj swoją pracę, zwiększ swój zasięg. 'Moje portfolio' to Twoje cyfrowe płótno na Instapay. Prześlij i wyświetl szeroką gamę treści – od przyciągających uwagę obrazów i filmów po informacyjne pliki PDF. Niezależnie od tego, czy jesteś artystą, freelancerem, czy przedsiębiorcą, ta funkcja pozwala Ci prezentować swoją pracę lub projekty w sposób wizualnie angażujący.",
    "Add New": "Dodaj nowe",
    "Money Out": "Wypłata",
    "Browsername": "Nazwa przeglądarki",
    "Browserversion": "Wersja przeglądarki",
    "Credit": "Kredyt",
    "Debit": "Debet",
    "Top Up": "Doładować",
    "Wallet To Wallet": "Portfel do portfela",
    "Accountant": "Księgowy",
    "Architect": "Architekt",
    "Artist": "Artysta",
    "Banker": "Bankier",
    "Business Owner": "Właściciel firmy",
    "Consultant": "Konsultant",
    "Doctor": "Lekarz",
    "Engineer": "Inżynier",
    "Farmer": "Rolnik",
    "Government Employee": "Pracownik rządowy",
    "IT Professional": "Specjalista IT",
    "Lawyer": "Prawnik",
    "Nurse": "Pielęgniarka",
    "Retailer": "Sprzedawca detaliczny",
    "Salesperson": "Sprzedawca",
    "Student": "Uczeń",
    "Teacher": "Nauczyciel",
    "Prefer Not to Say": "Wolę nie mówić",
    "Timezone": "Strefa czasowa",
    "Subscription Payment": "Płatność subskrypcyjna",
    "Starting From": "Począwszy od",
    "Recurring Cycle": "Cykl powtarzający się",
    "Until I stop": "Dopóki nie przestanę",
    "Schedule To": "Zaplanuj na",
    "Schedule at": "Zaplanuj na",
    "Selected time must be at least 5 minutes from now.": "Wybrany czas musi wynosić co najmniej 5 minut od teraz.",
    "They Recieve": "oni otrzymują",
    "Attach Files": "Dołącz pliki",
    "Wallet Selected": "Wybrano portfel",
    "You Have Selected PKR Currency Wallet For This Transaction": "Wybrałeś portfel walutowy PKR do tej transakcji",
    "Instant": "Natychmiastowy",
    "Subscription": "Subskrypcja",
    "Schedule": "Harmonogram",
    "Sms/Email Verification Code": "Kod weryfikacyjny SMS/email",
    "Confirm Your Password": "Potwierdź swoje hasło",
    "This Security Feature Requires Password Confirmation!": "Ta funkcja zabezpieczeń wymaga potwierdzenia hasła!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Użyj aplikacji, aby zeskanować kod QR lub użyj swojego tajnego kodu, aby aktywować dwuskładnikowe uwierzytelnianie Google",
    "OR": "LUB",
    "Paste This Key In The Googe Authenticator App": "Wklej ten klucz w aplikacji Google Authenticator",
    "Show Secret Key": "Pokaż klucz tajny",
    "Enter The Six-Digit Code From The Application": "Wprowadź sześciocyfrowy kod z aplikacji",
    "6 Digit Code": "Kod 6-cyfrowy",
    "Enable": "Włącz",
    "Disable": "Wyłącz",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Po zeskanowaniu obrazu kodu kreskowego aplikacja wyświetli sześciocyfrowy kod, który możesz wprowadzić poniżej",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Dwuskładnikowe uwierzytelnianie Google jest teraz połączone z Twoim kontem Instapay",
    "Min Amount:": "Minimalna kwota:",
    "Minimum Amount": "Minimalna kwota",
    "Maximum Amount": "Maksymalna kwota",
    "Identity Verification Required": "Wymagana weryfikacja tożsamości",
    "Select Your Wallet Currency": "Wybierz walutę portfela",
    "Setup Authenticator": "Skonfiguruj uwierzytelniający",
    "Note": "Notatka",
    "This Is A Test Notice": "To jest ogłoszenie testowe",
    "Session Expired": "Sesja wygasła",
    "You have been logged out for security reasons, please login again to continue.": "Zostałeś wylogowany z powodów bezpieczeństwa, zaloguj się ponownie, aby kontynuować.",
    "Redirecting You To Login Page In 5 Seconds": "Przekierowywanie na stronę logowania za 5 sekund",
    "Searching": "Wyszukiwanie",
    "Recipient Mobile Number": "Numer telefonu odbiorcy",
    "TOP-UP SUMMARY": "Podsumowanie doładowania",
    "Amount": "Kwota",
    "Something went wrong while getting countries.": "Wystąpił błąd podczas pobierania krajów.",
    "Select Destination Country": "Wybierz kraj docelowy.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR nie jest obsługiwany przez PayPal, USD będzie używane jako domyślna waluta.",
    "Select A Date": "Wybierz datę",
    "Select A Timezone": "Wybierz strefę czasową",
    "Proceed": "Kontynuuj",
    "Proceeding": "W trakcie",
    "You Can Now Close This Webpage And Go Back To Instagram": "Możesz teraz zamknąć tę stronę i wrócić do Instagrama",
    "Select A Time": "Wybierz godzinę",
    "Location Permission Denied": "Odmowa dostępu do lokalizacji",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Postępuj zgodnie z poniższymi instrukcjami, aby włączyć usługi lokalizacyjne w przeglądarce po wcześniejszym odrzuceniu zgody:",
    "1: Chrome Browser": "1: Przeglądarka Chrome",
    "Open the Chrome app.": "Otwórz aplikację Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Dotknij menu z trzema kropkami (prawy górny róg) i wybierz Ustawienia.",
    "Scroll down to Site Settings > Location.": "Przewiń w dół do Ustawienia witryny > Lokalizacja.",
    "Locate the website in the list or search for it.": "Znajdź witrynę na liście lub wyszukaj ją.",
    "Tap the website and select Allow under Location Access.": "Dotknij witryny i wybierz Zezwól w sekcji Dostęp do lokalizacji.",
    "2: Firefox Browser": "2: Przeglądarka Firefox",
    "Open the Firefox app.": "Otwórz aplikację Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Dotknij menu z trzema liniami (prawy dolny róg) i przejdź do Ustawień.",
    "Select Privacy & Security > Site Permissions > Location.": "Wybierz Prywatność i bezpieczeństwo > Uprawnienia witryny > Lokalizacja.",
    "Find the website in the list and set the permission to Allow.": "Znajdź witrynę na liście i ustaw uprawnienie na Zezwól.",
    "3: Samsung Internet Browser": "3: Przeglądarka Samsung Internet",
    "Open the Samsung Internet app.": "Otwórz aplikację Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Dotknij menu z trzema liniami (prawy dolny róg) i wybierz Ustawienia.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Przejdź do Witryny i pliki do pobrania > Uprawnienia witryny > Lokalizacja.",
    "Find the website and change the permission to Allow.": "Znajdź witrynę i zmień uprawnienie na Zezwól.",
    "4: Safari Browser": "4: Przeglądarka Safari",
    "Open the Settings app.": "Otwórz aplikację Ustawienia.",
    "Scroll down and select Safari.": "Przewiń w dół i wybierz Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Dotknij Lokalizacja i ustaw na Zapytaj następnym razem lub Zezwalaj podczas używania aplikacji.",
    "Revisit the website and when prompt, allow location access": "Odwiedź ponownie stronę internetową i, gdy pojawi się monit, zezwól na dostęp do lokalizacji.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Porada: Jeśli strona internetowa nie pojawia się w ustawieniach przeglądarki, wyczyść pamięć podręczną lub historię przeglądarki i spróbuj ponownie.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Aby wysłać żądanie płatności, udostępnij swoją lokalizację—pomoże nam to zwiększyć bezpieczeństwo i zbudować zaufanie dla płynniejszego doświadczenia!",
    "Decline": "Odrzuć",
    "Share Location": "Udostępnij lokalizację"
}