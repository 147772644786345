export const sw = {
    "How Others Can Find You": "Jinsi Wengine Wanavyoweza Kukupata",
    "Schedules": "Mipango",
    "Login": "Ingia",
    "Start Your InstaPay Journey Today!": "Anza Safari Yako ya InstaPay Leo!",
    "Phone Number or Email": "Nambari ya Simu au Barua Pepe",
    "Login Using Social Media": "Ingia Kwa Kutumia Mitandao ya Kijamii",
    "Continue": "Endelea",
    "Don't Have an Account? Sign Up": "Huna Akaunti? Jiandikishe",
    "Sign Up": "Jiandikishe",
    "Edit": "Hariri",
    "Enter Your Password": "Weka Nywila Yako",
    "Forgot Password?": "Umesahau Nywila?",
    "Account Type": "Aina ya Akaunti",
    "Individual": "Mtu Binafsi",
    "Business": "Biashara",
    "Already Have an Account? Sign In": "Tayari Una Akaunti? Ingia",
    "Sing In": "Ingia",
    "Enter Your Personal Details": "Weka Maelezo Yako Binafsi",
    "Add Photo or Logo": "Ongeza Picha au Nembo",
    "Enter Your Name": "Weka Jina Lako",
    "Back": "Rudi Nyuma",
    "Phone Number": "Nambari ya Simu",
    "Email Address": "Anwani ya Barua Pepe",
    "Create a Strong Password": "Tengeneza Nywila Imara",
    "Password Requirements": "Mahitaji ya Nenosiri",
    "Must be between 9 and 64 characters": "Lazima iwe kati ya herufi 9 na 64",
    "Include at least two of the following:": "Jumuisha angalau mbili kati ya zifuatazo:",
    "Uppercase character": "Herufi Kubwa",
    "Lowercase character": "Herufi Ndogo",
    "Number": "Nambari",
    "Special character": "Herufi Maalum",
    "Confirm Password": "Thibitisha Nywila",
    "Finalize Your Process": "Maliza Mchakato Wako",
    "Referral Code (Optional)": "Kodi ya Rufaa (Hiari)",
    "It will autofill if you're signing up with an invitation link.": "Itajaza kiotomatiki ikiwa unajiandikisha kupitia kiungo cha mwaliko.",
    "I agree with": "Nakubaliana na",
    "Terms & Conditions": "Masharti na Vigezo",
    "Privacy Policy": "Sera ya Faragha",
    "Create Your Account": "Tengeneza Akaunti Yako",
    "Enter Code": "Weka Kodi",
    "A one-time verification code has been sent to your email address  and phone number.": "Kodi ya kuthibitisha mara moja imetumwa kwa anwani yako ya barua pepe na nambari ya simu.",
    "Resend available in ": "Tuma tena inapatikana katika",
    "seconds": "sekunde",
    "Verify": "Thibitisha",
    "Verification Code Verified": "Kodi ya Kuthibitisha Imethibitishwa",
    "Please continue to the next step.": "Tafadhali endelea kwa hatua inayofuata.",
    "Finish": "Maliza",
    "Finishing": "Kumalizia",
    "Company Name": "Jina la Kampuni",
    "Enter Your Business Details": "Weka Maelezo ya Biashara Yako",
    "Country ": "Nchi",
    "You cannot change the country once your account is verified.": "Huwezi kubadilisha nchi mara tu akaunti yako itakapothibitishwa.",
    "Learn more": "Jifunze zaidi",
    "Optional": "Hiari",
    "Continuing": "Kuendelea",
    "Didn't receive the verification code?": "Hukupokea kodi ya kuthibitisha?",
    "Resend Now": "Tuma Tena Sasa",
    "Search Here": "Tafuta Hapa",
    "Search for users, services, and more on InstaPay": "Tafuta watumiaji, huduma, na zaidi kwenye InstaPay",
    "Search users here": "Tafuta watumiaji hapa",
    "Earn money with our affiliate program!": "Pata pesa na programu yetu ya ushirika!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Pata kipato cha kudumu kwa kushiriki kodi yako ya ushirika ya InstaPay na wafuasi wako. Kwa kila muamala wanaofanya, utapata kamisheni, \nhivyo kuongeza faida kutokana na ushawishi wako mtandaoni.",
    "Learn More": "Jifunze Zaidi",
    "Balance Amount": "Kiasi cha Salio",
    "You spent less compared to last month.": "Umetumia kidogo ukilinganisha na mwezi uliopita.",
    "Referral Rewards": "Zawadi za Rufaa",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Uhamisho wa Haraka kwa Mawasiliano Yako ya Hivi Karibuni:",
    "Number of Transactions": "Idadi ya Miamala",
    "Total Transaction Amount": "Jumla ya Kiasi cha Muamala",
    "Total Sent": "Jumla Ilitumwa",
    "Total Received": "Jumla Ilipokelewa",
    "Add Funds": "Ongeza Fedha",
    "Send Money": "Tuma Pesa",
    "Request Money": "Omba Pesa",
    "Send a Quote": "Tuma Nukuu",
    "Pay With Crypto": "Lipa Kwa Kutumia Crypto",
    "Payment Insights": "Maono ya Malipo",
    "Recent Transactions ": "Miamala ya Hivi Karibuni",
    "Today": "Leo",
    "My Wallets": "Wallet Zangu",
    "Balance": "Salio",
    "Wallet ID": "Kitambulisho cha Wallet",
    "Monthly limit": "Kikomo cha Kila Mwezi",
    "Top Up": "Jaza",
    "Summary": "Muhtasari",
    "Settings": "Mipangilio",
    "More": "Zaidi",
    "Upgrade to Business Account": "Boresha kwa Akaunti ya Biashara",
    "Conversion": "Ubainishaji",
    "Enter Amount": "Weka Kiasi",
    "Exchanged Amount": "Kiasi Kilichobadilishwa",
    "Convert": "Badilisha",
    "You Are Converting": "Unabadilisha",
    "From": "Kutoka",
    "To": "Kwenda",
    "Exchange Rate": "Kiwango cha Kubadilisha",
    "Fees": "Ongeza maoni",
    "You Will Receive": "Utapokea",
    "Slide To Confirm": "Vuta Kuthibitisha",
    "Verification Code Confirmed - You'll be redirected to the next step": "Kodi ya Kuthibitisha Imethibitishwa - Utaelekezwa kwa hatua inayofuata",
    "Moving to next step in": "Kuhamia kwenye hatua inayofuata katika",
    "Thanks for using InstaPay": "Asante kwa kutumia InstaPay",
    "Your funds have been credited to your wallet.": "Fedha zako zimeingizwa kwenye wallet yako.",
    "Dashboard": "Dashibodi",
    "Accounts": "Akaunti",
    "Transactions": "Miamala",
    "Other Transactions": "Miamala Mingine",
    "Payments": "Malipo",
    "Beneficiaries": "Wanufaika",
    "Referrals": "Rufaa",
    "Quotations": "Nukuu",
    "Pending": "Inayosubiri",
    "My QR Code Sticker": "Stika Yangu ya QR Code",
    "My Portfolio": "Portfolio Yangu",
    "My Payment Address": "Anwani Yangu ya Malipo",
    "Analytics": "Uchambuzi",
    "Profile": "Profaili",
    "Dark Mode": "Modi ya Giza",
    "Support": "Msaada",
    "Logout": "Ondoka",
    "Powered By": "Imewezeshwa na",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Kampuni ya Fintech ya Uswisi",
    "MAIN MENU": "MENU KUU",
    "OTHERS": "WENGINE",
    "Wallet": "Wallet",
    "Default": "Chaguo-msingi",
    "Breakdown": "Uchanganuzi",
    "Credit": "Mkopo",
    "Debit": "Deni",
    "Wallet Management": "Usimamizi wa Wallet",
    "Top-up Your Wallet": "Jaza Wallet Yako",
    "Download Statement": "Pakua Taarifa",
    "Block the Wallet": "Zuia Wallet",
    "Wallet Status": "Hali ya Wallet",
    "-Select-File-Type-": "-Chagua-Aina-ya-Faili-",
    "Download Now": "Pakua Sasa",
    "Downloading": "Inapakuliwa",
    "All": "Yote",
    "Sent": "Ilitumwa",
    "Received": "Ilipokelewa",
    "Requested": "Iliyoombwa",
    "Quoted": "Iliyonukuliwa",
    "Search Transactions": "Tafuta Miamala",
    "Date & Time": "Tarehe na Wakati",
    "Type": "Aina",
    "Transaction ID": "Kitambulisho cha Muamala",
    "Recipient": "Mpokeaji",
    "Amount": "Kiasi",
    "Status": "Hali",
    "Payment Type": "Aina ya Malipo",
    "Sent Payments": "Malipo Yaliyotumwa",
    "Received Payments": "Malipo Yaliyopokelewa",
    "Date": "Tarehe",
    "Cycles/Time": "Mizunguko/Muda",
    "Payment As": "Malipo Kama",
    "Name": "Jina",
    "Cancel": "Ghairi",
    "Subscriptions & Scheduled": "Michango na Ilipangwa",
    "Select the option that matches your needs from the list below:": "Chagua chaguo linalolingana na mahitaji yako kutoka kwenye orodha iliyo hapa chini:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Tuma pesa kwa urahisi kwa marafiki, familia, au biashara ndani ya nchi au kimataifa. Chagua kutoka kwa chaguo nyingi za uhamisho, ikiwa ni pamoja \nna amana za benki, Wallet za simu, uchukuzi wa pesa taslimu, kadi za malipo, na uhamisho kutoka Wallet hadi Wallet. Furahia urahisi na usalama wa \nInstaPay kwa uhamisho wa pesa usio na mshono na wa papo hapo.\"",
    "International Transfer": "Uhamisho wa Kimataifa",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Tuma pesa kuvuka mipaka kwa urahisi. Hamisha fedha kimataifa kwa marafiki, familia, au biashara kwa kubofya mara chache tu. Chagua kutoka kwa \nwigo mpana wa njia za malipo ikiwa ni pamoja na akaunti ya benki, pesa za simu/Wallet za simu, eneo la uchukuzi wa pesa taslimu, au kadi ya malipo \nkwa manunuzi ya urahisi na yenye usalama.",
    "Select the country where you want to send the money!": "Chagua nchi unayotaka kutuma pesa!",
    "Search Country": "Tafuta Nchi",
    "Trouble Sending Money?": "Una shida Kutuma Pesa?",
    "Confirm": "Thibitisha",
    "Select Payout Channel": "Chagua Njia ya Malipo",
    "Select your most convenient option to send the money.": "Chagua chaguo linalokufaa zaidi kutuma pesa.",
    "Select The Bank": "Chagua Benki",
    "Search Bank": "Tafuta Benki",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Tuma pesa moja kwa moja kwenye akaunti ya benki ya mpokeaji. Furahia urahisi na usalama wa kuhamisha pesa bila mshono kwenda benki yoyote duniani.",
    "Bank Transfer": "Uhamisho wa Benki",
    "Enter the amount": "Weka kiasi",
    "Tax": "Kodi",
    "Amount the recipient will receive": "Kiasi ambacho mpokeaji atapokea",
    "Select Remarks / Reason": "Chagua Maoni / Sababu",
    "Comments": "Maoni",
    "Attach Files": "Ambatanisha Faili",
    "Groups": "Vikundi",
    "Manage Groups": "Dhibiti Vikundi",
    "Add New Group": "Ongeza Kikundi Kipya",
    "Invite": "Mwalike Mtu",
    "Manage Beneficiaries": "Dhibiti Wapokeaji",
    "Recipients List": "Orodha ya Wapokeaji",
    "Search Contacts": "Tafuta Mawasiliano",
    "Confirm Your Payment": "Thibitisha Malipo Yako",
    "Select Country": "Chagua Nchi",
    "Select the Recipient's Payment Channel": "Chagua Njia ya Malipo ya Mpokeaji",
    "Change Selection": "Badilisha Uchaguzi",
    "You Are Transferring ": "Unahamisha",
    "Oops! Something Went Wrong.": "Samahani! Kuna Tatizo.",
    "We're Sorry About That. ": "Pole kwa hilo.",
    "Go back to Payments Page.": "Rudi kwenye Ukurasa wa Malipo.",
    "Wallet to Wallet Transfer": "Uhamisho wa Wallet kwa Wallet",
    "Request a customized price quote from service providers or vendors.": "Omba nukuu ya bei iliyobinafsishwa kutoka kwa watoa huduma au wauzaji.",
    "Enter the Wallet ID": "Weka Kitambulisho cha Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Weka Kitambulisho cha Wallet, Barua Pepe, au Namba ya Simu ya mpokeaji.",
    "Recipient not on InstaPay?": "Je, Mpokeaji Hayupo kwenye InstaPay? Mwalike.",
    "Invite Them": "Mwalike",
    "My Beneficiaries": "Wapokeaji Wangu",
    "Enter the Amount You Wish to Transfer.": "Weka Kiasi Unachotaka Kutuma.",
    "Sending to ": "Unatuma kwa",
    "You Have Selected Wallet to Wallet Transfer": "Umechagua Uhamisho wa Wallet kwa Wallet",
    "Authenticate Your Payment.": "Thibitisha Malipo Yako.",
    "You are transferring": "Unahamisha",
    "Total ": "Jumla",
    "Where Is My Payment?": "Iko Wapi Malipo Yangu?",
    "Share": "Shiriki",
    "Your Payment Is Confirmed": "Malipo Yako Yamehakikishwa",
    "QR Code Payment": "Malipo ya QR Code",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Hamisha pesa mara moja kwa watumiaji wengine wa InstaPay kupitia huduma yetu salama ya wallet-to-wallet. Tuma na kupokea fedha bila usumbufu ndani ya sekunde.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scan QR kwa Kamera yako au Pakia kutoka kwa Kifaa chako.",
    "Upload from Your Device.": "Pakia kutoka Kwa Kifaa chako.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Mbali na hilo, tumia Anwani ya Malipo ya Pekee ya InstaPay (UPA)",
    "Enter InstaPay UPA": "Weka InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Mpokeaji Hayupo kwenye InstaPay? Mwalike",
    "Verify QR Code": "Thibitisha QR Code",
    "Scan/Upload QR Code of the Recipient": "Scan/Pakia QR Code ya Mpokeaji",
    "Payment Confirmed": "Malipo Yamehakikishwa",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Omba nukuu za bei zilizobinafsishwa kutoka kwa watoa huduma au wauzaji ili kupata makadirio sahihi ya gharama za huduma au bidhaa unazohitaji.",
    "Choose The Beneficiary or Group": "Chagua Mpokeaji au Kikundi",
    "Enter the Amount You Wish to Request.": "Weka Kiasi Unachotaka Kuomba.",
    "You Are Requesting Money": "Unauliza Pesa",
    "You are requesting": "Unauliza",
    "As": "Kama",
    "Conversion of Your Cryptocurrencies": "Ubadilishaji wa Sarafu Zako za Crypto",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Pata uzoefu wetu wa mtumiaji, salama, na uongofu wa Crypto kwenda Fiat uliofanisi.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Chagua Sarafu ya Crypto Unayotaka Kuuza.",
    "Next": "Inayofuata",
    "Enter the Crypto Wallet Address of the Recipient.": "Weka Anwani ya Wallet ya Crypto ya Mpokeaji.",
    "Minimum Transactional Value: $100.": "Thamani ya chini ya Muamala: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Kumbuka: Bei za crypto hubadilika mara kwa mara. Rejelea nukuu ya mwisho kwenye ukurasa wa kuthibitisha oda.",
    "Select Network": "Chagua Mtandao",
    "Send A Quote": "Tuma Nukuu",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Tuma nukuu ya bei ya kina kwa wateja wako. Wasilisha huduma zako, bidhaa, au ofa kwa njia ya kitaalamu na nukuu iliyobinafsishwa.",
    "Choose the Beneficiary": "Chagua Mpokeaji",
    "Allow Receiver to Bargain": "Ruhusu Mpokeaji Kubishana",
    "Enter the Title for Your Quotation.": "Weka Kichwa cha Nukuu Yako.",
    "Enter the Description for Your Quotation": "Weka Maelezo ya Nukuu Yako",
    "You are Quoting": "Unatoa Nukuu",
    "Title": "Kichwa",
    "Description": "Maelezo",
    "Attachments": "Viambatanisho",
    "Confirm Your Quotation": "Thibitisha Nukuu Yako",
    "Verify The Quotation": "Hakiki Nukuu",
    "Your quotation is confirmed": "Nukuu yako imethibitishwa",
    "Request A Quote": "Omba Nukuu",
    "Coming Soon": "Hivi Karibuni",
    "Add Beneficiary": "Ongeza Mpokeaji",
    "Search for Beneficiaries": "Tafuta Wapokeaji",
    "Connected Accounts": "Akaunti Zilizounganishwa",
    "Details": "Maelezo",
    "First Name": "Jina la Kwanza",
    "Last Name": "Jina la Mwisho",
    "Country": "Nchi",
    "City": "Mji",
    "Address Line": "Mstari wa Anwani",
    "Mobile Number": "Nambari ya Simu",
    "Relationship With Beneficiary": "Uhusiano na Mpokeaji",
    "Bank Account": "Akaunti ya Benki",
    "Mobile Money/Mobile Wallet": "Pesa za Mkononi/Wallet ya Mkononi",
    "Payment Card": "Kadi ya Malipo",
    "Crypto Wallet": "Wallet ya Crypto",
    "InstaPay Account": "Akaunti ya InstaPay",
    "Cash Pickup": "Uchukuzi wa Pesa Taslimu",
    "IBAN / SWIFT Code": "IBAN au Nambari ya Swift/BIC",
    "Swift Code": "Nambari ya Swift",
    "Account Number": "Nambari ya Akaunti",
    "Bank Name": "Jina la Benki",
    "Branch Name": "Jina la Tawi",
    "Branch Street": "Mtaa wa Tawi",
    "Province": "Mkoa",
    "Postal Code": "Nambari ya Posta",
    "Mobile Money Provider": "Mtoa Huduma wa Pesa za Mkononi",
    "Wallet Name": "Jina la Wallet",
    "Wallet Number": "Nambari ya Wallet",
    "Card Holder Name": "Jina la Mmiliki wa Kadi",
    "Card Number": "Nambari ya Kadi",
    "Expiry Date": "Tarehe ya Mwisho",
    "Crypto Currency": "Sarafu ya Crypto",
    "Wallet Address": "Anwani ya Wallet",
    "Wallet Holder Name": "Jina la Mmiliki wa Wallet",
    "Wallet Currency": "Sarafu ya Wallet",
    "Select Document Type": "Chagua Aina ya Nyaraka",
    "Enter Document Number": "Weka Nambari ya Nyaraka",
    "Add Individual Account": "Ongeza Akaunti ya Mtu Binafsi",
    "Add Business Account": "Ongeza Akaunti ya Biashara",
    "Company Address": "Anwani ya Kampuni",
    "Company Email": "Barua Pepe ya Kampuni",
    "Company Phone No": "Nambari ya Simu ya Kampuni",
    "Total Referrals": "Jumla ya Rufaa",
    "My Earnings": "Mapato Yangu",
    "Your Referral ID": "Kitambulisho Chako cha Rufaa",
    "Share Your Unique Referral Link": "Shiriki Kiungo Chako cha Pekee cha Rufaa",
    "How do I Refer a Friend to InstaPay?": "Ninawezaje Kumrejelea Rafiki kwa InstaPay?",
    "Step 01": "Hatua ya 01",
    "Share your InstaPay unique referral link with your friends.": "Shiriki kiungo chako cha pekee cha rufaa cha InstaPay na marafiki zako.",
    "Step 02": "Hatua ya 02",
    "Earn rewards when they transact $100 or more": "Pata malipo unapowashawishi kufanya manunuzi ya $100 au zaidi",
    "Step 03": "Hatua ya 03",
    "Earn up to $0.06 for every transaction your followers make.": "Pata hadi $0.06 kwa kila manunuzi yako yatakayofanywa na wafuasi wako.",
    "My Referrals": "Rufaa Zangu",
    "Referral Complete": "Rufaa Imekamilika",
    "Received Quotations": "Nukuu Zilizopokelewa",
    "Sent Quotations": "Nukuu Zilizotumwa",
    "Search Pending Items": "Tafuta Vitu Vinavyosubiri",
    "Actions": "Hatua",
    "Received Requests": "Maombi Yaliyopokelewa",
    "Sent Requests": "Maombi Yaliyotumwa",
    "No Actions": "Hakuna Hatua",
    "You Requested": "Uliomba",
    "Via": "Kupitia",
    "How was your experience?": "Ulikuwa na uzoefu gani?",
    "Leave a Review": "Toa Maoni",
    "Overall Ratings": "Viwango vya Jumla",
    "Visit Profile": "Tembelea Profaili",
    "Ratings": "Viwango",
    "Reviews": "Maoni",
    "No reviews available": "Hakuna maoni yaliyopo",
    "Select Your Currency": "Chagua Sarafu Yako",
    "Proceed": "Endelea",
    "Commision": "Kamisheni",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Fungua Malipo Yasiyo na Usumbufu na Stika ya QR ya InstaPay",
    "Effortless and Swift:": "Rahisi na ya Haraka:",
    "Instantly download your unique InstaPay QR code sticker.": "Pakua papo hapo stika yako ya kipekee ya QR ya InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Uzoefu wa malipo bila pesa taslimu bila kuhitaji miundombinu ngumu kama vile mashine za malipo.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Kusanya malipo kwa haraka – hakuna tena uingizaji wa nambari za kadi ya mkopo au simu.",
    "Each payment includes customer information for your convenience.": "Kila malipo yanajumuisha taarifa za mteja kwa urahisi wako.",
    "Economically Smart:": "Kiuchumi ni Akili:",
    "Zero activation fees – start immediately without upfront costs.": "Hakuna ada za kuanzisha – anza mara moja bila gharama za awali.",
    "Forget about fixed monthly charges; pay as you transact.": "Sahau kuhusu ada za kila mwezi zisizobadilika; lipa unavyofanya manunuzi.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Acha kutumia vifaa; hakuna gharama za mashine inamaanisha kuokoa zaidi kwako.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Furahia ada za chini za manunuzi kwa asilimia 0.75 tu kwa malipo ya QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "Hatuweki kiwango cha chini cha kamisheni; unaokoa zaidi kwa kila manunuzi.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Uzoefu wa furaha ya malipo ya papo hapo, kuongeza mtiririko wako wa fedha.",
    "Make Every Transaction Count with InstaPay": "Fanya Kila Manunuzi Yahesabike na InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Pakua QR",
    "QR Code Status": "Hali ya QR Code",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Shiriki msimbo wako wa QR au kiungo cha anwani ya malipo ili kupokea malipo mara moja kwenye wallet yako ya InstaPay.",
    "QR Code Title": "Jina la QR Code",
    "View Transactions": "Tazama Manunuzi",
    "My QR Codes:": "Misimbo Yangu ya QR:",
    "Load More": "Pakia Zaidi",
    "Benefits Of Portfolio": "Faida za Portfolio",
    "Benefits:": "Faida:",
    "Enhanced Visibility:": "Muonekano Ulioimarishwa:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Inua wasifu wako wa umma. Portfolio yako inafanya kazi kama maonyesho yanayobadilika, yanayopatikana moja kwa moja kutoka kwenye Anwani yako ya Malipo.",
    "Engage Your Audience:": "Shirikisha Watazamaji Wako:",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Unganisha na wageni kupitia safari yako ya ubunifu au ya kitaaluma. Ruhusu kazi yako ikuwakilishe, ukiunda ushirikiano wa kina zaidi na wateja watarajiwa au wafuasi.",
    "Integrated Social Proof:": "Uthibitisho wa Kijamii Uliounganishwa:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Onyesha ushawishi wako kwenye mitandao ya kijamii. Wageni wanaweza kuona uenezi wako kwenye mitandao ya kijamii, ukiongeza uaminifu na muktadha kwenye portfolio yako.",
    "Seamless Transactions:": "Manunuzi Bila Usumbufu:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Wezesha wageni kuomba au kuanzisha malipo moja kwa moja kutoka kwenye ukurasa wako wa umma. Portfolio yako sio tu inaonyesha kazi yako bali pia inarahisisha mwingiliano wa kifedha.",
    "Personalized Storytelling:": "Uelezeo Binafsi:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Tumia sehemu ya 'Kuhusu Mimi' kushiriki hadithi yako, dhamira, au maono, ukiunda uhusiano wa kibinafsi na watazamaji wako.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Portfolio Yangu' sio tu nyumba ya sanaa; ni chombo cha kujenga chapa yako, kushirikisha watazamaji wako, na kurahisisha mwingiliano wako wa kifedha kwenye InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Onyesha Kazi Yako, Panua Ufikiaji Wako. 'Portfolio Yangu' ni turubai yako ya kidijitali kwenye InstaPay. Pakia na onyesha aina mbalimbali ya maudhui – kutoka kwenye picha zenye mvuto na \nvideo hadi kwenye PDF zenye taarifa muhimu. Iwe wewe ni msanii, mtaalamu huru, au mjasiriamali, kipengele hiki kinakuwezesha kuwasilisha kazi zako au miradi yako kwa njia inayovutia \nkwa mtazamo.",
    "Add/Manage Portfolio": "Ongeza/Dhibiti Portfolio",
    "Add New": "Ongeza Mpya",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Boresha Mapato Yako ya Mitandao ya Kijamii na Anwani Yako ya Malipo ya InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Fanya Iwe Rahisi kwa Wafuasi Wako Kukuunga Mkono:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integreshe kwa urahisi Anwani Yako ya Malipo ya InstaPay katika machapisho yako ya mitandao ya kijamii.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Nakili na Bandika kiungo cha anwani yako ya malipo katika kila chapisho lako, hadithi, au kwa uwazi katika wasifu wako.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "ahihi kwa washawishi, wasanii, wajasiriamali, au mtu yeyote mwenye jamii yenye hamu ya kuonyesha usaidizi wao.",
    "Instant Support, Endless Possibilities:": "Msaada wa Papo hapo, Uwezekano Usio na Kikomo:",
    "Transform the way you monetize your social media content.": "Badilisha njia unayotengeneza mapato kupitia maudhui yako ya mitandao ya kijamii.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Ruhusu wafuasi wako kutuma fedha kwako papo hapo kwa kubofya tu, moja kwa moja kutoka kwenye mitandao yao ya kijamii wanayopendelea.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Sahihi kwa msaada wa wakati halisi wakati wa matangazo ya moja kwa moja, kama mfumo wa kutoa vidokezo kwa maudhui yako ya ubunifu, au kufadhili mradi wako mkubwa ujao.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Mahali pa Kuweka Anwani Yako ya Malipo ya InstaPay kwa Athari Kubwa:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Wasifu wa Mitandao ya Kijamii: Weka kwenye sehemu ya wasifu wako au kuhusu sehemu kwa kuonekana kila wakati.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Machapisho na Matangazo: Jumuisha katika machapisho yako ya kibinafsi, hasa yale yanayoonyesha kazi yako au wito maalum wa usaidizi.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Hadithi na Reels: Integreshe katika hadithi zako za kuvutia au reels za kushangaza ili kukamata michango ya papo hapo.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Matangazo ya Moja kwa Moja: Shiriki wakati wa vipindi vyako vya moja kwa moja, kufanya iwe rahisi kwa watazamaji kuchangia kwa wakati halisi.",
    "Simple, Fast, and Secure:": "Rahisi, Haraka, na Salama:",
    "No complex setup – get started in moments.": "Hakuna usanidi mgumu - anza katika muda mfupi.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Furahia usalama na kasi ya usindikaji wa malipo ya kuaminika wa InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Pokea malipo kutoka kwa mtu yeyote, popote, bila wao kuhitaji akaunti ya InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Boresha Mchezo Wako wa Mitandao ya Kijamii na Anwani ya Malipo ya InstaPay.",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Anwani ya Malipo ya Virtual)",
    "Payment Address Title": "Kichwa cha Anwani ya Malipo",
    "Currency": "Sarafu",
    "Payment Address Description": "Maelezo ya Anwani ya Malipo",
    "Update": "Sasisha",
    "Updating": "Kusasisha",
    "Total Transaction": "Jumla ya Muamala",
    "Last 30 days": "Siku 30 zilizopita",
    "Last 1 month": "Mwezi 1 uliopita",
    "Last 1 year": "Mwaka 1 uliopita",
    "Custom Date": "Tarehe Maalum",
    "Payment Methods": "Njia za Malipo",
    "Payment Types": "Aina za Malipo",
    "Requested Amount": "Kiasi Kinachoombwa",
    "Quoted Amount": "Kiasi Kilichonukuliwa",
    "About Me": "Kuhusu Mimi",
    "Followers": "Wafuasi",
    "InstaPay ID": "Kitambulisho cha InstaPay",
    "Download QR Code": "Pakua QR Code",
    "Initiate A Payment": "Anzisha Malipo",
    "Report this person": "Ripoti mtu huyu",
    "Add now": "Ongeza sasa",
    "Recent Reviews": "Mapitio ya Hivi Karibuni",
    "Reviews As Seller": "Mapitio Kama Muuzaji",
    "Reviews As Buyer": "Mapitio Kama Mnunuzi",
    "Select Language": "Chagua Lugha",
    "Profile Completion": "Ukamilifu wa Profaili",
    "Profile Completed": "Profaili Imekamilika",
    "Basic Info": "Taarifa za Msingi",
    "Username": "Jina la Mtumiaji",
    "Gender": "Jinsia",
    "Male": "Mwanaume",
    "Female": "Mwanamke",
    "-Select-Gender-": "-Chagua Jinsia-",
    "Date Of Birth": "Tarehe ya Kuzaliwa",
    "Address": "Anwani",
    "Edit Profile": "Hariri Profaili",
    "Update Profile": "Sasisha Profaili",
    "Updating Profile": "Kusasisha Profaili",
    "Complete Now": "Kamilisha Sasa",
    "Password": "Nenosiri",
    "Change Password": "Badilisha Nenosiri",
    "New Password": "Nenosiri Jipya",
    "Re-enter New Password": "Ingiza tena Nenosiri Jipya",
    "Please follow this guide for a strong password": "Tafadhali fuata mwongozo huu kwa nenosiri imara",
    "Include at least one special character.": "Jumuisha angalau herufi moja maalum.",
    "Minimum of 8 characters.": "Urefu wa angalau herufi 8.",
    "Include at least one number": "Jumuisha angalau namba moja",
    "Change it often for enhanced security.": "Badilisha mara kwa mara kwa usalama zaidi.",
    "Security Questions": "Maswali ya Usalama",
    "Security Question": "Swali la Usalama",
    "Select your question": "Chagua swali lako",
    "Answer To The Question": "Jibu la Swali",
    "Nominee": "Mteule",
    "Relationship with Nominee": "Uhusiano na Mteule",
    "Nominee Contact Number": "Nambari ya Mawasiliano ya Mteule",
    "Nominee Address": "Anwani ya Mteule",
    "Delete Nominee": "Futa Mteule",
    "Update Nominee": "Sasisha Mteule",
    "Details Updated Successfully!": "Maelezo Yamesasishwa kwa Mafanikio!",
    "Success": "Mafanikio",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Unaweza kuchagua Mteule wa chaguo lako, mradi tu si mtoto mdogo, kupitia jukwaa la InstaPay lenyewe. Unakiri kwamba hakuna saini halisi inayohitajika kumteua mteule, na usajili mtandaoni \nutazingatiwa kuwa wa mwisho na unaofunga. Katika tukio la kifo cha mmiliki wa InstaPay Wallet, salio katika Wallet litahamishiwa kwa Mteule aliyerejeshwa. KEMIT KINGDOM itaondolewa \nkwenye madeni yake yote baada ya kukabidhi kiasi kwa Mteule. Mteule atatoa nyaraka zozote zinazohitajika na InstaPay, ikiwa ni pamoja na zile za utambulisho na uthibitisho wa kifo.",
    "Add Nominee": "Ongeza Mteule",
    "Two Factor Authentication": "Uthibitishaji wa Hatua Mbili",
    "SMS Number": "Namba ya SMS",
    "Updated Successfully!": "Imesasishwa Kikamilifu!",
    "Social Network Accounts": "Akaunti za Mitandao ya Kijamii",
    "Here, you can set up and manage your integration settings.": "Hapa, unaweza kuanzisha na kudhibiti mipangilio yako ya ushirikiano.",
    "Social Network Account": "Akaunti ya Mtandao wa Kijamii",
    "Activate Account": "Tumia Akaunti",
    "Enter the code below in the InstaPay Chatbot.": "Ingiza msimbo hapa chini kwenye Chatbot ya InstaPay.",
    "The code will expire in ": "Msimbo utaisha muda wake katika",
    "Notifications": "Arifa",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Rekebisha jinsi unavyopokea arifa. Mipangilio hii inatumika kwa shughuli unazofuatilia.",
    "Activity": "Shughuli",
    "Email": "Barua pepe",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Chanzo",
    "Payment Requests": "Maombi ya Malipo",
    "Bulk Payments": "Malipo ya Wingi",
    "Identity Verification": "Uthibitishaji wa Utambulisho",
    "Verify Your Identity": "Thibitisha Utambulisho Wako",
    "Identity Verification Status:": "Hali ya Uthibitishaji wa Utambulisho:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Elezea kwa ufupi kuhusu wewe na ueleze sababu zako za kutumia InstaPay.",
    "Enter Your Message": "Ingiza Ujumbe Wako",
    "Select Occupation": "Chagua Taaluma",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Inapaswa kuwa hati ya utambulisho iliyotolewa na serikali yenye picha. Hati zote za utambulisho lazima ziandikwe kwa alfabeti ya Kilatini. Ikiwa la, tafsiri iliyothibitishwa inahitajika.",
    "Accepted Documents": "Hati Zinazokubaliwa",
    "A national identity card": "Kitambulisho cha Taifa",
    "A valid passport": "Pasipoti halali",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Ruhusa za kuishi halali (zinakubaliwa tu ikiwa zimetolewa na nchi ya Ulaya au Uswisi)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Uthibitishaji wa Sura ya Kibaiolojia: Kulinganisha video na picha ya pasipoti",
    "Address Verification:": "Uthibitishaji wa Anwani:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Toa taarifa ya benki au bili ya huduma (isiyozidi miezi 3) kama uthibitisho wa anwani",
    "Additional Documents:": "Nyongeza ya Nyaraka:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom ina haki ya kuomba nyaraka zozote za ziada (Uchunguzi wa Kina wa Kisheria - EDD) au taarifa zaidi inayohitajika kwa ajili ya kufanya uchunguzi wa kina, \nkulingana na wajibu wake wa kisheria.",
    "Start Verification": "Anza Uthibitishaji",
    "Sessions": "Vikao",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Hii ni orodha ya vifaa ambavyo vimeingia kwenye akaunti yako. Ondoa vyovyote usivyovitambua.",
    "Your Withdrawal Options": "Chaguo lako la Kutoa Pesa",
    "Choose your preferred method for withdrawing payments.": "Chagua njia unayopendelea ya kutoa malipo.",
    "No withdrawal options currently available in your country.": "Hakuna chaguo za kutoa pesa zilizopo kwa sasa katika nchi yako.",
    "Download Your Data": "Pakua Data Yako",
    "Request a Copy of Your Data": "Omba Nakala ya Data Yako",
    "Submit a request to download a copy of your data": "Tuma ombi la kupakua nakala ya data yako",
    "Which Data Should Be Included in the Download?": "Ni Data Ipi Inapaswa Kujumuishwa Katika Upakuaji?",
    "Select all applicable options.": "Chagua chaguo zote zinazotumika.",
    "Personal Information": "Taarifa Binafsi",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Taarifa hii inatumika kuhakikisha usalama wa akaunti yako, kuboresha uzoefu wako, na kuwasiliana nawe kama inavyohitajika.",
    "Name and Date Of Birth": "Jina na tarehe ya kuzaliwa",
    "Phone number": "Nambari ya simu",
    "Financial Information": "Taarifa za Kifedha",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Tunatumia taarifa hii kurahisisha malipo na kutuma au kupokea pesa kwa haraka.",
    "Bank accounts": "Akaunti za benki",
    "Debit or credit cards": "Kadi za deni au za mkopo",
    "Credit Information": "Taarifa ya Mkopo",
    "Includes your credit application information": "Inajumuisha taarifa yako ya maombi ya mkopo",
    "Other Information (not included in file)": "Taarifa Nyingine (ambazo hazijajumuishwa kwenye faili)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Inajumuisha taarifa za kifaa, data za matumizi ya kiufundi, taarifa za geolocation, mapendeleo ya masoko, historia ya idhini, na data zinazotumika kwa huduma nyingine kama vile mkopo, \nuthibitishaji wa utambulisho, mawasiliano na PayPal, na wasindikaji wa chama cha tatu.",
    "Choose file type:": "Chagua aina ya faili:",
    "Select file type": "Chagua aina ya faili",
    "PDF file can be easily opened on any computer": "Faili ya PDF inaweza kufunguliwa kwa urahisi kwenye kompyuta yoyote",
    "Submit Request": "Tuma Ombi",
    "Delete Your Account": "Futa Akaunti Yako",
    "Ask us to delete your data and close this account": "Tuombe tufute data yako na kufunga akaunti hii",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Tunatumia data yako kutoa huduma, kwa hivyo ikiwa tutafuta data yako, pia tunahitaji kufunga akaunti yako.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Ikiwa akaunti yako ina kikomo, nenda kwenye Kituo cha Maamuzi, chagua 'Nenda kwa Vikomo vya Akaunti', kisha bonyeza 'Tatua'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Hakikisha malipo yoyote yaliyosalia yameondolewa. Hatutaweza kufunga akaunti yako mpaka yafanyike.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Ikiwa una salio hasi, bonyeza kiungo cha Tatua karibu na salio lako.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Mara tu akaunti yako ikiwa safi, na ikiwa unastahiki, tutafunga akaunti yako na kufuta data yako. Tunahitaji kuhifadhi baadhi ya data yako kwa sababu za kisheria na kanuni. Kujifunza zaidi, tazama ",
    "privacy statement": "taarifa yetu ya faragha",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Tutakutumia barua pepe kukujuza hali, na ikiwa kuna hatua zingine unahitaji kuchukua.",
    "Have more questions about data deletion?": "Una maswali zaidi kuhusu ufutaji wa data?",
    "Contact us": "Wasiliana nasi",
    "I understand that account deletion is permanent and can't be reversed.": "Naelewa kwamba kufuta akaunti ni jambo la kudumu na haliwezi kubadilishwa.",
    "Are you sure you want to close your account?": "Una uhakika unataka kufunga akaunti yako?",
    "Yes, Continue": "Ndiyo, Endelea",
    "Logging In": "Kuingia",
    "Complete your profile to streamline your transaction process.": "Kamilisha wasifu wako ili kurahisisha mchakato wa muamala wako.",
    "Skip": "Ruka",
    "Complete Your Profile": "Kamilisha Wasifu Wako",
    "API Key": "Ufunguo wa API",
    "Get API Key": "Pata Ufunguo wa API",
    "Generating New API Key": "Kutengeneza Ufunguo Mpya wa API",
    "Generate API Key": "Tengeneza Ufunguo wa API",
    "Regenerate API Key": "Tengeneza Tena Ufunguo wa API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Ili kutumia uwezo kamili wa muamala usio na mshono wa InstaPay, fuata hatua hizi za haraka kuzalisha ufunguo wako wa API. Kwanza, jiandikishe kwa akaunti katika\n[URL yako ya usajili] na ufikie Dashibodi ya Msanidi. Nenda kwenye sehemu ya 'Ufunguo wa API', zalishe ufunguo mpya, na hakikisha unahifadhiwa kwa usalama.\n",
    "Delete API Key": "Futa Ufunguo wa API",
    "Webhook URL": "URL ya Webhook",
    "KYB Verification": "Uhakiki wa KYB",
    "Additional Files": "Faili Zaidi",
    "Business Verification": "Uhakiki wa Biashara",
    "--Select-Your-Company-Type--": "--Chagua-Aina-Ya-Kampuni-Yako--",
    "Documents Required:": "Nyaraka Zinazohitajika:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Donondoo Rasmi ya Usajili wa Kampuni (Hati ya Usajili/Uingizaji wa Kikundi)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Dondoo hii inatumika kama kitambulisho rasmi cha kampuni na inapaswa kupatikana kutoka kwa usajili wa biashara wa eneo husika. Inaonyesha hali ya sasa ya biashara (iwapo iko hai au imefungwa).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Nyaraka hiyo inapaswa kuwa ya sasa, tarehe ya kutoa isizidi miezi 3.",
    "The document must include the following details:": "Hati hiyo lazima iwe na maelezo yafuatayo:",
    "Legal name of the company": "Jina la kisheria la kampuni",
    "Full registered address": "Anwani kamili iliyosajiliwa",
    "Company registration number": "Nambari ya usajili wa kampuni",
    "Date of incorporation": "Tarehe ya uingizaji",
    "Description of the company's activities/purpose": "Maelezo ya shughuli/kipengele cha kampuni",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Taarifa za mwakilishi wa kisheria: jina kamili, tarehe na mahali pa kuzaliwa, anwani ya makazi (mahitaji ya chini: nchi ya makazi), na uraia",
    "If applicable, the document should also include:": "Ikiwa inafaa, hati hiyo pia inapaswa kujumuisha:",
    "Brand or trading names": "Majina ya biashara au ya kibiashara",
    "Value Added Tax (VAT) number": "Nambari ya Kodi ya Ongezeko la Thamani (VAT)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Orodha ya Wamiliki Wakuu Wenye Manufaa (UBOs) - hii ni lazima katika nchi fulani, vinginevyo, inaweza kutolewa kama hati tofauti.",
    "Proof of Legal Representative's Authority": "Uthibitisho wa Mamlaka ya Mwakilishi wa Kisheria",
    "This document validates an individual's authority to represent the company": "Hati hii inathibitisha mamlaka ya mtu binafsi kuiwakilisha kampuni",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Kawaida, mwakilishi wa kisheria hupewa jina katika dondoo ya usajili wa kampuni. Hata hivyo, ikiwa hii si hivyo, hati kama vile Wosia wa Wakili au Uwekaji wa Madaraka lazima upatikane.",
    "Power of Attorney/Delegation of Powers:": "Wosia wa Wakili/Uwekaji wa Madaraka:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Mtu anayekabidhi mamlaka lazima awe na haki halali ya kufanya hivyo. Mtu huyu anapaswa kuorodheshwa kama mwakilishi wa kisheria katika dondoo ya usajili wa kampuni.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Muundo wa Umiliki na Nyaraka za Wamiliki Wakuu Wafaidika (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Uthibitisho wa orodha ya kampuni katika soko la hisa husika unapaswa kutolewa. Hii inaweza kuwa ukurasa kutoka kwenye tovuti ya soko la hisa ukionyesha maelezo ya kampuni.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.",
    "Collection of Identification Documents": "Ukusanyaji wa Nyaraka za Utambulisho",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Hati ya utambulisho lazima itolewe na serikali, ikiwa na picha ya mtu huyo. Hati zote za utambulisho lazima ziandikwe kwa alfabeti ya Kilatini. Kama sivyo, takribani tafsiri iliyothibitishwa inahitajika.",
    "Accepted documents:": "Nyaraka zilizokubaliwa:",
    "Valid National Identity Card": "Kadi halali ya Utambulisho wa Taifa",
    "Valid Passport": "Pasipoti halali",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Ruhusa halali za Ukaazi - Hizi zitakubaliwa tu ikiwa zimetolewa na nchi ya Ulaya.",
    "Only legal representatives are subject to the identification document collection process.": "Hati ya utambulisho lazima itolewe na serikali, ikiwa na picha ya mtu huyo. Hati zote za utambulisho lazima ziandikwe kwa alfabeti ya Kilatini. Kama sivyo, takribani tafsiri iliyothibitishwa inahitajika.",
    "Additional Requirement:": "Mahitaji ya Ziada:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "Katika kesi zinazohusisha Wosia wa Kisheria au Ujumbe wa Mamlaka, pande zote mbili (mtu anayetoa mamlaka na anayepokea) lazima watoe nakala ya kitambulisho chao.",
    "Additional Documents": "Nyaraka za Ziada",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Bili ya Huduma (kama vile gesi, umeme, simu ya mkononi au simu ya mezani); au nyaraka iliyotolewa na idara ya serikali inayoonyesha anwani na jina la Mwisho wa mtumiaji (kwa mfano, Cheti cha Makazi); au",
    "Bank Statement; or": "Taarifa ya Benki; au",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "nyaraka iliyotolewa na idara ya serikali inayoonyesha anwani na jina la Mwisho wa mtumiaji (kwa mfano, Cheti cha Makazi).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom ina haki ya kuomba nyaraka zozote za ziada au taarifa zinazohitajika kwa ajili ya kufanya uthibitisho wa kina, kulingana na wajibu wake wa kisheria.",
    "Note:": "Kumbuka:",
    "Partners": "Washirika",
    "Add New Partner": "Ongeza Mshirika Mpya",
    "Designation": "Cheo",
    "Partner Name": "Jina la Mshirika",
    "Partner Email": "Barua Pepe ya Mshirika",
    "Partner Designation": "Cheo cha Mshirika",
    "Add Now": "Ongeza Sasa",
    "Adding": "Kuongeza",
    "Delete Partner": "Futa Mshirika",
    "Delete": "Futa",
    "Deleting": "Kufuta",
    "Are you sure, you want to remove sxz as partner?": "Je, una uhakika unataka kumwondoa sxz kama mshirika?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Donwloadi ya Usajili wa Taasisi (Hati ya Usajili/Uumbaji wa Taasisi)",
    "This document should be either:": "Hati hii inaweza kuwa:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Uthibitisho wa uumbaji wa taasisi, ukitoa maelezo ya taasisi, tarehe ya uumbaji, na kusudi.",
    "A local registry extract:": "Donwloadi ya usajili wa eneo:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Inayohitajika kulingana na sheria za nchi, ikitumika kama 'kitambulisho cha sasa' cha taasisi, kikionyesha hali yake (hai au imefungwa).",
    "If a registry extract exists, it must not be older than 3 months.": "Ikiwa kuna donwloadi ya usajili, haipaswi kuwa ya zamani zaidi ya miezi 3.",
    "Entity name": "Jina la taasisi",
    "Registration number (if applicable, based on the country's requirements)": "Nambari ya usajili (ikiwa inafaa, kulingana na mahitaji ya nchi)",
    "Date of creation": "Tarehe ya uumbaji",
    "Description of the entity's activities or purpose": "Maelezo ya shughuli au kusudi la taasisi",
    "Trading names": "Majina ya biashara",
    "Articles of Association": "Makala ya Muungano",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Makala ya Muungano yanatumika kama hati ya kikatiba ya taasisi, ikielezea sheria zake za uendeshaji.\n",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Hakuna kikomo cha muda kwa hati hii, maana yake toleo la hivi karibuni zaidi linahitajika. Kwa kawaida, aina hizi za taasisi zinahitaji mkutano wa kila mwaka ambao unaweza kusababisha toleo \nlililosasishwa la Makala ya Muungano.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Mifano ya mabadiliko yanayoweza kutokea ni pamoja na masasisho kwenye jina la taasisi, anwani, kusudi, bodi ya usimamizi, majukumu, majukumu, n.k.\n",
    "The document should contain information about:": "Hati hiyo inapaswa kuwa na taarifa kuhusu:",
    "The entity itself, including its legal name, registered address, and purpose": "Taasisi yenyewe, ikiwa ni pamoja na jina lake la kisheria, anwani iliyosajiliwa, na kusudi",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Sheria zake za ndani za uendeshaji, kama vile mchakato wa uteuzi wa wanachama wa bodi ya usimamizi, majukumu yao na madaraka, miongoni mwa maelezo mengine. Orodha hii si ya mwisho.",
    "How the entity is funded": "Jinsi taasisi inavyopata ufadhili",
    "Appointment/Removal/Changes on Board of Management Members:": "Uteuzi/Uondolewaji/Mabadiliko kwenye Bodi ya Usimamizi:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Mara nyingi masasisho haya hufanyika kulingana na mahitaji ya shirika au kama ilivyoelezwa katika Makala ya Muungano. Mabadiliko yanarekodiwa kupitia dakika za mikutano ya bodi ya usimamizi, \nambazo zinasajiliwa na kuthibitishwa ili kuonyesha orodha iliyosasishwa ya wanachama, kama vile rais, makamu wa rais, mweka hazina, na katibu mkuu.\"",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Dakika za hivi karibuni za mkutano wa bodi ya usimamizi, pamoja na orodha ya sasa ya wanachama, zitahitajika pamoja na Makala ya Muungano, ikiwa zinapatikana.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Angalizo: Iwapo orodha ya sasa ya wanachama wa bodi ya usimamizi haijajumuishwa katika Makala ya hivi karibuni ya Muungano na hakuna dakika za mkutano wa bodi zinazopatikana, orodha hii lazima \nitolewe. Inapaswa kuwa na tarehe na sahihi ya mwakilishi wa kisheria aliyeteuliwa hivi karibuni, ambaye uteuzi wake unaweza kuthibitishwa kupitia nyaraka husika.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Hati hii inaonyesha mamlaka ya mtu binafsi kuiwakilisha taasisi. Hati hii inaweza kuwa:",
    "The latest Articles of Association": "Makala ya hivi karibuni ya Muungano",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Orodha ya sasa ya wanachama wa bodi ya usimamizi (ambayo inaweza kujumuishwa katika dakika za mkutano wa bodi ya usimamizi)",
    "The document should specify:": "Hati hiyo inapaswa kubainisha:",
    "Full name of the individual(s)": "Jina kamili la mtu/matu)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Jukumu la mtu/matu). Taarifa hii itathibitishwa kwa kulinganisha na Makala ya Muungano ili kuhakikisha kwamba jukumu la mtu huyo linamruhusu kuiwakilisha taasisi. Hii ni muhimu hasa pale \nambapo mwombaji si rais, na majukumu mengine yana mamlaka ya uwakilishi yenye mipaka kama ilivyoelezwa katika Makala ya Muungano.",
    "Additional information about the legal representative that will be collected includes:": "Haya ni maelezo ya ziada yanayohusu mwakilishi wa kisheria yatakayokusanywa:",
    "Date and place of birth": "Anwani ya makazi",
    "Residential address (at least the country of residence should be stated)": "Anwani ya makazi (angalau nchi ya makazi itajwe)",
    "Nationality": "Nambari ya usajili",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Muundo wa umiliki na Wamiliki wa Faida wa Mwisho (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Kawaida, aina hizi za taasisi, kutokana na muundo na madhumuni yao, hazina wamiliki wenye manufaa. Hata hivyo, wanachama wa bodi watatambuliwa na kushughulikiwa kama hao kwa ajili ya \nuchunguzi, ukusanyaji wa kitambulisho na uhakiki, nk.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Idadi ndogo ya vyama inaweza kuwa na wamiliki wenye manufaa, hasa wale wanaozalisha faida. Katika hali kama hizo, maelezo haya yatatamkwa katika Katiba ya Chama. Watu hawa watatambuliwa \nkwa urahisi na kushughulikiwa kama hao, sambamba na wanachama wa bodi ya usimamizi.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Mahitaji ya Ziada: Katika kesi zinazohusisha Power of Attorney au Uwakilishi wa Mamlaka, pande zote mbili (mtu anayetoa mamlaka na yule anayepokea) lazima watoe nakala ya hati zao za utambulisho.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Donondoo ya Usajili wa Mfanyabiashara Pekee (Hati ya Usajili/Uundaji wa Kiumbe)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Hati hii inatumika kama rejista ya eneo kwa wafanyabiashara pekee, inayohitajika katika nchi ambapo usajili wa aina hii ni lazima. Inathibitisha kuwa mtu huyo anajihusisha na shughuli za kitaaluma kama \nmfanyabiashara pekee. Jina la hati hii linatofautiana kutoka nchi hadi nchi.",
    "Where applicable, the document should not be older than 3 months.": "Mahali inapohitajika, nyaraka hii haipaswi kuwa na umri wa zaidi ya miezi mitatu. Nyaraka hiyo lazima iwe na:",
    "The document must contain:": "Jina la mtu binafsi",
    "Individual's name": "Tarehe na mahali pa kuzaliwa",
    "Residential address": "Uraia",
    "Registration number": "Mfanyabiashara binafsi ndiye mtu pekee anayetakiwa kwenye mchakato wa kukusanya nyaraka za utambulisho.",
    "The sole trader is the only individual subject to the identification document collection process.": "Hakuna Miamala",
    "No Transactions": "Hakuna Vitu Vilivyosubiri",
    "No Pending Items": "Hakuna Wafaidika",
    "No Beneficiaries": "Tuma Pesa Kwa",
    "Send Money To": "Thibitisho",
    "Confirmation": "Unaweka",
    "You are setting": "kama chaguo-msingi.",
    "as default.": "Awali",
    "Previously": "ilikuwa QR chaguo-msingi.",
    "was default QR.": "Kategoria",
    "Category": "-Chagua-Kutoka-Kwenye-Orodha-",
    "-Select-From-The-List-": "Ada",
    "Add a note": "Makundi Yameongezwa kwa Mafanikio!",
    "Categories Added Successfully!": "Tarehe ya kuanza kwa usajili",
    "Subscription starting date": "Hadi",
    "Until": "Miamala Inayofuata",
    "Next Transaction": "Njia ya Muamala",
    "Transaction Method": "Kubali nukuu",
    "Accept the quotation": "Chagua Sarafu",
    "Choose Currency": "Kubali",
    "Accept": "Bishana na nukuu",
    "Bargain the quotation": "Ingiza Kiasi cha Upinzani:",
    "Enter Counter Amount:": "Bishana",
    "Bargain": "Ghairi nukuu",
    "Decline the quotation": "Je, una uhakika unataka kukataa nukuu hii?",
    "Are you sure, you want to decline this quotation?": "Kataa",
    "Decline": "Akaunti Binafsi",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "Mei",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Ago",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "Today": "Leo",
    "Spent In": "Imetumika Ndani ya",
    "Revised Amount": "Kiasi kilichorekebishwa",
    "You spent more comparing to last month": "Umetumia zaidi ikilinganishwa na mwezi uliopita",
    "Last": "Iliyopita",
    "days": "Siku",
    "Year": "Miaka",
    "Instant Mobile Top-up Across 150+ Countries": "Ongeza Salio la Simu Papo Hapo Katika Nchi Zaidi ya 150",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Furahisha wapendwa wako au ongeza salio la simu yako mwenyewe, popote ulipo! Huduma ya Ongeza Salio la Simu ya InstaPay inakuwezesha kuongeza salio la simu za mkononi papo hapo katika nchi zaidi ya 150. Kamili kwa zawadi au kuhakikisha unaendelea kuwa na mawasiliano, huduma yetu imeundwa kwa urahisi, kasi, na usalama.",
    "How It Works - Step-by-Step Guide:": "Jinsi Inavyofanya Kazi - Mwongozo wa Hatua kwa Hatua:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Chagua 'Ongeza Salio la Simu': Fikia kipengele hiki moja kwa moja ndani ya app ya InstaPay kwenye majukwaa kama WhatsApp, Twitter, Telegram, au Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Chagua Sarafu na Kiwango: Chagua kutoka sarafu nyingi (USD, GBP, EUR, n.k.) na eleza kiasi unachotaka kuongeza.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Ingiza Namba ya Simu: Andika namba unayotaka kuongezea salio—yako au ya mpendwa wako. Mfumo wetu unahakikisha mchakato salama na wa haraka.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Thibitisha na Lipa: Angalia maelezo ya ongezeko la salio mara mbili, thibitisha, na fanya malipo kwa njia unayopendelea. Muamala unashughulikiwa papo hapo na kwa usalama.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Pokea Thibitisho Papo Hapo: Wewe na mpokeaji (ikiwa inafaa) mtapokea taarifa ya uthibitisho mara tu ongezeko la salio litakapofanikiwa.",
    "Benefits:": "Faida:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Ufikiaji wa Kimataifa: Tuma salio la hewa bila taabu kwa zaidi ya nchi 150.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Athari ya Papo Hapo: Ona faida mara moja—kamilifu kwa zawadi za dakika za mwisho au mawasiliano ya dharura.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Usalama Kamili: Furahia amani ya akili na mfumo wetu wa malipo salama.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Chaguo Mbalimbali za Malipo: Chagua kati ya kadi za mkopo, PayPal, au mkoba wako wa InstaPay kwa malipo.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Tayari kusambaza furaha au kuendelea kuwa na mawasiliano? Ongeza salio sasa na ufanye siku ya mtu—au siku yako mwenyewe!",
    "Pick a language": "Chagua lugha",
    "French": "Kifaransa",
    "Spanish": "Kihispania",
    "Portuguese": "Kireno",
    "German": "Kijerumani",
    "Ukrainian": "Kiukraine",
    "Italian": "Kiitaliano",
    "Russian": "Kirusi",
    "Arabic": "Kiarabu",
    "Polish": "Kipolandi",
    "Dutch (Nederlands)": "Kiholanzi",
    "Yoruba": "Kiyoruba",
    "Indonesian": "Kiindonesia",
    "Turkish": "Kituruki",
    "Swahili": "Kiswahili",
    "Hausa": "Kihausa",
    "Hindi": "Kihindi",
    "Urdu": "Kiurdu",
    "Chinese": "Kichina",
    "Select Your Preferred Language": "Chagua Lugha Unayopendelea",
    "Please select the language you would \nlike to use for the eKYC process.": "Tafadhali chagua lugha unayopenda kutumia kwa mchakato wa eKYC.",
    "Select Your Nationality": "Chagua Uraia Wako",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Chagua uraia wako ili kuona hati za utambulisho zinazosaidiwa kwa nchi yako.",
    "Grant permission to use the camera.": "Ruhusu matumizi ya kamera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Gusa au bonyeza \"Ruhusu\" unapoulizwa kutoa ruhusa ya upatikanaji wa kamera ili kunasa hati hiyo.",
    "Upload Your Documents from \nGallery/Photos": "Pakia Hati Zako kutoka kwa Picha/Galeri",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Ikiwa unakutana na matatizo na kamera yako au ikiwa mfumo wako hauna moja, unaweza kupakia picha za hati kutoka kwa hifadhi yako ya ndani.",
    "Upload Your ID.": "Pakia Kitambulisho Chako.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Hakikisha kitambulisho chako ni safi na hakijachakaa ili kuhakikisha uthibitisho wa haraka.",
    "Valid Proof of Address": "Ushahidi wa Anwani Halali",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Hakikisha kwamba ushahidi wako wa anwani ni wa sasa na tarehe ya taarifa iko ndani ya miezi mitatu iliyopita kutoka tarehe ya kuanza kwa uthibitisho wako wa eKYC.\n\n2. Hakikisha ushahidi wako wa anwani ni safi na hakijachakaa ili kuhakikisha uthibitisho wa haraka.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Taarifa Muhimu\n\nTafadhali soma hatua zote za mwongozo huu kwa uangalifu kabla ya kuanza uthibitisho wako wa eKYC.\n\nEpuka makosa ya kawaida:\n\n* Hakikisha nyaraka zako zote ni wazi, zinasomeka, na ziko za kisasa.\n* Hakikisha kwamba taarifa unayotoa inalingana na maelezo yaliyomo kwenye nyaraka zako rasmi.\n* Fuata maagizo kwa umakini ili kuepuka ucheleweshaji au kukataliwa.\n\nKumbuka: \nIkiwa uthibitisho wako wa eKYC utashindikana na unahitaji kuwasilisha tena, utatozwa ada ya ziada kwa ajili ya kuwasilisha tena.\n\nKwa kufuata miongozo hii, unaweza kuhakikisha mchakato wa uthibitisho wa eKYC unakuwa rahisi na wenye mafanikio.\n\nKwa maswali yoyote au msaada, tafadhali wasiliana na timu yetu ya msaada kupitia support@insta-pay.ch",
    "Personal Transactions": "Miamala ya Kibinafsi",
    "Business Transactions": "Miamala ya Biashara",
    "Shopping & Purchases": "Manunuzi na Ununuzi",
    "Bills & Utilities": "Bili na Huduma",
    "Entertainment & Leisure": "Burudani na Pumziko",
    "Investments & Savings": "Uwekezaji na Akiba",
    "Health & Wellness": "Afya na Ustawi",
    "Transportation": "Usafiri",
    "Education": "Elimu",
    "Miscellaneous": "Mengineyo",
    "Family Support": "Msaada wa familia",
    "WALLET": "WALLET",
    "Add a currency": "Ongeza sarafu",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Unapoongeza sarafu, malipo yoyote unayopokea katika sarafu hiyo yatawekwa kwenye salio lako la InstaPay. Sarafu yako ya msingi itatumika kwa kutuma au kuomba malipo isipokuwa ielezwe vinginevyo.",

    "balance amount ": "kiasi cha salio",
    "referral ": "rujukan",
    "crypto wallet balance": "mizani ya pochi ya crypto",
    "money in": "pesa ndani",
    "money out": "pesa nje",
    "analytics": "uchambuzi",
    "see more": "ona zaidi",
    "manage my Wallets and see the transaction details": "simamia Wallets zangu na uone maelezo ya miamala yangu",
    "set as default Wallet": "weka kama Wallet ya chaguo-msingi",
    "add currency": "ongeza sarafu",
    "convert funds ": "geuza fedha",
    "withdraw balance": "toa salio",
    "chart names": "majina ya chati",
    "withdraw balance from PKR wallet": "toa salio kutoka kwenye mkoba wa PKR",
    "select a payout channel": "chagua njia ya malipo",
    "you have entered an amount below the minimum payment range": "umeingiza kiasi kilicho chini ya kiwango cha malipo cha chini",
    "change": "badilisha",
    "edit": "hariri",
    "moving to next step in ...": "kufuata hatua inayofuata ndani ya ...",
    "you are withdrawing ...": "unatoa ...",
    "where is my payment": "malipo yangu yako wapi",
    "download": "pakua",
    "IBAN / SWIFT Code": "IBAN au SWIFT/BIC code",
    "swift code": "SWIFT code",
    "account number": "namba ya akaunti",
    "branch street": "barabara ya tawi",
    "city": "mji",
    "province": "mkoa",
    "postal code": "nambari ya posta",
    "all payout channels": "njia zote za malipo",
    "bussiness beneficiary": "mnufaika wa biashara",
    "no quotation": "hakuna nukuu",
    "no pending items": "hakuna vitu vinavyosubiri",
    "QR status": "Hali ya QR",
    "Portfolio": "Kijitabu",
    "edit whole portfolio page": "hariri ukurasa mzima wa kijitabu",
    "personal account": "akaunti ya kibinafsi",
    "Chart names": "Majina ya chati",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "zaidi",
    "not connected": "haijaunganishwa",
    "code": "msimbo",
    "ratings": "alama",
    "current password": "nywila ya sasa",
    "include at least one uppercase and lowercase": "jumuisha angalau herufi moja kubwa na ndogo",
    "my social network accounts": "akaunti zangu za mitandao ya kijamii",
    "SMS ": "SMS",
    "view identity verfication guide": "tazama mwongozo wa uthibitishaji wa utambulisho",
    "boimetric face recognition": "utambuzi wa uso wa kibaolojia",
    "please select a payer (withdrawl options)": "tafadhali chagua mlipaji (chaguzi za uondoaji)",
    "IBAN number ": "nambari ya IBAN",
    "account holder name": "jina la mwenye akaunti",
    "download data": "pakua data",
    "bank transfer": "uhamisho wa benki",
    "mobile wallet": "mkoba wa simu",
    "all bank names": "majina yote ya benki",
    "pay via card": "lipa kwa kadi",
    "you have entered an amount that is below the payer range": "umeingiza kiasi kilicho chini ya kiwango cha mlipaji",
    "trouble sending money": "shida kutuma pesa",
    "please follow these example": "tafadhali fuata mifano hii",
    "wallet ID ": "ID ya Wallet",
    "username": "jina la mtumiaji",
    "email": "barua pepe",
    "phone number": "nambari ya simu",
    "no user found": "hakuna mtumiaji aliyepatikana",
    "search": "tafuta",
    "scan/upload QR code of the recipient": "changanua/pakia QR code ya mpokeaji",
    "invite them": "waalike",
    "trouble quoting money": "shida ya kutoa bei ya pesa",
    "select a country": "chagua nchi",
    "airtime details": "maelezo ya airtime",
    "enter the phone number": "ingiza nambari ya simu",
    "service type": "aina ya huduma",
    "select a service": "chagua huduma",
    "service list": "orodha ya huduma",
    "amount entered is less than the ....": "kiasi kilichoingizwa ni chini ya ....",
    "total": "jumla",
    "total credit the receiver gets": "mikopo jumla anayopokea mpokeaji",
    "take ": "chukua",
    "OTP has been verified , You'll be redirected......": "OTP imethibitishwa, utahamishwa......",
    "Link Your Instagram Account to InstaPay": "Unganisha Akaunti yako ya Instagram na InstaPay",
    "Step 01 – From Instagram App": "Hatua ya 01 – Kutoka kwenye Programu ya Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Fungua programu ya Instagram, tafuta ukurasa wa wasifu wa \"InstaPay\", chagua \"Ujumbe\" ili kufungua kisanduku cha gumzo, andika/sema \"Habari\" na bonyeza tuma ili kuanzisha mazungumzo na chatbot ya InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Hatua ya 02 – Kutoka kwenye Ukurasa wa Wasifu wa Instagram wa InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot ya InstaPay itaonyesha vifungo viwili vya utekelezaji: \"Jiandikishe\" na \"Unganisha\". Chagua \"Unganisha\", na chatbot itakuomba uingize jina lako la mtumiaji la InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Hatua ya 03 – Kutoka kwenye Ukurasa wa Wasifu wa Instagram wa InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot ya InstaPay itakuomba nambari ya uthibitisho ya InstaPay. Ingiza nambari uliyopata kutoka kwenye mipangilio ya InstaPay chini ya \"Akaunti Zangu za Mitandao ya Kijamii\" na bonyeza tuma.",
    "Step 04 – From InstaPay Instagram Profile Page": "Hatua ya 04 – Kutoka kwenye Ukurasa wa Wasifu wa Instagram wa InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Utapokea ujumbe wa pongezi unaothibitisha kuwa akaunti yako ya Instagram sasa imelandanishwa na InstaPay. Bonyeza \"Menyu Kuu\" kuanza kutumia huduma za InstaPay.",
    "Additional Tips": "Vidokezo vya Ziada",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Linda Akaunti Yako: Hakikisha akaunti yako ya Instagram iko salama kwa kuwezesha uthibitishaji wa vipengele viwili (2FA) ili kulinda huduma zako za InstaPay zilizounganishwa.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Gundua Zaidi: Mara baada ya kuunganishwa, gundua aina mbalimbali za huduma zinazotolewa na InstaPay, kutoka kwa miamala laini hadi uondoaji wa papo hapo.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Endelea Kusasishwa: Fuata masasisho yetu, kwani hivi karibuni tutaunga mkono kuunganisha akaunti zingine za mitandao ya kijamii ili kufanya uzoefu wako uwe umeunganishwa zaidi.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Msaada na Usaidizi: Ikiwa unakutana namasuala yoyote wakati wa mchakato wa kuunganisha, wasiliana na timu yetu ya usaidizi kwa msaada.",
    "Future Enhancements": "Uboreshaji wa Baadaye",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Katika siku za usoni, InstaPay itakuwezesha kuunganisha akaunti zaidi za mitandao ya kijamii, ikikupa kubadilika zaidi na urahisi. Endelea kufuatilia masasisho ya kusisimua!",
    "Personal Transactions": "Miamala ya Kibinafsi",
    "Business Transactions": "Miamala ya Biashara",
    "Shopping & Purchases": "Manunuzi na Ununuzi",
    "Bills & Utilities": "Bili na Huduma",
    "Entertainment & Leisure": "Burudani na Pumziko",
    "Investments & Savings": "Uwekezaji na Akiba",
    "Health & Wellness": "Afya na Ustawi",
    "Transportation": "Usafiri",
    "Education": "Elimu",
    "Miscellaneous": "Mengineyo",
    "Family Support": "Msaada wa familia",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Anza Safari Yako ya InstaPay Leo!",
    "Enter your phone number": "Ingiza nambari yako ya simu",
    "Login with email": "Ingia na barua pepe",
    "Don't have an account?": "Huna akaunti?",
    "Sign Up": "Jiandikishe",
    "Continue": "Endelea",
    "We recommend you download and install the Google Authenticator app": "Tunapendekeza upakue na usakinishe programu ya Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Uthibitishaji wa vipengele viwili (2FA) ndiyo njia bora ya kujilinda mtandaoni.",
    "Strong security with Google Authenticator": "Usalama thabiti na Google Authenticator",
    "Enter your password": "Ingiza nenosiri lako",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Tunapendekeza upakue na usakinishe programu ya Google Authenticator ili kuhakikisha unapata misimbo yako ya uthibitisho mara moja, kuepuka ucheleweshaji wowote au matatizo na SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Msimbo wa Uthibitishaji wa Vipengele Viwili",
    "Problem with the token?": "Tatizo na tokeni?",
    "Verify using SMS": "Tumia SMS kuthibitisha",
    "Verify": "Thibitisha",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Uthibitishaji wa SMS",
    "Enter the SMS code sent to +41********4053": "Ingiza msimbo wa SMS uliotumwa kwa +41********4053",
    "Verify Using Authenticator App": "Tumia programu ya Authenticator kuthibitisha",
    "Resend code in 120 seconds": "Tuma upya msimbo ndani ya sekunde 120",
    "Verify ": "Thibitisha",
    "SMS - Web Mobile": null,
    "My own account": "Akaunti yangu mwenyewe",
    "Friends & Family": "Marafiki na Familia",
    "Aunt": "Shangazi",
    "Brother-in-law": "Shemeji",
    "Cousin": "Binamu",
    "Daughter": "Binti",
    "Father": "Baba",
    "Father-in-law": "Baba mkwe",
    "Friend": "Rafiki",
    "Grandfather": "Babu",
    "Grandmother": "Bibi",
    "Husband": "Mume",
    "Mother": "Mama",
    "Mother-in-law": "Mama mkwe",
    "Nephew": "Mpwa (kijana)",
    "Niece": "Mpwa (msichana)",
    "Self (i.e. the sender, himself)": "Mwenyewe (yaani mtumaji)",
    "Sister": "Dada",
    "Sister-in-law": "Shemeji (wa kike)",
    "Son": "Mwana",
    "Uncle": "Mjomba",
    "Wife": "Mke",
    "Others not listed": "Wengine ambao hawajatajwa",
    "Merchant": "Mfanyabiashara",
    "No relationship": "Hakuna uhusiano",
    "Add Funds": "Ongeza Fedha",
    "Enter the Amount": "Weka Kiasi:",
    "Fees": "Ada:",
    "You Will Get": "Utapata:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Thamani ya muamala inapaswa kuwa kati ya [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Kiasi Batili: Kiwango chako cha sasa cha uthibitishaji wa kitambulisho kinapunguza kiasi cha juu unachoweza kuongeza. Tafadhali boresha uthibitishaji wa kitambulisho chako ili kufaidika na viwango vya juu.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Bei ya sarafu hubadilika mara kwa mara kulingana na hali ya soko. Tafadhali rejelea bei kwenye ukurasa wa uthibitishaji wa agizo kama nukuu yako ya mwisho.",
    "Next": "Inayofuata",
    "Upgrade Identity Verification": "Boresha Uthibitishaji wa Kitambulisho",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Omba pesa kutoka kwa yeyote, popote, pamoja na kwenye majukwaa ya mitandao ya kijamii. Ukiwa na InstaPay, maombi yako ya malipo yanapatikana mara moja yanapokubaliwa na fedha zinatumwa kwa Akaunti zako za Kupokea. Rahisisha miamala yako na udhibiti malipo kwa urahisi na usalama.",
    "You’ve been logged out.": "Umetoka nje.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Kwa usalama wako, umetoka nje baada ya dakika 15 za kutofanya kazi. Tafadhali ingia tena ikiwa unataka kutumia InstaPay.",
    "LOGIN AGAIN": "INGIA TENA",
    "Brother": "Ndugu",
    "Current Password": "Nenosiri la sasa",
    "The new password cannot be the same as the old one": "Nenosiri jipya haliwezi kuwa sawa na lile la zamani.",
    "Minimum password length: [X] characters ": "Urefu wa chini wa nenosiri: [X] herufi",
    "Include at least one uppercase and one lowercase letter.": "Jumlisha angalau herufi moja kubwa na ndogo.",
    "Nominee Information": "Taarifa za Mteule",
    "Verification Code Preferences": "Mapendeleo ya Kadi ya Uthibitishaji",
    "How Others Can Find You": "Jinsi Wengine Wanavyoweza Kukupata",
    "My Social Network Accounts": "Akaunti Zangu za Mitandao ya Kijamii",
    "Login Activity": "Shughuli za Kuingia",
    "Receiving Accounts": "Akaunti za Kupokea",
    "Change Your Password To Continue": "Badilisha Nenosiri Lako ili Uendelee",
    "Continue with": "Endelea na",
    "Back to Login": "Rudi kwenye Kuingia",
    "Send OTP": "Tuma OTP",
    "A Verification code has been sent to your email and number.": "Msimbo wa Uthibitishaji umetumwa kwa barua pepe na namba yako.",
    "Resend available in": "Rudia inapatikana ndani ya",
    "Create a strong Password": "Tengeneza Nenosiri Nguvu",
    "Password must:": "Nenosiri Lazima:",
    "Be between 9 and 64 characters": "Liwe kati ya herufi 9 hadi 64",
    "Include at least two of the following:": "Ijumuisha angalau mbili kati ya zifuatazo:",
    "Lowercase Character": "Herufi Ndogo",
    "Special Character": "Herufi Maalum",
    "Password Updated Successfully!": "Nenosiri Limefanikiwa Kusasishwa!",
    "Click below to login": "Bonyeza chini ili kuingia",

    "Send Money": "Tuma Pesa",
    "Enter the amount": null,
    "Recent Transfer": "Uhamisho wa Hivi Karibuni",
    "See all": "Ona yote",
    "Send again": "Tuma tena",
    "You Send": "Unatuma",
    "They Receive": "Wanapokea",
    "Receive Method": "Njia ya kupokea",
    "Select method": "Chagua njia",
    "Next": "Inayofuata",
    "Trouble sending money?": "Shida ya kutuma pesa?",
    "Where do you want to send money ?": "Unataka kutuma pesa wapi?",
    "Search ": "Tafuta",
    "Confirm": "Thibitisha",
    "Bank Deposit": "Amana ya Benki",
    "Cash Pick-up": "Kuchukua Fedha Taslimu",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Kadi ya Benki",
    "Crypto Wallet": "Wallet Kripto",
    "What is the receiver’s Mobile Wallet?": "Wallet Simu wa mpokeaji ni nini?",
    "Transfer fees": "Ada za uhamisho",
    "Total to pay": "Jumla ya kulipa",
    "Choose the beneficiary!": "Chagua mpokeaji!",
    "Country": "Nchi",
    "Receive method": "Njia ya kupokea",
    "You send": "Unatuma",
    "change": "badilisha",
    "edit": "ongeza mpya",
    "add new": "Wapokeaji",
    "Beneficiaries": "Simamia Wapokeaji",
    "Manage Beneficiaries": "Tafuta",
    "Search": "Inayofuata",
    "CASH PICK-UP": "KUCHUKUA PESA TASLIMU",
    "Where will your receiver collect the money": "Mpokeaji wako atachukua pesa wapi",
    "Cash is available to collect within minutes from any branch of [name] ": "Pesa zinapatikana kuchukuliwa ndani ya dakika kutoka kwa tawi lolote la [jina]",
    "BANK ACCOUNT": "HESABU YA BENKI",
    "Transfer to recipient’s bank account": "Hamisha kwa akaunti ya benki ya mpokeaji",
    "BANK CARD": "KADI YA BENKI",
    "Fast transfer directly to recipient’s bank card": "Uhamisho wa haraka moja kwa moja kwa kadi ya benki ya mpokeaji",
    "CRYPTO WALLET": "WALLET KRIPTO",
    "Fast transfer directly to a crypto wallet": "Uhamisho wa haraka moja kwa moja kwa Wallet kripto",
    "Review transfer": "Kagua uhamisho",
    "Receiver's Details": "Maelezo ya mpokeaji",
    "Edit": "Hariri",
    "Name": "Jina",
    "Reason for sending": "Sababu ya kutuma",
    "Sending": "Kutuma",
    "Receiver gets": "Mpokeaji anapata",
    "Exchange rate": "Kiwango cha ubadilishaji",
    "Estimated time": "Muda uliokadiriwa",
    "Select Payment Type": "Chagua Aina ya Malipo",
    "Credit cards": "Kadi za mkopo",
    "Domestic cards": "Kadi za Ndani",
    "Mobile Wallets": "Wallet Simu",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KADI YA KREDI",
    "Credit Card": "Kadi ya Kredi",
    "Select card": "Chagua kadi",
    "Card Number": "Nambari ya Kadi",
    "Enter the 16-digit card number on the card": "Ingiza nambari ya kadi ya tarakimu 16",
    "Expiry Date": "Tarehe ya Kuisha",
    "Enter the expiration date of the card": "Ingiza tarehe ya kumalizika kwa kadi",
    "CVV Number": "Nambari ya CVV",
    "Enter the 3 or 4 digit number on the card": "Ingiza nambari ya tarakimu 3 au 4 kwenye kadi",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Chagua Wallet",
    "My Wallet": "Wallet yangu",
    "Conversion": "Ubadilishaji",
    "Convert": "Badilisha",
    "Your payment is confirmed": "Malipo yako yamethibitishwa",
    "Thanks for using InstaPay": "Asante kwa kutumia InstaPay",
    "Status": "Hali",
    "Share": "Shiriki",
    "Transfer created": "Uhamisho umeundwa",
    "Payment received": "Malipo yamepokelewa",
    "Payment processed": "Malipo yamechakatwa",
    "Transfer successful": "Uhamisho umefaulu",
    "It may take few hours for the funds to appear in [user name] account": "Inaweza kuchukua masaa machache kwa fedha kuonekana kwenye akaunti ya [jina la mtumiaji]",
    "Transfer failed": "Uhamisho umeshindwa",
    "Transaction Details": "Maelezo ya Muamala",
    "Need help?": "Unahitaji msaada?",
    "Service Details": "Maelezo ya Huduma",
    "Funds Deposit Speed": "Upatikanaji wa Fedha",
    "Cut-off Time": "Muda wa Kukatwa",
    "Bank holiday list": "Orodha ya Sikukuu za Benki",
    "Threshold limits": "Viwango vya Muamala",
    "null": null,
    "Instant, Real Time": "Papo hapo, Wakati Halisi",
    "Same day, T+1": "Siku hiyo hiyo, T+1",
    "Non-instant, T+1": "Sio papo hapo, T+1",
    "Instant Payment, Real Time": "Malipo ya Papo hapo, Wakati Halisi",
    "Transaction processed before 3:00 PM Local time": "Muamala umefanywa kabla ya saa 9:00 Alasiri Saa za eneo",
    "Delivered the same day": "Imewasilishwa siku hiyo hiyo",
    "Transactions processed post 3:00 PM": "Muamala uliofanywa baada ya saa 9:00 Alasiri",
    "T+1 business days": "T+1 siku za kazi",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Malipo yaliyofanywa katika siku za kazi kabla ya saa 9:00 Alasiri yatahifadhiwa katika akaunti ya benki inayopokea kabla ya saa sita usiku siku hiyo hiyo.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Malipo yaliyofanywa katika siku za kazi baada ya saa 9:00 Alasiri yatahifadhiwa kabla ya saa sita usiku siku inayofuata.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Malipo yaliyofanywa siku ya Jumamosi, Jumapili au siku ya sikukuu yatahifadhiwa katika akaunti kabla ya saa sita usiku siku inayofuata ya kazi.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Saa za eneo",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Kiwango cha chini kwa kila muamala:",
    "Maximum per transaction limit:": "Kiwango cha juu kwa kila muamala:",
    "Daily threshold limit:": "Kiwango cha kila siku:",
    "Weekly threshold limit:": "Kiwango cha kila wiki:",
    "Monthly threshold limit:": "Kiwango cha kila mwezi:",
    "Yearly threshold limit:": "Kiwango cha kila mwaka:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Akaunti ya Msingi (Haijathibitishwa)",
    "Identity Verified": "Utambulisho Umeshakubaliwa",

    "Good Evening!": "Habari ya Jioni!",
    "Total Balance": "Jumla ya Mizani ya Akaunti",
    "Quick Transfer": "Uhamisho wa Haraka wa Fedha",
    "No transactions": "Hakuna miamala iliyopatikana",
    "Blocked balance": "Mizani Iliyofungiwa",
    "Available balance": "Mizani Inayopatikana",
    "Insufficient Balance in PKR": "Mizani Haitoshi katika PKR",
    "Chat with us": "Zungumza nasi",
    "Add Currency": "Ongeza Sarafu",
    "Education": "Elimu",
    "Divers": "Mengineyo",
    "Family support": "Msaada wa Familia",
    "Others": "Matumizi Mengine",
    "Transport": "Usafiri",
    "schedule payment": "Malipo Yaliyopangwa",
    "subscription payment": "Malipo ya Kurudia",
    "No payment": "Hakuna malipo yaliyopatikana",
    "No Beneficiaries": "Hakuna Wafaidika Waliopo",
    "Code Postal": "Nambari ya Posta",
    "Additional Information": "Taarifa za Ziada",
    "Gender": "Jinsia",
    "Date Of Birth": "Tarehe ya Kuzaliwa",
    "Nationality": "Utaifa",
    "Occupation": "Kazi (chaguzi zote pia)",
    "ID Type": "Aina ya Kitambulisho (chaguzi zote pia)",
    "ID Number": "Nambari ya Kitambulisho",
    "Select Beneficiary Payment Channels": "Chagua Njia za Malipo kwa Wafaidika",
    "wallet crypto": "Mfuko wa Crypto",
    "Add another wallet": "Ongeza mfuko mwingine",
    "Enterprise": "Biashara",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "Wafaidika wa biashara wanakuja hivi karibuni! Utaweza kufanya miamala ya biashara duniani kote, kupanua upatikanaji wako kuvuka mipaka.",
    "Joined At": "Jiunga tarehe",
    "Profile Link": "Kiungo cha Profaili",
    "comission": "Kamisheni",
    "my withdrawls": "Kuvuta Kwangu",
    "My Referrals": "Rufaa Zangu",
    "search quotation": "Tafuta Nukuu",
    "no quotation": "Hakuna nukuu zilizopatikana",
    "actions": "Vitendo",
    "search request": "Tafuta ombi",
    "no pending items ": "Hakuna vipengele vilivyokosekana vilivyopatikana",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "Shiriki Msimbo wako wa QR au kiungo cha malipo ili upokee malipo papo hapo kwenye pochi yako ya InstaPay",
    "Description": "Maelezo",
    "save": "Hifadhi",
    "Share your Insta-Pay unique payment link": "Shiriki kiungo chako cha malipo cha InstaPay ambacho ni cha kipekee",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Inua wasifu wako wa umma. Kumbukumbu yako inafanya kazi kama maonyesho yenye nguvu, inapatikana moja kwa moja kutoka kwa Anwani yako ya Malipo",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Ungana na hadhira yako kupitia safari yako ya kitaalam au ubunifu. Wacha kazi yako ijionyeshe na kuvutia wateja au wafuasi zaidi kwa kina.",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Onyesha ufikiaji wako wa mitandao ya kijamii. Wageni wanaweza kuona athari yako ya mitandao ya kijamii, ikiongeza uaminifu kwa kumbukumbu yako.",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Waruhusu wageni kuomba au kuanzisha malipo moja kwa moja kutoka kwenye ukurasa wako wa umma. Kumbukumbu yako sio tu maonyesho bali pia njia ya kuwezesha miamala rahisi ya kifedha.",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Tumia sehemu ya \"Kuhusu Mimi\" kushiriki hadithi yako, misheni yako, au maono yako, kujenga uhusiano wa kibinafsi na hadhira yako.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"Kumbukumbu Yangu\" ni zaidi ya jumba la maonyesho; ni zana ya kujenga chapa yako, kujihusisha na hadhira yako, na kurahisisha maingiliano ya kifedha kwenye InstaPay.",
    "No Portfolio Items": "Hakuna Vipengele vya Kumbukumbu Vilivyopatikana",
    "Add New Portfolio Item": "Ongeza Kipengele Kipya cha Kumbukumbu",
    "Title": "Kichwa",
    "drag/drop or upload a file(photo/video/pdf)": "Buruta/Achia au pakia faili (picha/video/pdf)",
    "discard": "Ondoa",
    "save and post ": "Hifadhi na Chapisha",
    "My Payment Address": "Anwani Yangu ya Malipo",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Inafaa kwa washawishi, wasanii, wajasiriamali, au mtu yeyote anayetamani jamii yake ionyeshe msaada kwa urahisi.",
    "Personal Account": "Hali ya Akaunti Binafsi",
    "spent in total": "Jumla ya Matumizi",
    "not connected": "Haijaunganishwa",
    "more": "Zaidi",
    "less": "Chache",
    "profile reports": "Ripoti za Profaili",
    "All notifications are in English langauge": "Taarifa zote ni kwa Kiingereza",
    "timezone": "Eneo la Saa",
    "nominee information": "Maelezo ya Mrithi",
    "full name": "Jina Kamili",
    "Relation with Nominee": "Mahusiano na Mrithi",
    "source": "Chanzo",
    "Google Authenticator": "Google Authenticator",
    "notifications": "Taarifa",
    "login": "Ingia",
    "View Identity Verification Guide": "Tazama Mwongozo wa Uthibitishaji wa Utambulisho",
    "verified": "Imethibitishwa",
    "select ": "Chagua",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Lazima iwe nyaraka ya utambulisho iliyotolewa na serikali yenye picha. Utambulisho wote lazima uwe katika herufi za Kilatini. Vinginevyo, tafsiri iliyothibitishwa inahitajika.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom inahifadhi haki ya kuomba nyaraka za ziada (Utunzaji wa Kina - EDD) au maelezo yoyote yanayohitajika kufanya uthibitishaji kamili, kwa mujibu wa majukumu yake ya kisheria.",
    "sessions": "Vipindi",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Fungua Kutoa Papo Hapo: Weka Akaunti zako za Kupokea Sasa!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Dhibiti kwa urahisi mahali unapotaka kupokea malipo kwa kuongeza maelezo ya akaunti zako za benki, pochi za simu, pochi za crypto, kadi za malipo, au sehemu za kuchukua pesa taslimu. Fuata hatua zilizo hapa chini ili kuhakikisha upatikanaji wa papo hapo wa fedha zako unapohitajika.",
    "Select the receiving channel below and setup the details.": "Chagua njia ya kupokea hapa chini na uweke maelezo.",
    "Please Select A Payer": "Tafadhali Chagua Mlipaji",
    "Number": "Nambari",
    "Account Holder Name": "Jina la Mwenye Akaunti",
    "Province": "Mkoa",
    "postal": "Anwani ya Posta",
    "delete channel": "Futa Njia",
    "set as default": "Weka kama Chaguo-Msingi",
    "edit": "Hariri",
    "all channels are in english language in recieving account page": "Njia zote ziko kwa Kiingereza kwenye ukurasa wa akaunti zinazopokea",
    "select file type": "Chagua Aina ya Faili",
    "download data": "Pakua Data",
    "INTERNATIONAL TRANSFER": "UHAMISHO WA KIMATAIFA",
    "enter the amount": "Weka Kiasi",
    "they recieve ": "Mpokeaji Anapokea",
    "trouble sending money": "Shida kutuma pesa?",
    "select destination country": "Chagua Nchi Lengwa",
    "bank transfer": "Uhamisho wa Benki",
    "recieving method": "Njia ya Kupokea",
    "payer": "Mlipaji",
    "The fee and the received amount may vary depending on the chosen Payment Method": "Ada na kiasi kinachopokelewa kinaweza kutofautiana kulingana na njia ya malipo iliyochaguliwa.",
    "search ": "Tafuta",
    "show more": "Onyesha Zaidi",
    "select remark reasons": "Chagua Sababu za Maoni",
    "credit and debit card": "Kadi za Mkopo na Deni",
    "Transfer Fees": "Ada za Uhamisho",
    "Estimated Time": "Wakati Unaokadiriwa:",
    "within minutes": "Ndani ya Dakika",
    "total ": "Jumla",
    "OTP verifications code": "Msimbo wa Uthibitishaji wa OTP",
    "verify using sms/email": "Thibitisha Kutumia SMS/Barua Pepe",
    "sms/email verification code": "Msimbo wa Uthibitishaji wa SMS/Barua Pepe",
    "Verify Using Authenticator": "Thibitisha Kutumia Authenticator",
    "WALLET TO WALLET TRANSFER": "UHAMISHO WA KUTOKA MFUKO KWENDA MFUKO",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Weka Kitambulisho cha Mfuko, Jina la Mtumiaji, Barua Pepe, au Nambari ya Simu ya mpokeaji.",
    "search": "Tafuta",
    "lookup": "Tafuta",
    "Recipient Not on InstaPay?": "Mpokeaji Sio kwenye InstaPay?",
    "invite them": "Waite",
    "Please follow these examples": "Tafadhali fuata mifano hii:",
    "Wallet ID: GY68J782": "Kitambulisho cha Mfuko: GY68J782",
    "InstaPay/Instagram Username": "Jina la Mtumiaji wa InstaPay/Instagram: instauser",
    "Email: user@email.com": "Barua Pepe: user@email.com",
    "With Country code": "Nambari ya Simu (na Msimbo wa Nchi)",
    "invite friend": "Mwalike Rafiki",
    "invite via phone number": "Mwalike kupitia Nambari ya Simu",
    "invite via email address": "Mwalike kupitia Anwani ya Barua Pepe",
    "next": "Inayofuata",
    "back": "Rudi",
    "Personalized Message": "Ujumbe wa Kibinafsi",
    "available currency": "Sarafu Inayopatikana",
    "attach files": "Ambatisha Faili",
    "total amount": "Jumla ya Kiasi",
    "instant payment": "Malipo ya Haraka",
    "starting from": "Kuanzia Tarehe",
    "recurring cycle": "Mzunguko wa Kurudia",
    "until I stop": "Mpaka Kusimamishwa",
    "start from": "Kuanzia Tarehe",
    "cycle": "Mzunguko",
    "total": "Jumla",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "Mpokeaji anaweza kupokea kiasi kilichorekebishwa kidogo kutokana na viwango vya kubadilisha fedha.",
    "schedule to": "Imepangwa kwa",
    "schedule at": "Imepangwa saa",
    "Scheduled Date": "Tarehe Iliyoratibiwa",
    "Scheduled time": "Saa Iliyoratibiwa",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "Mpokeaji anaweza kupokea kiasi kilichorekebishwa kidogo kutokana na viwango vya kubadilisha fedha.",
    "QR CODE PAYMENT ": "MALIPO YA MSIMBO WA QR",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Vinginevyo, tumia Msimbo wa Alfanumeriki wa InstaPay.",
    "PYAYMENT REQUEST": "OMBI LA MALIPO",
    "choose the beneficiary": "Chagua Mfaidika",
    "trouble requesting money": "Shida Kuomba Pesa?",
    "Standard Request": "Ombi la Kawaida",
    "Subscription Request": "Ombi la Kurudia",
    "Schedule Request": "Ombi Lililopangwa",
    "Security Request": "Ombi la Amana ya Usalama",
    "Instalment Request": "Mpango wa Malipo ya Awamu",
    "Split Request": "Malipo ya Kugawanyika",
    "You recieved": "Umepokea",
    "is this your location?": "Je, huu ndio eneo lako?",
    "yes": "Ndiyo",
    "Authenticator Code": "Msimbo wa Authenticator",
    "Enter a code shown in the app to make sure everything works fine.": "Weka msimbo unaoonyeshwa kwenye programu ili kuhakikisha kila kitu ni sahihi.",
    "SEND A QUOTE": "TUMA NUKUU",
    "Trouble Quoting Money?": "Shida Kutuma Nukuu?",
    "Please follow these examples:": "Tafadhali fuata mifano hii:",
    "Full Name: Muhammad Imtiaz": "Jina Kamili: Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "Jina la Mtumiaji wa InstaPay/Instagram: instauser",
    "Phone Number: 44795396600 (With Country Code)": "Nambari ya Simu: 44795396600 (na Msimbo wa Nchi)",
    "SEND TOP-UP AND E-SIM": "TUMA JUMLA NA E-SIM",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Endelea kuunganishwa kimataifa! Huduma ya Kujaza Salio ya InstaPay inakuwezesha kuongeza salio kwenye simu papo hapo, kununua eSIM, vocha zilizobandikwa, kadi za zawadi, na kulipia bili za huduma katika zaidi ya nchi 150. Inafaa kwa zawadi au kuwa tayari, huduma yetu inaweka kipaumbele urahisi, kasi, na usalama.",
    "MOBILE AIRTIME AND E-SIM": "HEWA YA SIMU YA MKONONI NA E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Chagua nchi ya kuongeza salio kwenye simu!",
    "Other Operator": "Watoa Huduma Wengine",
    "airtime": "Hewa",
    "bundle": "Pakiti",
    "data": "Data",
    "Enter custom amount": "Weka Kiasi Maalum",
    "Credit & Debit Card": "Kadi ya Mkopo na Deni",
    "Bank Transfer": "Uhamisho wa Benki",
    "Mobile Wallet": "Mfuko wa Simu ya Mkononi",
    "InstaPay Wallet": "Mfuko wa InstaPay",
    "PayPal": "PayPal",
    "add card payment": "Ongeza Malipo ya Kadi",
    "save details to use next time.": "Hifadhi Maelezo kwa Matumizi ya Baadaye",
    "Recipient Mobile Number": "Nambari ya Simu ya Mpokeaji",
    "Amount": "Kiasi",
    "Service": "Huduma",
    "Destination": "Lengo",
    "Provider": "Mtoa Huduma",
    "Airtime": "Hewa",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Within minutes": "Ndani ya Dakika",
    "top-up summary": "Muhtasari wa Kuongeza Salio",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR haiwezi kutumika kwenye PayPal, USD itatumika kama chaguo la msingi.",
    "Amount in USD": "Kiasi kwa USD",
    "Send Top-up": "Tuma Salio",
    "available sunservices": "Huduma Zinazopatikana",
    "login with email": "Ingia Kwa Barua Pepe",
    "Already Have an Account?": "Tayari Una Akaunti? Ingia",
    "profile picture": "Picha ya Profaili",
    "add photo/logo": "Ongeza Picha/Logo",
    "first name": "Jina la Kwanza",
    "enter your first name": "Weka Jina Lako la Kwanza",
    "last name": "Jina la Mwisho",
    "enter your last name": "Weka Jina Lako la Mwisho",
    "email(optional)": "Barua Pepe (Hiari)",
    "enter your email address": "Weka Anwani Yako ya Barua Pepe",
    "password must": "Nenosiri Lazima",
    "It will be autofill, if you're signing up with invitation link": "Jaza Moja kwa Moja kwa Kujisajili kwa Mwaliko",
    "change number": "Badilisha Nambari",
    "signup error": "Hitilafu ya Kujisajili",
    "Something went wrong while sending sms!": "Kuna tatizo wakati wa kutuma SMS! Tafadhali jaribu tena baadaye.",
    "Errors": "Hitilafu zimetokea. Tafadhali rejelea orodha ifuatayo kwa maelezo.",
    "Generic error": "Hitilafu ya Kawaida",
    "Trust Payments API requires the 'requests' library": "API ya Malipo ya Trust inahitaji maktaba ya 'requests' kufanya kazi ipasavyo.",
    "Send error": "Hitilafu wakati wa mchakato wa kutuma. Tafadhali jaribu tena.",
    "Receive error": "Hitilafu wakati wa mchakato wa kupokea. Tafadhali jaribu tena.",
    "Invalid credentials provided": "Hati batili. Tafadhali hakikisha na jaribu tena.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Tatizo limetokea wakati wa kuunganisha na seva za Malipo ya Trust. Tafadhali angalia muunganisho wako na ujaribu tena.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Hitilafu isiyotarajiwa wakati wa kuunganisha na seva za Malipo ya Trust. Ikiwa tatizo hili litaendelea, wasiliana na support@trustpayments.com.",
    "Unknown error. If this persists please contact Trust Payments": "Hitilafu isiyojulikana. Tafadhali wasiliana na msaada wa Malipo ya Trust ikiwa tatizo litaendelea.",
    "Incorrect usage of the Trust Payments API": "Matumizi yasiyo sahihi ya API ya Malipo ya Trust. Tafadhali hakikisha ombi na jaribu tena.",
    "Invalid card details": "Maelezo ya kadi si sahihi. Tafadhali angalia na ingiza maelezo sahihi.",
    "Invalid date": "Tarehe batili iliyotolewa.",
    "Invalid date/time": "Muundo batili wa tarehe/wakati. Tafadhali toa muundo sahihi.",
    "Invalid details": "Maelezo batili. Tafadhali hakikisha na ingiza maelezo sahihi.",
    "Card number does not match card type": "Nambari ya kadi hailingani na aina ya kadi iliyotajwa.",
    "Malformed XML": "Hitilafu ya uundaji wa XML. Tafadhali hakikisha na sahihisha XML.",
    "XML does not match schema": "XML hailingani na mpangilio unaotarajiwa.",
    "Invalid file format": "Muundo wa faili batili. Tafadhali tumia muundo unaotumika.",
    "Empty file contents": "Maudhui ya faili tupu. Tafadhali toa maudhui halali.",
    "Invalid file contents": "Maudhui ya faili batili. Tafadhali hakikisha faili na ujaribu tena.",
    "Malformed JSON": "JSON isiyo sahihi. Tafadhali sahihisha muundo wa JSON.",
    "StApi Error": "Hitilafu ya StApi",
    "Invalid fields specified in request": "Sehemu batili zilizotajwa kwenye ombi. Tafadhali hakikisha na sahihisha.",
    "Missing parent": "Habari ya mzazi inakosekana.",
    "Refund requires settled parent or parent that's due to settle today": "Rudisha pesa inahitaji muamala wa mzazi uliosuluhishwa au ule unaotakiwa kusuluhishwa leo.",
    "Refund requires authorisation parent": "Rudisha pesa inahitaji muamala wa idhini ya mzazi.",
    "Refund amount too great": "Kiasi cha kurejesha kinazidi kiwango kinachoruhusiwa.",
    "No acquirer specified": "Hakuna habari ya mpokeaji iliyotolewa.",
    "Repeat amount too great": "Kiasi cha kurudia kinazidi kiwango kinachoruhusiwa.",
    "Split amount too great": "Kiasi cha kugawanya kinazidi kiwango kinachoruhusiwa.",
    "Cannot refund a declined transaction": "Haiwezi kurejesha muamala uliokataliwa.",
    "Refund requires a settled parent": "Rudisha pesa inahitaji muamala wa mzazi uliosuluhishwa.",
    "Reversal requires a cancelled auth parent": "Kufuta kunahitaji muamala wa idhini uliofutwa.",
    "Cannot override amount in child transaction": "Haiwezi kubadilisha kiasi katika muamala wa mtoto.",
    "Cannot override currency in child transaction": "Haiwezi kubadilisha sarafu katika muamala wa mtoto.",
    "Subscription requires RECUR account": "Usajili unahitaji mipangilio ya akaunti ya kurudia.",
    "Subscription requires successful parent": "Usajili unahitaji muamala wa mzazi uliofanikiwa.",
    "Risk Decisions must have AUTH as parent": "Maamuzi ya hatari lazima yahusishwe na muamala wa idhini.",
    "Chargebacks must have AUTH/REFUND as parent": "Kurejesha pesa lazima kuunganishwe na muamala wa idhini au kurejesha pesa.",
    "Refund amount less than Minimum allowed": "Kiasi cha kurejesha ni chini ya kiwango cha chini kinachoruhusiwa.",
    "Refund requires paypaltransactionid": "Kurejesha pesa kunahitaji kitambulisho cha muamala cha PayPal.",
    "Invalid split transaction": "Muamala wa kugawanya batili.",
    "Cannot reverse AUTH processed more than 48 hours ago": "Haiwezi kufuta idhini iliyofanywa zaidi ya masaa 48 yaliyopita.",
    "Reversal requires acquirerreferencedata": "Kufuta kunahitaji data ya kumbukumbu ya mpokeaji.",
    "Cannot reverse AUTH processed by a different acquirer": "Haiwezi kufuta idhini iliyofanywa na mpokeaji tofauti.",
    "Payment type does not support repeats": "Aina ya malipo haiungi mkono miamala inayorudiwa.",
    "Reversal missing required data": "Kufuta kukosa data inayohitajika.",
    "Missing token": "Habari ya tokeni inakosekana.",
    "Subscription with an accountcheck parent not supported on current acquirer": "Usajili na mzazi wa ukaguzi wa akaunti hauungwi mkono na mpokeaji wa sasa.",
    "Subscription cannot be used as a parent": "Usajili hauwezi kutumiwa kama muamala wa mzazi.",
    "Invalid parent": "Aina ya muamala wa mzazi batili.",
    "Payment type does not support refunds": "Aina ya malipo haiungi mkono kurejesha pesa.",
    "Invalid incremental transaction": "Muamala wa nyongeza batili.",
    "Partial reversals not supported": "Kufuta kwa sehemu hakungiwi mkono.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Miamala ya mzazi na mtoto lazima iwe na aina sawa ya malipo kwa maswali ya 3D salama.",
    "Payment type does not support card scheme updates": "Aina ya malipo haiungi mkono masasisho ya mpango wa kadi.",
    "Cannot reverse AUTH at this time, please try again": "Haiwezi kufuta idhini kwa wakati huu. Tafadhali jaribu tena baadaye.",
    "Cannot determine token": "Haiwezekani kubaini tokeni. Tafadhali hakikisha na jaribu tena.",
    "Service Temporarily Disabled": "Huduma imesitishwa kwa muda. Tafadhali jaribu tena baadaye.",
    "Login first request": "Ingia ili kufanya ombi.",
    "Invalid username/password": "Jina la mtumiaji au nenosiri sio sahihi. Tafadhali jaribu tena.",
    "Invalid session": "Kikao si halali. Tafadhali ingia tena.",
    "Session has expired": "Kikao kimekwisha muda wake. Tafadhali ingia tena.",
    "Password expired": "Nenosiri limeisha muda wake. Tafadhali weka upya nenosiri lako.",
    "Password has been previously used": "Nenosiri lilitumika awali. Tafadhali chagua nenosiri jipya.",
    "MyST user account has been locked": "Akaunti ya mtumiaji ya MyST imefungwa. Tafadhali wasiliana na msaada.",
    "New password does not match confirmed password": "Nenosiri jipya halilingani na leno liliohakikishwa.",
    "Incorrect current password": "Nenosiri la sasa sio sahihi. Tafadhali jaribu tena.",
    "Invalid selection": "Chaguo batili lilifanywa. Tafadhali chagua chaguo halali.",
    "User already exists": "Mtumiaji tayari yupo. Tafadhali ingia au weka upya nenosiri ikiwa inahitajika.",
    "No transaction found": "Hakuna muamala uliopatikana.",
    "Invalid selected transactions": "Muamala uliochaguliwa sio sahihi.",
    "Data supplied has not been saved": "Data haijahifadhiwa. Tafadhali jaribu tena.",
    "Invalid request type": "Aina ya ombi si sahihi.",
    "Missing request type, at least one request type must be selected": "Aina ya ombi haipo. Angalau moja lazima ichaguliwe.",
    "Invalid payment type": "Aina ya malipo sio sahihi.",
    "Missing payment type, at least one payment type must be selected": "Aina ya malipo haipo. Angalau moja lazima ichaguliwe.",
    "Invalid error code": "Nambari ya kosa sio sahihi.",
    "Missing error code, at least one error code must be selected": "Nambari ya kosa haipo. Angalau moja lazima ichaguliwe.",
    "Invalid filter description": "Maelezo ya kichujio sio sahihi.",
    "Invalid destination description": "Maelezo ya marudio sio sahihi.",
    "Invalid notification type": "Aina ya taarifa sio sahihi.",
    "Invalid destination": "Marudio sio sahihi.",
    "Invalid field selected": "Uwanja uliyochaguliwa sio sahihi.",
    "Invalid email from address": "Anwani ya barua pepe ya mtumaji sio sahihi.",
    "Invalid email subject": "Mstari wa mada ya barua pepe sio sahihi.",
    "Invalid email type": "Aina ya barua pepe sio sahihi.",
    "Unable to process request": "Haiwezekani kuchakata ombi. Tafadhali jaribu tena baadaye.",
    "No file selected for upload": "Hakuna faili iliyochaguliwa kupakiwa.",
    "Invalid file size": "Ukubwa wa faili sio sahihi. Tafadhali toa faili ya ukubwa unaokubalika.",
    "Invalid filename": "Jina la faili sio sahihi.",
    "Invalid extension": "Kiendelezi cha faili sio sahihi.",
    "User requires at least one site reference": "Mtumiaji anahitaji angalau marejeo ya tovuti moja.",
    "Only ST-level users can have '*' access": "Watumiaji wa kiwango cha ST pekee wanaweza kuwa na haki za ufikiaji '*'.",
    "Request failed": "Ombi limeshindwa. Tafadhali jaribu tena baadaye.",
    "Invalid File Contents": "Maudhui ya faili sio sahihi.",
    "Maximum number of files uploaded": "Idadi ya juu ya faili zilizopakiwa imefikiwa. Tafadhali futa baadhi ya faili na jaribu tena.",
    "Insufficient gateway access privileges": "Haki za ufikiaji wa lango hazitoshi.",
    "Maximum file size limit reached": "Kikomo cha ukubwa wa faili kimefikiwa.",
    "Username(s) must be a valid user(s)": "Majina ya mtumiaji lazima yawe watumiaji halali.",
    "Site reference(s) must be a valid site(s)": "Marejeo ya tovuti lazima yawe tovuti halali.",
    "Unable to send email, please verify the details and try again": "Haiwezekani kutuma barua pepe. Tafadhali hakiki maelezo na jaribu tena.",
    "Negative already exists": "Thamani hasi tayari ipo. Tafadhali hakiki na ufanye marekebisho.",
    "Cannot delete a search owned by another user": "Haiwezi kufuta utafutaji unaomilikiwa na mtumiaji mwingine.",
    "Invalid search": "Utafutaji sio sahihi. Tafadhali jaribu tena na vigezo halali.",
    "Cannot delete the specified search, the search name cannot be found": "Haiwezi kufuta utafutaji uliochaguliwa. Jina la utafutaji halijapatikana.",
    "Search parameter is too short": "Kigezo cha utafutaji ni kifupi sana. Tafadhali weka masharti maalum ya utafutaji.",
    "Duplicate custom fields defined": "Viwanja vya kawaida vilivyo nakiliwa vimeelezwa. Tafadhali ondoa nakala.",
    "Cannot allocate selected users, insufficient privileges": "Haiwezi kugawa watumiaji waliochaguliwa. Haki hazitoshi.",
    "Allocated users have access to additional sites": "Watumiaji waliopewa wanaweza kufikia tovuti za ziada.",
    "Allocated users have access to additional users": "Watumiaji waliopewa wanaweza kufikia watumiaji wa ziada.",
    "User with current role cannot be allocated users": "Watumiaji walio na nafasi ya sasa hawawezi kuwagawia watumiaji wengine.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Tovuti hii inahitaji kivinjari chako kikubali vidakuzi ili kuingia. Vidakuzi vinaweza kukubaliwa kwa kubofya 'Nakubali' hapa chini.",
    "User requires at least one site reference or site group": "Mtumiaji anahitaji angalau marejeo ya tovuti moja au kundi la tovuti.",
    "Allocated users have access to additional site groups": "Watumiaji waliopewa wanaweza kufikia vikundi vya tovuti za ziada.",
    "No statement found": "Hakuna taarifa iliyopatikana.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "Taarifa zilizotolewa hazijasasishwa katika huduma ya tatu ya MobilePay.",
    "Bypass": "Pita",
    "Insufficient access privileges": "Haki za ufikiaji hazitoshi.",
    "Coding error": "Kosa la usimbaji limegunduliwa.",
    "Insufficient privileges": "Haki hazitoshi kumaliza hatua hii.",
    "Invalid request": "Ombi sio sahihi. Tafadhali hakiki na jaribu tena.",
    "Invalid field": "Uwanja uliowekwa sio sahihi. Tafadhali hakiki na urekebishe uingizaji.",
    "Unknown site": "Tovuti haijulikani.",
    "Banned card": "Kadi iliyozuiwa.",
    "XML element parse error": "Kosa la uchambuzi wa kipengele cha XML. Tafadhali hakiki muundo wa XML.",
    "Maestro must use SecureCode": "Muamala wa Maestro lazima utumie SecureCode kwa uthibitisho.",
    "Multiple email addresses must be separated with , or ;": "Anwani nyingi za barua pepe lazima zitenganishwe na koma (,) au nuktamwili (;).",
    "Invalid site reference for alias": "Marejeo ya tovuti sio sahihi kwa jina la alias.",
    "Invalid version number": "Nambari ya toleo sio sahihi. Tafadhali ingiza nambari ya toleo halali.",
    "Unknown user": "Mtumiaji hajulikani. Tafadhali hakiki na jaribu tena.",
    "Cannot determine account": "Haiwezekani kuamua akaunti.",
    "JSON element parse error": "Kosa la uchambuzi wa kipengele cha JSON. Tafadhali hakiki muundo wa JSON.",
    "Wallet type configuration error": "Hitilafu ya usanidi wa aina ya pochi.",
    "Wallet type not supported on this request": "Aina ya pochi haitumiki kwa ombi hili.",
    "The card number you have provided is incorrect, please verify your details and try again": "Nambari ya kadi uliyopewa sio sahihi. Tafadhali hakiki maelezo yako na jaribu tena.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "Msimbo wa usalama (CVV2) uliotoa sio sahihi. Tafadhali hakiki na jaribu tena.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "Tarehe ya kumalizika muda uliyopewa sio sahihi. Tafadhali hakiki maelezo yako na jaribu tena.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "Mwezi wa kumalizika muda uliyopewa sio sahihi. Tafadhali hakiki na jaribu tena.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "Mwaka wa kumalizika muda uliyopewa sio sahihi. Tafadhali hakiki na jaribu tena.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Haiwezekani kuchakata malipo yako kutokana na hitilafu za muunganisho. ID ya Ombi haifanani. Tafadhali jaribu tena.",
    "The issue number you have provided is incorrect, please verify your details and try again": "Nambari ya toleo iliyotolewa sio sahihi. Tafadhali hakiki na jaribu tena.",
    "The payment type you have provided is incorrect, please verify your details and try again": "Aina ya malipo uliyopewa sio sahihi. Tafadhali hakiki na jaribu tena.",
    "Unable to process your payment, please contact the website": "Haiwezekani kuchakata malipo yako. Tafadhali wasiliana na tovuti kwa msaada.",
    "There are errors with these fields: {0}": "Kuna makosa kwenye maeneo haya: {0}",
    "No account found": "Hakuna akaunti iliyopatikana.",
    "Refund cannot be processed": "Rudisho haliwezi kusindika.",
    "Transaction de-activated": "Muamala umezimwa.",
    "Socket receive error": "Hitilafu ya kupokea soketi.",
    "Socket connection error": "Hitilafu ya muunganisho wa soketi.",
    "Socket closed": "Soketi imefungwa.",
    "Invalid data received": "Data sio sahihi iliyopokelewa.",
    "Invalid SQL": "Swala la SQL sio sahihi.",
    "Timeout": "Muda umepita.",
    "Invalid acquirer": "Maelezo ya mpokeaji sio sahihi.",
    "Unable to connect to acquirer": "Haiwezi kuunganishwa na mpokeaji.",
    "Invalid response from acquirer": "Jibu kutoka kwa mpokeaji sio sahihi.",
    "No available transport": "Hakuna usafiri unaopatikana.",
    "File size too large": "Ukubwa wa faili ni mkubwa mno.",
    "Socket send error": "Kosa la kutuma soketi.",
    "Communication error": "Kosa la mawasiliano.",
    "Proxy error": "Kosa la wakala.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Imeshindikana kushughulikia malipo yako kutokana na hitilafu za muunganisho. Tafadhali hakiki maelezo yako na ujaribu tena ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Imeshindikana kushughulikia malipo yako kutokana na hitilafu za muunganisho (hali ya majibu ya HTTP {0}). Tafadhali hakiki maelezo yako na ujaribu tena ({1}).",
    "Wrong number of emails": "Idadi ya anwani za barua pepe si sahihi.",
    "Bank System Error": "Kosa la mfumo wa benki.",
    "Wrong number of transactions": "Idadi ya miamala si sahihi.",
    "Invalid transport configuration": "Usanidi wa usafiri si sahihi.",
    "No valid updates specified": "Hakuna masasisho halali yaliyotolewa.",
    "Transaction reference not found": "Marejeleo ya muamala hayajapatikana.",
    "Settlebaseamount too large": "Kiasi cha msingi cha malipo ni kikubwa mno.",
    "Transaction not updatable": "Muamala hauwezi kusasishwa.",
    "No searchable filter specified": "Hakuna kichujio kinachotafutika kilichobainishwa.",
    "Timeout Error": "Hitilafu ya muda umekwisha. Tafadhali jaribu tena.",
    "3-D Secure Transport Error": "Hitilafu ya usafiri wa 3-D Secure.",
    "Unauthenticated": "Ombi halijathibitishwa.",
    "Site Suspended": "Tovuti imesimamishwa.",
    "No updates performed": "Hakuna masasisho yaliyofanyika.",
    "Invalid Request": "Ombi si sahihi.",
    "Invalid Response": "Majibu si sahihi.",
    "Invalid Acquirer": "Maelezo ya mpokeaji si sahihi.",
    "Invalid account data": "Data ya akaunti si sahihi.",
    "Missing": "Habari haipo.",
    "Payment Error": "Hitilafu ya malipo.",
    "Invalid acquirer for 3-D Secure": "Mpokeaji si sahihi kwa 3-D Secure.",
    "Invalid payment type for 3-D Secure": "Aina ya malipo si sahihi kwa 3-D Secure.",
    "Invalid updates specified": "Masasisho si sahihi yaliyotolewa.",
    "Manual investigation required": "Uchunguzi wa mikono unahitajika.",
    "Invalid headers": "Vichwa si sahihi.",
    "Max fraudscore exceeded": "Alama ya ulaghai imezidi kiwango cha juu.",
    "Invalid filters": "Vichujio si sahihi vilivyotolewa.",
    "Merchant System Error": "Hitilafu ya mfumo wa mfanyabiashara.",
    "Your payment is being processed. Please wait...": "Malipo yako yanachakatwa. Tafadhali subiri...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Haiwezekani kubainisha 'maelezo ya aina ya ombi' na 'maelezo ya aina ya maombi' katika ombi moja.",
    "Acquirer missing original transaction data": "Mpokeaji anakosa data ya awali ya muamala.",
    "Insufficient funds": "Fedha hazitoshi.",
    "Unable to process due to scheme restrictions": "Haiwezekani kuchakata kutokana na vikwazo vya mpango.",
    "Failed Screening": "Upimaji umeshindwa.",
    "Unable to process due to restrictions": "Haiwezekani kuchakata kutokana na vikwazo.",
    "Invalid process": "Mchakato si sahihi.",
    "Risk Referral": "Rufaa ya hatari inahitajika.",
    "Name Pick required": "Jina linahitajika kwa uteuzi.",
    "Address Pick required": "Anwani inahitajika kwa uteuzi.",
    "IP not in range": "Anwani ya IP haipo katika wigo.",
    "Invalid button configuration": "Usanidi wa kitufe si sahihi.",
    "Unrecognised response from acquirer": "Majibu yasiyotambulika kutoka kwa mpokeaji.",
    "Decline": "Kukataa.",
    "Uncertain result": "Matokeo yasiyo na uhakika.",
    "Soft Decline": "Kukataa kidogo.",
    "Refer to Issuer": "Rejelea mpokeaji kwa habari zaidi.",
    "Request is queued please check the transaction later for the status": "Ombi limetiwa foleni. Tafadhali angalia hali ya muamala baadaye.",
    "Generic Retry": "Jaribu tena kwa ujumla.",
    "Soft Decline retry": "Jaribu tena kwa kukataa kidogo.",
    "There has been a problem with your payment, please verify your details and try again": "Kuna tatizo na malipo yako. Tafadhali hakiki maelezo yako na jaribu tena.",
    "Unknown error": "Hitilafu isiyojulikana.",

    "Login": "Ingia",
    "Enter Your Email": "Ingiza barua pepe yako",
    "Login With Phone Number": "Ingia kwa nambari ya simu",
    "Verify Using Sms/Email": "Thibitisha Kwa Kutumia Sms/Barua Pepe",
    "Resend Code In 100 Seconds": "Tuma tena msimbo ndani ya sekunde 100",
    "Verify": "Thibitisha",
    "Verifying": "Inathibitishwa",
    "Last Week": "Wiki iliyopita",
    "Older Transaction": "Muamala wa zamani",
    "Total": "Jumla",
    "Conversion Confirmed": "Ubadilishaji ulithibitishwa",
    "User Account Limit": "Kiwango cha akaunti ya mtumiaji",
    "Monthly Balance Limit": "Kiwango cha salio cha kila mwezi",
    "Total Limit": "Kiwango jumla",
    "Used": "Kilichotumika",
    "Remaining": "Kilichobaki",
    "Allowed Minimum Topup Amount": "Kiasi cha chini kinachoruhusiwa cha kuongeza salio",
    "Allowed Maximum Topup Amount": "Kiasi cha juu kinachoruhusiwa cha kuongeza salio",
    "Incoming Limit": "Kiwango kinachopokelewa",
    "Daily limit": "Kiwango cha Kila Siku",
    "Monthly limit": "Kiwango cha Kila Mwezi",
    "Yearly limit": "Kiwango cha Kila Mwaka",
    "Sending Limit": "Kiwango cha kutuma",
    "Allowed Number Of Transactions": "Idadi ya miamala inayoruhusiwa",
    "Total Amount": "Jumla ya Kiasi",
    "Amount To Send": "Kiasi Cha Kutuma",
    "Destination Amount": "Kiasi Cha Lengo",
    "Note": "Maelezo",
    "Revised Amount": "Kiasi Kilichorekebishwa",
    "Save": "Hifadhi",
    "Date": "Tarehe",
    "Action": "Hatua",
    "Payment Scheduled At": "Malipo Yanayopangwa",
    "Transaction Fee": "Ada ya Transakisheni",
    "Number Of Cycle": "Idadi ya Mizunguko",
    "Subscription Starting Date": "Tarehe ya Kuanza kwa Usajili",
    "Until": "Mpaka",
    "Next Transaction": "Shughuli Inayofuata",
    "Pay Now": "Lipa Sasa",
    "Transaction Method": "Njia ya Shughuli",
    "Mobile Money Provider": "Mtoa Huduma ya Pesa za Simu",
    "Wallet Name": "Jina la Pochi",
    "Wallet Number": "Nambari ya Pochi",
    "Crypto Currency": "Sarafu ya Kidijitali",
    "Wallet Address": "Anwani ya Pochi",
    "Select Document Type": "Chagua Aina ya Hati",
    "Edit Document Number": "Hariri Nambari ya Hati",
    "My Withdrawals": "Uondoaji Wangu",
    "Bargainable": "Inaweza Kujadiliwa",
    "Description": "Maelezo",
    "Attached Files": "Faili Zilizounganishwa",
    "Sent": "Imetumwa",
    "Accepted": "Imeidhinishwa",
    "Decline": "Imekataliwa",
    "Pending": "Inasubiri",
    "Overall Rating": "Tathmini ya Jumla",
    "No Review": "Hakuna Mapitio",
    "0 Rating": "Tathmini 0",
    "Transaction Map": "Ramani ya Transaksheni",
    "Send Money": "Tuma Pesa",
    "Sending Method": "Njia ya Kutuma",
    "Trouble Sending Money": "Matatizo ya Kutuma Pesa",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Ada na Kiasi Kilichopokelewa Kinaweza Kutofautiana Kulingana na Njia ya Malipo Iliyochaguliwa.",
    "Select Method": "Chagua Njia",
    "Completed": "Imekamilika",
    "How Was Your Experience?": "Vipi Ulikuwa na Uzoefu Wako?",
    "Leave A Review": "Acha Mapitio",
    "Review History": "Historia ya Mapitio",
    "Write A Review": "Andika Mapitio",
    "Send And Post": "Tuma na Chapisha",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Mabadiliko ya Crypto hadi Fiat ni suluhisho thabiti na salama linalobadilisha sarafu za crypto kuwa sarafu za fiat kwa urahisi. Inakidhi mahitaji mbalimbali ya watumiaji, ikitoa njia nyingi za malipo zilizobinafsishwa kulingana na mapendeleo ya mtu binafsi na maeneo.\"",
    "Review Created": "Mapitio Yaliyoundwa",
    "Transactions": "Manunuzi",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Buruta/Angusha au Pakia Faili (Picha/Video/Pdf)",
    "Max Size 1GB": "Ukubwa wa Juu 1GB",
    "Title Description": "Maelezo ya Kichwa",
    "Add New": "Ongeza Mpya",
    "Discard": "Futa",
    "Save And Post": "Tuma na Chapisha",
    "Personal Account": "Akaunti Binafsi",
    "Conversion": "Mabadiliko",
    "Spent In Total": "Jumla Iliyotumika",
    "International": "Kimataifa",
    "Recharge": "Recharge",
    "Top Up Wallet To Wallet": "Ongeza Salio kutoka kwa Wallet hadi Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Haijaunganishwa",
    "More": "Zaidi",
    "Less": "Kidogo",
    "Profile Reports": "Ripoti za Profaili",
    "Timezone": "Muda wa Eneo",
    "Cancel": "Batilisha",
    "Full Name": "Jina Kamili",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Nyaraka Zaidi",
    "Profile Complete": "Profaili Kamili",
    "Verified": "Imehakikishwa",
    "Bank Account": "Akaunti ya Benki",
    "Mobile Wallet": "Wallet ya Simu",
    "Cash Pickup": "Uchangishaji wa Pesa",
    "Add": "Ongeza",
    "Configured": "Iliundwa",
    "Email": "Barua Pepe",
    "Download Data": "Pakua Takwimu",
    "Enter The Amount": "Ingiza Kiasi",
    "They Receive": "Wanachopokea",
    "Where Do You Want To Send Money": "Unataka Kutuma Pesa Wapi",
    "Bank Transfer": "Uhamisho wa Benki",
    "Receiving Method": "Njia ya Kupokea",
    "Payer": "Mlipaji",
    "Minimum Amount": "Kiasi Kidogo",
    "Maximum Amount": "Kiasi Cha Juu",
    "Search": "Tafuta",
    "Show More": "Onyesha Zaidi",
    "Select Remark / Reasons (All)": "Chagua Maelezo / Sababu (Zote)",
    "Attach Files": "Ambatisha Faili",
    "Holiday List": "Orodha ya Likizo",
    "No Data To Show": "Hakuna Data ya Kuonyesha",
    "Threshold Limits": "Vizingiti vya Kiwango",
    "Amount Limits": "Vizingiti vya Kiasi",
    "OTP Verification Code": "Nambari ya Uthibitishaji ya OTP",
    "Verify Using Authenticator": "Thibitisha Kwa Kutumia Authenticator",
    "Sms/Email Verification Code": "Nambari ya Uthibitishaji ya Sms/Barua Pepe",
    "Invite Them": "Waalike",
    "Lookup": "Tafuta",
    "Invite Friend": "Mwalike Rafiki",
    "Invite Via Phone Number": "Mwalike Kwa Nambari ya Simu",
    "Invite Via Email Address": "Mwalike Kwa Anwani ya Barua Pepe",
    "Next": "Ifuatayo",
    "Back": "Rudi",
    "Invite Via Email": "Mwalike Kwa Barua Pepe",
    "Available Currency": "Sarafu Inayopatikana",
    "Add Remarks And Comments": "Ongeza Maelezo na Maoni",
    "Recipient Details": "Maelezo ya Mpokeaji",
    "Go Back": "Rudi Nyuma",
    "Trouble Requesting Money": "Tatizo la Kuomba Pesa",
    "Instant": "Mara Moja",
    "Subscription": "Uanachama",
    "Schedule": "Ratiba",
    "Choose Request Type": "Chagua Aina ya Ombi",
    "Mobile Airtime": "Airtime ya Simu",
    "Incorrect Phone Number": "Nambari ya Simu Isiyo Sahihi",
    "Make Sure You Have Entered A Correct Phone Number.": "Hakikisha Umekalia Nambari Sahihi ya Simu.",
    "Close": "Funga",
    "Operator": "Mtoa Huduma",
    "Recipient Mobile Number": "Nambari ya Simu ya Mpokeaji",
    "Amount": "Kiasi",
    "Service": "Huduma",
    "Destination": "Lengo",
    "Top Up Summary": "Muhtasari wa Top Up",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Kiwango cha Kutuma cha Kila Siku Kimetozwa",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Tuma nukuu ya bei ya kina kwa mteja wako au mnunuzi. Onyesha huduma yako, bidhaa zako, au ofa zako kwa njia ya kitaalamu na nukuu ya kibinafsi.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Malipo yaliyofanywa katika siku za kazi kabla ya saa 3:00 PM yatatolewa kwenye akaunti ya benki ya mpokeaji kabla ya katikati ya usiku ya siku hiyo hiyo.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Seti akaunti zako za kupokea katika nchi hadi tatu na toa mara moja fedha kutoka kwa maombi ya malipo yaliyoidhinishwa kwa njia zako za malipo zilizowezeshwa.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom Inahifadhi Haki Ya Kuomba Nyaraka Zingine (Uangalizi Bora - EDD) Au Taarifa Zaidi Zinazohitajika Kufanya Uhakiki Kamili, Kulingana Na Wajibu Wake Wa Kisheria.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Lazima Iwe Ni Nyaraka Ya Kitambulisho Kilichotolewa Na Serikali Na Picha. Nyaraka Zote Za Kitambulisho Lazima Zikubaliane Na Alfabeti Ya Kilatini. Ikiwa Hapana, Tafsiri Iliyothibitishwa Inahitajika.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Onyesha Kazi Yako, Panua Ufikiaji Wako. 'Portfolio Yangu' Ni Kanvasi Yako Ya Kidijitali Kwenye Instapay. Pakia Na Onyesha Aina Mbalimbali Za Maudhui - Kutoka Kwa Picha Na Video Zinazovutia Hadi PDF Zinazofundisha. Iwe Wewe Ni Msanii, Mteja Huru, Au Mjasiriamali, Kipengele Hiki Kinakuwezesha Kuonyesha Kazi Yako Au Miradi Kwa Njia Inayovutia Kwa Macho.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Shiriki Nambari Yako Ya QR Au Kiungo Cha Malipo Ili Kupokea Malipo Mara Moja Katika Insta Wallet Yako.",
    "Afghanistan": "Afghanistan",
    "Aland Islands": "Visiwa vya Åland",
    "Albania": "Albania",
    "Algeria": "Aljeria",
    "American Samoa": "Samoa ya Kimarekani",
    "Andorra": "Andora",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua na Barbuda",
    "Argentina": "Ajentina",
    "Armenia": "Armenia",
    "Aruba": "Aruba",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaijan": "Azerbaijan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Belarusi",
    "Belgium": "Ubelgiji",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Bolivia (Jamhuri ya Watu)",
    "Bonaire - Sint Eustatius and Saba": "Bonaire - Sint Eustatius na Saba",
    "Bosnia and Herzegovina": "Bosnia na Herzegovina",
    "Botswana": "Botswana",
    "Brazil": "Brazili",
    "British Indian Ocean Territory": "Eneo la Bahari la Hindi la Uingereza",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgaria",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Visiwa vya Cabo Verde",
    "Cambodia": "Kambodia",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cayman Islands": "Visiwa vya Cayman",
    "Central African Republic": "Jamhuri ya Afrika ya Kati",
    "Chad": "Chad",
    "Chile": "Chile",
    "China": "Uchina",
    "Christmas Island": "Kisiwa cha Krismasi",
    "Cocos (Keeling) Islands": "Visiwa vya Cocos (Keeling)",
    "Colombia": "Kolombia",
    "Comoros": "Komoro",
    "Congo": "Jamhuri ya Kongo",
    "Congo - Democratic Republic of the": "Jamhuri ya Kidemokrasia ya Kongo",
    "Cook Islands": "Visiwa vya Cook",
    "Costa Rica": "Costa Rica",
    "Cote d'Ivoire": "Cote d'Ivoire",
    "Croatia": "Kroatia",
    "Cuba": "Kuba",
    "Curacao": "Curaçao",
    "Cyprus": "Cyprus",
    "Czech Republic": "Jamhuri ya Czech",
    "Denmark": "Denmark",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "Jamhuri ya Dominika",
    "Ecuador": "Ekwado",
    "Egypt": "Misri",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Guinea Ikweta",
    "Eritrea": "Eritrea",
    "Estonia": "Estonia",
    "Eswatini": "Eswatini",
    "Ethiopia": "Ethiopia",
    "Falkland Islands (Malvinas)": "Visiwa vya Falkland (Malvinas)",
    "Faroe Islands": "Visiwa vya Faroe",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "Ufaransa",
    "French Polynesia": "Polynesia ya Ufaransa",
    "French Southern Territories": "Nchi za Kusini za Ufaransa",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgia",
    "Germany": "Ujerumani",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Ugiriki",
    "Greenland": "Greenland",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Guinea (Conakry)",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Visiwa vya Heard na Visiwa vya McDonald",
    "Holy See": "Mji wa Vatikani",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungary",
    "Iceland": "Island",
    "India": "India",
    "Indonesia": "Indonesia",
    "Iran (Islamic Republic of)": "Iran (Jamhuri ya Kiislamu)",
    "Iraq": "Iraki",
    "Ireland": "Jamhuri ya Ireland",
    "Isle of Man": "Kisiwa cha Man",
    "Israel": "Israeli",
    "Italy": "Italia",
    "Jamaica": "Jamaika",
    "Japan": "Japani",
    "Jersey": "Jersey",
    "Jordan": "Jordan",
    "Kazakhstan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Korea (Jamhuri ya Watu wa Kidemokrasia)",
    "Korea - Republic of": "Korea - Jamhuri ya Korea",
    "Kuwait": "Kuwaiti",
    "Kyrgyzstan": "Kyrgyzstan",
    "Lao People's Democratic Republic": "Laos (Jamhuri ya Lao)",
    "Latvia": "Latvia",
    "Lebanon": "Lebanoni",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libya",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lithuania",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Maldives",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Visiwa vya Marshall",
    "Mauritania": "Mauritania",
    "Mauritius": "Mauritius",
    "Mexico": "Meksiko",
    "Micronesia (Federated States of)": "Micronesia (Nchi za Shirikisho)",
    "Moldova - Republic of": "Moldova - Jamhuri ya",
    "Monaco": "Monaco",
    "Mongolia": "Mongolia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Moroko",
    "Mozambique": "Msumbiji",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepali",
    "Netherlands": "Ufalme wa Uholanzi",
    "New Caledonia": "New Caledonia",
    "New Zealand": "New Zealand",
    "Nicaragua": "Nicaragua",
    "Niger": "Nijeri",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Kisiwa cha Norfolk",
    "North Macedonia": "Macedonia Kaskazini",
    "Northern Mariana Islands": "Visiwa vya Mariana vya Kaskazini",
    "Norway": "Norwei",
    "Oman": "Omani",
    "Pakistan": "Pakistani",
    "Palau": "Palau",
    "Palestine - State of": "Palestina - Jimbo la",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Guinea Mpya",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Ufilipino",
    "Pitcairn": "Visiwa vya Pitcairn",
    "Poland": "Polandi",
    "Portugal": "Ureno",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Romania": "Romania",
    "Russian Federation": "Shirikisho la Urusi",
    "Rwanda": "Rwanda",
    "Saint Barth\u00e9lemy": "Saint Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena - Ascension na Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts na Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (Sehemu ya Ufaransa)",
    "Saint Vincent and the Grenadines": "Saint Vincent na Grenadini",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé na Principe",
    "Senegal": "Senegali",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Sint Maarten (Dutch part)": "Sint Eustatius",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Solomon Islands": "Visiwa vya Solomon",
    "Somalia": "Somalia",
    "South Africa": "Afrika Kusini",
    "South Georgia and the South Sandwich Islands": "South Georgia na Visiwa vya South Sandwich",
    "South Sudan": "Sudan Kusini",
    "Spain": "Hispania",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard na Jan Mayen",
    "Sweden": "Sweden",
    "Switzerland": "Uswisi",
    "Syrian Arab Republic": "Jamhuri ya Kiarabu ya Syria",
    "Tajikistan": "Tajikistan",
    "United Republic of Tanzania": "Jamhuri ya Muungano wa Tanzania",
    "Thailand": "Thailand",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad na Tobago",
    "Tunisia": "Tunisia",
    "Turkey": "Uturuki",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Visiwa vya Turks na Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraina",
    "United Arab Emirates": "Emirates za Kiarabu za Umoja",
    "United Kingdom of Great Britain and Northern Ireland": "Uingereza",
    "United States of America": "Marekani",
    "United States Minor Outlying Islands": "Visiwa vidogo vya Marekani",
    "Uruguay": "Urugwai",
    "Uzbekistan": "Uzbekistani",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Jamhuri ya Kibolivari)",
    "Viet Nam": "Vietnamu",
    "Virgin Islands (British)": "Visiwa vya Virgin (Uingereza)",
    "Virgin Islands (U.S.)": "Visiwa vya Virgin (Marekani)",
    "Wallis and Futuna": "Wallis na Futuna",
    "Yemen": "Yemeni",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Taiwan": "Taiwani",
    "Palestine": "Palestina",
    "Western Sahara": "Sahara Magharibi",
    "Kosovo": "Kosovo",
    "South Ossetia": "Ossetia Kusini",
    "Abkhazia": "Abkhazia",
    "Nagorno-Karabakh (Artsakh)": "Nagorno-Karabakh (Artsakh)",
    "Transnistria": "Transnistria",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Cyprus ya Kaskazini",
    "Saudi Arabia": "Saudi Arabia",
    "Yugoslavia": "Yugoslavia",

    "Login": "Ingia",
    "Enter Your Email": "Weka barua pepe yako",
    "Login With Phone Number": "Ingia kwa nambari ya simu",
    "Verify Using Sms/Email": "Thibitisha Kwa Kutumia Sms/Barua Pepe",
    "Resend Code In 100 Seconds": "Tuma tena msimbo ndani ya sekunde 100",
    "Verify": "Thibitisha",
    "Verifying": "Inathibitishwa",
    "Last Week": "Wiki iliyopita",
    "Older Transaction": "Muamala wa zamani",
    "Total": "Jumla",
    "Conversion Confirmed": "Ubadilishaji ulithibitishwa",
    "User Account Limit": "Kiwango cha akaunti ya mtumiaji",
    "Monthly Balance Limit": "Kiwango cha salio cha kila mwezi",
    "Total Limit": "Kiwango jumla",
    "Used": "Kilichotumika",
    "Remaining": "Kilichobaki",
    "Allowed Minimum Topup Amount": "Kiasi cha chini kinachoruhusiwa cha kuongeza salio",
    "Allowed Maximum Topup Amount": "Kiasi cha juu kinachoruhusiwa cha kuongeza salio",
    "Incoming Limit": "Kiwango kinachopokelewa",
    "Daily Limit": "Kiwango cha Kila Siku",
    "Monthly Limit": "Kiwango cha Kila Mwezi",
    "Yearly Limit": "Kiwango cha Kila Mwaka",
    "Sending Limit": "Kiwango cha kutuma",
    "Allowed Number Of Transactions": "Idadi ya miamala inayoruhusiwa",
    "Total Amount": "Jumla ya Kiasi",
    "Amount To Send": "Kiasi Cha Kutuma",
    "Destination Amount": "Kiasi Cha Lengo",
    "Note": "Maelezo",
    "Revised Amount": "Kiasi Kilichorekebishwa",
    "Save": "Hifadhi",
    "Date": "Tarehe",
    "Action": "Hatua",
    "Payment Scheduled At": "Malipo Yanayopangwa",
    "Transaction Fee": "Ada ya Transakisheni",
    "Number Of Cycle": "Idadi ya Mizunguko",
    "Subscription Starting Date": "Tarehe ya Kuanza kwa Usajili",
    "Until": "Mpaka",
    "Next Transaction": "Shughuli Inayofuata",
    "Pay Now": "Lipa Sasa",
    "Transaction Method": "Njia ya Shughuli",
    "Mobile Money Provider": "Mtoa Huduma ya Pesa za Simu",
    "Wallet Name": "Jina la Pochi",
    "Wallet Number": "Nambari ya Pochi",
    "Crypto Currency": "Sarafu ya Kidijitali",
    "Wallet Address": "Anwani ya Pochi",
    "Select Document Type": "Chagua Aina ya Hati",
    "Edit Document Number": "Hariri Nambari ya Hati",
    "My Withdrawals": "Uondoaji Wangu",
    "Bargainable": "Inaweza Kujadiliwa",
    "Description": "Maelezo",
    "Attached Files": "Faili Zilizounganishwa",
    "Sent": "Imetumwa",
    "Accepted": "Imeidhinishwa",
    "Decline": "Imekataliwa",
    "Pending": "Inasubiri",
    "Overall Rating": "Tathmini ya Jumla",
    "No Review": "Hakuna Mapitio",
    "0 Rating": "Tathmini 0",
    "Transaction Map": "Ramani ya Transaksheni",
    "Send Money": "Tuma Pesa",
    "Sending Method": "Njia ya Kutuma",
    "Trouble Sending Money": "Matatizo ya Kutuma Pesa",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Ada na Kiasi Kilichopokelewa Kinaweza Kutofautiana Kulingana na Njia ya Malipo Iliyochaguliwa.",
    "Select Method": "Chagua Njia",
    "Completed": "Imekamilika",
    "How Was Your Experience?": "Vipi Ulikuwa na Uzoefu Wako?",
    "Leave A Review": "Acha Mapitio",
    "Review History": "Historia ya Mapitio",
    "Write A Review": "Andika Mapitio",
    "Send And Post": "Tuma na Chapisha",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Mabadiliko ya Crypto hadi Fiat ni suluhisho thabiti na salama linalobadilisha sarafu za crypto kuwa sarafu za fiat kwa urahisi. Inakidhi mahitaji mbalimbali ya watumiaji, ikitoa njia nyingi za malipo zilizobinafsishwa kulingana na mapendeleo ya mtu binafsi na maeneo.\"",
    "Review Created": "Mapitio Yaliyoundwa",
    "Transactions": "Manunuzi",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Buruta/Angusha au Pakia Faili (Picha/Video/Pdf)",
    "Max Size 1GB": "Ukubwa wa Juu 1GB",
    "Title Description": "Maelezo ya Kichwa",
    "Add New": "Ongeza Mpya",
    "Discard": "Futa",
    "Save And Post": "Tuma na Chapisha",
    "Personal Account": "Akaunti Binafsi",
    "Conversion": "Mabadiliko",
    "Spent In Total": "Jumla Iliyotumika",
    "International": "Kimataifa",
    "Recharge": "Recharge",
    "Top Up Wallet To Wallet": "Ongeza Salio kutoka kwa Wallet hadi Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Haijaunganishwa",
    "More": "Zaidi",
    "Less": "Kidogo",
    "Profile Reports": "Ripoti za Profaili",
    "Timezone": "Muda wa Eneo",
    "Cancel": "Batilisha",
    "Full Name": "Jina Kamili",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Nyaraka Zaidi",
    "Profile Complete": "Profaili Kamili",
    "Verified": "Imehakikishwa",
    "Bank Account": "Akaunti ya Benki",
    "Mobile Wallet": "Wallet ya Simu",
    "Cash Pickup": "Uchangishaji wa Pesa",
    "Add": "Ongeza",
    "Configured": "Iliundwa",
    "Email": "Barua Pepe",
    "Download Data": "Pakua Takwimu",
    "Enter The Amount": "Ingiza Kiasi",
    "They Receive": "Wanachopokea",
    "Where Do You Want To Send Money": "Unataka Kutuma Pesa Wapi",
    "Bank Transfer": "Uhamisho wa Benki",
    "Receiving Method": "Njia ya Kupokea",
    "Payer": "Mlipaji",
    "Minimum Amount": "Kiasi Kidogo",
    "Maximum Amount": "Kiasi Cha Juu",
    "Search": "Tafuta",
    "Show More": "Onyesha Zaidi",
    "Select Remark / Reasons (All)": "Chagua Maelezo / Sababu (Zote)",
    "Attach Files": "Ambatisha Faili",
    "Holiday List": "Orodha ya Likizo",
    "No Data To Show": "Hakuna Data ya Kuonyesha",
    "Threshold Limits": "Vizingiti vya Kiwango",
    "Amount Limits": "Vizingiti vya Kiasi",
    "OTP Verification Code": "Nambari ya Uthibitishaji ya OTP",
    "Verify Using Authenticator": "Thibitisha Kwa Kutumia Authenticator",
    "Sms/Email Verification Code": "Nambari ya Uthibitishaji ya Sms/Barua Pepe",
    "Invite Them": "Waalike",
    "Lookup": "Tafuta",
    "Invite Friend": "Mwalike Rafiki",
    "Invite Via Phone Number": "Mwalike Kwa Nambari ya Simu",
    "Invite Via Email Address": "Mwalike Kwa Anwani ya Barua Pepe",
    "Next": "Ifuatayo",
    "Back": "Rudi",
    "Invite Via Email": "Mwalike Kwa Barua Pepe",
    "Available Currency": "Sarafu Inayopatikana",
    "Add Remarks And Comments": "Ongeza Maelezo na Maoni",
    "Recipient Details": "Maelezo ya Mpokeaji",
    "Go Back": "Rudi Nyuma",
    "Trouble Requesting Money": "Tatizo la Kuomba Pesa",
    "Instant": "Mara Moja",
    "Subscription": "Uanachama",
    "Schedule": "Ratiba",
    "Choose Request Type": "Chagua Aina ya Ombi",
    "Mobile Airtime": "Airtime ya Simu",
    "Incorrect Phone Number": "Nambari ya Simu Isiyo Sahihi",
    "Make Sure You Have Entered A Correct Phone Number.": "Hakikisha Umekalia Nambari Sahihi ya Simu.",
    "Close": "Funga",
    "Operator": "Mtoa Huduma",
    "Recipient Mobile Number": "Nambari ya Simu ya Mpokeaji",
    "Amount": "Kiasi",
    "Service": "Huduma",
    "Destination": "Lengo",
    "Top Up Summary": "Muhtasari wa Top Up",
    "Daily Sending Limit Exceeded": "Kiwango cha Kutuma cha Kila Siku Kimetozwa",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Tuma nukuu ya bei ya kina kwa mteja wako au mnunuzi. Onyesha huduma yako, bidhaa zako, au ofa zako kwa njia ya kitaalamu na nukuu ya kibinafsi.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Malipo yaliyofanywa katika siku za kazi kabla ya saa 3:00 PM yatatolewa kwenye akaunti ya benki ya mpokeaji kabla ya katikati ya usiku ya siku hiyo hiyo.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Seti akaunti zako za kupokea katika nchi hadi tatu na toa mara moja fedha kutoka kwa maombi ya malipo yaliyoidhinishwa kwa njia zako za malipo zilizowezeshwa.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom Inahifadhi Haki Ya Kuomba Nyaraka Zingine (Uangalizi Bora - EDD) Au Taarifa Zaidi Zinazohitajika Kufanya Uhakiki Kamili, Kulingana Na Wajibu Wake Wa Kisheria.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Lazima Iwe Ni Nyaraka Ya Kitambulisho Kilichotolewa Na Serikali Na Picha. Nyaraka Zote Za Kitambulisho Lazima Zikubaliane Na Alfabeti Ya Kilatini. Ikiwa Hapana, Tafsiri Iliyothibitishwa Inahitajika.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Onyesha Kazi Yako, Panua Ufikiaji Wako. 'Portfolio Yangu' Ni Kanvasi Yako Ya Kidijitali Kwenye Instapay. Pakia Na Onyesha Aina Mbalimbali Za Maudhui - Kutoka Kwa Picha Na Video Zinazovutia Hadi PDF Zinazofundisha. Iwe Wewe Ni Msanii, Mteja Huru, Au Mjasiriamali, Kipengele Hiki Kinakuwezesha Kuonyesha Kazi Yako Au Miradi Kwa Njia Inayovutia Kwa Macho.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Shiriki Nambari Yako Ya QR Au Kiungo Cha Malipo Ili Kupokea Malipo Mara Moja Katika Insta Wallet Yako.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Una dakika 15 kukamilisha muamala.",
    "Time Remaining": "Tafadhali kamilisha ndani ya muda uliowekwa ili kuepuka muda wa URL kuisha.",
    "Please Process The Payment Via Your Selected Payment Type": "Muda Uliosalia",
    "Payment Type": "Tafadhali fanya malipo kupitia aina ya malipo uliyoteua.",
    "Top-Up Wallet": "Aina ya malipo",
    "Confirm Your Payment": "JAZA POCHI",
    "You Will Get": "Thibitisha Malipo Yako",
    "Amount To Pay": "Utapokea",
    "Proceed To Payment": "Kiasi cha kulipa",
    "Load/Deposit Money To The Wallet": "Endelea na malipo",
    "Withdraw": "Pakia/weka pesa kwenye pochi",
    "Elevate Your Business With InstaPay": "Toa",
    "Higher Balances & Transaction Limits": "Inua Biashara Yako na InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Salio kubwa na viwango vikubwa vya miamala",
    "Full Feature Access": "Fungua uhuru wa kusimamia salio kubwa na kushughulikia kiasi kikubwa cha miamala, na uaminifu unaotarajia kutoka kwa InstaPay.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Ufikiaji wa Kipengele Kamili",
    "Global Reach": "Ufikio wa Ulimwengu",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Panua biashara yako bila mipaka. InstaPay hukunganisha na wateja na washirika duniani kote, na kufanya miamala ya kimataifa kuwa rahisi zaidi kuliko hapo awali.",
    "Advanced Security": "Usalama wa Juu",
    "Business Registration Available Soon\n": "Usajili wa biashara utapatikana hivi karibuni.",
    "Amount After Fee": "Kiasi Baada ya Ada",
    "Allowed Maximum Amount Per Transaction": "Kiwango cha Juu Kinachoruhusiwa kwa Kila Muamala",
    "Transfer": "Hamisha",
    "Uncategorized": "Haijapangwa",
    "Currency ISO Code & Amount": "Msimbo wa ISO wa Sarafu na Kiasi",
    "Choose The Top-Up Channel": "Chagua njia ya kuongeza salio",
    "You Selected": "uliyochagua",
    "Payout Channel": "njia ya malipo",
    "Your Details": "maelezo yako",
    "Detailed Confirmed": "maelezo yamethibitishwa",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Ongeza uwezo wako wa kifedha kwa kuboresha akaunti yako kuwa ya Biashara ya InstaPay. Furahia nguvu kamili ya InstaPay na vipengele vilivyoboreshwa vilivyoundwa mahususi kwa ukuaji wa biashara.",
    "Profile Picture": "Picha ya wasifu",
    "Add Photo Or Logo": "Ongeza Picha au Nembo",
    "First Name": "Jina la kwanza",
    "Last Name": "Jina la mwisho",
    "Enter Your First Name": "Weka jina lako la kwanza",
    "Enter Your Last Name": "Weka jina lako la mwisho",
    "Email (Optional)": "Barua pepe (hiari)",
    "Password Must": "Nenosiri lazima",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Itajazwa kiotomatiki ikiwa unasajili kupitia kiungo cha mwaliko.",
    "Change Number": "Badilisha namba",
    "Search Country": "Tafuta nchi",
    "Select From The List": "Chagua kutoka kwenye orodha",
    "Received Amount": "Kiasi kilichopokelewa",

    "null": null,
    "Processing": "Inachakatwa",
    "Verify Using Sms/Email": "Thibitisha kwa SMS/Barua Pepe",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Tayari ipo",
    "You Can Have Only 8 Wallets": "Unaweza kuwa na pochi 8 tu",
    "Save": "Hifadhi",
    "0 File": "Faili 0",
    "Search From The List": "Tafuta kutoka kwenye orodha",
    "Amount To Send": "Kiasi cha kutuma",
    "Wallet To Wallet": "Wallet kwa Wallet",
    "TOP-UP WALLET ": "JAZA POCHI",
    "Enter The Amount": "Ingiza kiasi",
    "Load/Deposit Money To The Wallet": "Pakia/Tia Pesa kwenye pochi",
    "Choose The Top-Up Channel": "Chagua njia ya kuongeza pochi",
    "Paypal": "Paypal",
    "You Will Get": "Utapata",
    "Amount To Pay": "Kiasi cha kulipa",
    "Card Holder Name ": "Jina la mwenye kadi",
    "Card Number": "Nambari ya kadi",
    "CVC": "CVC",
    "MM/YY": "MM/Mwaka",
    "Your Details ": "Maelezo yako",
    "You Selected": "Umechagua",
    "Detailed Confirmed": "Maelezo yamethibitishwa",
    "Transaction Failed": "Muamala umeshindwa",
    "Something Went Wrong. Try Again.": "Kuna tatizo. Jaribu tena.",
    "Convert Funds": "Geuza fedha",
    "How Would You Like To Cashout?:": "Ungependa kutoa pesa vipi?:",
    "Default Receiving Account": "Akaunti ya Kupokea Chaguo-msingi",
    "Specify Receiving Account": "Eleza Akaunti ya Kupokea",
    "Which Receiving Account Would You Want Use?:": "Ungependa kutumia akaunti gani ya kupokea?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Simu ya Benki",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Hakuna",
    "Request": "Omba",
    "Subscription Payment": "Malipo ya Usajili",
    "Is not supported by Paypal, USD will be used as the default currency.": "Haitumiki na PayPal, USD itatumika kama sarafu chaguo-msingi.",
    "Review Payment": "Kagua Malipo",
    "Google Authenticator": "google authenticator",
    "Scheduled Has Been Cancelled": "Ratiba imefutwa",
    "Select Country First": "Chagua nchi kwanza",
    "Select Gender": "Chagua jinsia",
    "Select Occupation": "Chagua kazi",
    "IBAN and SWIFT/BIC": "IBAN na SWIFT/BIC",
    "Mobile Money Provider": "Mtoa Huduma ya Pesa ya Simu",
    "Wallet Name": "Jina la Wallet",
    "Wallet Number ": "Nambari ya Wallet",
    "Crypto Currency": "Sarafu ya Crypto",
    "Wallet Address ": "Anwani ya Wallet",
    "Select Document Type ": "Chagua Aina ya Hati",
    "Enter Document Number": "Weka Nambari ya Hati",
    "Msisdn": "MSISDN",
    "Edit ": "Hariri",
    "My Withdrawls": "Uondoaji Wangu",
    "Declined": "Imekataliwa",
    "Accepted": "Imekubaliwa",
    "Overall Ratings": "Ukadiriaji wa Jumla",
    "0 Ratings": "Ukadiriaji 0",
    "No Reviews": "Hakuna Mapitio",
    "Send Money": "Tuma Pesa",
    "Trouble Sending Money?": "Tatizo la Kutuma Pesa?",
    "Payment Request": "Ombi la Malipo",
    "How Was Your Experience?": "Uzoefu wako ulikuwaje?",
    "Leave A Review": "Acha hakiki",
    "Write Review Message": "Andika ujumbe wa hakiki",
    "Send And Post": "Tuma na chapisha",
    "You Rated": "Umeweka kiwango",
    "Review History": "Historia ya hakiki",
    "Max Size 1GB": "Ukubwa wa juu 1GB",
    "Title": "Kichwa",
    "Description": "Maelezo",
    "Add New": "Ongeza mpya",
    "My Payment Address": "Anwani Yangu ya Malipo",
    "Personal Account": "Akaunti Binafsi",
    "Updated Successfully": "Imefanikiwa kusasishwa",
    "Spent In": "Imetumika kwa",
    "TOP UP": "Ongeza salio",
    "Quotation": "Nukuu",
    "Amount": "Kiasi",
    "Not Connected": "Hajaunganishwa",
    "More": "Zaidi",
    "Less": "Kidogo",
    "Reports": "Ripoti",
    "User Profile": "Wasifu wa mtumiaji",
    "Cancel": "Ghairi",
    "Saving": "Kuhifadhi",
    "Confirm ": "kuthibitisha",
    "Verify ": "Thibitisha",
    "Delete Country": "Futa nchi",
    "Bank Account": "Akaunti ya benki",
    "Cash Pickup": "Uchapaji wa Fedha",
    "Mobile Wallet ": "Wallet ya simu",
    "Delete Country Withdrawals": "Futa Uondoaji wa Nchi",
    "Yes , Delete It": "Ndio, Futa",
    "E-sim": "E-sim",
    "Utilities": "Huduma",
    "Searching": "Kutafuta",
    "Recipient Mobile Number": "Nambari ya simu ya mpokeaji",
    "TOP-UP SUMMARY": "Muhtasari wa Kujaza",
    "Trouble Requesting Money?": "Matatizo ya Kutuma Fedha?",
    "Choose The Beneficiary": "Chagua Mpokeaji",
    "Enter The amount": "Ingiza kiasi",
    "You selected": "Ulichagua",
    "Timezone": "Muda wa eneo",
    "Starting From": "Kuanzia",
    "Recurring Cycle": "Mzunguko wa Mara kwa Mara",
    "Until I stop": "Hadi nisimame",
    "Schedule To": "Ratibu kwa",
    "Schedule at": "Ratibu saa",
    "Selected time must be at least 5 minutes from now.": "Wakati uliochaguliwa lazima uwe angalau dakika 5 kutoka sasa.",
    "Is this your Location?": "Hii ni eneo lako?",
    "Yes ": "Ndio",
    "Select Remark Reason": "Chagua sababu ya maelezo",
    "Attach File": "Ambatisha faili",
    "Invite via Phone Number": "Alika kwa Nambari ya Simu",
    "Invite Friend": "Alika Rafiki",
    "Invite via Email Address": "Alika kwa Anwani ya Barua pepe",
    "Invite via": "Alika kupitia",
    "Phone": "Simu:",
    "Message": "Ujumbe:",
    "Send": "Tuma",
    "Invited Succesfully!": "Aliyealikwa Kwa Mafanikio!",
    "Email": "Barua pepe",
    "Bank Transfer": "Uhamisho wa Benki",
    "Mobile Wallet": "Pochi ya Simu",
    "Select The Bank": "Chagua Benki",
    "Select The Payer": "Chagua Mlipaji",
    "Min Amount:": "Kiasi Kidogo:",
    "Max Amount": "Kiasi Kikubwa",
    "Other Beneficiaries:": "Wengine Wanufaika:",
    "Back ": "Rudisha",
    "Next": "Ifuatayo",
    "Lookup": "Tafuta",
    "Invite Them": "Waite",
    "Delete Channel ": "Futa Channel",
    "Set As Default": "Weka kama Chaguo la Kawaida",
    "See More": "Angalia Zaidi",
    "Session Details": "Maelezo ya Kikao",
    "Platform": "Jukwaa",
    "Windows": "Windows",
    "Browser Name": "Jina la kivinjari",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Toleo la kivinjari",
    "IP Address": "Anwani ya IP",
    "Karachi": "Karachi",
    "State": "Jimbo",
    "Sindh": "Sindh",
    "Active": "Hai",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Hii pochi ya sarafu kwa sasa imeshazimwa/haifanyi kazi na fedha haiwezi kuongezwa.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Hii pochi ya sarafu kwa sasa imeshazimwa/haifanyi kazi na fedha haiwezi kubadilishwa.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Hii pochi ya sarafu kwa sasa imeshazimwa/haifanyi kazi na huwezi kutoa salio.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Hii pochi ya sarafu kwa sasa imeshazimwa/haifanyi kazi na huwezi kupakua taarifa.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Hii pochi ya sarafu kwa sasa imetengwa kama ya msingi na haiwezi kuzimwa. Ili kuendelea, tafadhali weka pochi ya sarafu nyingine kama ya msingi kwanza.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Hii pochi ya sarafu kwa sasa imetengwa kama ya msingi na haiwezi kuzimwa. Ili kuendelea, tafadhali weka pochi ya sarafu nyingine kama ya msingi kwanza.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Je, uko tayari kufuta maelezo ya uondoaji ya nchi hii?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Seti akaunti zako za kupokea fedha katika nchi tatu na upate fedha papo hapo kutoka kwa maombi ya malipo yaliyoidhinishwa kwenye njia zako za malipo zilizowekwa kazi.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Hakuna mpokeaji aliyetambuliwa kutoka Pakistan mwenye maelezo ya Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "Ujumbe wa mwaliko umekubaliwa kutumika",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Ada na kiasi kilichopokelewa kinaweza kutofautiana kulingana na njia ya malipo iliyochaguliwa.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Shiriki QR Code yako au kiunga cha malipo ili kulipwa mara moja kwenye pochi yako ya insta.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Vuta/Dondosha au Pakia faili (Picha/Vidyo/PDF)",
    "Login Again": "ingia tena",
    "Unblock The Wallet": "unganisha pochi",
    "Invalid Amount": "kiasi kisichofaa",
    "Add Payment Card": "ongeza kadi ya malipo",
    "Save Details To Use Next Time": "hifadhi maelezo ili yaweze kutumiwa baadaye",
    "Paid": "malipwa",
    "Started On": "ilianza mnamo",
    "Num Of Cycles": "idadi ya mizunguko",
    "Ends On": "inamalizika mnamo",
    "Next Payment At": "malipo yajayo mnamo",
    "Beneficiary": "mpokeaji",
    "Recieved Quotation": "nukuu iliyopewa",
    "Sent Quotation": "nukuu iliyotumwa",
    "Decline Offer": "kata ofa",
    "Are You Sure That You Want To Decline The Offer?": "uko tayari kweli kutaka kukata ofa?",
    "Decline The Offer": "kata ofa",
    "Bargain Offer": "ofa ya majadiliano",
    "Enter Counter Amount": "ingiza kiasi cha dhidi",
    "Custom Amount": "kiasi cha kielelezo",
    "Send The Offer": "tuma ofa",
    "The Offer Is Sent": "ofa imetumwa",
    "Countered Offer": "ofa ya kupinga",
    "Applied": "ilitumika",
    "Accept Quotation": "kubali nukuu",
    "Quotation Not Found Or Already Exist": "Nukuu haikutafutwa au tayari ipo",
    "Recieved Request": "ombi lililopokelewa",
    "Sent Request": "ombi lililotumwa",
    "Search Request": "tafuta ombi",
    "Search Quotation": "tafuta nukuu",
    "QR Status": "hali ya QR",
    "QR Code Description": "maelezo ya QR code",
    "Download Your QR Code": "Pakua QR Code yako",
    "Your QR Code": "QR yako",
    "Your QR Code Poster": "bango la QR yako",
    "Download  ": "pakua",
    "Select Relation": "chagua uhusiano",
    "Nominee Permanent Address": "anwani ya kudumu ya mteja",
    "Enter Your Message": "ingiza ujumbe wako",
    "Select": "chagua",
    "Select File Type": "chagua aina ya faili",
    "Send TOP-UP And E-Sim": "Tuma juu ya upya na E-sim",
    "Search Users": "tafuta watumiaji",
    "Select Payment Request Type": "chagua aina ya ombi la malipo",
    "Wallet To Wallet Transfer": "uhamisho wa pochi hadi pochi",
    "Holiday List": "orodha ya mapumziko",
    "No Data To Show": "hakuna data ya kuonyesha",
    "They Recieve": "wao hupokea",
    "You Will Be Logged Out In 60 Seconds": "Utakuwa umefungiwa ndani ya sekunde 60",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Hakikisha panya yako inasogea au bonyeza kitufe chochote kwenye kibodi yako ili kurekebisha kipima muda kuwa dakika 15",
    "You've Been Logged Out": "Umefungiwa",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Kwa usalama wako, umefungiwa baada ya dakika 15 za kutokufanya chochote. Tafadhali ingia tena ikiwa unataka kutumia InstaPay.",
    "Cash": "Pesa Taslimu",
    "Business": "Biashara",
    "Gift": "Zawadi",
    "Salary": "Mshahara",
    "Lottery": "Bahati Nasibu",
    "Savings": "Akiba",
    "Other": "Nyingine",
    "Select Source Of Fund": "Chagua Chanzo cha Fedha",
    "Select your source of funds": "Chagua chanzo chako cha fedha",
    "Request Times Out. Please Try Again!": "Ombi limeisha muda. Tafadhali jaribu tena!",
    "Resend Code In 100 Seconds": "Tuma tena msimbo ndani ya sekunde 100",
    "Computer Service": "Huduma ya Kompyuta",
    "Family Support": "Msaada wa Familia",
    "Education": "Elimu",
    "Gift And Other Donations": "Zawadi na michango mingine",
    "Medical Treatment": "Matibabu",
    "Maintenance Or Other Expenses": "Matunzo au gharama zingine",
    "Travel": "Safari",
    "Small Value Remittance": "Tuma fedha za thamani ndogo",
    "Liberalized Remittance": "Tuma fedha za uhuru",
    "Construction Expenses": "Gharama za ujenzi",
    "Hotel Accommodation": "Malazi ya hoteli",
    "Advertising And/Or Public Relations Related Expenses": "Matumizi ya matangazo na/au uhusiano wa umma",
    "Fees For Advisory Or Consulting Service": "Malipo ya huduma ya ushauri au ushauri",
    "Business Related Insurance Payment": "Malipo ya bima inayohusiana na biashara",
    "Insurance Claims Payment": "Malipo ya madai ya bima",
    "Delivery Fees": "Ada za usafirishaji",
    "Payments For Exported Goods": "Malipo ya bidhaa zilizozalishwa",
    "Payment For Services": "Malipo ya huduma",
    "Payment Of Loans": "Malipo ya mikopo",
    "Office Expenses": "Matumizi ya ofisi",
    "Residential Property Purchase": "Ununuzi wa mali za makazi",
    "Property Rental Payment": "Malipo ya kodi ya mali",
    "Royalty, Trademark, Patent And Copyright Fees": "Malipo ya kifalme, alama ya biashara, hati miliki na ada za hakimiliki",
    "Investment In Shares": "Uwekezaji katika hisa",
    "Fund Investment": "Uwekezaji katika mfuko",
    "Tax Payment": "Malipo ya kodi",
    "Transportation Fees": "Ada za usafiri",
    "Utility Bills": "Malipo ya huduma",
    "Personal Transfer": "Uhamisho wa binafsi",
    "Payment Of Salary": "Malipo ya mshahara",
    "Payment Of Rewards": "Malipo ya zawadi",
    "Payment Of Influencer": "Malipo kwa Mvuto",
    "Broker, Commitment, Guarantee And Other Fees": "Ada za wakala, ahadi, dhamana na nyinginezo",
    "Other Purposes": "Madhumuni mengine",
    "Aunt": "Shangazi",
    "Brother": "Kaka",
    "Brother-In-Law": "Mume wa dada",
    "Cousin": "Binamu",
    "Daughter": "Binti",
    "Father": "Baba",
    "Father-In-Law": "Mume wa baba",
    "Friend": "Rafiki",
    "Grandfather": "Babu",
    "Grandmother": "Bibi",
    "Husband": "Mume",
    "Mother": "Mama",
    "Mother-In-Law": "Mume wa mama",
    "Nephew": "Shangazi",
    "Niece": "Dada",
    "Self (i.e. The Sender, Himself)": "Mume wa dada",
    "Sister": "Mwanaume",
    "Sister-In-Law": "Mjomba",
    "Son": "Mwanaume",
    "Uncle": "Mjomba",
    "Wife": "Mke",
    "Others Not Listed": "Wengine ambao hawajaorodheshwa",
    "Passport": "Pasipoti",
    "National Identification Card": "Kadi ya Utambulisho ya Kitaifa",
    "Driving License": "Leseni ya Udereva",
    "Social Security Card/Number": "Kadi ya Usalama wa Jamii/Namba",
    "Tax Payer Identification Card/Number": "Kadi ya Utambulisho wa Mkulipa Kodi/Namba",
    "Senior Citizen Identification Card": "Kadi ya Utambulisho wa Mzee",
    "Birth Certificate": "Cheti cha Kuzaliwa",
    "Village Elder Identification Card": "Kadi ya Utambuzi ya Mzee wa Kijiji",
    "Permanent Residency Identification Card": "Kadi ya Utambulisho ya Makazi ya Kudumu",
    "Alien Registration Certificate/Card": "Cheti/Kadi ya Usajili wa Mgeni",
    "PAN Card": "Kadi ya PAN",
    "Voter’s Identification Card": "Kadi ya Utambulisho ya Mpiga Kura",
    "Health Insurance Card/Number": "Kadi/Nambari ya Bima ya Afya",
    "Employer Identification Card": "Kadi ya Utambulisho ya Mwajiri",
    "Others Not Listed": "Mengineyo Yasiyo Orodheshwa",
    "Bank Account": "Akaunti ya Benki",
    "Cash Pickup": "Ujumuaji wa Fedha",
    "Card": "Kadi",
    "Arts & Entertainment": "Sanaa na Burudani",
    "Banking & Finance": "Benki na Fedha",
    "Education & Research": "Elimu na Utafiti",
    "Engineering & Construction": "Uhandisi na Ujenzi",
    "Healthcare & Medicine": "Huduma za Afya na Dawa",
    "Information Technology & Services": "Teknolojia ya Habari na Huduma",
    "Legal & Compliance": "Sheria na Uzingatia",
    "Manufacturing & Production": "Utengenezaji na Uzalishaji",
    "Marketing & Sales": "Masoko na Mauzo",
    "Non-profit & Charity": "Isiyo kwa faida & Hisani",
    "Real Estate & Property": "Mali Isiyo na Ardhi",
    "Retail & Wholesale": "Rejareja & Uuzaji wa Jumla",
    "Science & Pharmaceuticals": "Sayansi & Dawa",
    "Transport & Logistics": "Usafiri & Usafirishaji",
    "Travel & Tourism": "Safari & Utalii",
    "Unemployed": "Mwaka wa Ajira",
    "Other": "Mengineyo",
    "Cash": "Pesa",
    "Business": "Biashara",
    "Gift": "Zawadi",
    "Salary": "Mishahara",
    "Lottery": "Bahati nasibu",
    "Savings": "Akiba",
    "Good Morning": "Habari za asubuhi",
    "Good Evening": "Habari za jioni",
    "Completed": "Imekamilika",
    "Load/Deposit Money To The Wallet": "Endelea na malipo",
    "Enter The Amount": "Ingiza kiasi",
    "Choose The Top-Up Channel": "Chagua njia ya kuongeza salio",
    "Good Night!": "Usiku Mwema!",
    "You Will Get": "Thibitisha Malipo Yako",
    "Amount To Pay": "Utapokea",
    "Top-Up Wallet": "Aina ya malipo",
    "Card Holder Name ": "Jina la mwenye kadi",
    "Card Number": "Nambari ya kadi",
    "CVC": "CVC",
    "MM/YY": "MM/Mwaka",
    "Save Details To Use Next Time": "hifadhi maelezo ili yaweze kutumiwa baadaye",
    "Select Or Add Payment Card": "Chagua au Ongeza Kadi ya Malipo",
    "Your Details ": "Maelezo yako",
    "You Selected": "Umechagua",
    "Detailed Confirmed": "Maelezo yamethibitishwa",
    "Transaction Failed": "Muamala umeshindwa",
    "Something Went Wrong. Try Again.": "Kuna tatizo. Jaribu tena.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Imeshindwa",
    "Initiated": "Imeanzishwa",
    "Revise": "Pitia",
    "Declined": "Imekataliwa",
    "Bargain-Accepted": "Punguzo-Kubaliliwa",
    "Accepted": "Imekubaliwa",
    "Decline The Offer": "Kataa Ofa",
    "Pending": "Liko Katika Hali ya Kusubiri",
    "Cancelled": "Imesitishwa",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Tuma nukuu ya bei ya kina kwa mteja wako au mnunuzi. Onyesha huduma yako, bidhaa zako, au ofa zako kwa njia ya kitaalamu na nukuu ya kibinafsi.",
    "Wallet to wallet": "Kutoka kwa pochi kwenda pochi",
    "Please follow these examples:": "Tafadhali fuata mifano hii:",
    "👤 InstaPay/Instagram Username: instauser": "👤 InstaPay/Instagram Jina la Mtumiaji: instauser",
    "📧 Email: user@email.com": "📧 Barua pepe: user@email.com",
    "Full Name": "Jina Kamili",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Karibu InstaPay! Ili kuhakikisha unaweza kutoa pesa mara moja kutoka kwa fedha unazopokea, unahitaji kuanzisha akaunti zako za kupokea kwenye mipangilio. Hii itakuwezesha kuhamisha pesa kwa urahisi kwenye akaunti zako za benki unazopenda, pochi za simu, pochi za crypto, au maeneo ya kuchukua pesa taslimu.",
    "You Have No Beneficiary": "Huna mnufaika yoyote",
    "Add Now": "Ongeza sasa",
    "Wallet-Status": "Hali ya pochi",
    "Select Payment Card": "Chagua kadi ya malipo",
    "Your funds have been credited to your wallet.": "Fedha zako zimewekwa kwenye pochi yako.",
    "Thanks for using InstaPay": "Asante kwa kutumia InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Pata malipo mara moja: Sanidi akaunti zako za kupokea sasa!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Fuata hatua hizi rahisi kuanzisha akaunti zako za kupokea na kuhakikisha uko tayari kila wakati kufikia fedha zako:",
    "STEP 01": "HATUA YA 01",
    "Go to Settings": "Nenda kwenye Mipangilio",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Fungua programu yako ya InstaPay na uende kwenye sehemu ya \"Mipangilio\".",
    "STEP 02": "HATUA YA 02",
    "Select Receiving Accounts": "Chagua Akaunti za Kupokea",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Gonga \"Akaunti za Kupokea\" ili kuanza kuongeza maelezo yako.",
    "Start Adding Accounts": "Anza Kuongeza Akaunti",
    "Phone Number: 44795396600 (With Country Code)": "Nambari ya Simu: 44795396600 (Pamoja na Msimbo wa Nchi)",
    "Select Country First": "Chagua nchi kwanza",
    "First Name": "Jina la kwanza",
    "Last Name": "Jina la mwisho",
    "Address Line": "Mstari wa Anwani",
    "Email Address": "Anwani ya Barua Pepe",
    "Select City": "Chagua Jiji",
    "Select Gender": "Chagua jinsia",
    "Select Occupation": "Chagua kazi",
    "Male": "Mwanaume",
    "Female": "Mwanamke",
    "Non-Binary": "Isiyo ya Kijinsia",
    "Prefer Not To Say'": "Sipendi kusema",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Hakuna Mtumiaji Aliyepatikana",
    "Email Address ": "Anwani ya Barua Pepe",
    "Select Document": "Chagua Hati",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Onyesha Kazi Yako, Panua Ufikiaji Wako. 'Portfolio Yangu' Ni Kanvasi Yako Ya Kidijitali Kwenye Instapay. Pakia Na Onyesha Aina Mbalimbali Za Maudhui - Kutoka Kwa Picha Na Video Zinazovutia Hadi PDF Zinazofundisha. Iwe Wewe Ni Msanii, Mteja Huru, Au Mjasiriamali, Kipengele Hiki Kinakuwezesha Kuonyesha Kazi Yako Au Miradi Kwa Njia Inayovutia Kwa Macho.",
    "Add New": "Ongeza Mpya",
    "Money Out": "Pesa Nje",
    "Browsername": "Jina la Kivinjari",
    "Browserversion": "Toleo la Kivinjari",
    "Credit": "Mikopo",
    "Debit": "Deni",
    "Top Up": "Ongeza Salio",
    "Wallet To Wallet": "Pochi kwa Pochi",
    "Accountant": "Mhasibu",
    "Architect": "Mbunifu",
    "Artist": "Msanii",
    "Banker": "Mfanyakazi wa Benki",
    "Business Owner": "Mmiliki wa Biashara",
    "Consultant": "Mshauri",
    "Doctor": "Daktari",
    "Engineer": "Mhandisi",
    "Farmer": "Mkulima",
    "Government Employee": "Mfanyakazi wa Serikali",
    "IT Professional": "Mtaalamu wa IT",
    "Lawyer": "Wakili",
    "Nurse": "Muuguzi",
    "Retailer": "Mfanyabiashara",
    "Salesperson": "Muuzaji",
    "Student": "Mwanafunzi",
    "Teacher": "Mwalimu",
    "Prefer Not to Say": "Ningependelea Kutojibu",
    "Timezone": "Muda wa eneo",
    "Subscription Payment": "Malipo ya Usajili",
    "Starting From": "Kuanzia",
    "Recurring Cycle": "Mzunguko wa Mara kwa Mara",
    "Until I stop": "Hadi nisimame",
    "Schedule To": "Ratibu kwa",
    "Schedule at": "Ratibu saa",
    "Selected time must be at least 5 minutes from now.": "Wakati uliochaguliwa lazima uwe angalau dakika 5 kutoka sasa.",
    "They Recieve": "wao hupokea",
    "Attach Files": "Ambatisha Faili",
    "Wallet Selected": "Kifuko Chochote Kilichochaguliwa",
    "You Have Selected PKR Currency Wallet For This Transaction": "Umechagua Kifuko cha Sarafu cha PKR kwa Miamala Hii",
    "Instant": "Mara Moja",
    "Subscription": "Uanachama",
    "Schedule": "Ratiba",
    "Sms/Email Verification Code": "Nambari ya Uthibitishaji ya Sms/Barua Pepe",
    "Confirm Your Password": "Thibitisha Nenosiri Lako",
    "This Security Feature Requires Password Confirmation!": "Vipengele Hivi vya Usalama Vinahitaji Thibitisho la Nenosiri!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Tumia Programu ya Kusoma QR Code au Tumia Nambari Yako ya Siri Kuanzisha Google Uthibitishaji wa Mbili wa Hatua",
    "OR": "AU",
    "Paste This Key In The Googe Authenticator App": "Bandika Funguo Hii Katika Programu ya Google Authenticator",
    "Show Secret Key": "Onyesha Funguo ya Siri",
    "Enter The Six-Digit Code From The Application": "Ingiza Nambari ya Sita ya Dijiti Kutoka kwa Programu",
    "6 Digit Code": "Nambari ya 6 ya Dijiti",
    "Enable": "Washa",
    "Disable": "Zima",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Baada ya Kusoma Picha ya Barcode, Programu Itaonyesha Nambari ya Sita ya Dijiti Ambayo Unaweza Kuingiza Hapo Chini",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Google Uthibitishaji wa Mbili wa Hatua Sasa Umeunganishwa na Akaunti yako ya Instapay",
    "Min Amount:": "Kiasi Kidogo:",
    "Minimum Amount": "Kiasi Kidogo",
    "Maximum Amount": "Kiasi Cha Juu",
    "Identity Verification Required": "Uthibitishaji wa kitambulisho unahitajika",
    "Select Your Wallet Currency": "Chagua sarafu ya pochi yako",
    "Setup Authenticator": "Weka mthibitishaji",
    "Note": "Kumbukumbu",
    "This Is A Test Notice": "Huu ni tangazo la mtihani",
    "Session Expired": "Kikao kimetoweka",
    "You have been logged out for security reasons, please login again to continue.": "Umefunguliwa kwa sababu za usalama, tafadhali ingia tena ili kuendelea.",
    "Redirecting You To Login Page In 5 Seconds": "Tunakuelekeza kwenye ukurasa wa kuingia kwa sekunde 5",
    "Searching": "Kutafuta",
    "Recipient Mobile Number": "Nambari ya simu ya mpokeaji",
    "TOP-UP SUMMARY": "Muhtasari wa Kujaza",
    "Amount": "Kiasi",
    "Something went wrong while getting countries.": "Kitu kilichokosea kilitokea wakati wa kupata nchi.",
    "Select Destination Country": "Chagua Nchi ya Kuelekea.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR haikubaliwi na Paypal, USD itatumika kama sarafu ya chaguo-msingi.",
    "Select A Date": "Chagua Tarehe",
    "Select A Timezone": "Chagua Eneo la Saa",
    "Proceed": "Endelea",
    "Proceeding": "Inayoendelea",
    "You Can Now Close This Webpage And Go Back To Instagram": "Sasa unaweza kufunga ukurasa huu wa wavuti na kurudi kwenye Instagram",
    "Select A Time": "Chagua Muda",
    "Location Permission Denied": "Ruhusa ya Mahali Imekataliwa",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Fuata maagizo hapa chini ili kuwezesha huduma za eneo kwenye kivinjari chako baada ya kukataa ruhusa hapo awali:",
    "1: Chrome Browser": "1: Kivinjari cha Chrome",
    "Open the Chrome app.": "Fungua programu ya Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Gonga menyu yenye nukta tatu (kona ya juu kulia) na uchague Mipangilio.",
    "Scroll down to Site Settings > Location.": "Shuka chini hadi Mipangilio ya Tovuti > Mahali.",
    "Locate the website in the list or search for it.": "Tafuta tovuti kwenye orodha au utafute.",
    "Tap the website and select Allow under Location Access.": "Gonga tovuti na uchague Ruhusu chini ya Ufikiaji wa Mahali.",
    "2: Firefox Browser": "2: Kivinjari cha Firefox",
    "Open the Firefox app.": "Fungua programu ya Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Gonga menyu yenye mistari mitatu (kona ya chini kulia) na uende kwenye Mipangilio.",
    "Select Privacy & Security > Site Permissions > Location.": "Chagua Faragha na Usalama > Ruhusa za Tovuti > Mahali.",
    "Find the website in the list and set the permission to Allow.": "Tafuta tovuti kwenye orodha na weka ruhusa kuwa Ruhusu.",
    "3: Samsung Internet Browser": "3: Kivinjari cha Samsung Internet",
    "Open the Samsung Internet app.": "Fungua programu ya Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Gonga menyu yenye mistari mitatu (kona ya chini kulia) na uchague Mipangilio.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Nenda kwenye Tovuti na Vipakuliwa > Ruhusa za Tovuti > Mahali.",
    "Find the website and change the permission to Allow.": "Tafuta tovuti na ubadilishe ruhusa kuwa Ruhusu.",
    "4: Safari Browser": "4: Kivinjari cha Safari",
    "Open the Settings app.": "Fungua programu ya Mipangilio.",
    "Scroll down and select Safari.": "Shuka chini na uchague Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Gonga Eneo na uweke kuuliza mara nyingine au Ruhusu Wakati wa Kutumia Programu.",
    "Revisit the website and when prompt, allow location access": "Tembelea tena tovuti na ukihimizwa, ruhusu ufikiaji wa eneo.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Kidokezo: Ikiwa tovuti haionekani katika mipangilio ya kivinjari, futa hifadhi ya akiba au historia ya kivinjari chako na ujaribu tena.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Ili kutuma ombi la malipo, tafadhali shiriki eneo lako—hii inatusaidia kuongeza usalama na kujenga uaminifu kwa uzoefu laini zaidi!",
    "Decline": "Kataa",
    "Share Location": "Shiriki eneo"
}