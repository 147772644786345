export const fr = {
    "How Others Can Find You": "Comment les autres peuvent vous trouver",
    "Schedules": "Horaires",
    "Login": "Connexion",
    "Start Your InstaPay Journey Today!": "Commencez votre aventure InstaPay aujourd'hui !",
    "Phone Number or Email": "Numéro de téléphone ou email",
    "Login Using Social Media": "Se connecter avec un réseau social",
    "Continue": "Continuer",
    "Don't Have an Account? Sign Up": "Vous n'avez pas de compte ? Inscrivez-vous",
    "Sign Up": "S'inscrire",
    "Edit": "Modifier",
    "Enter Your Password": "Entrez votre mot de passe",
    "Forgot Password?": "Mot de passe oublié ?",
    "Account Type": "Type de compte",
    "Individual": "Individuel",
    "Business": "Entreprise",
    "Already Have an Account? Sign In": "Vous avez déjà un compte ? Connectez-vous",
    "Sing In": "Se connecter",
    "Enter Your Personal Details": "Entrez vos informations personnelles",
    "Add Photo or Logo": "Ajouter une photo ou un logo",
    "Enter Your Name": "Entrez votre nom",
    "Back": "Retour",
    "Phone Number": "Numéro de téléphone",
    "Email Address": "Adresse email",
    "Create a Strong Password": "Créez un mot de passe fort",
    "Password Requirements": "Exigences du mot de passe",
    "Must be between 9 and 64 characters": "Doit contenir entre 9 et 64 caractères",
    "Include at least two of the following:": "Inclure au moins deux des éléments suivants :",
    "Uppercase character": "Un caractère majuscule",
    "Lowercase character": "Un caractère minuscule",
    "Number": "Un chiffre",
    "Special character": "Un caractère spécial",
    "Confirm Password": "Confirmer le mot de passe",
    "Finalize Your Process": "Finalisez le processus",
    "Referral Code (Optional)": "Code de parrainage (Optionnel)",
    "It will autofill if you're signing up with an invitation link.": "Il sera rempli automatiquement si vous vous inscrivez via un lien d'invitation.",
    "I agree with": "Je suis d'accord avec les",
    "Terms & Conditions": "Termes et conditions",
    "Privacy Policy": "La Politique de Confidentialité",
    "Create Your Account": "Créez votre compte",
    "Enter Code": "Entrez le code",
    "A one-time verification code has been sent to your email address  and phone number.": "Un code de vérification à usage unique a été envoyé à votre adresse email \net numéro de téléphone.",
    "Resend available in ": "Possibilité de renvoi dans",
    "seconds": "secondes",
    "Verify": "Vérifier",
    "Verification Code Verified": "Code de vérification confirmé",
    "Please continue to the next step.": "Veuillez continuer à l'étape suivante.",
    "Finish": "Terminer",
    "Finishing": "En cours de finalisation",
    "Company Name": "Nom de l'entreprise",
    "Enter Your Business Details": "Entrez les détails de votre entreprise",
    "Country ": "Pays",
    "You cannot change the country once your account is verified.": "Vous ne pouvez pas changer de pays une fois votre compte vérifié.",
    "Learn more": "En savoir plus",
    "Optional": "Optionnel",
    "Continuing": "Continuer",
    "Didn't receive the verification code?": "Vous n'avez pas reçu le code de vérification ?",
    "Resend Now": "Renvoyer maintenant",
    "Search Here": "Rechercher ici",
    "Search for users, services, and more on InstaPay": "Recherchez utilisateurs, services et plus sur InstaPay",
    "Search users here": "Rechercher des utilisateurs ici",
    "Earn money with our affiliate program!": "Gagnez de l'argent grâce à notre programme d'affiliation !",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Générez des revenus passifs en partageant votre code d'affiliation InstaPay \navec vos followers. À chaque transaction qu'ils effectuent, vous percevez \nune commission, maximisant ainsi les bénéfices issus de votre influence en ligne.",
    "Learn More": "En savoir plus",
    "Balance Amount": "Montant du solde",
    "You spent less compared to last month.": "Vous avez dépensé moins par rapport au mois dernier.",
    "Referral Rewards": "Primes de Recommandations",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Transfert d'argent rapide vers vos contacts les plus récents :",
    "Number of Transactions": "Nombre de transactions",
    "Total Transaction Amount": "Montant total des transactions",
    "Total Sent": "Total envoyé",
    "Total Received": "Total reçu",
    "Add Funds": "Ajouter des fonds",
    "Send Money": "Envoyer de l'argent",
    "Request Money": "Demander de l'Argent",
    "Send a Quote": "Envoyer un devis",
    "Pay With Crypto": "Payer en Crypto",
    "Payment Insights": "Aperçu des paiements",
    "Recent Transactions ": "Transactions récentes",
    "Today": "Aujourd'hui",
    "My Wallets": "Mes Wallets",
    "Balance": "Solde",
    "Wallet ID": "Wallet ID",
    "Monthly limit": "Limite mensuelle",
    "Top Up": "Recharger",
    "Summary": "Résumé",
    "Settings": "Paramètres",
    "More": "Plus",
    "Upgrade to Business Account": "Passer au Compte Professionnel",
    "Conversion": "Conversion",
    "Enter Amount": "Saisissez le Montant",
    "Exchanged Amount": "Montant converti",
    "Convert": "Convertir",
    "You Are Converting": "Vous effectuez un Change",
    "From": "À l'attention de",
    "To": "À",
    "Exchange Rate": "Taux de Change",
    "Fees": "Frais",
    "You Will Receive": "Montant à Recevoir",
    "Slide To Confirm": "Glisser pour confirmer",
    "Verification Code Confirmed - You'll be redirected to the next step": "Code de vérification confirmé - Vous serez redirigé vers l'étape suivante.",
    "Moving to next step in": "Passage à l'étape suivante dans",
    "Thanks for using InstaPay": "Merci d'utiliser InstaPay",
    "Your funds have been credited to your wallet.": "Vos fonds ont été crédités sur votre Wallet.",
    "Dashboard": "Espace Client",
    "Accounts": "Comptes",
    "Transactions": "Transactions",
    "Other Transactions": "Autres Transactions",
    "Payments": "Paiements",
    "Beneficiaries": "Bénéficiaires",
    "Referrals": "Parrainages",
    "Quotations": "Devis",
    "Pending": "En attente",
    "My QR Code Sticker": "Mon Sticker QR Code",
    "My Portfolio": "Mon Portfolio",
    "My Payment Address": "Mon Adresse de Paiement",
    "Analytics": "Statistiques",
    "Profile": "Profil",
    "Dark Mode": "Mode Sombre",
    "Support": "Support",
    "Logout": "Déconnexion",
    "Powered By": "Powered By",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "A Swiss Company",
    "MAIN MENU": "MENU PRINCIPAL",
    "OTHERS": "AUTRES",
    "Wallet": "Wallet",
    "Default": "Par défaut",
    "Breakdown": "Répartition",
    "Credit": "Crédit",
    "Debit": "Débit",
    "Wallet Management": "Gestion du Wallet",
    "Top-up Your Wallet": "Recharger Votre Wallet",
    "Download Statement": "Télécharger le Relevé",
    "Block the Wallet": "Bloquer le Wallet",
    "Wallet Status": "Statut du Wallet",
    "-Select-File-Type-": "-Sélectionner-Type-de-Fichier-",
    "Download Now": "Télécharger Maintenant",
    "Downloading": "Téléchargement en cours",
    "All": "Tous",
    "Sent": "Envoyés",
    "Received": "Reçus",
    "Requested": "Demandés",
    "Quoted": "Devis",
    "Search Transactions": "Rechercher des Transactions",
    "Date & Time": "Date & Heure",
    "Type": "Type",
    "Transaction ID": "ID de Transaction",
    "Recipient": "Destinataire",
    "Amount": "Montant",
    "Status": "Statut",
    "Payment Type": "Type de Paiement",
    "Sent Payments": "Paiements Envoyés",
    "Received Payments": "Paiements Reçus",
    "Date": "Date",
    "Cycles/Time": "Cycles/Heure",
    "Payment As": "Paiement Comme",
    "Name": "Nom",
    "Cancel": "Annuler",
    "Subscriptions & Scheduled": "Abonnements & Paiement Planifiés",
    "Select the option that matches your needs from the list below:": "Sélectionnez l'option qui correspond à vos besoins dans la liste ci-dessous :",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Envoyez facilement de l'argent à des amis, à la famille ou à des entreprises, localement ou \nà l'international. Choisissez parmi plusieurs options de transfert, y compris les dépôts bancaires, \nles Wallets mobiles, le retrait en espèces, les cartes de paiement, et les transferts de Wallet à Wallet. \nProfitez de la commodité et de la sécurité d'InstaPay pour des transferts d'argent instantanés \net sans souci.",
    "International Transfer": "Transfert International",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Envoyez de l'argent à l'étranger facilement. Transférez des fonds à l'international vers des amis, \nde la famille ou des entreprises en quelques clics. Choisissez parmi une large gamme de canaux \nde paiement, y compris compte bancaire, argent mobile/Wallet mobile, point de retrait en espèces \nou carte de paiement pour des transactions pratiques et sécurisées.",
    "Select the country where you want to send the money!": "Sélectionnez le pays où vous voulez envoyer l'argent !",
    "Search Country": "Rechercher un pays",
    "Trouble Sending Money?": "Des problèmes pour envoyer de l'argent ?",
    "Confirm": "Confirmer",
    "Select Payout Channel": "Sélectionnez le Canal de Paiement",
    "Select your most convenient option to send the money.": "Sélectionnez l'option la plus pratique pour envoyer l'argent.",
    "Select The Bank": "Sélectionnez la Banque",
    "Search Bank": "Rechercher une banque",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Envoyez de l'argent directement sur le compte bancaire du destinataire. Profitez de la commodité \net de la sécurité de transférer des fonds sans problème vers n'importe quelle banque dans le monde.",
    "Bank Transfer": "Virement Bancaire",
    "Enter the amount": "Saisissez le montant",
    "Tax": "Taxe",
    "Amount the recipient will receive": "Montant que le destinataire recevra",
    "Select Remarks / Reason": "Sélectionnez Remarques / Raison",
    "Comments": "Laissez un commentaire",
    "Attach Files": "Joindre des fichiers",
    "Groups": "Groupes",
    "Manage Groups": "Gérer les Groupes",
    "Add New Group": "Ajouter un Nouveau Groupe",
    "Invite": "Inviter une Personne",
    "Manage Beneficiaries": "Gérer les Bénéficiaires",
    "Recipients List": "Liste des Destinataires",
    "Search Contacts": "Rechercher des Contacts",
    "Confirm Your Payment": "Confirmer Votre Paiement",
    "Select Country": "Sélectionnez le Pays",
    "Select the Recipient's Payment Channel": "Sélectionnez le Canal de Paiement du Destinataire",
    "Change Selection": "Modifier de Sélection",
    "You Are Transferring ": "Vous transférez",
    "Oops! Something Went Wrong.": "Oups ! Quelque Chose a Mal Tourné.",
    "We're Sorry About That. ": "Nous Sommes Désolés Pour Cela.",
    "Go back to Payments Page.": "Retourner à la Page de Paiements.",
    "Wallet to Wallet Transfer": "Transfert de Wallet à Wallet",
    "Request a customized price quote from service providers or vendors.": "Demandez un devis personnalisé auprès de prestataires de services ou de fournisseurs.",
    "Enter the Wallet ID": "Entrez l'ID du Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Entrez l'ID du Wallet, l'Email ou le Numéro de Téléphone du destinataire.",
    "Recipient not on InstaPay?": "Le destinataire n'est pas sur InstaPay ? Invitez-le.",
    "Invite Them": "Invitez-le",
    "My Beneficiaries": "Mes Bénéficiaires",
    "Enter the Amount You Wish to Transfer.": "Saisissez le montant à transférer",
    "Sending to ": "Envoi à",
    "You Have Selected Wallet to Wallet Transfer": "Vous avez sélectionné le Transfert de Wallet à Wallet",
    "Authenticate Your Payment.": "Authentifiez Votre Paiement.",
    "You are transferring": "Vous transférez",
    "Total ": "Total",
    "Where Is My Payment?": "Où est mon paiement ?",
    "Share": "Partager",
    "Your Payment Is Confirmed": "Votre paiement est confirmé",
    "QR Code Payment": "Paiement par QR Code",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Transférez instantanément de l'argent à d'autres utilisateurs InstaPay avec notre service sécurisé de \nWallet à Wallet. Envoyez et recevez des fonds sans tracas en quelques secondes.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scannez le QR avec votre caméra ou téléchargez depuis votre appareil.",
    "Upload from Your Device.": "Téléchargez depuis votre appareil.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternativement, utilisez l'Adresse de Paiement Unique InstaPay (UPA)",
    "Enter InstaPay UPA": "Entrez l'UPA InstaPay",
    "Recipient not on InstaPay? Invite Them": "Le destinataire n'est pas sur InstaPay ? Invitez-le",
    "Verify QR Code": "Vérifiez le QR Code",
    "Scan/Upload QR Code of the Recipient": "Scannez/Téléchargez le QR Code du destinataire",
    "Payment Confirmed": "Paiement confirmé",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Demandez des devis personnalisés auprès de prestataires de services ou de fournisseurs pour obtenir des \nestimations de coûts précises pour les services ou produits dont vous avez besoin.",
    "Choose The Beneficiary or Group": "Choisissez le Bénéficiaire ou le Groupe",
    "Enter the Amount You Wish to Request.": "Entrez le Montant que Vous Souhaitez Demander.",
    "You Are Requesting Money": "Vous effectuez une demande de paiement",
    "You are requesting": "Vous effectuez une demande ",
    "As": "En tant que",
    "Conversion of Your Cryptocurrencies": "Conversion de vos cryptomonnaies",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Découvrez notre conversion de Crypto en Fiat sécurisée, centrée sur l'utilisateur et efficace.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Choisissez la Cryptomonnaie que Vous Souhaitez Liquider.",
    "Next": "Suivant",
    "Enter the Crypto Wallet Address of the Recipient.": "Entrez l'Adresse du Wallet Crypto du Destinataire.",
    "Minimum Transactional Value: $100.": "Valeur Transactionnelle Minimum : 100 $.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Note : Les prix des cryptos changent fréquemment. Référez-vous au devis final sur la page de confirmation de \ncommande.",
    "Select Network": "Sélectionnez le Réseau",
    "Send A Quote": "Envoyer un Devis",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Envoyez un devis détaillé à vos clients ou consommateurs. Présentez de manière professionnelle vos services, \nproduits ou propositions avec un devis personnalisé.",
    "Choose the Beneficiary": "Choisissez le Bénéficiaire",
    "Allow Receiver to Bargain": "Permettre au Destinataire de Négocier",
    "Enter the Title for Your Quotation.": "Entrez le Titre de Votre Devis.",
    "Enter the Description for Your Quotation": "Entrez la Description de Votre Devis",
    "You are Quoting": "Vous faites un devis",
    "Title": "Titre",
    "Description": "Description",
    "Attachments": "Pièces jointes",
    "Confirm Your Quotation": "Confirmer Votre Devis",
    "Verify The Quotation": "Vérifiez le Devis",
    "Your quotation is confirmed": "Votre devis est confirmé",
    "Request A Quote": "Demander un Devis",
    "Coming Soon": "Bientôt Disponible",
    "Add Beneficiary": "Ajouter un Bénéficiaire",
    "Search for Beneficiaries": "Rechercher des Bénéficiaires",
    "Connected Accounts": "Comptes Connectés",
    "Details": "Détails",
    "First Name": "Prénom",
    "Last Name": "Nom de famille",
    "Country": "Pays",
    "City": "Ville",
    "Address Line": "Adresse",
    "Mobile Number": "Numéro de mobile",
    "Relationship With Beneficiary": "Lien avec le Bénéficiaire",
    "Bank Account": "Compte Bancaire",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Carte de Paiement",
    "Crypto Wallet": "Wallet Crypto",
    "InstaPay Account": "Compte InstaPay",
    "Cash Pickup": "Retrait en Espèces",
    "IBAN / SWIFT Code": "Code IBAN ou Swift/BIC",
    "Swift Code": "Code Swift",
    "Account Number": "Numéro de Compte",
    "Bank Name": "Nom de la Banque",
    "Branch Name": "Nom de l'Agence",
    "Branch Street": "Rue de l'Agence",
    "Province": "Province",
    "Postal Code": "Code Postal",
    "Mobile Money Provider": "Opérateur de Mobile Money",
    "Wallet Name": "Nom du Wallet",
    "Wallet Number": "Numéro du Wallet",
    "Card Holder Name": "Nom du Titulaire de la Carte",
    "Card Number": "Numéro de la Carte",
    "Expiry Date": "Date d'Expiration",
    "Crypto Currency": "Cryptomonnaie",
    "Wallet Address": "Adresse du Wallet",
    "Wallet Holder Name": "Nom du Propriétaire du Wallet",
    "Wallet Currency": "Devise du Wallet",
    "Select Document Type": "Sélectionner le Type de Document",
    "Enter Document Number": "Entrer le Numéro de Document",
    "Add Individual Account": "Ajouter un Compte Individuel",
    "Add Business Account": "Ajouter un Compte d'Entreprise",
    "Company Address": "Adresse de l'Entreprise",
    "Company Email": "Email de l'Entreprise",
    "Company Phone No": "Numéro de Téléphone de l'Entreprise",
    "Total Referrals": "Total de Parrainages",
    "My Earnings": "Mes Gains",
    "Your Referral ID": "Votre ID de Parrainage",
    "Share Your Unique Referral Link": "Partagez votre lien de parrainage unique InstaPay",
    "How do I Refer a Friend to InstaPay?": "Comment parrainer un ami sur InstaPay ?",
    "Step 01": "Étape 01",
    "Share your InstaPay unique referral link with your friends.": "Partagez votre lien de parrainage unique InstaPay avec vos amis.",
    "Step 02": "Étape 02",
    "Earn rewards when they transact $100 or more": "Gagnez des récompenses lorsque ceux-ci effectuent des transactions de 100 $ ou plus",
    "Step 03": "Étape 03",
    "Earn up to $0.06 for every transaction your followers make.": "Gagnez jusqu'à 0,06 $ pour chaque transaction effectuée par vos abonnés.",
    "My Referrals": "Mes Parrainages",
    "Referral Complete": "Parrainage Complet",
    "Received Quotations": "Devis Reçus",
    "Sent Quotations": "Devis Envoyés",
    "Search Pending Items": "Rechercher des Éléments en Attente",
    "Actions": "Actions",
    "Received Requests": "Demandes Reçues",
    "Sent Requests": "Demandes Envoyées",
    "No Actions": "Aucune Action",
    "You Requested": "Vous avez Demandé",
    "Via": "Via",
    "How was your experience?": "Comment était votre expérience ?",
    "Leave a Review": "Laisser un Avis",
    "Overall Ratings": "Évaluations Globales",
    "Visit Profile": "Visiter le Profil",
    "Ratings": "Évaluations",
    "Reviews": "Commentaires",
    "No reviews available": "Aucun avis disponible",
    "Select Your Currency": "Sélectionnez Votre Devise",
    "Proceed": "Procéder",
    "Commision": "Commission",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Simplifiez vos transactions avec le Sticker QR Code InstaPay ",
    "Effortless and Swift:": "Rapide et Facile :",
    "Instantly download your unique InstaPay QR code sticker.": "Téléchargez instantanément votre sticker QR Code InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Effectuez des paiements sans espèces et sans tracas, sans la nécessité d'un terminal de paiement.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Réceptionnez vos paiements instantanément – oubliez la saisie rébarbative des numéros de carte.",
    "Each payment includes customer information for your convenience.": "Chaque paiement intègre les informations du client pour simplifier votre gestion.",
    "Economically Smart:": "Économiquement Avantageux :",
    "Zero activation fees – start immediately without upfront costs.": "Aucun frais d'activation – débutez sans frais de démarrage.",
    "Forget about fixed monthly charges; pay as you transact.": "Finis les coûts mensuels immuables ; vous payez au gré de vos opérations",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Abandonnez le matériel coûteux ; économisez plus sans frais de terminal.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Bénéficiez de frais de transaction avantageux, à seulement 0,75 % pour chaque paiement par QR code.",
    "We don't impose minimum commissions; you save more with each transaction.": "Aucune commission minimale requise ; réalisez plus d'économies sur chaque transaction.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Expérimentez le plaisir des paiements immédiats, optimisant ainsi votre flux de trésorerie.",
    "Make Every Transaction Count with InstaPay": "Chaque Transaction Compte avec InstaPay",
    "Wallet QR": "QR Wallet",
    "Download QR": "Télécharger QR",
    "QR Code Status": "Statut du Code QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Partagez votre Code QR ou lien de votre adresse de paiement pour recevoir instantanément des paiements \ndans votre wallet InstaPay.",
    "QR Code Title": "Titre du Code QR",
    "View Transactions": "Consulter les Transactions",
    "My QR Codes:": "Mes Codes QR :",
    "Load More": "Charger Plus",
    "Benefits Of Portfolio": "Avantages du Portfolio",
    "Benefits:": "Avantages :",
    "Enhanced Visibility:": "Visibilité Accrue :",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "Valorisez votre profil public. Votre portfolio est une vitrine dynamique, accessible depuis votre Adresse de Paiement.\n",
    "Engage Your Audience:": "Engagez Votre Audience :",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Interagissez avec les visiteurs à travers votre parcours créatif ou professionnel. Laissez votre travail parler pour vous, \ncréant un engagement plus profond avec des clients potentiels ou existants.",
    "Integrated Social Proof:": "Preuve Sociale Intégrée:",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Affichez votre influence sur les réseaux sociaux. Les visiteurs peuvent voir votre portée sur les réseaux \nsociaux, ajoutant de la crédibilité et du contexte à votre portfolio.",
    "Seamless Transactions:": "Transactions Simplifiées :",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Permettez aux visiteurs de demander ou d'initier des paiements directement depuis votre page publique. \nVotre portfolio ne se contente pas de présenter votre travail, il facilite également les interactions financières.",
    "Personalized Storytelling:": "Narration Personnalisée : ",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Utilisez la section 'À Propos de Moi' pour partager votre histoire, mission ou vision, créant une connexion \npersonnelle avec votre audience.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "\"Mon Portfolio\" est plus qu'une simple galerie ; c'est un outil pour construire votre marque, engager votre \naudience et rationaliser vos interactions financières sur InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Mettez en Valeur Votre Travail, Élargissez Votre Portée. 'Mon Portfolio' est votre toile numérique sur InstaPay. \nTéléchargez et affichez une gamme variée de contenus – des images captivantes et vidéos aux PDF informatifs. \nQue vous soyez artiste, freelance ou entrepreneur, cette fonctionnalité vous permet de présenter votre travail ou \nprojets de manière visuellement attrayante",
    "Add/Manage Portfolio": "Ajouter/Gérer le Portfolio",
    "Add New": "Ajouter Nouveau",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximisez vos gains sur les réseaux sociaux avec votre Adresse de Paiement InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Rendez-le Facile pour Votre Audience de Vous Soutenir :",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Intégrez aisément votre Adresse de Paiement InstaPay dans vos publications sur les réseaux sociaux.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Copiez et collez facilement le lien de votre adresse de paiement dans chacun de vos posts, stories, ou de manière visible sur \nvotre profil.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Idéal pour les influenceurs, artistes, entrepreneurs, ou toute personne ayant une communauté désireuse de montrer son soutien.",
    "Instant Support, Endless Possibilities:": "Soutien Instantané, Possibilités Infinies :",
    "Transform the way you monetize your social media content.": "Transformez votre manière de monétiser votre contenu sur les réseaux sociaux.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Permettez à vos followers de vous envoyer des fonds instantanément d'un simple clic, directement depuis leurs réseaux sociaux \npréférés.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Idéal pour un soutien en temps réel lors de vos diffusions en direct, comme un système de pourboires pour votre contenu créatif, \nou pour financer votre prochain grand projet.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Où Placer Votre Adresse de Paiement InstaPay pour un Impact Maximum :",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Sur vos profils de réseaux sociaux : Épinglez-la dans votre biographie ou dans la section 'À propos' pour une visibilité permanente.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Publications et Annonces : Intégrez-la dans vos posts individuels, en particulier ceux qui mettent en avant votre travail ou vos \nappels à soutien spécifiques.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Histoires & Reels : Intégrez-la dans vos histoires interactives ou vos reels captivants pour capturer des contributions instantanées.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Diffusions en Direct : Partagez-la lors de vos LIVE, facilitant les contributions des spectateurs en temps réel.",
    "Simple, Fast, and Secure:": "Simple, Rapide et Sécurisé :",
    "No complex setup – get started in moments.": "Commencez en quelques instants sans configuration complexe.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Profitez de la sécurité et de la rapidité du traitement de paiement fiable d'InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Recevez des paiements de n'importe qui, n'importe où, sans qu'ils aient besoin d'un compte InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Élevez votre présence sur les réseaux sociaux avec l'Adresse de Paiement InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "Adresse de Paiement Virtuelle InstaPay (VPA)",
    "Payment Address Title": "Titre de l'Adresse de Paiement",
    "Currency": "Devise",
    "Payment Address Description": "Description de l'Adresse de Paiement",
    "Update": "Mise à Jour",
    "Updating": "Mise à Jour en Cours",
    "Total Transaction": "Total des Transactions",
    "Last 30 days": "Derniers 30 jours",
    "Last 1 month": "Dernier mois",
    "Last 1 year": "Dernière année",
    "Custom Date": "Date Personnalisée",
    "Payment Methods": "Méthodes de Paiement",
    "Payment Types": "Types de Paiement",
    "Requested Amount": "Montant Demandé",
    "Quoted Amount": "Montant du Devis",
    "About Me": "À Propos de Moi",
    "Followers": "Followers",
    "InstaPay ID": "ID InstaPay",
    "Download QR Code": "Télécharger le QR Code",
    "Initiate A Payment": "Initier un Paiement",
    "Report this person": "Signaler cette personne",
    "Add now": "Ajouter maintenant",
    "Recent Reviews": "Avis Récents",
    "Reviews As Seller": "Avis en tant que Vendeur",
    "Reviews As Buyer": "Avis en tant qu'Acheteur",
    "Select Language": "Sélectionner la Langue",
    "Profile Completion": "Achèvement du Profil",
    "Profile Completed": "Profil Complété",
    "Basic Info": "Informations de Base",
    "Username": "Nom d'Utilisateur",
    "Gender": "Genre",
    "Male": "Homme",
    "Female": "Femme",
    "-Select-Gender-": "-Choisir le Genre-",
    "Date Of Birth": "Date de Naissance",
    "Address": "Adresse",
    "Edit Profile": "Modifier le Profil",
    "Update Profile": "Mettre à Jour le Profil",
    "Updating Profile": "Mise à Jour du Profil",
    "Complete Now": "Compléter Maintenant",
    "Password": "Mot de Passe",
    "Change Password": "Changer le Mot de Passe",
    "New Password": "Nouveau Mot de Passe",
    "Re-enter New Password": "Ressaisir le Nouveau Mot de Passe",
    "Please follow this guide for a strong password": "Veuillez suivre ce guide pour un mot de passe sécurisé :",
    "Include at least one special character.": "Inclure au moins un caractère spécial.",
    "Minimum of 8 characters.": "Minimum de 8 caractères.",
    "Include at least one number": "Inclure au moins un chiffre.",
    "Change it often for enhanced security.": "Le changer régulièrement pour une sécurité renforcée.",
    "Security Questions": "Questions de sécurité",
    "Security Question": "Question de sécurité",
    "Select your question": "Sélectionnez votre question",
    "Answer To The Question": "Réponse à la question",
    "Nominee": "Bénéficiaire désigné",
    "Relationship with Nominee": "Relation avec le bénéficiaire désigné",
    "Nominee Contact Number": "Numéro de contact du bénéficiaire désigné",
    "Nominee Address": "Adresse du bénéficiaire désigné",
    "Delete Nominee": "Supprimer le bénéficiaire désigné",
    "Update Nominee": "Mettre à jour le bénéficiaire désigné",
    "Details Updated Successfully!": "Mise à jour des informations réussie.",
    "Success": "Succès",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "Vous pouvez désigner un bénéficiaire de votre choix, à condition qu'il ne soit pas mineur, directement via la plateforme InstaPay. \nVous reconnaissez qu'aucune signature manuscrite n'est nécessaire pour nommer le bénéficiaire, et que l'inscription en ligne sera \nconsidérée comme définitive et contraignante. En cas de décès du détenteur d'un Wallet InstaPay, le solde du Wallet sera transféré \nau bénéficiaire enregistré. KEMIT KINGDOM sera déchargé de toutes ses responsabilités dès la remise du montant au bénéficiaire. \nLe bénéficiaire devra fournir tous les documents requis par InstaPay, y compris ceux pour l'identification et la preuve de décès.",
    "Add Nominee": "Ajouter un Bénéficiaire",
    "Two Factor Authentication": "Authentification à Deux Facteurs",
    "SMS Number": "Numéro SMS",
    "Updated Successfully!": "Mise à jour réussie !",
    "Social Network Accounts": "Comptes Réseaux Sociaux",
    "Here, you can set up and manage your integration settings.": "Ici, vous pouvez configurer et gérer vos paramètres.",
    "Social Network Account": "Compte Réseau Social",
    "Activate Account": "Activer le Compte",
    "Enter the code below in the InstaPay Chatbot.": "Entrez le code ci-dessous dans le Chatbot InstaPay.",
    "The code will expire in ": "Le code expirera dans",
    "Notifications": "Notifications",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Personnalisez la manière dont vous recevez les notifications. Ces paramètres s'appliquent aux activités que vous surveillez.",
    "Activity": "Activité",
    "Email": "Email",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Source",
    "Payment Requests": "Demandes de paiement",
    "Bulk Payments": "Paiements en masse",
    "Identity Verification": "Vérification d'Identité",
    "Verify Your Identity": "Vérifiez Votre Identité",
    "Identity Verification Status:": "Statut de la Vérification d'Identité :",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Décrivez-vous brièvement et expliquez vos raisons d'utiliser InstaPay.",
    "Enter Your Message": "Entrez votre message",
    "Select Occupation": "Sélectionnez votre profession",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "Le document doit être une pièce d'identité délivrée par le gouvernement avec photo. Tous les documents d'identification doivent être \nrédigés en alphabet latin. Sinon, une traduction certifiée est requise.",
    "Accepted Documents": "Documents Acceptés",
    "A national identity card": "Une carte d'identité nationale",
    "A valid passport": "Un passeport valide",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Permis de séjour valides (acceptés uniquement s'ils sont délivrés par un pays européen ou en Suisse)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Reconnaissance Faciale Biométrique : Comparaison vidéo avec photo du passeport",
    "Address Verification:": "Vérification d'Adresse :",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Fournir un relevé bancaire ou une facture (datant de moins de 3 mois) comme preuve d'adresse",
    "Additional Documents:": "Documents Supplémentaires :",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom se réserve le droit de demander tout document supplémentaire (Diligence Due Renforcée - DDR) ou toute information \nsupplémentaire nécessaire pour effectuer des vérifications approfondies, conformément à ses obligations légales.",
    "Start Verification": "Commencer la Vérification",
    "Sessions": "Sessions",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Voici une liste des appareils qui se sont connectés à votre compte. Supprimez ceux que vous ne reconnaissez pas.",
    "Your Withdrawal Options": "Vos Options de Retrait",
    "Choose your preferred method for withdrawing payments.": "Choisissez votre méthode préférée pour retirer les paiements.",
    "No withdrawal options currently available in your country.": "Aucunes options de retrait actuellement disponibles dans votre pays.",
    "Download Your Data": "Téléchargez Vos Données",
    "Request a Copy of Your Data": "Demandez une Copie de Vos Données",
    "Submit a request to download a copy of your data": "Soumettez une demande pour télécharger vos données",
    "Which Data Should Be Included in the Download?": "Quelles données devraient être incluses dans le téléchargement ?",
    "Select all applicable options.": "Sélectionnez toutes les options applicables.",
    "Personal Information": "Informations Personnelles",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Ces informations sont utilisées pour sécuriser votre compte, personnaliser votre expérience et vous contacter si nécessaire.",
    "Name and Date Of Birth": "Nom et date de naissance",
    "Phone number": "Numéro de téléphone",
    "Financial Information": "Informations Financières",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Nous utilisons ces informations pour accélérer vos paiements et pour envoyer ou recevoir de l'argent en quelques clics.",
    "Bank accounts": "Comptes bancaires",
    "Debit or credit cards": "Cartes de débit ou de crédit",
    "Credit Information": "Informations sur le Crédit",
    "Includes your credit application information": "Inclut les informations de votre demande de crédit",
    "Other Information (not included in file)": "Autres Informations (non incluses dans le fichier)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Inclut les infos sur l'appareil, les données d'utilisation technique, les informations de géolocalisation, les préférences marketing, \nl'historique de consentement,",
    "Choose file type:": "Choisissez le type de fichier :",
    "Select file type": "Sélectionnez le type de fichier",
    "PDF file can be easily opened on any computer": "Le fichier PDF peut être facilement ouvert sur n'importe quel ordinateur",
    "Submit Request": "Soumettre la Demande",
    "Delete Your Account": "Supprimer Votre Compte",
    "Ask us to delete your data and close this account": "Demandez-nous de supprimer vos données et de fermer ce compte",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Nous utilisons vos données pour fournir des services, donc si nous supprimons vos données, nous devons également fermer \nvotre compte.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Si votre compte est limité, allez au Centre de Résolution, sélectionnez 'Aller aux Limitations de Compte', puis cliquez sur 'Résoudre'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Assurez-vous que tous les paiements en attente sont réglés. Nous ne pourrons pas fermer votre compte tant qu'ils ne le seront pas.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Si vous avez un solde négatif, cliquez sur le lien Résoudre à côté de votre solde.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Une fois votre compte clair, et si vous êtes éligible, nous fermerons votre compte et supprimerons vos données. Nous devons\nconserver certaines de vos données pour des raisons légales et réglementaires. Pour en savoir plus, consultez notre",
    "privacy statement": "déclaration de confidentialité",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Nous vous enverrons un email pour vous informer du statut, et s'il y a d'autres étapes à suivre.",
    "Have more questions about data deletion?": "Vous avez d'autres questions sur la suppression des données ?",
    "Contact us": "Contactez-nous",
    "I understand that account deletion is permanent and can't be reversed.": "Je comprends que la suppression du compte est permanente et ne peut être annulée.",
    "Are you sure you want to close your account?": "Êtes-vous sûr de vouloir fermer votre compte ?",
    "Yes, Continue": "Oui, Continuer",
    "Logging In": "Connexion",
    "Complete your profile to streamline your transaction process.": "Complétez votre profil pour simplifier votre processus de transaction.",
    "Skip": "Passer",
    "Complete Your Profile": "Complétez Votre Profil",
    "API Key": "Clé API",
    "Get API Key": "Obtenir la Clé API",
    "Generating New API Key": "Générer une Nouvelle Clé API",
    "Generate API Key": "Générer la Clé API",
    "Regenerate API Key": "Régénérer la Clé API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "Pour exploiter pleinement le potentiel des transactions fluides d'InstaPay, suivez ces étapes rapides pour générer votre clé API. \nD'abord, inscrivez-vous à un compte à [votre URL d'inscription] et accédez au Tableau de Bord des Développeurs. \nNaviguez vers la section 'Clés API', générez une nouvelle clé et assurez-vous de son stockage sécurisé.",
    "Delete API Key": "Supprimer la Clé API",
    "Webhook URL": "URL de Webhook",
    "KYB Verification": "Vérification KYB",
    "Additional Files": "Fichiers Supplémentaires",
    "Business Verification": "Vérification d'Entreprise",
    "--Select-Your-Company-Type--": "--Sélectionnez-Votre-Type-d'Entreprise--",
    "Documents Required:": "Documents Requis :",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Extrait du Registre Officiel de l'Entreprise (Document d'Enregistrement/Incorporation de l'Entité)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "Ce document sert de 'carte d'identité' officielle de l'entreprise et doit être obtenu auprès du registre local des entreprises.\nIl reflète le statut actuel de l'entreprise (active ou fermée).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Le document doit être à jour, avec une date d'émission ne dépassant pas 3 mois.",
    "The document must include the following details:": "Le document doit inclure les détails suivants :",
    "Legal name of the company": "Nom légal de l'entreprise",
    "Full registered address": "Adresse complète enregistrée",
    "Company registration number": "Numéro d'immatriculation de l'entreprise",
    "Date of incorporation": "Date de création",
    "Description of the company's activities/purpose": "Description des activités/objectifs de l'entreprise",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Informations sur le représentant légal : nom complet, date et lieu de naissance, adresse résidentielle \n(au minimum, le pays de résidence), et nationalité",
    "If applicable, the document should also include:": "Si applicable, le document doit également inclure :",
    "Brand or trading names": "Noms commerciaux ou de marque",
    "Value Added Tax (VAT) number": "Numéro de TVA (Taxe sur la Valeur Ajoutée)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "Liste des Propriétaires Bénéficiaires Ultimes (UBOs) - cela est obligatoire dans certains pays, sinon, cela peut être fourni dans un \ndocument séparé.",
    "Proof of Legal Representative's Authority": "Preuve de l'Autorité du Représentant Légal",
    "This document validates an individual's authority to represent the company": "Ce document valide l'autorité d'un individu à représenter l'entreprise",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Typiquement, le représentant légal est nommé dans l'extrait du registre de l'entreprise. Cependant, si ce n'est pas le cas, un document \ntel qu'une Procuration ou une Délégation de Pouvoirs doit être sécurisé.",
    "Power of Attorney/Delegation of Powers:": "Procuration/Délégation de Pouvoirs :",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "L'individu conférant l'autorité doit être légitimement habilité à le faire. Cet individu devrait être listé comme représentant légal \ndans l'extrait du registre de l'entreprise.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Documentation sur la Structure de Propriété et les Propriétaires Bénéficiaires Ultimes (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Des preuves de la cotation de l'entreprise sur la bourse pertinente doivent être fournies. Cela pourrait être une page du site web de la \nbourse affichant les détails de l'entreprise.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "Si moins de 75% des actions de l'entreprise sont détenues publiquement, tous les actionnaires possédant ou contrôlant plus de 25% \ndes actions seront soumis aux exigences de vérification du Propriétaire Bénéficiaire Ultime (UBO).",
    "Collection of Identification Documents": "Collecte de Documents d'Identification",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "Le document d'identification doit être délivré par le gouvernement, portant une photographie de l'individu. Tous les documents d'identification \ndoivent être rédigés en alphabet latin. Sinon, une traduction certifiée est requise.",
    "Accepted documents:": "Documents acceptés :",
    "Valid National Identity Card": "Carte d'identité nationale valide",
    "Valid Passport": "Passeport valide",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Permis de résidence valides - Ces derniers seront acceptés uniquement s'ils sont délivrés par un pays européen.",
    "Only legal representatives are subject to the identification document collection process.": "Seuls les représentants légaux sont soumis au processus de collecte de documents d'identification.",
    "Additional Requirement:": "Exigence supplémentaire :",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "Dans les cas impliquant une Procuration ou une Délégation de Pouvoirs, les deux parties \n(l'individu accordant l'autorité et celui la recevant) doivent fournir une copie de leur pièce d'identité.",
    "Additional Documents": "Documents supplémentaires ",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Facture de services (comme gaz, électricité, téléphone ou facture de téléphone mobile) ; ou un document délivré par un département \ngouvernemental montrant l'adresse et le nom de l'utilisateur final (par exemple, Certificat de Résidence) ; ou",
    "Bank Statement; or": "Relevé bancaire ; ou",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "Un document délivré par un département gouvernemental montrant l'adresse et le nom de l'utilisateur final \n(par exemple, Certificat de Résidence).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "Kemit Kingdom se réserve le droit de demander tout document supplémentaire ou information complémentaire nécessaire pour effectuer \ndes vérifications approfondies, conformément à ses obligations légales.",
    "Note:": "Note :",
    "Partners": "Partenaires",
    "Add New Partner": "Ajouter un Nouveau Partenaire",
    "Designation": "Désignation",
    "Partner Name": "Nom du Partenaire",
    "Partner Email": "Email du Partenaire",
    "Partner Designation": "Désignation du Partenaire",
    "Add Now": "Ajouter Maintenant",
    "Adding": "Ajout en cours",
    "Delete Partner": "Supprimer Partenaire",
    "Delete": "Supprimer",
    "Deleting": "Suppression en cours",
    "Are you sure, you want to remove sxz as partner?": "Êtes-vous sûr de vouloir retirer sxz en tant que partenaire ?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Extrait du Registre de l'Entité (Document de Création/Enregistrement de l'Entité)",
    "This document should be either:": "Ce document doit être :",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Une preuve de création de l'entité, fournissant les détails de l'entité, la date de création et l'objet.",
    "A local registry extract:": "Un extrait du registre local :",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Requis selon la réglementation du pays, servant de 'carte d'identité' actuelle de l'entité, indiquant son statut (actif ou fermé).",
    "If a registry extract exists, it must not be older than 3 months.": "Si un extrait du registre existe, il ne doit pas être plus ancien que 3 mois.",
    "Entity name": "Nom de l'entité",
    "Registration number (if applicable, based on the country's requirements)": "Numéro d'enregistrement (si applicable, selon les exigences du pays)",
    "Date of creation": "Date de création",
    "Description of the entity's activities or purpose": "Description des activités ou de l'objet de l'entité",
    "Trading names": "Noms commerciaux",
    "Articles of Association": "Statuts de l'Association",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "Les Statuts servent de document constitutionnel de l'entité, détaillant ses règles de fonctionnement.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "Il n'y a pas de contrainte de temps sur ce document, ce qui signifie que la version la plus récente est demandée. Typiquement, ces types \nd'entités nécessitent au moins une réunion annuelle qui peut entraîner une version mise à jour des Statuts de l'Association",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Des exemples de changements qui pourraient survenir incluent des mises à jour du nom de l'entité, de l'adresse, de l'objet, du conseil \nd'administration, des rôles, des responsabilités, etc.",
    "The document should contain information about:": "Le document doit contenir des informations sur :",
    "The entity itself, including its legal name, registered address, and purpose": "L'entité elle-même, y compris son nom légal, son adresse enregistrée et son objet",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Ses règles de fonctionnement internes, telles que le processus de nomination des membres du conseil d'administration, leurs rôles et \npouvoirs, parmi d'autres détails. Cette liste n'est pas exhaustive.",
    "How the entity is funded": "Comment l'entité est financée",
    "Appointment/Removal/Changes on Board of Management Members:": "Nomination/Retrait/Changements au sein du Conseil d'Administration :",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "Ces mises à jour surviennent généralement en fonction des besoins de l'entité ou tel que stipulé dans les Statuts de l'Association. \nLes changements sont documentés dans les procès-verbaux des réunions du conseil de gestion, qui sont enregistrés et certifiés pour \nrefléter la liste mise à jour des membres, tels que le président, le vice-président, le trésorier et le secrétaire général.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "Les derniers procès-verbaux de réunion du conseil de gestion, incluant la liste actuelle des membres, seront requis en plus des Statuts \nde l'Association, si disponibles.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Note : Si la liste actuelle des membres du conseil de gestion n'est pas incluse dans les derniers Statuts de l'Association et qu'il n'y a pas \nde procès-verbaux de réunion disponibles, cette liste doit être fournie. Elle doit être datée et signée par le représentant légal le plus \nrécemment nommé, dont la nomination peut être vérifiée à travers la documentation connexe.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Ce document démontre l'autorité d'un individu à représenter l'entité. Ce document pourrait être :",
    "The latest Articles of Association": "Les derniers Statuts ",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "La liste actuelle des membres du conseil de gestion (qui peut être incluse dans les procès-verbaux des réunions du conseil de gestion)",
    "The document should specify:": "Le document doit spécifier :",
    "Full name of the individual(s)": "Nom complet de(s) individu(s)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Fonction de(s) individu(s). Ces informations seront vérifiées avec les Statuts de l'Association pour s'assurer que la fonction de l'individu \nlui permet de lier l'entité. Cela est particulièrement important lorsque le demandeur n'est pas le président, et que d'autres fonctions ont \nune autorité de représentation limitée comme indiqué dans les Statuts de l'Association.",
    "Additional information about the legal representative that will be collected includes:": "Les informations supplémentaires sur le représentant légal qui seront collectées incluent :",
    "Date and place of birth": "Date et lieu de naissance",
    "Residential address (at least the country of residence should be stated)": "Adresse résidentielle (au moins le pays de résidence doit être indiqué)",
    "Nationality": "Nationalité",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Structure de l'Actionnariat & Bénéficiaires Economiques (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Typiquement, ces types d'entités, compte tenu de leur structure et de leur objet, n'ont pas de propriétaires bénéficiaires. \nCependant, les membres du conseil seront identifiés et traités en tant que tels pour des fins de vérification d'identité, \nde collecte et de vérification, etc.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "Un petit nombre d'associations pourraient avoir des propriétaires bénéficiaires, en particulier celles générant des profits. Dans de tels cas, \nce détail sera mentionné dans les Statuts de l'Association. Ces individus seront facilement identifiés et traités en tant que tels, aux côtés \ndes membres du conseil de gestion.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Exigence Supplémentaire : Dans les cas impliquant une Procuration ou une Délégation de Pouvoirs, les deux parties \n(l'individu conférant l'autorité et celui la recevant) doivent fournir une copie de leurs documents d'identification.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Extrait du Registre du Commerçant Individuel (Document d'Enregistrement/Création de l'Entité)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Ce document sert de registre local pour les commerçants individuels, requis dans les pays où une telle inscription est obligatoire. Il vérifie \nque l'individu exerce une activité professionnelle en tant que commerçant individuel. Le nom de ce document varie d'un pays à l'autre.",
    "Where applicable, the document should not be older than 3 months.": "Lorsqu'applicable, le document ne doit pas être plus ancien que 3 mois.",
    "The document must contain:": "Le document doit contenir :",
    "Individual's name": "Nom de l'individu",
    "Residential address": "Adresse résidentielle",
    "Registration number": "Numéro d'enregistrement",
    "The sole trader is the only individual subject to the identification document collection process.": "Le commerçant individuel est le seul individu soumis au processus de collecte de documents d'identification.",
    "No Transactions": "Aucune Transaction",
    "No Pending Items": "Aucun Élément en Attente",
    "No Beneficiaries": "Aucun Bénéficiaire",
    "Send Money To": "Envoyer de l'Argent À",
    "Confirmation": "Confirmation",
    "You are setting": "Vous définissez",
    "as default.": "par défaut.",
    "Previously": "Précédemment",
    "was default QR.": "était le QR par défaut.",
    "Category": "Catégorie",
    "-Select-From-The-List-": "-Sélectionner-Dans-La-Liste-",
    "Add a note": "Ajouter une note",
    "Categories Added Successfully!": "Catégories Ajoutées avec Succès !",
    "Subscription starting date": "Date de début de l'abonnement",
    "Until": "Jusqu'à",
    "Next Transaction": "Prochaine Transaction",
    "Transaction Method": "Méthode de Transaction",
    "Accept the quotation": "Accepter le devis",
    "Choose Currency": "Choisir la Devise",
    "Accept": "Accepter",
    "Bargain the quotation": "Négocier le devis",
    "Enter Counter Amount:": "Entrer le Montant de la Contre-Offre :",
    "Bargain": "Négocier",
    "Decline the quotation": "Annuler le devis",
    "Are you sure, you want to decline this quotation?": "Êtes-vous sûr de vouloir refuser ce devis ?",
    "Decline": "Refuser",
    "Personal Account": "Compte Personnel",
    "Jan": "Janv",
    "Feb": "Fév",
    "Mar": "Mar",
    "Apr": "Avr",
    "May": "Mai",
    "Jun": "Juin",
    "Jul": "Juil",
    "Aug": "Août",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Déc",
    "Today": "Aujourd'hui",
    "Spent In": "Passé dans",
    "Revised Amount": "Montant révisé",
    "You spent more comparing to last month": "Vous avez dépensé plus par rapport au mois dernier",
    "Last": "Dernier",
    "days": "Jours",
    "Year": "Années",
    "Instant Mobile Top-up Across 150+ Countries": "Recharge Mobile Instantanée dans Plus de 150 Pays",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Faites plaisir à vos proches ou rechargez votre propre téléphone, où que vous soyez ! Le service de Recharge Mobile d'InstaPay vous permet de recharger instantanément les téléphones mobiles dans plus de 150 pays. Idéal pour les cadeaux ou pour rester constamment connecté, notre service est conçu pour la facilité, la rapidité et la sécurité.",
    "How It Works - Step-by-Step Guide:": "Comment ça marche - Guide étape par étape :",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Choisissez 'Recharge Mobile' : Accédez à cette fonctionnalité directement dans l'application InstaPay sur des plateformes telles que WhatsApp, Twitter, Telegram ou Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Sélectionnez la devise et le montant : Choisissez parmi plusieurs devises (USD, GBP, EUR, etc.) et spécifiez le montant que vous souhaitez recharger.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Entrez le numéro de mobile : Tapez le numéro que vous souhaitez recharger — le vôtre ou celui d'un proche. Notre système garantit un processus sécurisé et rapide.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Confirmez et payez : Vérifiez les détails de la recharge, confirmez et effectuez le paiement en utilisant votre méthode préférée. La transaction est traitée instantanément et en toute sécurité.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Recevez une confirmation instantanée : Vous et le destinataire (le cas échéant) recevrez une notification de confirmation une fois la recharge réussie.",
    "Benefits:": "Avantages :",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Portée globale : Envoyez facilement du crédit téléphonique dans plus de 150 pays.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Impact instantané : Voyez les avantages immédiatement — parfait pour les cadeaux de dernière minute ou les communications urgentes.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Sécurité complète : Profitez de la tranquillité d'esprit avec notre système de paiement sécurisé.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Options de paiement polyvalentes : Choisissez parmi les cartes de crédit, PayPal ou votre portefeuille InstaPay pour les paiements.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Prêt à répandre la joie ou à rester connecté ? Rechargez maintenant et faites le bonheur de quelqu'un — ou le vôtre !",
    "Pick a language": "Choisissez une langue",
    "French": "Français",
    "Spanish": "Espagnol",
    "Portuguese": "Portugais",
    "German": "Allemand",
    "Ukrainian": "Ukrainien",
    "Italian": "Italien",
    "Russian": "Russe",
    "Arabic": "Arabe",
    "Polish": "Polonais",
    "Dutch (Nederlands)": "Néerlandais",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonésien",
    "Turkish": "Turc",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinois",
    "Select Your Preferred Language": "Sélectionnez votre langue préférée",
    "Please select the language you would \nlike to use for the eKYC process.": "Veuillez sélectionner la langue que vous souhaitez utiliser pour le processus d'eKYC.",
    "Select Your Nationality": "Sélectionnez votre nationalité",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Choisissez votre nationalité pour voir les documents d'identité pris en charge pour votre pays.",
    "Grant permission to use the camera.": "Accordez la permission d'utiliser la caméra.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Appuyez ou cliquez sur \"Autoriser\" lorsque vous êtes invité à accorder la permission d'accès à la caméra pour capturer le document.",
    "Upload Your Documents from \nGallery/Photos": "Téléchargez vos documents depuis la galerie/photos",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Si vous rencontrez des problèmes avec votre caméra ou si votre système n'en a pas, vous pouvez télécharger manuellement des photos du document depuis votre stockage local.",
    "Upload Your ID.": "Téléchargez votre pièce d'identité.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Assurez-vous que votre pièce d'identité est claire et non floue pour garantir une vérification rapide",
    "Valid Proof of Address": "Preuve d'adresse valide",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Assurez-vous que votre preuve d'adresse est actuelle et que la date de l'extrait est dans les trois derniers mois à compter de la date de début de votre vérification eKYC.\n\n2. Assurez-vous que votre preuve d'adresse est claire et non floue pour garantir une vérification rapide.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Avis Important\n\nVeuillez lire attentivement toutes les étapes de ce guide avant de commencer votre vérification eKYC.\n\nÉvitez les erreurs courantes :\n\n* Assurez-vous que tous vos documents sont clairs, lisibles et à jour.\n* Vérifiez que les informations fournies correspondent aux détails de vos documents officiels.\n* Suivez les instructions précisément pour éviter tout retard ou rejet.\n\nRemarque : \nSi votre vérification eKYC échoue et que vous devez la soumettre à nouveau, des frais supplémentaires vous seront facturés.\n\nEn suivant ces directives, vous pouvez vous assurer d’une vérification eKYC fluide et réussie.\n\nPour toute question ou assistance, veuillez contacter notre équipe de support à support@insta-pay.ch",
    "Personal Transactions": "Transactions personnelles",
    "Business Transactions": "Transactions commerciales",
    "Shopping & Purchases": "Achats et achats",
    "Bills & Utilities": "Factures et services publics",
    "Entertainment & Leisure": "Divertissement et loisirs",
    "Investments & Savings": "Investissements et épargne",
    "Health & Wellness": "Santé et bien-être",
    "Transportation": "Transport",
    "Education": "Éducation",
    "Miscellaneous": "Divers",
    "Family Support": "Support familial",
    "WALLET": "WALLET",
    "Add a currency": "Ajouter une devise",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Lorsque vous ajoutez une devise, tous les paiements que vous recevez dans cette devise seront crédités à votre solde InstaPay. Votre devise principale sera utilisée pour envoyer ou demander des paiements, sauf indication contraire.",

    "balance amount ": "montant du solde",
    "referral ": "parrainage",
    "crypto wallet balance": "solde du portefeuille crypto",
    "money in": "argent entrant",
    "money out": "argent sortant",
    "analytics": "analytique",
    "see more": "voir plus",
    "manage my Wallets and see the transaction details": "gérer mes Wallets et voir le détail de mes transactions",
    "set as default Wallet": "définir comme Wallet par défaut",
    "add currency": "ajouter une devise",
    "convert funds ": "convertir des fonds",
    "withdraw balance": "retirer le solde",
    "chart names": "noms des graphiques",
    "withdraw balance from PKR wallet": "retirer le solde du wallet PKR",
    "select a payout channel": "sélectionner un canal de paiement",
    "you have entered an amount below the minimum payment range": "vous avez entré un montant en dessous du montant minimum requis",
    "change": "changer",
    "edit": "modifier",
    "moving to next step in ...": "passage à l'étape suivante dans ...",
    "you are withdrawing ...": "vous retirez ...",
    "where is my payment": "où est mon paiement",
    "download": "télécharger",
    "IBAN / SWIFT Code": "IBAN ou code SWIFT/BIC",
    "swift code": "code SWIFT",
    "account number": "numéro de compte",
    "branch street": "rue de la succursale",
    "city": "ville",
    "province": "province",
    "postal code": "code postal",
    "all payout channels": "tous les canaux de paiement",
    "bussiness beneficiary": "bénéficiaire d'entreprise",
    "no quotation": "pas de devis",
    "no pending items": "aucun élément en attente",
    "QR status": "Statut du QR",
    "Portfolio": "Portefeuille",
    "edit whole portfolio page": "modifier toute la page du portefeuille",
    "personal account": "compte personnel",
    "Chart names": "Noms des graphiques",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "plus",
    "not connected": "non connecté",
    "code": "code",
    "ratings": "évaluations",
    "current password": "mot de passe actuel",
    "include at least one uppercase and lowercase": "inclure au moins une majuscule et une minuscule",
    "my social network accounts": "mes comptes de réseaux sociaux",
    "SMS ": "SMS",
    "view identity verfication guide": "voir le guide de vérification d'identité",
    "boimetric face recognition": "reconnaissance faciale biométrique",
    "please select a payer (withdrawl options)": "veuillez sélectionner un payeur (options de retrait)",
    "IBAN number ": "numéro IBAN",
    "account holder name": "nom du titulaire du compte",
    "download data": "télécharger les données",
    "bank transfer": "virement bancaire",
    "mobile wallet": "portefeuille mobile",
    "all bank names": "tous les noms de banques",
    "pay via card": "payer par carte",
    "you have entered an amount that is below the payer range": "vous avez entré un montant inférieur à la plage du payeur",
    "trouble sending money": "problème pour envoyer de l'argent",
    "please follow these example": "veuillez suivre ces exemples",
    "wallet ID ": "ID du Wallet",
    "username": "nom d'utilisateur",
    "email": "email",
    "phone number": "numéro de téléphone",
    "no user found": "aucun utilisateur trouvé",
    "search": "recherche",
    "scan/upload QR code of the recipient": "scannez/téléchargez le code QR du destinataire",
    "invite them": "invitez-les",
    "trouble quoting money": "problème de devis",
    "select a country": "sélectionner un pays",
    "airtime details": "détails du crédit",
    "enter the phone number": "entrer le numéro de téléphone",
    "service type": "type de service",
    "select a service": "sélectionner un service",
    "service list": "liste des services",
    "amount entered is less than the ....": "le montant saisi est inférieur à ....",
    "total": "total",
    "total credit the receiver gets": "crédit total reçu par le destinataire",
    "take ": "prendre",
    "OTP has been verified , You'll be redirected......": "OTP a été vérifié, vous serez redirigé......",
    "Link Your Instagram Account to InstaPay": "Liez votre compte Instagram à InstaPay",
    "Step 01 – From Instagram App": "Étape 01 – Depuis l'application Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Ouvrez l'application Instagram, recherchez la page de profil \"InstaPay\", sélectionnez \"Message\" pour ouvrir la boîte de chat, tapez/dîtes \"Salut\" et cliquez sur envoyer pour initier la conversation avec le chatbot InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Étape 02 – Depuis la page de profil Instagram d'InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Le chatbot InstaPay affichera deux boutons d'action : \"S'inscrire\" et \"Connecter\". Sélectionnez \"Connecter\", et le chatbot vous invitera à entrer votre nom d'utilisateur InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Étape 03 – Depuis la page de profil Instagram d'InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Le chatbot InstaPay vous demandera ensuite le code de vérification InstaPay. Entrez le code que vous avez obtenu dans les paramètres d'InstaPay sous \"Mes comptes de réseaux sociaux\" et cliquez sur envoyer.",
    "Step 04 – From InstaPay Instagram Profile Page": "Étape 04 – Depuis la page de profil Instagram d'InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Vous recevrez un message de félicitations confirmant que votre compte Instagram est maintenant synchronisé avec InstaPay. Cliquez sur \"Menu principal\" pour commencer à utiliser les services InstaPay.",
    "Additional Tips": "Conseils supplémentaires",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Sécurisez votre compte : Assurez-vous que votre compte Instagram est sécurisé en activant l'authentification à deux facteurs (2FA) pour protéger vos services InstaPay liés.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Explorez plus : Une fois lié, explorez la large gamme de services qu'offre InstaPay, des transactions sans friction aux retraits instantanés.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Restez à jour : Restez à l'affût de nos mises à jour, car nous prendrons bientôt en charge le lien avec d'autres comptes de réseaux sociaux pour rendre votre expérience encore plus intégrée.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Aide & Support : Si vous rencontrez des problèmes lors du processus de liaison, contactez notre équipe de support pour obtenir de l'aide.",
    "Future Enhancements": "Améliorations futures",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Dans un avenir proche, InstaPay vous permettra de lier davantage de comptes de réseaux sociaux, vous offrant encore plus de flexibilité et de commodité. Restez à l'écoute pour des mises à jour passionnantes !",
    "Personal Transactions": "Transactions personnelles",
    "Business Transactions": "Transactions commerciales",
    "Shopping & Purchases": "Achats et achats",
    "Bills & Utilities": "Factures et services publics",
    "Entertainment & Leisure": "Divertissement et loisirs",
    "Investments & Savings": "Investissements et épargne",
    "Health & Wellness": "Santé et bien-être",
    "Transportation": "Transport",
    "Education": "Éducation",
    "Miscellaneous": "Divers",
    "Family Support": "Support familial",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Commencez votre aventure InstaPay dès aujourd'hui !",
    "Enter your phone number": "Entrez votre numéro de téléphone",
    "Login with email": "Connexion avec l'email",
    "Don't have an account?": "Vous n'avez pas de compte ?",
    "Sign Up": "Inscrivez-vous",
    "Continue": "Continuer",
    "We recommend you download and install the Google Authenticator app": "Nous vous recommandons de télécharger et d'installer l'application Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "L'authentification à deux facteurs (2FA) est la meilleure façon de vous protéger en ligne.",
    "Strong security with Google Authenticator": "Sécurité renforcée avec Google Authenticator",
    "Enter your password": "Entrez votre mot de passe",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Nous vous recommandons de télécharger et d'installer l'application Google Authenticator pour vous assurer de recevoir vos codes de vérification instantanément, évitant ainsi tout retard ou problème de SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Code d'authentification à deux facteurs",
    "Problem with the token?": "Problème avec le jeton ?",
    "Verify using SMS": "Vérifiez en utilisant SMS",
    "Verify": "Vérifier",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Vérification par SMS",
    "Enter the SMS code sent to +41********4053": "Entrez le code SMS envoyé à +41********4053",
    "Verify Using Authenticator App": "Vérifiez en utilisant l'application Authenticator",
    "Resend code in 120 seconds": "Renvoyer le code dans 120 secondes",
    "Verify ": "Vérifier",
    "SMS - Web Mobile": null,
    "My own account": "Mon propre compte",
    "Friends & Family": "Amis & Famille",
    "Aunt": "Tante",
    "Brother-in-law": "Beau-frère",
    "Cousin": "Cousin",
    "Daughter": "Fille",
    "Father": "Père",
    "Father-in-law": "Beau-père",
    "Friend": "Ami",
    "Grandfather": "Grand-père",
    "Grandmother": "Grand-mère",
    "Husband": "Mari",
    "Mother": "Mère",
    "Mother-in-law": "Belle-mère",
    "Nephew": "Neveu",
    "Niece": "Nièce",
    "Self (i.e. the sender, himself)": "Soi-même (c'est-à-dire l'expéditeur lui-même)",
    "Sister": "Sœur",
    "Sister-in-law": "Belle-sœur",
    "Son": "Fils",
    "Uncle": "Oncle",
    "Wife": "Femme",
    "Others not listed": "Autres non répertorié",
    "Merchant": "Commerçant",
    "No relationship": "Aucune relation",
    "Add Funds": "Ajouter des fonds",
    "Enter the Amount": "Entrez le montant:",
    "Fees": "Frais:",
    "You Will Get": "Vous recevrez:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ La valeur transactionnelle doit être comprise entre [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Montant invalide : Votre niveau actuel de vérification d'identité limite le montant maximum que vous pouvez ajouter. Veuillez mettre à niveau votre vérification d'identité pour bénéficier de limites plus élevées.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Le prix de la devise change fréquemment en fonction des conditions du marché. Veuillez vous référer au prix sur la page de confirmation de commande comme votre devis final.",
    "Next": "Suivant",
    "Upgrade Identity Verification": "Mettre à niveau la vérification d'identité",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Demandez de l'argent à n'importe qui, n'importe où, y compris via les plateformes de réseaux sociaux. Avec InstaPay, vos demandes de paiement sont instantanément accessibles une fois acceptées et les fonds sont envoyés à vos comptes de réception. Simplifiez vos transactions et gérez vos paiements avec facilité et sécurité.",
    "You’ve been logged out.": "Vous avez été déconnecté.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Pour votre sécurité, vous avez été déconnecté après 15 minutes d'inactivité. Veuillez vous reconnecter si vous souhaitez utiliser InstaPay.",
    "LOGIN AGAIN": "SE CONNECTER À NOUVEAU",
    "Brother": "Frère",
    "Current Password": "Mot de passe actuel",
    "The new password cannot be the same as the old one": "Le nouveau mot de passe ne peut pas être identique à l'ancien.",
    "Minimum password length: [X] characters ": "Longueur minimale du mot de passe : [X] caractères",
    "Include at least one uppercase and one lowercase letter.": "Inclure au moins une majuscule et une minuscule.",
    "Nominee Information": "Nominee Information",
    "Verification Code Preferences": "Préférences du code de vérification",
    "How Others Can Find You": "Comment les autres peuvent vous trouver",
    "My Social Network Accounts": "Mes comptes de réseaux sociaux",
    "Login Activity": "Activité de connexion",
    "Receiving Accounts": "Comptes de réception",
    "Change Your Password To Continue": "Changez votre mot de passe pour continuer",
    "Continue with": "Continuer avec",
    "Back to Login": "Retour à la connexion",
    "Send OTP": "Envoyer le code OTP",
    "A Verification code has been sent to your email and number.": "Un code de vérification a été envoyé à votre email et à votre numéro.",
    "Resend available in": "Renvoyer disponible dans",
    "Create a strong Password": "Créer un mot de passe fort",
    "Password must:": "Le mot de passe doit :",
    "Be between 9 and 64 characters": "Être compris entre 9 et 64 caractères",
    "Include at least two of the following:": "Inclure au moins deux des éléments suivants :",
    "Lowercase Character": "Caractère en minuscule",
    "Special Character": "Caractère spécial",
    "Password Updated Successfully!": "Mot de passe mis à jour avec succès !",
    "Click below to login": "Cliquez ci-dessous pour vous connecter",

    "Send Money": "Envoyer de l'argent",
    "Enter the amount": null,
    "Recent Transfer": "Transfert récent",
    "See all": "Voir tout",
    "Send again": "Envoyer à nouveau",
    "You Send": "Vous envoyez",
    "They Receive": "Ils reçoivent",
    "Receive Method": "Méthode de réception",
    "Select method": "Sélectionnez la méthode",
    "Next": "Suivant",
    "Trouble sending money?": "Des problèmes pour envoyer de l'argent?",
    "Where do you want to send money ?": "Où voulez-vous envoyer de l'argent?",
    "Search ": "Recherche",
    "Confirm": "Confirmer",
    "Bank Deposit": "Dépôt Bancaire",
    "Cash Pick-up": "Retrait en Espèces",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Carte Bancaire",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Quel est le Wallet mobile du destinataire?",
    "Transfer fees": "Frais de transfert",
    "Total to pay": "Total à payer",
    "Choose the beneficiary!": "Choisissez le bénéficiaire!",
    "Country": "Pays",
    "Receive method": "Méthode de réception",
    "You send": "Vous envoyez",
    "change": "modifier",
    "edit": "ajouter nouveau",
    "add new": "Bénéficiaires",
    "Beneficiaries": "Gérer les bénéficiaires",
    "Manage Beneficiaries": "Rechercher",
    "Search": "Suivant",
    "CASH PICK-UP": "RETRAIT EN ESPÈCES",
    "Where will your receiver collect the money": "Où votre destinataire collectera-t-il l'argent?",
    "Cash is available to collect within minutes from any branch of [name] ": "L'argent est disponible pour être collecté en quelques minutes dans n'importe quelle succursale de [nom]",
    "BANK ACCOUNT": "COMPTE BANCAIRE",
    "Transfer to recipient’s bank account": "Transfert vers le compte bancaire du destinataire",
    "BANK CARD": "CARTE BANCAIRE",
    "Fast transfer directly to recipient’s bank card": "Transfert rapide directement sur la carte bancaire du destinataire",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Transfert rapide directement vers un Wallet crypto",
    "Review transfer": "Vérifier le transfert",
    "Receiver's Details": "Destinataire",
    "Edit": "Modifier",
    "Name": "Nom",
    "Reason for sending": "Raison de l'envoi",
    "Sending": "Envoi",
    "Receiver gets": "Le destinataire reçoit",
    "Exchange rate": "Taux de change",
    "Estimated time": "Temps estimé",
    "Select Payment Type": "Sélectionnez le type de paiement",
    "Credit cards": "Cartes de crédit",
    "Domestic cards": "Cartes domestiques",
    "Mobile Wallets": "Wallets mobiles",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "CARTE DE CRÉDIT",
    "Credit Card": "Carte de crédit",
    "Select card": "Sélectionnez la carte",
    "Card Number": "Numéro de carte",
    "Enter the 16-digit card number on the card": "Entrez le numéro de carte à 16 chiffres ",
    "Expiry Date": "Date d'expiration",
    "Enter the expiration date of the card": "Entrez la date d'expiration de la carte",
    "CVV Number": "Numéro CVV",
    "Enter the 3 or 4 digit number on the card": "Entrez le numéro de 3 ou 4 chiffres sur la carte",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Sélectionnez le portefeuille",
    "My Wallet": "Mon Wallet",
    "Conversion": "Conversion",
    "Convert": "Convertir",
    "Your payment is confirmed": "Votre paiement est confirmé",
    "Thanks for using InstaPay": "Merci d'utiliser InstaPay",
    "Status": "Statut",
    "Share": "Partager",
    "Transfer created": "Transfert créé",
    "Payment received": "Paiement reçu",
    "Payment processed": "Paiement traité",
    "Transfer successful": "Transfert réussi",
    "It may take few hours for the funds to appear in [user name] account": "Il peut prendre quelques heures pour que les fonds apparaissent sur le compte de [nom de l'utilisateur]",
    "Transfer failed": "Transfert échoué",
    "Transaction Details": "Détails de la transaction",
    "Need help?": "Besoin d'aide?",
    "Service Details": "Détails du Service",
    "Funds Deposit Speed": "Disponibilité des Fonds",
    "Cut-off Time": "Heure Limite",
    "Bank holiday list": "Liste des Jours Fériés Bancaires",
    "Threshold limits": "Limites Transactionnelles",
    "null": null,
    "Instant, Real Time": "Instantané, en temps réel",
    "Same day, T+1": "Le même jour, T+1",
    "Non-instant, T+1": "Non-instantané, T+1",
    "Instant Payment, Real Time": "Paiement instantané, en temps réel",
    "Transaction processed before 3:00 PM Local time": "Transaction traitée avant 15h00 heure locale",
    "Delivered the same day": "Livré le même jour",
    "Transactions processed post 3:00 PM": "Transactions traitées après 15h00",
    "T+1 business days": "T+1 jours ouvrables",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Les paiements effectués en semaine avant 15h00 seront crédités sur le compte bancaire destinataire avant minuit du même jour.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Les paiements effectués en semaine après 15h00 seront crédités avant minuit du jour suivant.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Les paiements effectués un samedi, dimanche ou jour férié seront crédités sur le compte avant minuit du 1er jour ouvrable suivant.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09h - 18h heure locale",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries ",
    "Min per transaction limit:": "Limite minimale par transaction :",
    "Maximum per transaction limit:": "Limite maximale par transaction :",
    "Daily threshold limit:": "Limite quotidienne :",
    "Weekly threshold limit:": "Limite hebdomadaire :",
    "Monthly threshold limit:": "Limite mensuelle :",
    "Yearly threshold limit:": "Limite annuelle :",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Compte de base (Non vérifié)",
    "Identity Verified": "Identité vérifiée",

    "Good Evening!": "Bonsoir !",
    "Total Balance": "Solde Total du Compte",
    "Quick Transfer": "Transfert Rapide de Fonds",
    "No transactions": "Aucune transaction trouvée",
    "Blocked balance": "Solde bloqué",
    "Available balance": "Solde disponible",
    "Insufficient Balance in PKR": "Solde Insuffisant en PKR",
    "Chat with us": "Discutez avec nous",
    "Add Currency": "Ajouter une Devise",
    "Education": "Éducation",
    "Divers": "Divers",
    "Family support": "Soutien Familial",
    "Others": "Autres Dépenses",
    "Transport": "Transport",
    "schedule payment": "Paiement programmé",
    "subscription payment": "Paiement récurrent",
    "No payment": "Aucun paiement trouvé",
    "No Beneficiaries": "Aucun bénéficiaire disponible",
    "Code Postal": "Code Postal",
    "Additional Information": "Informations supplémentaires",
    "Gender": "Sexe",
    "Date Of Birth": "Date de Naissance",
    "Nationality": "Nationalité",
    "Occupation": "Profession (toutes les options également)",
    "ID Type": "Type de pièce d'identité (toutes les options également)",
    "ID Number": "Numéro d'identification",
    "Select Beneficiary Payment Channels": "Sélectionnez les Canaux de Paiement du Bénéficiaire",
    "wallet crypto": "Wallet crypto",
    "Add another wallet": "Ajouter un autre wallet",
    "Enterprise": "Entreprise",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "Les bénéficiaires professionnels arrivent bientôt ! Vous pourrez effectuer des transactions commerciales à l'échelle mondiale, étendant ainsi votre portée au-delà des frontières.",
    "Joined At": "Inscrit le",
    "Profile Link": "Lien de Profil",
    "comission": "Commission",
    "my withdrawls": "Mes Retraits",
    "My Referrals": "Mes Parrainages",
    "search quotation": "Rechercher des devis",
    "no quotation": "Aucun devis trouvé",
    "actions": "Actions",
    "search request": "Demande de recherche",
    "no pending items ": "Aucun élément en attente trouvé",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "Partagez votre code QR ou votre lien de paiement pour recevoir instantanément des paiements dans votre wallet InstaPay",
    "Description": "Description",
    "save": "Enregistrer",
    "Share your Insta-Pay unique payment link": "Partagez votre lien de paiement InstaPay unique",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Mettez en avant votre profil public. Votre portfolio sert de vitrine dynamique, accessible directement depuis votre adresse de paiement.",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Connectez-vous avec votre audience à travers votre parcours professionnel ou créatif. Laissez votre travail se présenter et engagez plus profondément des clients potentiels ou des supporters.",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Affichez votre influence sur les réseaux sociaux. Les visiteurs peuvent voir votre portée sur les réseaux sociaux, ajoutant ainsi de la crédibilité à votre portfolio.",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Permettez aux visiteurs de demander ou d'initier des paiements directement depuis votre page publique. Votre portfolio n'est pas seulement une vitrine, mais aussi un moyen de faciliter les transactions financières.",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Utilisez la section \"À propos de moi\" pour partager votre histoire, votre mission ou vision, créant ainsi une connexion personnelle avec votre audience.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"Mon Portfolio\" est plus qu'une simple galerie ; c'est un outil pour construire votre marque, engager votre audience, et simplifier les interactions financières sur InstaPay.",
    "No Portfolio Items": "Aucun élément de portfolio trouvé",
    "Add New Portfolio Item": "Ajouter un nouvel élément au portfolio",
    "Title": "Titre",
    "drag/drop or upload a file(photo/video/pdf)": "Glisser/Déposer ou télécharger un fichier (photo/vidéo/pdf)",
    "discard": "Annuler",
    "save and post ": "Enregistrer et Publier",
    "My Payment Address": "Mon Adresse de Paiement",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Parfait pour les influenceurs, artistes, entrepreneurs, ou toute personne souhaitant permettre à sa communauté de montrer facilement son soutien.",
    "Personal Account": "Statut du Compte Personnel",
    "spent in total": "Total Dépensé",
    "not connected": "Non Connecté",
    "more": "Plus",
    "less": "Moins",
    "profile reports": "Rapports de Profil",
    "All notifications are in English langauge": "Toutes les notifications sont en anglais",
    "timezone": "Fuseau horaire",
    "nominee information": "Informations sur le bénéficiaire",
    "full name": "Nom Complet",
    "Relation with Nominee": "Relation avec le bénéficiaire",
    "source": "Source",
    "Google Authenticator": "Google Authenticator",
    "notifications": "Notifications",
    "login": "Connexion",
    "View Identity Verification Guide": "Voir le Guide de Vérification d'Identité",
    "verified": "Vérifié",
    "select ": "Sélectionner",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Il doit s'agir d'un document d'identité délivré par le gouvernement, avec une photo. Toutes les pièces d'identité doivent être en alphabet latin. Sinon, une traduction certifiée est requise.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom se réserve le droit de demander des documents supplémentaires (Due Diligence renforcée - EDD) ou toute information nécessaire pour effectuer une vérification complète, conformément à ses obligations légales.",
    "sessions": "Sessions",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Débloquez les retraits instantanés : configurez vos comptes de réception dès maintenant !",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Gérez facilement l'endroit où vous souhaitez recevoir les paiements en ajoutant les détails de vos comptes bancaires, wallets mobiles, wallets crypto, cartes de paiement, ou points de retrait en espèces. Suivez les étapes ci-dessous pour assurer un accès instantané à vos fonds à tout moment.",
    "Select the receiving channel below and setup the details.": "Sélectionnez le canal de réception ci-dessous et configurez les détails.",
    "Please Select A Payer": "Veuillez sélectionner un payeur",
    "Number": "Numéro",
    "Account Holder Name": "Nom du titulaire du compte",
    "Province": "Province",
    "postal": "Adresse Postale",
    "delete channel": "Supprimer le canal",
    "set as default": "Définir comme par défaut",
    "edit": "Éditer",
    "all channels are in english language in recieving account page": "Tous les canaux sont en anglais sur la page des comptes de réception",
    "select file type": "Sélectionner le type de fichier",
    "download data": "Télécharger les données",
    "INTERNATIONAL TRANSFER": "TRANSFERT INTERNATIONAL",
    "enter the amount": "Entrez le montant",
    "they recieve ": "Le bénéficiaire reçoit",
    "trouble sending money": "Problème lors de l'envoi d'argent ?",
    "select destination country": "Sélectionner le pays de destination",
    "bank transfer": "Transfert bancaire",
    "recieving method": "Méthode de réception",
    "payer": "Payeur",
    "The fee and the received amount may vary depending on the chosen Payment Method": "Les frais et le montant reçu peuvent varier en fonction de la méthode de paiement choisie.",
    "search ": "Rechercher",
    "show more": "Afficher plus",
    "select remark reasons": "Sélectionner les raisons de la remarque",
    "credit and debit card": "Cartes de crédit et de débit",
    "Transfer Fees": "Frais de transfert",
    "Estimated Time": "Temps estimé :",
    "within minutes": "En quelques minutes",
    "total ": "Total",
    "OTP verifications code": "Code de vérification OTP",
    "verify using sms/email": "Vérifier via SMS/Email",
    "sms/email verification code": "Code de vérification SMS/Email",
    "Verify Using Authenticator": "Vérifier via l'authentificateur",
    "WALLET TO WALLET TRANSFER": "TRANSFERT DE WALLET À WALLET",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Entrez l'ID du wallet, le nom d'utilisateur, l'email ou le numéro de téléphone du destinataire.",
    "search": "Rechercher",
    "lookup": "Rechercher",
    "Recipient Not on InstaPay?": "Destinataire non inscrit sur InstaPay ?",
    "invite them": "Invitez-le",
    "Please follow these examples": "Veuillez suivre ces exemples :",
    "Wallet ID: GY68J782": "ID de Wallet : GY68J782",
    "InstaPay/Instagram Username": "Nom d'utilisateur InstaPay/Instagram : instauser",
    "Email: user@email.com": "Email : user@email.com",
    "With Country code": "Numéro de téléphone (avec indicatif du pays)",
    "invite friend": "Inviter un ami",
    "invite via phone number": "Inviter via numéro de téléphone",
    "invite via email address": "Inviter via adresse email",
    "next": "Suivant",
    "back": "Retour",
    "Personalized Message": "Message personnalisé",
    "available currency": "Devise disponible",
    "attach files": "Joindre des fichiers",
    "total amount": "Montant total",
    "instant payment": "Paiement instantané",
    "starting from": "À partir de",
    "recurring cycle": "Cycle récurrent",
    "until I stop": "Jusqu'à annulation",
    "start from": "À partir de",
    "cycle": "Cycle",
    "total": "Total",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "Le bénéficiaire peut recevoir un montant légèrement ajusté en fonction des taux de change.",
    "schedule to": "Programmé pour",
    "schedule at": "Programmé à",
    "Scheduled Date": "Date programmée",
    "Scheduled time": "Heure programmée",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "Le bénéficiaire peut recevoir un montant légèrement ajusté en fonction des taux de change.",
    "QR CODE PAYMENT ": "PAIEMENT PAR CODE QR",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Sinon, utilisez le code QR alphanumérique InstaPay.",
    "PYAYMENT REQUEST": "DEMANDE DE PAIEMENT",
    "choose the beneficiary": "Choisissez le bénéficiaire",
    "trouble requesting money": "Problème pour demander de l'argent ?",
    "Standard Request": "Demande standard",
    "Subscription Request": "Demande récurrente",
    "Schedule Request": "Demande programmée",
    "Security Request": "Demande de dépôt de garantie",
    "Instalment Request": "Plan de versements",
    "Split Request": "Paiement fractionné",
    "You recieved": "Vous avez reçu",
    "is this your location?": "Est-ce votre emplacement ?",
    "yes": "Oui",
    "Authenticator Code": "Code Authentificateur",
    "Enter a code shown in the app to make sure everything works fine.": "Entrez le code affiché dans l'application pour vous assurer que tout est correct.",
    "SEND A QUOTE": "ENVOYER UN DEVIS",
    "Trouble Quoting Money?": "Problème pour envoyer un devis ?",
    "Please follow these examples:": "Veuillez suivre ces exemples :",
    "Full Name: Muhammad Imtiaz": "Nom Complet : Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "Nom d'utilisateur InstaPay/Instagram : instauser",
    "Phone Number: 44795396600 (With Country Code)": "Numéro de téléphone : 44795396600 (avec indicatif du pays)",
    "SEND TOP-UP AND E-SIM": "ENVOYER UNE RECHARGE ET E-SIM",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Restez connecté où que vous soyez ! Le service de recharge d'InstaPay vous permet de recharger instantanément des téléphones, d'acheter des eSIM, des bons d'achat de marque, des cartes-cadeaux, et de payer des factures de services publics dans plus de 150 pays. Idéal pour des cadeaux ou pour rester prêt, notre service privilégie la facilité, la rapidité et la sécurité.",
    "MOBILE AIRTIME AND E-SIM": "RECHARGE MOBILE ET E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Sélectionnez le pays pour recharger un mobile !",
    "Other Operator": "Autres Opérateurs",
    "airtime": "Recharge",
    "bundle": "Forfait",
    "data": "Données",
    "Enter custom amount": "Entrer un montant personnalisé",
    "Credit & Debit Card": "Carte de crédit et de débit",
    "Bank Transfer": "Transfert bancaire",
    "Mobile Wallet": "Wallet Mobile",
    "InstaPay Wallet": "Wallet InstaPay",
    "PayPal": "PayPal",
    "add card payment": "Ajouter une carte de paiement",
    "save details to use next time.": "Enregistrer les détails pour une utilisation future",
    "Recipient Mobile Number": "Numéro de téléphone du destinataire",
    "Amount": "Montant",
    "Service": "Service",
    "Destination": "Destination",
    "Provider": "Fournisseur",
    "Airtime": "Recharge",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Within minutes": "En quelques minutes",
    "top-up summary": "Résumé de la recharge",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "Le PKR n'est pas pris en charge par PayPal, l'USD sera utilisé par défaut.",
    "Amount in USD": "Montant en USD",
    "Send Top-up": "Envoyer la recharge",
    "available sunservices": "Services disponibles",
    "login with email": "Se connecter avec l'email",
    "Already Have an Account?": "Déjà un compte ? Connectez-vous",
    "profile picture": "Photo de profil",
    "add photo/logo": "Ajouter une photo/logo",
    "first name": "Prénom",
    "enter your first name": "Entrez votre prénom",
    "last name": "Nom",
    "enter your last name": "Entrez votre nom",
    "email(optional)": "Email (facultatif)",
    "enter your email address": "Entrez votre adresse email",
    "password must": "Mot de passe obligatoire",
    "It will be autofill, if you're signing up with invitation link": "Auto-remplissage lors de l'inscription par lien d'invitation",
    "change number": "Changer de numéro",
    "signup error": "Erreur d'inscription",
    "Something went wrong while sending sms!": "Une erreur est survenue lors de l'envoi du SMS ! Veuillez réessayer plus tard.",
    "Errors": "Des erreurs se sont produites. Veuillez vous référer à la liste suivante pour plus de détails.",
    "Generic error": "Erreur générique",
    "Trust Payments API requires the 'requests' library": "L'API Trust Payments nécessite la bibliothèque 'requests' pour fonctionner correctement.",
    "Send error": "Erreur lors de l'envoi. Veuillez réessayer.",
    "Receive error": "Erreur lors de la réception. Veuillez réessayer.",
    "Invalid credentials provided": "Identifiants invalides. Veuillez vérifier et réessayer.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Un problème est survenu lors de la connexion aux serveurs de Trust Payments. Veuillez vérifier votre connexion et réessayer.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Erreur inattendue lors de la connexion aux serveurs de Trust Payments. Si le problème persiste, contactez support@trustpayments.com.",
    "Unknown error. If this persists please contact Trust Payments": "Erreur inconnue. Veuillez contacter le support de Trust Payments si le problème continue.",
    "Incorrect usage of the Trust Payments API": "Utilisation incorrecte de l'API Trust Payments. Veuillez vérifier la demande et réessayer.",
    "Invalid card details": "Détails de la carte invalides. Veuillez vérifier et entrer les informations correctes.",
    "Invalid date": "Date invalide fournie.",
    "Invalid date/time": "Format de date/heure invalide. Veuillez fournir le bon format.",
    "Invalid details": "Détails invalides. Veuillez vérifier et entrer les informations correctes.",
    "Card number does not match card type": "Le numéro de carte ne correspond pas au type de carte spécifié.",
    "Malformed XML": "Erreur de formatage XML. Veuillez vérifier et corriger le XML.",
    "XML does not match schema": "Le XML ne correspond pas au schéma attendu.",
    "Invalid file format": "Format de fichier invalide. Veuillez utiliser un format pris en charge.",
    "Empty file contents": "Contenu du fichier vide. Veuillez fournir un contenu valide.",
    "Invalid file contents": "Contenu du fichier invalide. Veuillez vérifier le fichier et réessayer.",
    "Malformed JSON": "JSON mal formé. Veuillez corriger le format JSON.",
    "StApi Error": "Erreur StApi",
    "Invalid fields specified in request": "Champs invalides spécifiés dans la demande. Veuillez vérifier et les corriger.",
    "Missing parent": "Informations parente manquantes.",
    "Refund requires settled parent or parent that's due to settle today": "Le remboursement nécessite une transaction parente réglée ou une transaction due pour règlement aujourd'hui.",
    "Refund requires authorisation parent": "Le remboursement nécessite une transaction parente d'autorisation.",
    "Refund amount too great": "Le montant du remboursement dépasse la limite autorisée.",
    "No acquirer specified": "Aucune information sur l'acquéreur fournie.",
    "Repeat amount too great": "Le montant de répétition dépasse la limite autorisée.",
    "Split amount too great": "Le montant de division dépasse la limite autorisée.",
    "Cannot refund a declined transaction": "Impossible de rembourser une transaction refusée.",
    "Refund requires a settled parent": "Le remboursement nécessite une transaction parente réglée.",
    "Reversal requires a cancelled auth parent": "L'annulation nécessite une transaction d'autorisation annulée.",
    "Cannot override amount in child transaction": "Impossible de remplacer le montant dans une transaction enfant.",
    "Cannot override currency in child transaction": "Impossible de remplacer la devise dans une transaction enfant.",
    "Subscription requires RECUR account": "L'abonnement nécessite une configuration de compte récurrent.",
    "Subscription requires successful parent": "L'abonnement nécessite une transaction parente réussie.",
    "Risk Decisions must have AUTH as parent": "Les décisions de risque doivent être liées à une transaction d'autorisation.",
    "Chargebacks must have AUTH/REFUND as parent": "Les rétrofacturations doivent être liées à une transaction d'autorisation ou de remboursement.",
    "Refund amount less than Minimum allowed": "Le montant du remboursement est inférieur au minimum autorisé.",
    "Refund requires paypaltransactionid": "Le remboursement nécessite un ID de transaction PayPal.",
    "Invalid split transaction": "Transaction de division invalide.",
    "Cannot reverse AUTH processed more than 48 hours ago": "Impossible d'annuler une autorisation traitée il y a plus de 48 heures.",
    "Reversal requires acquirerreferencedata": "L'annulation nécessite des données de référence de l'acquéreur.",
    "Cannot reverse AUTH processed by a different acquirer": "Impossible d'annuler une autorisation traitée par un autre acquéreur.",
    "Payment type does not support repeats": "Le type de paiement ne prend pas en charge les transactions répétées.",
    "Reversal missing required data": "Données requises manquantes pour l'annulation.",
    "Missing token": "Informations sur le jeton manquantes.",
    "Subscription with an accountcheck parent not supported on current acquirer": "L'abonnement avec vérification de compte parent n'est pas pris en charge par l'acquéreur actuel.",
    "Subscription cannot be used as a parent": "L'abonnement ne peut pas être utilisé comme transaction parente.",
    "Invalid parent": "Type de transaction parente invalide.",
    "Payment type does not support refunds": "Le type de paiement ne prend pas en charge les remboursements.",
    "Invalid incremental transaction": "Transaction incrémentale invalide.",
    "Partial reversals not supported": "Les annulations partielles ne sont pas prises en charge.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Les transactions parente et enfant doivent avoir le même type de paiement pour les requêtes 3D Secure.",
    "Payment type does not support card scheme updates": "Le type de paiement ne prend pas en charge les mises à jour des schémas de carte.",
    "Cannot reverse AUTH at this time, please try again": "Impossible d'annuler l'autorisation pour le moment. Veuillez réessayer plus tard.",
    "Cannot determine token": "Impossible de déterminer le jeton. Veuillez vérifier et réessayer.",
    "Service Temporarily Disabled": "Service temporairement désactivé. Veuillez réessayer plus tard.",
    "Login first request": "Connexion requise avant de faire une demande.",
    "Invalid username/password": "Nom d'utilisateur ou mot de passe invalide. Veuillez réessayer.",
    "Invalid session": "Session invalide. Veuillez vous reconnecter.",
    "Session has expired": "Session expirée. Veuillez vous reconnecter.",
    "Password expired": "Mot de passe expiré. Veuillez réinitialiser votre mot de passe.",
    "Password has been previously used": "Mot de passe déjà utilisé précédemment. Veuillez en choisir un nouveau.",
    "MyST user account has been locked": "Le compte utilisateur MyST a été verrouillé. Veuillez contacter le support.",
    "New password does not match confirmed password": "Le nouveau mot de passe ne correspond pas à la confirmation.",
    "Incorrect current password": "Mot de passe actuel incorrect. Veuillez réessayer.",
    "Invalid selection": "Sélection invalide. Veuillez choisir une option valide.",
    "User already exists": "L'utilisateur existe déjà. Veuillez vous connecter ou réinitialiser le mot de passe si nécessaire.",
    "No transaction found": "Aucune transaction trouvée.",
    "Invalid selected transactions": "Transactions sélectionnées invalides.",
    "Data supplied has not been saved": "Les données n'ont pas été enregistrées. Veuillez réessayer.",
    "Invalid request type": "Type de demande invalide.",
    "Missing request type, at least one request type must be selected": "Type de demande manquant. Au moins un doit être sélectionné.",
    "Invalid payment type": "Type de paiement invalide.",
    "Missing payment type, at least one payment type must be selected": "Type de paiement manquant. Au moins un doit être sélectionné.",
    "Invalid error code": "Code d'erreur invalide.",
    "Missing error code, at least one error code must be selected": "Code d'erreur manquant. Au moins un doit être sélectionné.",
    "Invalid filter description": "Description de filtre invalide.",
    "Invalid destination description": "Description de destination invalide.",
    "Invalid notification type": "Type de notification invalide.",
    "Invalid destination": "Destination invalide.",
    "Invalid field selected": "Champ sélectionné invalide.",
    "Invalid email from address": "Adresse email de l'expéditeur invalide.",
    "Invalid email subject": "Sujet de l'email invalide.",
    "Invalid email type": "Type d'email invalide.",
    "Unable to process request": "Impossible de traiter la demande. Veuillez réessayer plus tard.",
    "No file selected for upload": "Aucun fichier sélectionné pour le téléchargement.",
    "Invalid file size": "Taille de fichier invalide. Veuillez fournir un fichier de taille acceptable.",
    "Invalid filename": "Nom de fichier invalide.",
    "Invalid extension": "Extension de fichier invalide.",
    "User requires at least one site reference": "L'utilisateur nécessite au moins une référence de site.",
    "Only ST-level users can have '*' access": "Seuls les utilisateurs de niveau ST peuvent avoir des droits d'accès '*'.",
    "Request failed": "Demande échouée. Veuillez réessayer plus tard.",
    "Invalid File Contents": "Contenu de fichier invalide.",
    "Maximum number of files uploaded": "Nombre maximum de fichiers téléchargés atteint. Veuillez en supprimer certains et réessayer.",
    "Insufficient gateway access privileges": "Privilèges d'accès à la passerelle insuffisants.",
    "Maximum file size limit reached": "Limite maximale de taille de fichier atteinte.",
    "Username(s) must be a valid user(s)": "Le(s) nom(s) d'utilisateur doivent être des utilisateurs valides.",
    "Site reference(s) must be a valid site(s)": "La(les) référence(s) de site doivent être des sites valides.",
    "Unable to send email, please verify the details and try again": "Impossible d'envoyer l'email. Veuillez vérifier les détails et réessayer.",
    "Negative already exists": "Une valeur négative existe déjà. Veuillez vérifier et corriger.",
    "Cannot delete a search owned by another user": "Impossible de supprimer une recherche appartenant à un autre utilisateur.",
    "Invalid search": "Recherche invalide. Veuillez réessayer avec des critères valides.",
    "Cannot delete the specified search, the search name cannot be found": "Impossible de supprimer la recherche spécifiée. Le nom de la recherche est introuvable.",
    "Search parameter is too short": "Le paramètre de recherche est trop court. Veuillez entrer des termes de recherche plus spécifiques.",
    "Duplicate custom fields defined": "Champs personnalisés en double définis. Veuillez résoudre les doublons.",
    "Cannot allocate selected users, insufficient privileges": "Impossible d'allouer les utilisateurs sélectionnés. Privilèges insuffisants.",
    "Allocated users have access to additional sites": "Les utilisateurs alloués ont accès à des sites supplémentaires.",
    "Allocated users have access to additional users": "Les utilisateurs alloués ont accès à des utilisateurs supplémentaires.",
    "User with current role cannot be allocated users": "Les utilisateurs avec le rôle actuel ne peuvent pas allouer d'autres utilisateurs.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Ce site nécessite que votre navigateur accepte les cookies pour se connecter. Les cookies peuvent être acceptés en cliquant sur 'J'accepte' ci-dessous.",
    "User requires at least one site reference or site group": "L'utilisateur nécessite au moins une référence de site ou de groupe de sites.",
    "Allocated users have access to additional site groups": "Les utilisateurs alloués ont accès à des groupes de sites supplémentaires.",
    "No statement found": "Aucun relevé trouvé.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "Les données fournies n'ont pas été mises à jour dans le service tiers MobilePay.",
    "Bypass": "Contourner",
    "Insufficient access privileges": "Privilèges d'accès insuffisants.",
    "Coding error": "Erreur de codage détectée.",
    "Insufficient privileges": "Privilèges insuffisants pour compléter cette action.",
    "Invalid request": "Demande invalide. Veuillez vérifier et réessayer.",
    "Invalid field": "Champ saisi invalide. Veuillez vérifier et corriger l'entrée.",
    "Unknown site": "Site inconnu.",
    "Banned card": "Carte interdite.",
    "XML element parse error": "Erreur d'analyse de l'élément XML. Veuillez vérifier la structure XML.",
    "Maestro must use SecureCode": "Les transactions Maestro doivent utiliser SecureCode pour la validation.",
    "Multiple email addresses must be separated with , or ;": "Les adresses email multiples doivent être séparées par des virgules (,) ou des points-virgules (;).",
    "Invalid site reference for alias": "Référence de site invalide pour l'alias.",
    "Invalid version number": "Numéro de version invalide. Veuillez entrer un numéro de version valide.",
    "Unknown user": "Utilisateur inconnu. Veuillez vérifier et réessayer.",
    "Cannot determine account": "Impossible de déterminer le compte.",
    "JSON element parse error": "Erreur d'analyse de l'élément JSON. Veuillez vérifier la structure JSON.",
    "Wallet type configuration error": "Erreur de configuration du type de wallet.",
    "Wallet type not supported on this request": "Le type de wallet n'est pas pris en charge pour cette demande.",
    "The card number you have provided is incorrect, please verify your details and try again": "Le numéro de carte fourni est incorrect. Veuillez vérifier vos détails et réessayer.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "Le code de sécurité (CVV2) que vous avez fourni est incorrect. Veuillez vérifier et réessayer.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "La date d'expiration fournie est incorrecte. Veuillez vérifier vos détails et réessayer.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "Le mois d'expiration fourni est incorrect. Veuillez vérifier et réessayer.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "L'année d'expiration fournie est incorrecte. Veuillez vérifier et réessayer.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Impossible de traiter votre paiement en raison d'erreurs de connexion. ID de demande non concordant. Veuillez réessayer.",
    "The issue number you have provided is incorrect, please verify your details and try again": "L'identifiant de la requête fourni est incorrect",
    "The payment type you have provided is incorrect, please verify your details and try again": "Le type de paiement fourni est incorrect. Veuillez vérifier et réessayer.",
    "Unable to process your payment, please contact the website": "Impossible de traiter votre paiement. Veuillez contacter le site pour obtenir de l'aide.",
    "There are errors with these fields: {0}": "Il y a des erreurs avec ces champs : {0}",
    "No account found": "Aucun compte trouvé.",
    "Refund cannot be processed": "Le remboursement ne peut pas être traité.",
    "Transaction de-activated": "Transaction désactivée.",
    "Socket receive error": "Erreur de réception du socket.",
    "Socket connection error": "Erreur de connexion du socket.",
    "Socket closed": "Socket fermé.",
    "Invalid data received": "Données reçues invalides.",
    "Invalid SQL": "Requête SQL invalide.",
    "Timeout": "Délai d'attente dépassé.",
    "Invalid acquirer": "Détails de l'acquéreur invalides.",
    "Unable to connect to acquirer": "Impossible de se connecter à l'acquéreur.",
    "Invalid response from acquirer": "Réponse invalide de l'acquéreur.",
    "No available transport": "Aucun transport disponible.",
    "File size too large": "Taille du fichier trop grande.",
    "Socket send error": "Erreur d'envoi du socket.",
    "Communication error": "Erreur de communication.",
    "Proxy error": "Erreur de proxy.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Impossible de traiter votre paiement en raison d'erreurs de connexion. Veuillez vérifier vos détails et réessayer ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Impossible de traiter votre paiement en raison d'erreurs de connexion (statut de la réponse HTTP {0}). Veuillez vérifier vos détails et réessayer ({1}).",
    "Wrong number of emails": "Nombre d'adresses email incorrect.",
    "Bank System Error": "Erreur du système bancaire.",
    "Wrong number of transactions": "Nombre de transactions incorrect.",
    "Invalid transport configuration": "Configuration de transport invalide.",
    "No valid updates specified": "Aucune mise à jour spécifiée valide.",
    "Transaction reference not found": "Référence de transaction introuvable.",
    "Settlebaseamount too large": "Montant de règlement trop élevé.",
    "Transaction not updatable": "Transaction non mise à jour.",
    "No searchable filter specified": "Aucun filtre de recherche spécifié.",
    "Timeout Error": "Erreur de délai d'attente. Veuillez réessayer.",
    "3-D Secure Transport Error": "Erreur de transport 3-D Secure.",
    "Unauthenticated": "Demande non authentifiée.",
    "Site Suspended": "Site suspendu.",
    "No updates performed": "Aucune mise à jour effectuée.",
    "Invalid Request": "Demande invalide.",
    "Invalid Response": "Réponse invalide.",
    "Invalid Acquirer": "Informations sur l'acquéreur invalides.",
    "Invalid account data": "Données de compte invalides.",
    "Missing": "Informations manquantes.",
    "Payment Error": "Erreur de paiement.",
    "Invalid acquirer for 3-D Secure": "Acquéreur invalide pour 3-D Secure.",
    "Invalid payment type for 3-D Secure": "Type de paiement invalide pour 3-D Secure.",
    "Invalid updates specified": "Mises à jour spécifiées invalides.",
    "Manual investigation required": "Enquête manuelle requise.",
    "Invalid headers": "En-têtes invalides.",
    "Max fraudscore exceeded": "Score de fraude maximal dépassé.",
    "Invalid filters": "Filtres invalides fournis.",
    "Merchant System Error": "Erreur du système marchand.",
    "Your payment is being processed. Please wait...": "Votre paiement est en cours de traitement. Veuillez patienter...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Impossible de spécifier à la fois 'description du type de demande' et 'descriptions du type de demande' dans une seule demande.",
    "Acquirer missing original transaction data": "L'acquéreur manque des données de transaction d'origine.",
    "Insufficient funds": "Fonds insuffisants.",
    "Unable to process due to scheme restrictions": "Impossible de traiter en raison des restrictions du système.",
    "Failed Screening": "Échec du filtrage.",
    "Unable to process due to restrictions": "Impossible de traiter en raison de restrictions.",
    "Invalid process": "Processus invalide.",
    "Risk Referral": "Référence de risque requise.",
    "Name Pick required": "Le nom est requis pour la sélection.",
    "Address Pick required": "L'adresse est requise pour la sélection.",
    "IP not in range": "Adresse IP hors plage.",
    "Invalid button configuration": "Configuration de bouton invalide.",
    "Unrecognised response from acquirer": "Réponse non reconnue de l'acquéreur.",
    "Decline": "Refus.",
    "Uncertain result": "Résultat incertain.",
    "Soft Decline": "Refus partiel.",
    "Refer to Issuer": "Référez-vous à l'émetteur pour plus d'informations.",
    "Request is queued please check the transaction later for the status": "La demande est en file d'attente. Veuillez vérifier le statut de la transaction plus tard.",
    "Generic Retry": "Nouvelle tentative générique.",
    "Soft Decline retry": "Nouvelle tentative après refus partiel.",
    "There has been a problem with your payment, please verify your details and try again": "Il y a eu un problème avec votre paiement. Veuillez vérifier vos détails et réessayer.",
    "Unknown error": "Erreur inconnue.",

    "Login": "Connexion",
    "Enter Your Email": "Entrez votre email",
    "Login With Phone Number": "Connexion avec numéro de téléphone",
    "Verify Using Sms/Email": "Vérifier par Sms/Email",
    "Resend Code In 100 Seconds": "Renvoyer le code en 100 secondes",
    "Verify": "Vérifier",
    "Verifying": "Vérification en cours",
    "Last Week": "La semaine dernière",
    "Older Transaction": "Transaction ancienne",
    "Total": "Total",
    "Conversion Confirmed": "Conversion confirmée",
    "User Account Limit": "Limite de compte utilisateur",
    "Monthly Balance Limit": "Limite de solde mensuel",
    "Total Limit": "Limite totale",
    "Used": "Utilisé",
    "Remaining": "Restant",
    "Allowed Minimum Topup Amount": "Montant minimum de recharge autorisé",
    "Allowed Maximum Topup Amount": "Montant maximum de recharge autorisé",
    "Incoming Limit": "Limite entrante",
    "Daily limit": "Limite quotidienne",
    "Monthly limit": "Limite mensuelle",
    "Yearly limit": "Limite annuelle",
    "Sending Limit": "Limite d'envoi",
    "Allowed Number Of Transactions": "Nombre autorisé de transactions",
    "Total Amount": "Montant Total",
    "Amount To Send": "Montant à envoyer",
    "Destination Amount": "Montant de destination",
    "Note": "Remarque",
    "Revised Amount": "Montant révisé",
    "Save": "Sauvegarder",
    "Date": "Date",
    "Action": "Action",
    "Payment Scheduled At": "Paiement programmé à",
    "Transaction Fee": "Frais de transaction",
    "Number Of Cycle": "Nombre de cycles",
    "Subscription Starting Date": "Date de début d'abonnement",
    "Until": "Jusqu'à",
    "Next Transaction": "Prochaine transaction",
    "Pay Now": "Payer maintenant",
    "Transaction Method": "Méthode de transaction",
    "Mobile Money Provider": "Fournisseur d'argent mobile",
    "Wallet Name": "Nom du portefeuille",
    "Wallet Number": "Numéro du portefeuille",
    "Crypto Currency": "Crypto-monnaie",
    "Wallet Address": "Adresse du portefeuille",
    "Select Document Type": "Sélectionner le type de document",
    "Edit Document Number": "Modifier le numéro de document",
    "My Withdrawals": "Mes retraits",
    "Bargainable": "Négociable",
    "Description": "Description",
    "Attached Files": "Fichiers joints",
    "Sent": "Envoyé",
    "Accepted": "Accepté",
    "Decline": "Refuser",
    "Pending": "En attente",
    "Overall Rating": "Note globale",
    "No Review": "Pas d'avis",
    "0 Rating": "0 Évaluation",
    "Transaction Map": "Carte de transaction",
    "Send Money": "Envoyer de l'argent",
    "Sending Method": "Méthode d'envoi",
    "Trouble Sending Money": "Problème d'envoi d'argent",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Les frais et le montant reçu peuvent varier en fonction de la méthode de paiement choisie.",
    "Select Method": "Sélectionner une méthode",
    "Completed": "Terminé",
    "How Was Your Experience?": "Comment s'est passée votre expérience ?",
    "Leave A Review": "Laisser un avis",
    "Review History": "Historique des avis",
    "Write A Review": "Écrire un avis",
    "Send And Post": "Envoyer et Publier",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Conversion de Crypto en Fiat est une solution robuste et sécurisée qui convertit sans problème les cryptomonnaies en monnaies fiduciaires. Elle répond aux besoins divers des utilisateurs, offrant une multitude de canaux de paiement adaptés aux préférences individuelles et aux emplacements.\"",
    "Review Created": "Avis créé",
    "Transactions": "Transactions",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Glisser/Déposer ou télécharger un fichier (Photo/Vidéo/Pdf)",
    "Max Size 1GB": "Taille maximale 1 Go",
    "Title Description": "Description du titre",
    "Add New": "Ajouter un nouveau",
    "Discard": "Jeter",
    "Save And Post": "Enregistrer et publier",
    "Personal Account": "Compte personnel",
    "Conversion": "Conversion",
    "Spent In Total": "Dépensé au total",
    "International": "International",
    "Recharge": "Recharger",
    "Top Up Wallet To Wallet": "Ajouter des fonds de portefeuille à portefeuille",
    "PayPal": "PayPal",
    "Instapay Wallet": "Portefeuille Instapay",
    "Not Connected": "Non connecté",
    "More": "Plus",
    "Less": "Moins",
    "Profile Reports": "Rapports de profil",
    "Timezone": "Fuseau horaire",
    "Cancel": "Annuler",
    "Full Name": "Nom complet",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Documents supplémentaires",
    "Profile Complete": "Profil complet",
    "Verified": "Vérifié",
    "Bank Account": "Compte bancaire",
    "Mobile Wallet": "Portefeuille mobile",
    "Cash Pickup": "Retrait d'argent",
    "Add": "Ajouter",
    "Configured": "Configuré",
    "Email": "E-mail",
    "Download Data": "Télécharger les données",
    "Enter The Amount": "Entrez le Montant",
    "They Receive": "Ils Reçoivent",
    "Where Do You Want To Send Money": "Où voulez-vous envoyer de l'argent",
    "Bank Transfer": "Virement bancaire",
    "Receiving Method": "Méthode de réception",
    "Payer": "Payeur",
    "Minimum Amount": "Montant minimum",
    "Maximum Amount": "Montant Maximum",
    "Search": "Rechercher",
    "Show More": "Montrer Plus",
    "Select Remark / Reasons (All)": "Sélectionner Remarque / Raisons (Tout)",
    "Attach Files": "Joindre des fichiers",
    "Holiday List": "Liste des Vacances",
    "No Data To Show": "Aucune donnée à afficher",
    "Threshold Limits": "Limites de Seuil",
    "Amount Limits": "Limites de Montant",
    "OTP Verification Code": "Code de Vérification OTP",
    "Verify Using Authenticator": "Vérifier avec Authenticator",
    "Sms/Email Verification Code": "Code de Vérification Sms/Email",
    "Invite Them": "Les Inviter",
    "Lookup": "Recherche",
    "Invite Friend": "Inviter un Ami",
    "Invite Via Phone Number": "Inviter via Numéro de Téléphone",
    "Invite Via Email Address": "Inviter via Adresse Email",
    "Next": "Suivant",
    "Back": "Retour",
    "Invite Via Email": "Inviter par Email",
    "Available Currency": "Monnaie Disponible",
    "Add Remarks And Comments": "Ajouter des Remarques et Commentaires",
    "Recipient Details": "Détails du Destinataire",
    "Go Back": "Retourner",
    "Trouble Requesting Money": "Problème pour Demander de l'Argent",
    "Instant": "Instantané",
    "Subscription": "Abonnement",
    "Schedule": "Planifier",
    "Choose Request Type": "Choisir le Type de Demande",
    "Mobile Airtime": "Temps d'Appel Mobile",
    "Incorrect Phone Number": "Numéro de Téléphone Incorrect",
    "Make Sure You Have Entered A Correct Phone Number.": "Assurez-vous d'avoir Entré un Numéro de Téléphone Correct.",
    "Close": "Fermer",
    "Operator": "Opérateur",
    "Recipient Mobile Number": "Numéro de Téléphone du Destinataire",
    "Amount": "Montant",
    "Service": "Service",
    "Destination": "Destination",
    "Top Up Summary": "Résumé de Rechargement",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Limite d'Envoi Quotidienne Dépassée",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Envoyez un devis détaillé à votre client ou client. Présentez vos services, produits ou offres de manière professionnelle avec un devis personnalisé.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Les paiements effectués pendant les jours de semaine avant 15h00 seront crédités sur le compte bancaire du destinataire avant minuit le même jour.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Configurez vos comptes de réception dans jusqu’à trois pays et retirez instantanément des fonds des demandes de paiement approuvées vers vos canaux de paiement activés.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Le Royaume de Kemit se réserve le droit de demander tout document supplémentaire (Diligence Raisonnée Renforcée - DDD) ou toute autre information nécessaire pour effectuer des vérifications approfondies, conformément à ses obligations légales.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Il doit s'agir d'un document d'identification émis par le gouvernement avec une photo. Tous les documents d'identification doivent être rédigés en alphabet latin. Si ce n'est pas le cas, une traduction certifiée est requise.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Mettez en valeur votre travail, amplifiez votre portée. 'Mon Portfolio' est votre toile numérique sur Instapay. Téléchargez et affichez une large gamme de contenu, des images et vidéos captivantes aux PDF informatifs. Que vous soyez artiste, freelance ou entrepreneur, cette fonctionnalité vous permet de présenter votre travail ou vos projets de manière visuellement attrayante.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Partagez votre code QR ou lien de paiement pour être payé instantanément sur votre Insta Wallet.",
    "Afghanistan": "Afghanistan",
    "Aland Islands": "Åland",
    "Albania": "Albanie",
    "Algeria": "Algérie",
    "American Samoa": "Samoa américaines",
    "Andorra": "Andorre",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua-et-Barbuda",
    "Argentina": "Argentine",
    "Armenia": "Arménie",
    "Aruba": "Aruba",
    "Australia": "Australie",
    "Austria": "Autriche",
    "Azerbaijan": "Azerbaïdjan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahreïn",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbade",
    "Belarus": "Bélarus",
    "Belgium": "Belgique",
    "Belize": "Belize",
    "Benin": "Bénin",
    "Bermuda": "Bermudes",
    "Bhutan": "Bhoutan",
    "Bolivia (Plurinational State of)": "Bolivie",
    "Bonaire - Sint Eustatius and Saba": "Pays-Bas caribéens",
    "Bosnia and Herzegovina": "Bosnie-Herzégovine",
    "Botswana": "Botswana",
    "Brazil": "Brésil",
    "British Indian Ocean Territory": "Territoire britannique de l'océan Indien",
    "Brunei Darussalam": "Brunéi Darussalam",
    "Bulgaria": "Bulgarie",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Cabo Verde",
    "Cambodia": "Cambodge",
    "Cameroon": "Cameroun",
    "Canada": "Canada",
    "Cayman Islands": "Îles Caïmans",
    "Central African Republic": "République centrafricaine",
    "Chad": "Tchad",
    "Chile": "Chili",
    "China": "Chine",
    "Christmas Island": "Île Christmas",
    "Cocos (Keeling) Islands": "Îles Cocos (Keeling)",
    "Colombia": "Colombie",
    "Comoros": "Comores",
    "Congo": "Congo",
    "Congo - Democratic Republic of the": "République démocratique du Congo",
    "Cook Islands": "Îles Cook",
    "Costa Rica": "Costa Rica",
    "Cote d'Ivoire": "Côte d'Ivoire",
    "Croatia": "Croatie",
    "Cuba": "Cuba",
    "Curacao": "Curaçao",
    "Cyprus": "Chypre",
    "Czech Republic": "République tchèque",
    "Denmark": "Danemark",
    "Djibouti": "Djibouti",
    "Dominica": "Dominique",
    "Dominican Republic": "République dominicaine",
    "Ecuador": "Équateur",
    "Egypt": "Égypte",
    "El Salvador": "Salvador",
    "Equatorial Guinea": "Guinée équatoriale",
    "Eritrea": "Érythrée",
    "Estonia": "Estonie",
    "Eswatini": "Eswatini",
    "Ethiopia": "Éthiopie",
    "Falkland Islands (Malvinas)": "Îles Malouines",
    "Faroe Islands": "Îles Féroé",
    "Fiji": "Fidji",
    "Finland": "Finlande",
    "France": "France",
    "French Polynesia": "Polynésie française",
    "French Southern Territories": "Terres australes et antarctiques françaises",
    "Gabon": "Gabon",
    "Gambia": "Gambie",
    "Georgia": "Géorgie",
    "Germany": "Allemagne",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grèce",
    "Greenland": "Groenland",
    "Grenada": "Grenade",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernesey",
    "Guinea (Conakry)": "Guinée",
    "Guinea-Bissau": "Guinée-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haïti",
    "Heard Island and McDonald Islands": "Îles Heard-et-MacDonald",
    "Holy See": "Saint-Siège",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hongrie",
    "Iceland": "Islande",
    "India": "Inde",
    "Indonesia": "Indonésie",
    "Iran (Islamic Republic of)": "Iran",
    "Iraq": "Irak",
    "Ireland": "Irlande",
    "Isle of Man": "Île de Man",
    "Israel": "Israël",
    "Italy": "Italie",
    "Jamaica": "Jamaïque",
    "Japan": "Japon",
    "Jersey": "Jersey",
    "Jordan": "Jordanie",
    "Kazakhstan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Corée du Nord",
    "Korea - Republic of": "Corée du Sud",
    "Kuwait": "Koweït",
    "Kyrgyzstan": "Kirghizistan",
    "Lao People's Democratic Republic": "Laos",
    "Latvia": "Lettonie",
    "Lebanon": "Liban",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libye",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lituanie",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Malaisie",
    "Maldives": "Maldives",
    "Mali": "Mali",
    "Malta": "Malte",
    "Marshall Islands": "Îles Marshall",
    "Mauritania": "Mauritanie",
    "Mauritius": "Maurice",
    "Mexico": "Mexique",
    "Micronesia (Federated States of)": "Micronésie (États fédérés de)",
    "Moldova - Republic of": "Moldavie",
    "Monaco": "Monaco",
    "Mongolia": "Mongolie",
    "Montenegro": "Monténégro",
    "Montserrat": "Montserrat",
    "Morocco": "Maroc",
    "Mozambique": "Mozambique",
    "Myanmar": "Birmanie",
    "Namibia": "Namibie",
    "Nauru": "Nauru",
    "Nepal": "Népal",
    "Netherlands": "Pays-Bas",
    "New Caledonia": "Nouvelle-Calédonie",
    "New Zealand": "Nouvelle-Zélande",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigéria",
    "Niue": "Niue",
    "Norfolk Island": "Île Norfolk",
    "North Macedonia": "Macédoine du Nord",
    "Northern Mariana Islands": "Îles Mariannes du Nord",
    "Norway": "Norvège",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palaos",
    "Palestine - State of": "Palestine",
    "Panama": "Panama",
    "Papua New Guinea": "Papouasie-Nouvelle-Guinée",
    "Paraguay": "Paraguay",
    "Peru": "Pérou",
    "Philippines": "Philippines",
    "Pitcairn": "Îles Pitcairn",
    "Poland": "Pologne",
    "Portugal": "Portugal",
    "Puerto Rico": "Porto Rico",
    "Qatar": "Qatar",
    "Romania": "Roumanie",
    "Russian Federation": "Russie",
    "Rwanda": "Rwanda",
    "Saint Barth\u00e9lemy": "Saint-Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Sainte-Hélène, Ascension et Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint-Kitts-et-Nevis",
    "Saint Lucia": "Sainte-Lucie",
    "Saint Martin (French part)": "Saint-Martin (partie française)",
    "Saint Vincent and the Grenadines": "Saint-Vincent-et-les-Grenadines",
    "Samoa": "Samoa",
    "San Marino": "Saint-Marin",
    "Sao Tome and Principe": "São Tomé-et-Príncipe",
    "Senegal": "Sénégal",
    "Serbia": "Serbie",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapour",
    "Sint Maarten (Dutch part)": "Sint Maarten (partie néerlandaise)",
    "Slovakia": "Slovaquie",
    "Slovenia": "Slovénie",
    "Solomon Islands": "Îles Salomon",
    "Somalia": "Somalie",
    "South Africa": "Afrique du Sud",
    "South Georgia and the South Sandwich Islands": "Géorgie du Sud et îles Sandwich du Sud",
    "South Sudan": "Soudan du Sud",
    "Spain": "Espagne",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Soudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
    "Sweden": "Suède",
    "Switzerland": "Suisse",
    "Syrian Arab Republic": "République arabe syrienne",
    "Tajikistan": "Tadjikistan",
    "United Republic of Tanzania": "République-Unie de Tanzanie",
    "Thailand": "Thaïlande",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinité-et-Tobago",
    "Tunisia": "Tunisie",
    "Turkey": "Turquie",
    "Turkmenistan": "Turkménistan",
    "Turks and Caicos Islands": "Îles Turques-et-Caïques",
    "Tuvalu": "Tuvalu",
    "Uganda": "Ouganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "Émirats arabes unis",
    "United Kingdom of Great Britain and Northern Ireland": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    "United States of America": "États-Unis d'Amérique",
    "United States Minor Outlying Islands": "Îles mineures éloignées des États-Unis",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Ouzbékistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (République bolivarienne du)",
    "Viet Nam": "Viêt Nam",
    "Virgin Islands (British)": "Îles Vierges britanniques",
    "Virgin Islands (U.S.)": "Îles Vierges des États-Unis",
    "Wallis and Futuna": "Wallis-et-Futuna",
    "Yemen": "Yémen",
    "Zambia": "Zambie",
    "Zimbabwe": "Zimbabwe",
    "Taiwan": "Taïwan",
    "Palestine": "Palestine",
    "Western Sahara": "Sahara occidental",
    "Kosovo": "Kosovo",
    "South Ossetia": "Ossétie du Sud",
    "Abkhazia": "Abkhazie",
    "Nagorno-Karabakh (Artsakh)": "Haut-Karabagh (Artsakh)",
    "Transnistria": "Transnistrie",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Chypre du Nord",
    "Saudi Arabia": "Arabie saoudite",
    "Yugoslavia": "Yougoslavie",

    "Login": "Connexion",
    "Enter Your Email": "Entrez votre email",
    "Login With Phone Number": "Connexion avec numéro de téléphone",
    "Verify Using Sms/Email": "Vérifier par Sms/Email",
    "Resend Code In 100 Seconds": "Renvoyer le code en 100 secondes",
    "Verify": "Vérifier",
    "Verifying": "Vérification en cours",
    "Last Week": "La semaine dernière",
    "Older Transaction": "Transaction ancienne",
    "Total": "Total",
    "Conversion Confirmed": "Conversion confirmée",
    "User Account Limit": "Limite de compte utilisateur",
    "Monthly Balance Limit": "Limite de solde mensuel",
    "Total Limit": "Limite totale",
    "Used": "Utilisé",
    "Remaining": "Restant",
    "Allowed Minimum Topup Amount": "Montant minimum de recharge autorisé",
    "Allowed Maximum Topup Amount": "Montant maximum de recharge autorisé",
    "Incoming Limit": "Limite entrante",
    "Daily Limit": "Limite quotidienne",
    "Monthly Limit": "Limite mensuelle",
    "Yearly Limit": "Limite annuelle",
    "Sending Limit": "Limite d'envoi",
    "Allowed Number Of Transactions": "Nombre autorisé de transactions",
    "Total Amount": "Montant Total",
    "Amount To Send": "Montant à envoyer",
    "Destination Amount": "Montant de destination",
    "Note": "Remarque",
    "Revised Amount": "Montant révisé",
    "Save": "Sauvegarder",
    "Date": "Date",
    "Action": "Action",
    "Payment Scheduled At": "Paiement programmé à",
    "Transaction Fee": "Frais de transaction",
    "Number Of Cycle": "Nombre de cycles",
    "Subscription Starting Date": "Date de début d'abonnement",
    "Until": "Jusqu'à",
    "Next Transaction": "Prochaine transaction",
    "Pay Now": "Payer maintenant",
    "Transaction Method": "Méthode de transaction",
    "Mobile Money Provider": "Fournisseur d'argent mobile",
    "Wallet Name": "Nom du portefeuille",
    "Wallet Number": "Numéro du portefeuille",
    "Crypto Currency": "Crypto-monnaie",
    "Wallet Address": "Adresse du portefeuille",
    "Select Document Type": "Sélectionner le type de document",
    "Edit Document Number": "Modifier le numéro de document",
    "My Withdrawals": "Mes retraits",
    "Bargainable": "Négociable",
    "Description": "Description",
    "Attached Files": "Fichiers joints",
    "Sent": "Envoyé",
    "Accepted": "Accepté",
    "Decline": "Refuser",
    "Pending": "En attente",
    "Overall Rating": "Note globale",
    "No Review": "Pas d'avis",
    "0 Rating": "0 Évaluation",
    "Transaction Map": "Carte de transaction",
    "Send Money": "Envoyer de l'argent",
    "Sending Method": "Méthode d'envoi",
    "Trouble Sending Money": "Problème d'envoi d'argent",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Les frais et le montant reçu peuvent varier en fonction de la méthode de paiement choisie.",
    "Select Method": "Sélectionner une méthode",
    "Completed": "Terminé",
    "How Was Your Experience?": "Comment s'est passée votre expérience ?",
    "Leave A Review": "Laisser un avis",
    "Review History": "Historique des avis",
    "Write A Review": "Écrire un avis",
    "Send And Post": "Envoyer et Publier",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Conversion de Crypto en Fiat est une solution robuste et sécurisée qui convertit sans problème les cryptomonnaies en monnaies fiduciaires. Elle répond aux besoins divers des utilisateurs, offrant une multitude de canaux de paiement adaptés aux préférences individuelles et aux emplacements.\"",
    "Review Created": "Avis créé",
    "Transactions": "Transactions",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Glisser/Déposer ou télécharger un fichier (Photo/Vidéo/Pdf)",
    "Max Size 1GB": "Taille maximale 1 Go",
    "Title Description": "Description du titre",
    "Add New": "Ajouter un nouveau",
    "Discard": "Jeter",
    "Save And Post": "Enregistrer et publier",
    "Personal Account": "Compte personnel",
    "Conversion": "Conversion",
    "Spent In Total": "Dépensé au total",
    "International": "International",
    "Recharge": "Recharger",
    "Top Up Wallet To Wallet": "Ajouter des fonds de portefeuille à portefeuille",
    "PayPal": "PayPal",
    "Instapay Wallet": "Portefeuille Instapay",
    "Not Connected": "Non connecté",
    "More": "Plus",
    "Less": "Moins",
    "Profile Reports": "Rapports de profil",
    "Timezone": "Fuseau horaire",
    "Cancel": "Annuler",
    "Full Name": "Nom complet",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Documents supplémentaires",
    "Profile Complete": "Profil complet",
    "Verified": "Vérifié",
    "Bank Account": "Compte bancaire",
    "Mobile Wallet": "Portefeuille mobile",
    "Cash Pickup": "Retrait d'argent",
    "Add": "Ajouter",
    "Configured": "Configuré",
    "Email": "E-mail",
    "Download Data": "Télécharger les données",
    "Enter The Amount": "Entrez le Montant",
    "They Receive": "Ils Reçoivent",
    "Where Do You Want To Send Money": "Où voulez-vous envoyer de l'argent",
    "Bank Transfer": "Virement bancaire",
    "Receiving Method": "Méthode de réception",
    "Payer": "Payeur",
    "Minimum Amount": "Montant minimum",
    "Maximum Amount": "Montant Maximum",
    "Search": "Rechercher",
    "Show More": "Montrer Plus",
    "Select Remark / Reasons (All)": "Sélectionner Remarque / Raisons (Tout)",
    "Attach Files": "Joindre des fichiers",
    "Holiday List": "Liste des Vacances",
    "No Data To Show": "Aucune donnée à afficher",
    "Threshold Limits": "Limites de Seuil",
    "Amount Limits": "Limites de Montant",
    "OTP Verification Code": "Code de Vérification OTP",
    "Verify Using Authenticator": "Vérifier avec Authenticator",
    "Sms/Email Verification Code": "Code de Vérification Sms/Email",
    "Invite Them": "Les Inviter",
    "Lookup": "Recherche",
    "Invite Friend": "Inviter un Ami",
    "Invite Via Phone Number": "Inviter via Numéro de Téléphone",
    "Invite Via Email Address": "Inviter via Adresse Email",
    "Next": "Suivant",
    "Back": "Retour",
    "Invite Via Email": "Inviter par Email",
    "Available Currency": "Monnaie Disponible",
    "Add Remarks And Comments": "Ajouter des Remarques et Commentaires",
    "Recipient Details": "Détails du Destinataire",
    "Go Back": "Retourner",
    "Trouble Requesting Money": "Problème pour Demander de l'Argent",
    "Instant": "Instantané",
    "Subscription": "Abonnement",
    "Schedule": "Planifier",
    "Choose Request Type": "Choisir le Type de Demande",
    "Mobile Airtime": "Temps d'Appel Mobile",
    "Incorrect Phone Number": "Numéro de Téléphone Incorrect",
    "Make Sure You Have Entered A Correct Phone Number.": "Assurez-vous d'avoir Entré un Numéro de Téléphone Correct.",
    "Close": "Fermer",
    "Operator": "Opérateur",
    "Recipient Mobile Number": "Numéro de Téléphone du Destinataire",
    "Amount": "Montant",
    "Service": "Service",
    "Destination": "Destination",
    "Top Up Summary": "Résumé de Rechargement",
    "Daily Sending Limit Exceeded": "Limite d'Envoi Quotidienne Dépassée",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Envoyez un devis détaillé à votre client ou client. Présentez vos services, produits ou offres de manière professionnelle avec un devis personnalisé.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Les paiements effectués pendant les jours de semaine avant 15h00 seront crédités sur le compte bancaire du destinataire avant minuit le même jour.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Configurez vos comptes de réception dans jusqu’à trois pays et retirez instantanément des fonds des demandes de paiement approuvées vers vos canaux de paiement activés.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Le Royaume de Kemit se réserve le droit de demander tout document supplémentaire (Diligence Raisonnée Renforcée - DDD) ou toute autre information nécessaire pour effectuer des vérifications approfondies, conformément à ses obligations légales.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Il doit s'agir d'un document d'identification émis par le gouvernement avec une photo. Tous les documents d'identification doivent être rédigés en alphabet latin. Si ce n'est pas le cas, une traduction certifiée est requise.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Mettez en valeur votre travail, amplifiez votre portée. 'Mon Portfolio' est votre toile numérique sur Instapay. Téléchargez et affichez une large gamme de contenu, des images et vidéos captivantes aux PDF informatifs. Que vous soyez artiste, freelance ou entrepreneur, cette fonctionnalité vous permet de présenter votre travail ou vos projets de manière visuellement attrayante.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Partagez votre code QR ou lien de paiement pour être payé instantanément sur votre Insta Wallet.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Vous avez 15 minutes pour terminer la transaction.",
    "Time Remaining": "Veuillez terminer dans le délai imparti pour éviter l'expiration de l'URL.",
    "Please Process The Payment Via Your Selected Payment Type": "Temps restant",
    "Payment Type": "Veuillez traiter le paiement via votre type de paiement sélectionné.",
    "Top-Up Wallet": "Type de paiement",
    "Confirm Your Payment": "RECHARGER LE PORTEFEUILLE",
    "You Will Get": "Confirmez votre paiement",
    "Amount To Pay": "Vous recevrez",
    "Proceed To Payment": "Montant à payer",
    "Load/Deposit Money To The Wallet": "Procéder au paiement",
    "Withdraw": "Charger/déposer de l'argent dans le portefeuille",
    "Elevate Your Business With InstaPay": "Retirer",
    "Higher Balances & Transaction Limits": "Élevez votre entreprise avec InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Soldes plus élevés et limites de transaction",
    "Full Feature Access": "Débloquez la liberté de gérer des soldes plus importants et de traiter des volumes de transactions plus élevés, avec la fiabilité attendue d'InstaPay.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Accès complet aux fonctionnalités",
    "Global Reach": "Portée mondiale",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Développez votre entreprise sans frontières. InstaPay vous connecte à des clients et partenaires dans le monde entier, rendant les transactions mondiales plus simples que jamais.",
    "Advanced Security": "Sécurité avancée",
    "Business Registration Available Soon\n": "L'enregistrement des entreprises sera bientôt disponible.",
    "Amount After Fee": "Montant après frais",
    "Allowed Maximum Amount Per Transaction": "Montant maximum autorisé par transaction",
    "Transfer": "Transférer",
    "Uncategorized": "Non classé",
    "Currency ISO Code & Amount": "Code ISO de devise et montant",
    "Choose The Top-Up Channel": "Choisissez le canal de recharge",
    "You Selected": "que vous avez sélectionné",
    "Payout Channel": "canal de paiement",
    "Your Details": "vos détails",
    "Detailed Confirmed": "détails confirmés",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Boostez votre potentiel financier en passant à un compte InstaPay Business. Profitez pleinement d'InstaPay avec des fonctionnalités améliorées conçues spécifiquement pour la croissance de votre entreprise.",
    "Profile Picture": "Photo de profil",
    "Add Photo Or Logo": "Ajouter une photo ou un logo",
    "First Name": "Prénom",
    "Last Name": "Nom de famille",
    "Enter Your First Name": "Entrez votre prénom",
    "Enter Your Last Name": "Entrez votre nom de famille",
    "Email (Optional)": "Email (optionnel)",
    "Password Must": "Le mot de passe doit",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Il sera rempli automatiquement si vous vous inscrivez avec un lien d'invitation.",
    "Change Number": "Changer de numéro",
    "Search Country": "Rechercher un pays",
    "Select From The List": "Sélectionnez dans la liste",
    "Received Amount": "Montant reçu",

    "null": null,
    "Processing": "Traitement",
    "Verify Using Sms/Email": "Vérifiez en utilisant SMS/Email",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Existe déjà",
    "You Can Have Only 8 Wallets": "Vous ne pouvez avoir que 8 portefeuilles",
    "Save": "Enregistrer",
    "0 File": "0 fichier",
    "Search From The List": "Rechercher dans la liste",
    "Amount To Send": "Montant à envoyer",
    "Wallet To Wallet": "Portefeuille à Portefeuille",
    "TOP-UP WALLET ": "RECHARGER LE PORTEFEUILLE",
    "Enter The Amount": "Entrez le montant",
    "Load/Deposit Money To The Wallet": "Charger/Déposer de l'argent dans le portefeuille",
    "Choose The Top-Up Channel": "Choisissez le canal de recharge",
    "Paypal": "Paypal",
    "You Will Get": "Vous recevrez",
    "Amount To Pay": "Montant à payer",
    "Card Holder Name ": "Nom du titulaire de la carte",
    "Card Number": "Numéro de carte",
    "CVC": "CVC",
    "MM/YY": "MM/AA",
    "Your Details ": "Vos détails",
    "You Selected": "Vous avez sélectionné",
    "Detailed Confirmed": "Détail confirmé",
    "Transaction Failed": "Transaction échouée",
    "Something Went Wrong. Try Again.": "Une erreur est survenue. Réessayez.",
    "Convert Funds": "Convertir les fonds",
    "How Would You Like To Cashout?:": "Comment souhaitez-vous encaisser?:",
    "Default Receiving Account": "Compte de réception par défaut",
    "Specify Receiving Account": "Spécifiez le compte de réception",
    "Which Receiving Account Would You Want Use?:": "Quel compte de réception souhaitez-vous utiliser?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Banque Mobile",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Aucun",
    "Request": "Demande",
    "Subscription Payment": "Paiement par abonnement",
    "Is not supported by Paypal, USD will be used as the default currency.": "N'est pas pris en charge par PayPal, l'USD sera utilisé comme devise par défaut.",
    "Review Payment": "Examiner le paiement",
    "Google Authenticator": "authentificateur Google",
    "Scheduled Has Been Cancelled": "La planification a été annulée",
    "Select Country First": "Sélectionnez d'abord le pays",
    "Select Gender": "Sélectionnez le genre",
    "Select Occupation": "Sélectionnez la profession",
    "IBAN and SWIFT/BIC": "IBAN et SWIFT/BIC",
    "Mobile Money Provider": "Fournisseur d'argent mobile",
    "Wallet Name": "Nom du portefeuille",
    "Wallet Number ": "Numéro de portefeuille",
    "Crypto Currency": "Cryptomonnaie",
    "Wallet Address ": "Adresse du portefeuille",
    "Select Document Type ": "Sélectionnez le type de document",
    "Enter Document Number": "Entrez le numéro du document",
    "Msisdn": "Msisdn",
    "Edit ": "Modifier",
    "My Withdrawls": "Mes retraits",
    "Declined": "Refusé",
    "Accepted": "Accepté",
    "Overall Ratings": "Évaluations globales",
    "0 Ratings": "0 évaluations",
    "No Reviews": "Aucun avis",
    "Send Money": "Envoyer de l'argent",
    "Trouble Sending Money?": "Problème pour envoyer de l'argent ?",
    "Payment Request": "Demande de paiement",
    "How Was Your Experience?": "Comment s'est passée votre expérience ?",
    "Leave A Review": "Laissez un avis",
    "Write Review Message": "Rédigez un message d'avis",
    "Send And Post": "Envoyer et publier",
    "You Rated": "Vous avez évalué",
    "Review History": "Historique des avis",
    "Max Size 1GB": "Taille max 1 Go",
    "Title": "Titre",
    "Description": "Description",
    "Add New": "Ajouter un nouveau",
    "My Payment Address": "Mon adresse de paiement",
    "Personal Account": "Compte personnel",
    "Updated Successfully": "Mis à jour avec succès",
    "Spent In": "Dépensé dans",
    "TOP UP": "Recharger",
    "Quotation": "Devis",
    "Amount": "Montant",
    "Not Connected": "Non connecté",
    "More": "Plus",
    "Less": "Moins",
    "Reports": "Rapports",
    "User Profile": "Profil utilisateur",
    "Cancel": "Annuler",
    "Saving": "Enregistrement",
    "Confirm ": "confirmer",
    "Verify ": "Vérifier",
    "Delete Country": "Supprimer le pays",
    "Bank Account": "Compte bancaire",
    "Cash Pickup": "Retrait en espèces",
    "Mobile Wallet ": "Portefeuille mobile",
    "Delete Country Withdrawals": "Supprimer les retraits par pays",
    "Yes , Delete It": "Oui, supprimez-le",
    "E-sim": "E-sim",
    "Utilities": "Services publics",
    "Searching": "Recherche",
    "Recipient Mobile Number": "Numéro de téléphone du destinataire",
    "TOP-UP SUMMARY": "RÉSUMÉ DE RECHARGE",
    "Trouble Requesting Money?": "Problème de demande d'argent?",
    "Choose The Beneficiary": "Choisissez le bénéficiaire",
    "Enter The amount": "Entrez le montant",
    "You selected": "Vous avez sélectionné",
    "Timezone": "Fuseau horaire",
    "Starting From": "À partir de",
    "Recurring Cycle": "Cycle récurrent",
    "Until I stop": "Jusqu'à ce que j'arrête",
    "Schedule To": "Planifier pour",
    "Schedule at": "Planifier à",
    "Selected time must be at least 5 minutes from now.": "L'heure sélectionnée doit être d'au moins 5 minutes à partir de maintenant.",
    "Is this your Location?": "Est-ce votre emplacement ?",
    "Yes ": "Oui",
    "Select Remark Reason": "Sélectionnez la raison du commentaire",
    "Attach File": "Joindre un fichier",
    "Invite via Phone Number": "Inviter par numéro de téléphone",
    "Invite Friend": "Inviter un ami",
    "Invite via Email Address": "Inviter par adresse e-mail",
    "Invite via": "Inviter via",
    "Phone": "Téléphone :",
    "Message": "Message :",
    "Send": "Envoyer",
    "Invited Succesfully!": "Invité avec succès !",
    "Email": "E-mail",
    "Bank Transfer": "Virement bancaire",
    "Mobile Wallet": "Portefeuille mobile",
    "Select The Bank": "Sélectionner la banque",
    "Select The Payer": "Sélectionner le payeur",
    "Min Amount:": "Montant minimum :",
    "Max Amount": "Montant maximum",
    "Other Beneficiaries:": "Autres bénéficiaires :",
    "Back ": "Retour",
    "Next": "Suivant",
    "Lookup": "Recherche",
    "Invite Them": "Les inviter",
    "Delete Channel ": "Supprimer le canal",
    "Set As Default": "Définir par défaut",
    "See More": "Voir plus",
    "Session Details": "Détails de la session",
    "Platform": "Plateforme",
    "Windows": "Windows",
    "Browser Name": "Nom du navigateur",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Version du navigateur",
    "IP Address": "Adresse IP",
    "Karachi": "Karachi",
    "State": "État",
    "Sindh": "Sindh",
    "Active": "Actif",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Ce portefeuille de devises est actuellement bloqué/désactivé et les fonds ne peuvent pas être ajoutés.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Ce portefeuille de devises est actuellement bloqué/désactivé et les fonds ne peuvent pas être convertis.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Ce portefeuille de devises est actuellement bloqué/désactivé et vous ne pouvez pas retirer le solde.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Ce portefeuille de devises est actuellement bloqué/désactivé et vous ne pouvez pas télécharger l'état de compte.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Ce portefeuille de devises est actuellement défini comme votre portefeuille par défaut et ne peut pas être bloqué. Pour continuer, veuillez définir un autre portefeuille de devises comme votre portefeuille par défaut.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Ce portefeuille de devises est actuellement défini comme votre portefeuille par défaut et ne peut pas être désactivé. Pour continuer, veuillez définir un autre portefeuille de devises comme votre portefeuille par défaut.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Êtes-vous sûr de vouloir supprimer les détails de retrait de ce pays ?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Configurez vos comptes de réception dans jusqu'à trois pays et retirez instantanément les fonds des demandes de paiement approuvées vers vos canaux de paiement activés.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Aucun bénéficiaire trouvé au Pakistan ayant les détails de Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "Le message d'invitation a été envoyé avec succès à",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Les frais et le montant reçu peuvent varier en fonction du mode de paiement choisi.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Partagez votre code QR ou le lien de paiement pour être payé instantanément dans votre portefeuille insta.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Glissez/Déposez ou téléchargez un fichier (Photo/Vidéo/PDF)",
    "Login Again": "se reconnecter",
    "Unblock The Wallet": "débloquer le portefeuille",
    "Invalid Amount": "montant invalide",
    "Add Payment Card": "ajouter une carte de paiement",
    "Save Details To Use Next Time": "enregistrer les détails pour une utilisation future",
    "Paid": "payé",
    "Started On": "commencé le",
    "Num Of Cycles": "nombre de cycles",
    "Ends On": "se termine le",
    "Next Payment At": "prochain paiement le",
    "Beneficiary": "bénéficiaire",
    "Recieved Quotation": "devis reçu",
    "Sent Quotation": "devis envoyé",
    "Decline Offer": "refuser l'offre",
    "Are You Sure That You Want To Decline The Offer?": "êtes-vous sûr de vouloir refuser l'offre ?",
    "Decline The Offer": "refuser l'offre",
    "Bargain Offer": "offre négociée",
    "Enter Counter Amount": "entrer le montant de contre-offre",
    "Custom Amount": "montant personnalisé",
    "Send The Offer": "envoyer l'offre",
    "The Offer Is Sent": "l'offre est envoyée",
    "Countered Offer": "offre contre-offre",
    "Applied": "appliqué",
    "Accept Quotation": "accepter le devis",
    "Quotation Not Found Or Already Exist": "devis non trouvé ou déjà existant",
    "Recieved Request": "demande reçue",
    "Sent Request": "demande envoyée",
    "Search Request": "rechercher une demande",
    "Search Quotation": "rechercher un devis",
    "QR Status": "statut QR",
    "QR Code Description": "description du code QR",
    "Download Your QR Code": "téléchargez votre code QR",
    "Your QR Code": "votre code QR",
    "Your QR Code Poster": "votre affiche de code QR",
    "Download  ": "télécharger",
    "Select Relation": "sélectionner la relation",
    "Nominee Permanent Address": "adresse permanente du nominé",
    "Enter Your Message": "entrer votre message",
    "Select": "sélectionner",
    "Select File Type": "sélectionner le type de fichier",
    "Send TOP-UP And E-Sim": "envoyer le rechargement et E-sim",
    "Search Users": "rechercher des utilisateurs",
    "Select Payment Request Type": "sélectionner le type de demande de paiement",
    "Wallet To Wallet Transfer": "transfert de portefeuille à portefeuille",
    "Holiday List": "liste des jours fériés",
    "No Data To Show": "aucune donnée à afficher",
    "They Recieve": "ils reçoivent",
    "You Will Be Logged Out In 60 Seconds": "Vous serez déconnecté dans 60 secondes",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Déplacez votre souris ou appuyez sur n'importe quelle touche de votre clavier pour réinitialiser le compteur à 15 minutes",
    "You've Been Logged Out": "Vous avez été déconnecté",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Pour votre sécurité, vous avez été déconnecté après 15 minutes d'inactivité. Veuillez vous reconnecter si vous souhaitez utiliser InstaPay.",
    "Cash": "Espèces",
    "Business": "Affaires",
    "Gift": "Cadeau",
    "Salary": "Salaire",
    "Lottery": "Loterie",
    "Savings": "Économies",
    "Other": "Autre",
    "Select Source Of Fund": "Sélectionnez la source de financement",
    "Select your source of funds": "Sélectionnez votre source de financement",
    "Request Times Out. Please Try Again!": "La demande a expiré. Veuillez réessayer !",
    "Resend Code In 100 Seconds": "Renvoyer le code en 100 secondes",
    "Computer Service": "Service informatique",
    "Family Support": "Soutien familial",
    "Education": "Éducation",
    "Gift And Other Donations": "Cadeaux et autres dons",
    "Medical Treatment": "Traitement médical",
    "Maintenance Or Other Expenses": "Entretien ou autres dépenses",
    "Travel": "Voyage",
    "Small Value Remittance": "Transfert de petite valeur",
    "Liberalized Remittance": "Transfert libéralisé",
    "Construction Expenses": "Dépenses de construction",
    "Hotel Accommodation": "Hébergement à l'hôtel",
    "Advertising And/Or Public Relations Related Expenses": "Publicité et/ou frais de relations publiques",
    "Fees For Advisory Or Consulting Service": "Frais pour services de conseil ou de consultation",
    "Business Related Insurance Payment": "Paiement d'assurance lié aux affaires",
    "Insurance Claims Payment": "Paiement de sinistres d'assurance",
    "Delivery Fees": "Frais de livraison",
    "Payments For Exported Goods": "Paiements pour biens exportés",
    "Payment For Services": "Paiement pour services",
    "Payment Of Loans": "Paiement de prêts",
    "Office Expenses": "Dépenses de bureau",
    "Residential Property Purchase": "Achat de propriété résidentielle",
    "Property Rental Payment": "Paiement de loyer de propriété",
    "Royalty, Trademark, Patent And Copyright Fees": "Frais de royalties, marques, brevets et droits d'auteur",
    "Investment In Shares": "Investissement en actions",
    "Fund Investment": "Investissement de fonds",
    "Tax Payment": "Paiement des taxes",
    "Transportation Fees": "Frais de transport",
    "Utility Bills": "Factures de services publics",
    "Personal Transfer": "Transfert personnel",
    "Payment Of Salary": "Paiement de salaire",
    "Payment Of Rewards": "Paiement de récompenses",
    "Payment Of Influencer": "Paiement d'Influenceur",
    "Broker, Commitment, Guarantee And Other Fees": "Frais de courtier, engagement, garantie et autres frais",
    "Other Purposes": "Autres objectifs",
    "Aunt": "Tante",
    "Brother": "Frère",
    "Brother-In-Law": "Beau-frère",
    "Cousin": "Cousin",
    "Daughter": "Fille",
    "Father": "Père",
    "Father-In-Law": "Beau-père",
    "Friend": "Ami",
    "Grandfather": "Grand-père",
    "Grandmother": "Grand-mère",
    "Husband": "Mari",
    "Mother": "Mère",
    "Mother-In-Law": "Belle-mère",
    "Nephew": "Neveu",
    "Niece": "Nièce",
    "Self (i.e. The Sender, Himself)": "Soi-même (c'est-à-dire l'expéditeur lui-même)",
    "Sister": "Sœur",
    "Sister-In-Law": "Belle-sœur",
    "Son": "Fils",
    "Uncle": "Oncle",
    "Wife": "Épouse",
    "Others Not Listed": "Autres non listés",
    "Passport": "Passeport",
    "National Identification Card": "Carte d'identité nationale",
    "Driving License": "Permis de conduire",
    "Social Security Card/Number": "Carte/Numéro de sécurité sociale",
    "Tax Payer Identification Card/Number": "Carte/Numéro d'identification des contribuables",
    "Senior Citizen Identification Card": "Carte d'identification des personnes âgées",
    "Birth Certificate": "Acte de naissance",
    "Village Elder Identification Card": "Carte d'identification du chef de village",
    "Permanent Residency Identification Card": "Carte de résident permanent",
    "Alien Registration Certificate/Card": "Carte de certificat d'enregistrement d'étranger",
    "PAN Card": "Carte PAN",
    "Voter’s Identification Card": "Carte d'identification de l'électeur",
    "Health Insurance Card/Number": "Carte/Numéro d'assurance maladie",
    "Employer Identification Card": "Carte d'identification de l'employeur",
    "Others Not Listed": "Autres non listés",
    "Bank Account": "Compte bancaire",
    "Cash Pickup": "Retrait en espèces",
    "Card": "Carte",
    "Arts & Entertainment": "Arts et Divertissements",
    "Banking & Finance": "Banque et Finance",
    "Education & Research": "Éducation et Recherche",
    "Engineering & Construction": "Ingénierie et Construction",
    "Healthcare & Medicine": "Soins de santé et Médecine",
    "Information Technology & Services": "Technologies de l'information et Services",
    "Legal & Compliance": "Légal et Conformité",
    "Manufacturing & Production": "Fabrication et Production",
    "Marketing & Sales": "Marketing et Ventes",
    "Non-profit & Charity": "Organisme à but non lucratif et Charité",
    "Real Estate & Property": "Immobilier et Propriété",
    "Retail & Wholesale": "Commerce de détail et de gros",
    "Science & Pharmaceuticals": "Science et Pharmaceutiques",
    "Transport & Logistics": "Transport et Logistique",
    "Travel & Tourism": "Voyage et Tourisme",
    "Unemployed": "Chômage",
    "Other": "Autre",
    "Cash": "Argent",
    "Business": "Affaires",
    "Gift": "Cadeau",
    "Salary": "Salaire",
    "Lottery": "Loterie",
    "Savings": "Épargne",
    "Good Morning": "Bonjour",
    "Good Evening": "Bonsoir",
    "Completed": "Terminé",
    "Load/Deposit Money To The Wallet": "Procéder au paiement",
    "Enter The Amount": "Entrez le montant",
    "Choose The Top-Up Channel": "Choisissez le canal de recharge",
    "Good Night!": "Bonne nuit !",
    "You Will Get": "Confirmez votre paiement",
    "Amount To Pay": "Vous recevrez",
    "Top-Up Wallet": "Type de paiement",
    "Card Holder Name ": "Nom du titulaire de la carte",
    "Card Number": "Numéro de carte",
    "CVC": "CVC",
    "MM/YY": "MM/AA",
    "Save Details To Use Next Time": "enregistrer les détails pour une utilisation future",
    "Select Or Add Payment Card": "Sélectionnez ou ajoutez une carte de paiement",
    "Your Details ": "Vos détails",
    "You Selected": "Vous avez sélectionné",
    "Detailed Confirmed": "Détail confirmé",
    "Transaction Failed": "Transaction échouée",
    "Something Went Wrong. Try Again.": "Une erreur est survenue. Réessayez.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Échoué",
    "Initiated": "Initié",
    "Revise": "Réviser",
    "Declined": "Refusé",
    "Bargain-Accepted": "Marché accepté",
    "Accepted": "Accepté",
    "Decline The Offer": "Refuser l'offre",
    "Pending": "En attente",
    "Cancelled": "Annulé",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Envoyez un devis détaillé à votre client ou client. Présentez vos services, produits ou offres de manière professionnelle avec un devis personnalisé.",
    "Wallet to wallet": "Portefeuille à portefeuille",
    "Please follow these examples:": "Veuillez suivre ces exemples :",
    "👤 InstaPay/Instagram Username: instauser": "👤 Nom d'utilisateur InstaPay/Instagram : instauser",
    "📧 Email: user@email.com": "📧 Email : user@email.com",
    "Full Name": "Nom complet",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Bienvenue sur InstaPay ! Pour vous assurer que vous pouvez retirer instantanément les fonds que vous recevez, vous devez configurer vos comptes de réception dans les paramètres. Cela vous permettra de transférer de l'argent sans effort vers vos comptes bancaires préférés, portefeuilles mobiles, portefeuilles cryptographiques ou points de retrait en espèces.",
    "You Have No Beneficiary": "Vous n'avez pas de bénéficiaire",
    "Add Now": "Ajouter maintenant",
    "Wallet-Status": "Statut du portefeuille",
    "Select Payment Card": "Sélectionnez une carte de paiement",
    "Your funds have been credited to your wallet.": "Vos fonds ont été crédités sur votre portefeuille.",
    "Thanks for using InstaPay": "Merci d'utiliser InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Payez immédiatement : Configurez vos comptes de réception maintenant !",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Suivez ces étapes simples pour configurer vos comptes de réception et vous assurer d'avoir toujours accès à vos fonds :",
    "STEP 01": "ÉTAPE 01",
    "Go to Settings": "Accédez aux Paramètres",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Ouvrez votre application InstaPay et accédez à la section \"Paramètres\".",
    "STEP 02": "ÉTAPE 02",
    "Select Receiving Accounts": "Sélectionnez Comptes de Réception",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Appuyez sur \"Comptes de Réception\" pour commencer à ajouter vos informations.",
    "Start Adding Accounts": "Commencez à Ajouter des Comptes",
    "Phone Number: 44795396600 (With Country Code)": "Numéro de Téléphone : 44795396600 (Avec Indicatif du Pays)",
    "Select Country First": "Sélectionnez d'abord le pays",
    "First Name": "Prénom",
    "Last Name": "Nom de famille",
    "Address Line": "Ligne d'adresse",
    "Email Address": "Adresse e-mail",
    "Select City": "Sélectionner une ville",
    "Select Gender": "Sélectionnez le genre",
    "Select Occupation": "Sélectionnez la profession",
    "Male": "Homme",
    "Female": "Femme",
    "Non-Binary": "Non-binaire",
    "Prefer Not To Say'": "Préfère ne pas dire",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Aucun utilisateur trouvé",
    "Email Address ": "Adresse e-mail",
    "Select Document": "Sélectionner un document",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Mettez en valeur votre travail, amplifiez votre portée. 'Mon Portfolio' est votre toile numérique sur Instapay. Téléchargez et affichez une large gamme de contenu, des images et vidéos captivantes aux PDF informatifs. Que vous soyez artiste, freelance ou entrepreneur, cette fonctionnalité vous permet de présenter votre travail ou vos projets de manière visuellement attrayante.",
    "Add New": "Ajouter nouveau",
    "Money Out": "Sortie d'argent",
    "Browsername": "Nom du navigateur",
    "Browserversion": "Version du navigateur",
    "Credit": "Crédit",
    "Debit": "Débit",
    "Top Up": "Recharger",
    "Wallet To Wallet": "Portefeuille à portefeuille",
    "Accountant": "Comptable",
    "Architect": "Architecte",
    "Artist": "Artiste",
    "Banker": "Banquier",
    "Business Owner": "Propriétaire d'entreprise",
    "Consultant": "Consultant",
    "Doctor": "Médecin",
    "Engineer": "Ingénieur",
    "Farmer": "Agriculteur",
    "Government Employee": "Employé gouvernemental",
    "IT Professional": "Professionnel de l'informatique",
    "Lawyer": "Avocat",
    "Nurse": "Infirmière",
    "Retailer": "Détaillant",
    "Salesperson": "Vendeur",
    "Student": "Étudiant",
    "Teacher": "Professeur",
    "Prefer Not to Say": "Préfère ne pas dire",
    "Timezone": "Fuseau horaire",
    "Subscription Payment": "Paiement par abonnement",
    "Starting From": "À partir de",
    "Recurring Cycle": "Cycle récurrent",
    "Until I stop": "Jusqu'à ce que j'arrête",
    "Schedule To": "Planifier pour",
    "Schedule at": "Planifier à",
    "Selected time must be at least 5 minutes from now.": "L'heure sélectionnée doit être d'au moins 5 minutes à partir de maintenant.",
    "They Recieve": "ils reçoivent",
    "Attach Files": "Joindre des fichiers",
    "Wallet Selected": "Portefeuille sélectionné",
    "You Have Selected PKR Currency Wallet For This Transaction": "Vous avez sélectionné le portefeuille en PKR pour cette transaction",
    "Instant": "Instantané",
    "Subscription": "Abonnement",
    "Schedule": "Planifier",
    "Sms/Email Verification Code": "Code de Vérification Sms/Email",
    "Confirm Your Password": "Confirmez votre mot de passe",
    "This Security Feature Requires Password Confirmation!": "Cette fonctionnalité de sécurité nécessite la confirmation du mot de passe !",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Utilisez l'application pour scanner le code QR ou utilisez votre code secret pour activer l'authentification à deux facteurs de Google",
    "OR": "OU",
    "Paste This Key In The Googe Authenticator App": "Collez cette clé dans l'application Google Authenticator",
    "Show Secret Key": "Afficher la clé secrète",
    "Enter The Six-Digit Code From The Application": "Entrez le code à six chiffres de l'application",
    "6 Digit Code": "Code à 6 chiffres",
    "Enable": "Activer",
    "Disable": "Désactiver",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Après avoir scanné l'image du code-barres, l'application affichera un code à six chiffres que vous pourrez saisir ci-dessous",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "L'authentification à deux facteurs de Google est maintenant liée à votre compte Instapay",
    "Min Amount:": "Montant minimum :",
    "Minimum Amount": "Montant minimum",
    "Maximum Amount": "Montant Maximum",
    "Identity Verification Required": "Vérification d'identité requise",
    "Select Your Wallet Currency": "Sélectionnez votre devise de portefeuille",
    "Setup Authenticator": "Configurer l'authentificateur",
    "Note": "Remarque",
    "This Is A Test Notice": "Ceci est un avis de test",
    "Session Expired": "Session expirée",
    "You have been logged out for security reasons, please login again to continue.": "Vous avez été déconnecté pour des raisons de sécurité, veuillez vous reconnecter pour continuer.",
    "Redirecting You To Login Page In 5 Seconds": "Redirection vers la page de connexion dans 5 secondes",
    "Searching": "Recherche",
    "Recipient Mobile Number": "Numéro de téléphone du destinataire",
    "TOP-UP SUMMARY": "RÉSUMÉ DE RECHARGE",
    "Amount": "Montant",
    "Something went wrong while getting countries.": "Une erreur s'est produite lors de la récupération des pays.",
    "Select Destination Country": "Sélectionnez le pays de destination",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR n'est pas pris en charge par Paypal, USD sera utilisé comme devise par défaut.",
    "Select A Date": "Sélectionnez une date",
    "Select A Timezone": "Sélectionnez un fuseau horaire",
    "Proceed": "Procéder",
    "Proceeding": "En cours",
    "You Can Now Close This Webpage And Go Back To Instagram": "Vous pouvez maintenant fermer cette page Web et revenir sur Instagram",
    "Select A Time": "Sélectionnez une heure",
    "Location Permission Denied": "Autorisation de localisation refusée",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Suivez les instructions ci-dessous pour activer les services de localisation dans votre navigateur après avoir refusé l'autorisation précédemment :",
    "1: Chrome Browser": "1 : Navigateur Chrome",
    "Open the Chrome app.": "Ouvrez l'application Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Appuyez sur le menu à trois points (coin supérieur droit) et sélectionnez Paramètres.",
    "Scroll down to Site Settings > Location.": "Faites défiler jusqu'à Paramètres du site > Localisation.",
    "Locate the website in the list or search for it.": "Recherchez le site Web dans la liste ou recherchez-le.",
    "Tap the website and select Allow under Location Access.": "Appuyez sur le site Web et sélectionnez Autoriser sous Accès à la localisation.",
    "2: Firefox Browser": "2 : Navigateur Firefox",
    "Open the Firefox app.": "Ouvrez l'application Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Appuyez sur le menu à trois lignes (coin inférieur droit) et accédez à Paramètres.",
    "Select Privacy & Security > Site Permissions > Location.": "Sélectionnez Confidentialité et sécurité > Autorisations du site > Localisation.",
    "Find the website in the list and set the permission to Allow.": "Trouvez le site Web dans la liste et définissez l'autorisation sur Autoriser.",
    "3: Samsung Internet Browser": "3 : Navigateur Internet Samsung",
    "Open the Samsung Internet app.": "Ouvrez l'application Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Appuyez sur le menu à trois lignes (coin inférieur droit) et sélectionnez Paramètres.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Accédez à Sites et téléchargements > Autorisations du site > Localisation.",
    "Find the website and change the permission to Allow.": "Trouvez le site Web et modifiez l'autorisation sur Autoriser.",
    "4: Safari Browser": "4 : Navigateur Safari",
    "Open the Settings app.": "Ouvrez l'application Paramètres.",
    "Scroll down and select Safari.": "Faites défiler vers le bas et sélectionnez Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Appuyez sur Position et définissez-la sur Demander la prochaine fois ou Autoriser pendant l'utilisation de l'application.",
    "Revisit the website and when prompt, allow location access": "Revisitez le site Web et, lorsqu'on vous le demande, autorisez l'accès à la position.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Astuce : Si le site Web n'apparaît pas dans les paramètres du navigateur, effacez le cache ou l'historique de votre navigateur et réessayez.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Pour envoyer une demande de paiement, veuillez partager votre position—cela nous aide à renforcer la sécurité et à instaurer la confiance pour une expérience plus fluide !",
    "Decline": "Refuser",
    "Share Location": "Partager la localisation"
}