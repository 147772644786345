export const yo = {
    "How Others Can Find You": "Ṣe ẹ̀dá mọ̀ láti wọ̀n",
    "Schedules": "Awọn iṣẹlẹ",
    "Login": "Wọle",
    "Start Your InstaPay Journey Today!": "Bẹrẹ Irin-ajo InstaPay Rẹ Loni!",
    "Phone Number or Email": "Nọmba Foonu tabi Imeeli",
    "Login Using Social Media": "Wọle Nipa Lilo Awujo Media",
    "Continue": "Tẹsiwaju",
    "Don't Have an Account? Sign Up": "Ko ni Akọọlẹ? Forukọsilẹ",
    "Sign Up": "Forukọsilẹ",
    "Edit": "Ṣàtúnṣe",
    "Enter Your Password": "Tẹ Ọrọigbaniwọle Rẹ",
    "Forgot Password?": "Gbagbe Ọrọigbaniwọle?",
    "Account Type": "Iru Akọọlẹ",
    "Individual": "Ẹni kọọkan",
    "Business": "Iṣowo",
    "Already Have an Account? Sign In": "Tẹlẹ ni Akọọlẹ? Wọle",
    "Sing In": "Wọle",
    "Enter Your Personal Details": "Tẹ Awọn Alaye Ti ara ẹni Rẹ",
    "Add Photo or Logo": "Fi Fọto tabi Aami kun",
    "Enter Your Name": "Tẹ Orukọ Rẹ",
    "Back": "Pada",
    "Phone Number": "Nọmba Foonu",
    "Email Address": "Àdírẹ́sì ìméèlì",
    "Create a Strong Password": "Ṣẹda Ọrọigbaniwọle Lagbara",
    "Password Requirements": "Àwọn Ìbéèrè Fún Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀",
    "Must be between 9 and 64 characters": "Gbọdọ wa laarin awọn ohun kikọ 9 si 64",
    "Include at least two of the following:": "Pẹlu o kere ju meji ninu atẹle:",
    "Uppercase character": "Ohun kikọ Nla",
    "Lowercase character": "Ohun kikọ Kekere",
    "Number": "Nọmba",
    "Special character": "Ohun kikọ Pataki",
    "Confirm Password": "Jẹrisi Ọrọigbaniwọle",
    "Finalize Your Process": "Parí Ilana Rẹ",
    "Referral Code (Optional)": "Koodu Tọka (Iyan)",
    "It will autofill if you're signing up with an invitation link.": "Yoo kun laifọwọyi ti o ba n forukọsilẹ pẹlu ọna asopọ ìpè.",
    "I agree with": "Mo gba pẹlu",
    "Terms & Conditions": "Awọn ofin & Ipo",
    "Privacy Policy": "Eto Afihan Asiri",
    "Create Your Account": "Ṣẹda Akọọlẹ Rẹ",
    "Enter Code": "Tẹ Koodu",
    "A one-time verification code has been sent to your email address  and phone number.": "A ti fi koodu ijẹrisi lẹẹkan ranṣẹ si adirẹsi imeeli rẹ ati nọmba foonu.",
    "Resend available in ": "Atunṣe wa ni",
    "seconds": "aaya",
    "Verify": "Ṣayẹwo",
    "Verification Code Verified": "Koodu Ijẹrisi Ti Ṣayẹwo",
    "Please continue to the next step.": "Jọwọ tẹsiwaju si igbesẹ ti n tẹle.",
    "Finish": "Parí",
    "Finishing": "Npari",
    "Company Name": "Orukọ Ile-iṣẹ",
    "Enter Your Business Details": "Tẹ Awọn Alaye Iṣowo Rẹ",
    "Country ": "Orilẹ-ede",
    "You cannot change the country once your account is verified.": "O ko le yi orilẹ-ede pada lẹhin ti a ti ṣayẹwo akọọlẹ rẹ.",
    "Learn more": "Kọ ẹkọ diẹ sii",
    "Optional": "Iyan",
    "Continuing": "Tẹsiwaju",
    "Didn't receive the verification code?": "Ko gba koodu ijẹrisi naa?",
    "Resend Now": "Fi pada ranṣẹ Bayi",
    "Search Here": "Wa Nibi",
    "Search for users, services, and more on InstaPay": "Wa fun awọn olumulo, iṣẹ, ati siwaju sii lori InstaPay",
    "Search users here": "Wa awọn olumulo nibi",
    "Earn money with our affiliate program!": "Jo'gun owo pẹlu eto alafaramo wa!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "\"Ṣe inawo owo-wiwọle afẹyinti nipa pinpin koodu alafaramo InstaPay rẹ pẹlu awọn ọmọlẹyin rẹ. Fun iṣowo kọọkan ti wọn ṣe, iwọ yoo jo'gun \nigbimọ kan, nitorinaa o pọ si ere lati ipa ayelujara rẹ.\"",
    "Learn More": "Kọ ẹkọ diẹ sii",
    "Balance Amount": "Iye Iwontunwonsi",
    "You spent less compared to last month.": "O lo din owo ju osù to kọja lọ.",
    "Referral Rewards": "Awọn Ẹbun Itọkasi",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Yiyara Gbigbe si Awọn Olubasọrọ Rẹ Tó Láti ṣẹṣẹ:",
    "Number of Transactions": "Nọmba Awọn Iṣowo",
    "Total Transaction Amount": "Apapọ Iye Iṣowo",
    "Total Sent": "Apapọ Ti a Ránṣẹ",
    "Total Received": "Apapọ Ti a Gba",
    "Add Funds": "Fi Owo Kun",
    "Send Money": "Fi Owo Ránṣẹ\n",
    "Request Money": "Beere Fun Owo",
    "Send a Quote": "Fi Iye Ránṣẹ",
    "Pay With Crypto": "San pẹlu Crypto",
    "Payment Insights": "Awọn Oye Isanwo",
    "Recent Transactions ": "Awọn Iṣowo Láìpẹ",
    "Today": "Lónìí",
    "My Wallets": "Awọn Wallet mi",
    "Balance": "Iwontunwonsi",
    "Wallet ID": "ID Wallet",
    "Monthly limit": "Iye Iṣẹ oṣooṣu",
    "Top Up": "Kun Oke",
    "Summary": "Akopọ",
    "Settings": "Eto",
    "More": "Diẹ sii",
    "Upgrade to Business Account": "Yípadà si Àkọọlẹ Iṣẹ",
    "Conversion": "Yíyípadà",
    "Enter Amount": "Tẹ Iye sii",
    "Exchanged Amount": "Iye Ti a Yípadà",
    "Convert": "Yípadà",
    "You Are Converting": "O n Yípadà",
    "From": "Láti ọdọ",
    "To": "Si",
    "Exchange Rate": "Oṣuwọn Paṣipaarọ",
    "Fees": "Owó Ìdíyelé",
    "You Will Receive": "Iye Ti O Ma Gba",
    "Slide To Confirm": "Fà Lati Jẹri",
    "Verification Code Confirmed - You'll be redirected to the next step": "Ijeri Koodu Ìjeri - O ma tò ọ lọ si igbesẹ tó tẹle",
    "Moving to next step in": "Lọ si igbesẹ tó tẹle nínú",
    "Thanks for using InstaPay": "O ṣeun fun lilo InstaPay",
    "Your funds have been credited to your wallet.": "A ti kọ owo rẹ sinu Wallet rẹ.",
    "Dashboard": "Dashboard",
    "Accounts": "Awọn Iroyin",
    "Transactions": "Awọn Iṣowo",
    "Other Transactions": "Awọn Iṣowo Míràn",
    "Payments": "Awọn Isanwo",
    "Beneficiaries": "Awọn Anfani",
    "Referrals": "Awọn Itọkasi",
    "Quotations": "Awọn Ìdárayá",
    "Pending": "Nduro",
    "My QR Code Sticker": "Alemo QR Code mi",
    "My Portfolio": "Awọn Ifihan mi",
    "My Payment Address": "Adirẹsi Isanwo mi",
    "Analytics": "Awọn Itupalẹ",
    "Profile": "Profaili",
    "Dark Mode": "Ipo Dudu",
    "Support": "Atilẹyin",
    "Logout": "Jade",
    "Powered By": "Ti pese nipasẹ",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Ile-iṣẹ Fintech Swiss",
    "MAIN MENU": "MENU AKỌKỌ",
    "OTHERS": "AWỌN MIRAN",
    "Wallet": "Wallet",
    "Default": "Aiyipada",
    "Breakdown": "Oniruuru",
    "Credit": "Gbigba",
    "Debit": "Fifunni",
    "Wallet Management": "Isakoso Wallet",
    "Top-up Your Wallet": "Kun Oke Wallet Rẹ",
    "Download Statement": "Gba Alaye",
    "Block the Wallet": "Dina Wallet",
    "Wallet Status": "Ipo Wallet",
    "-Select-File-Type-": "-Yan-Iru-Faili-",
    "Download Now": "Gba Bayi",
    "Downloading": "Gbigba",
    "All": "Gbogbo",
    "Sent": "Ti Ránṣẹ",
    "Received": "Ti Gba",
    "Requested": "Ti Beere",
    "Quoted": "Ti Sọ",
    "Search Transactions": "Wa Awọn Iṣowo",
    "Date & Time": "Ọjọ & Akoko",
    "Type": "Iru",
    "Transaction ID": "ID Iṣowo",
    "Recipient": "Olugba",
    "Amount": "Iye",
    "Status": "Ìpo",
    "Payment Type": "Iru Isanwo",
    "Sent Payments": "Awọn Isanwo Ti a Ránṣẹ",
    "Received Payments": "Awọn Isanwo Ti a Gba",
    "Date": "Ọjọ",
    "Cycles/Time": "Awọn Àkókò/Igba",
    "Payment As": "Bi Isanwo",
    "Name": "Oruko",
    "Cancel": "Fagilee",
    "Subscriptions & Scheduled": "Awọn Alabapin & Ti a Ṣeto",
    "Select the option that matches your needs from the list below:": "Yan aṣayan ti o baamu awọn aini rẹ lati atokọ ti o wa ni isalẹ:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Fi owo ranṣẹ si ọrẹ, ẹbi, tabi awọn iṣowo ni agbegbe tabi kariaye pẹlu irọrun. Yan lati ọpọlọpọ awọn aṣayan gbigbe, pẹlu idogo banki, mobile wallets,\nibi ti a le gba owo, awọn kaadi isanwo, ati awọn gbigbe lati wallet si wallet. Gbadun irọrun ati aabo ti InstaPay fun awọn gbigbe owo \nlaisiyonu ati lẹsẹkẹsẹ.",
    "International Transfer": "Gbigbe Kariaye\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Fi owo kọja awọn aala pẹlu irọrun. Gbe owo kariaye si ọrẹ, ẹbi, tabi awọn iṣowo ni diẹ ninu awọn tẹ ni kia kia. Yan lati inu ọpọlọpọ awọn ikanni \nisanwo pẹlu iroyin banki, owo alagbeka/mobile wallet, ibi ti a le gba owo, tabi kaadi isanwo fun awọn iṣowo ti o rọrun ati ailewu.",
    "Select the country where you want to send the money!": "Yan Orilẹ-ede Nibiti O Fẹ Fi Owo Ránṣẹ Si!",
    "Search Country": "Ṣawari Orilẹ-ede",
    "Trouble Sending Money?": "Iṣoro Nini Fi Owo Ránṣẹ?",
    "Confirm": "Jẹrisi",
    "Select Payout Channel": "Yan Ikanni Isanwo",
    "Select your most convenient option to send the money.": "Yan aṣayan ti o rọrun julọ lati fi owo ránṣẹ.",
    "Select The Bank": "Yan Banki",
    "Search Bank": "Ṣawari Banki",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Fi owo taara si iroyin banki ti olugba. Gbadun irọrun ati aabo ti gbigbe owo laisiyonu si eyikeyi banki kakiri aye.",
    "Bank Transfer": "Gbigbe Banki",
    "Enter the amount": "Tẹ iye owo sii",
    "Tax": "Owo-ori",
    "Amount the recipient will receive": "Iye ti olugba yoo gba",
    "Select Remarks / Reason": "Yan Awọn Asọye / Idi",
    "Comments": "Awọn Asọye",
    "Attach Files": "Fi Awọn faili Kun",
    "Groups": "Awọn Ẹgbẹ",
    "Manage Groups": "Ṣakoso Awọn Ẹgbẹ",
    "Add New Group": "Fi Ẹgbẹ Tuntun Kun",
    "Invite": "Pè Eniyan Kan",
    "Manage Beneficiaries": "Ṣakoso Awọn Anfani",
    "Recipients List": "Atokọ Awọn Olugba",
    "Search Contacts": "Ṣawari Awọn Olubasọrọ",
    "Confirm Your Payment": "Jẹrisi Isanwo Rẹ",
    "Select Country": "Yan Orilẹ-ede",
    "Select the Recipient's Payment Channel": "Yan Ikanni Isanwo Olugba",
    "Change Selection": "Yipada Aṣayan",
    "You Are Transferring ": "O N Fi Owo Ránṣẹ",
    "Oops! Something Went Wrong.": "Oops! Nkan Ti Ṣẹlẹ.",
    "We're Sorry About That. ": "A Tọrọ Gafara Nipa Eyi.",
    "Go back to Payments Page.": "Pada si Oju-iwe Awọn Isanwo.",
    "Wallet to Wallet Transfer": "Gbigbe Lati Wallet Si Wallet",
    "Request a customized price quote from service providers or vendors.": "Beere fun iye owo aṣa lati ọdọ awọn olupese iṣẹ tabi awọn oniṣowo.",
    "Enter the Wallet ID": "Tẹ ID Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Tẹ ID Wallet, Imeeli, tabi Nọmba Foonu ti olugba.",
    "Recipient not on InstaPay?": "Ṣe Olugba Ko Wa Lori InstaPay? Pè Wọn.",
    "Invite Them": "Pè Wọn",
    "My Beneficiaries": "Awọn Anfani Mi",
    "Enter the Amount You Wish to Transfer.": "Tẹ Iye Ti O Fẹ Fi Ránṣẹ.",
    "Sending to ": "Fi Ránṣẹ Si",
    "You Have Selected Wallet to Wallet Transfer": "O Ti Yan Gbigbe Lati Wallet Si Wallet",
    "Authenticate Your Payment.": "Ṣe Ijerisi Isanwo Rẹ.",
    "You are transferring": "O N Fi Owo Ránṣẹ",
    "Total ": "Lapapọ",
    "Where Is My Payment?": "Ibo Ni Isanwo Mi Wa?",
    "Share": "Pin",
    "Your Payment Is Confirmed": "Isanwo Rẹ Ti Fidi Rẹ Mulẹ",
    "QR Code Payment": "Isanwo Koodu QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Fi owo gbe lẹsẹkẹsẹ si awọn olumulo InstaPay miiran pẹlu iṣẹ wa ti o ni aabo lati wallet si wallet. Fi ati gba owo laisi wahala laarin iṣẹju-aaya.",
    "Scan QR with Your Camera or Upload from Your Device.": "Ṣayẹwo QR pẹlu Kamẹra Rẹ tabi Gbe lati Ẹrọ Rẹ.",
    "Upload from Your Device.": "Gbe lati Ẹrọ Rẹ.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Bi omiiran, Lo Adirẹsi Isanwo Alailẹgbẹ InstaPay (UPA)",
    "Enter InstaPay UPA": "Tẹ InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Ṣe Olugba Ko Wa Lori InstaPay? Pè Wọn",
    "Verify QR Code": "Ṣe Ayẹwo Koodu QR",
    "Scan/Upload QR Code of the Recipient": "Ṣayẹwo/Gbe Koodu QR ti Olugba",
    "Payment Confirmed": "Isanwo Ti Fidi Rẹ Mulẹ",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Beere fun iye owo adani lati ọdọ awọn olupese iṣẹ tabi awọn olutaja lati gba iṣiro idiyele to tọ fun awọn iṣẹ tabi awọn ọja ti o nilo.",
    "Choose The Beneficiary or Group": "Yan Ẹnìkan Tó Jẹ́ Ànfàní Tàbí Ẹgbẹ́",
    "Enter the Amount You Wish to Request.": "Tẹ iye owó tí o fẹ́ béèrè sí.",
    "You Are Requesting Money": "Ìwọ ń béèrè owó",
    "You are requesting": "Ìwọ ń béèrè",
    "As": "Bí",
    "Conversion of Your Cryptocurrencies": "Yípadà Òwò Owó Oníbíṣẹ́ rẹ",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Ṣe ìrírí ìyípadà owó oníbíṣẹ́ sí owó fíátì wa ti ó dára fún olùmúlò, aàbò, àti dídára.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Yan owó oníbíṣẹ́ tí o fẹ́ ta.",
    "Next": "Lẹ́yìn náà",
    "Enter the Crypto Wallet Address of the Recipient.": "Tẹ adírẹ́sì Wallet owó oníbíṣẹ́ olùgbà náà wọlé.",
    "Minimum Transactional Value: $100.": "Iye Owó Ti Ó Kéré Jùlọ Fún Gbígba Owó: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Àkíyèsí: Iye owó oníbíṣẹ́ máa ń yípadà nígbà gbogbo. Tọ́ka sí ìsọtẹ́lẹ̀ ìkẹyìn ní ojú-ìwé jẹ́rìí ìbéèrè.",
    "Select Network": "Yan Nẹtiwọ́kì",
    "Send A Quote": "Rán Ìsọtẹ́lẹ̀ Kan",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Rán ìsọtẹ́lẹ̀ owó aláyé sí àwọn alábàárà rẹ tàbí àwọn oníbàárà rẹ. Fi ìṣẹ́ rẹ, àwọn ọjà rẹ, tàbí àwọn ìfilọ́ rẹ hàn ní ọ̀nà ọjọgbọn pẹ̀lú ìsọtẹ́lẹ̀ owó tí \na ṣe pàtàkì fún wọn.",
    "Choose the Beneficiary": "Yan olùrànlọ́wọ́",
    "Allow Receiver to Bargain": "Gba olùgbéejáde láàyè láti pàṣípààrọ̀",
    "Enter the Title for Your Quotation.": "Tẹ àkọlé fún ìsọtẹ́lẹ̀ owó rẹ.",
    "Enter the Description for Your Quotation": "Tẹ àpèjúwe fún ìsọtẹ́lẹ̀ owó rẹ.",
    "You are Quoting": "O ń ṣe ìsọtẹ́lẹ̀ owó",
    "Title": "Àkọlé",
    "Description": "Àlàyé",
    "Attachments": "Àfikún",
    "Confirm Your Quotation": "Jẹ́rìí sí ìsọtẹ́lẹ̀ owó rẹ",
    "Verify The Quotation": "Ṣàyẹ̀wò ìsọtẹ́lẹ̀ owó",
    "Your quotation is confirmed": "Ìsọtẹ́lẹ̀ owó rẹ ti jẹ́rìí",
    "Request A Quote": "Beere Ìdáhùn",
    "Coming Soon": "Tí ń bọ̀ laipẹ",
    "Add Beneficiary": "Fi olùrànlọ́wọ́ kun",
    "Search for Beneficiaries": "Ṣàwárí àwọn olùrànlọ́wọ́",
    "Connected Accounts": "Àwọn àkọọ́lẹ́ tí a sopọ̀ mọ́ra",
    "Details": "Àlàyé",
    "First Name": "Orúkọ àkọ́kọ́",
    "Last Name": "Orúkọ ìdílé",
    "Country": "Orílẹ̀-èdè",
    "City": "Ìlú",
    "Address Line": "Àdírẹ́sì",
    "Mobile Number": "Nọ́mbà fóònù alágbèéká",
    "Relationship With Beneficiary": "Ìbáṣepọ̀ pẹ̀lú olùrànlọ́wọ́",
    "Bank Account": "Àkọọ́lẹ́ bánkì",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Káàdì ìsanwó",
    "Crypto Wallet": "Crypto Wallet",
    "InstaPay Account": "Àkọọ́lẹ́ InstaPay",
    "Cash Pickup": "Gbígbà owó ní ààyè",
    "IBAN / SWIFT Code": "Kóòdù IBAN tàbí Swift/BIC",
    "Swift Code": "Kóòdù Swift",
    "Account Number": "Nọ́mbà àkọọ́lẹ́",
    "Bank Name": "Orúkọ bánkì",
    "Branch Name": "Orúkọ ẹ̀ka bánkì",
    "Branch Street": "Ìlà ẹ̀ka bánkì",
    "Province": "Ìpínlẹ̀",
    "Postal Code": "Kóòdù ìpòsíta",
    "Mobile Money Provider": "Olùpèsè Mobile Money",
    "Wallet Name": "Orúkọ Wallet",
    "Wallet Number": "Nọ́mbà Wallet",
    "Card Holder Name": "Orúkọ olóye káàdì",
    "Card Number": "Nọ́mbà káàdì",
    "Expiry Date": "Ọjọ́ ìparí",
    "Crypto Currency": "Owó Crypto",
    "Wallet Address": "Àdírẹ́sì Wallet",
    "Wallet Holder Name": "Orúkọ olóye Wallet",
    "Wallet Currency": "Òwò owó Wallet",
    "Select Document Type": "Yàn ìrú ìwé àmúlò",
    "Enter Document Number": "Tẹ nọ́mbà ìwé àmúlò",
    "Add Individual Account": "Fi àkọọ́lẹ́ kọọ̀kan kun",
    "Add Business Account": "Fi àkọọ́lẹ́ ìṣẹ́ kun",
    "Company Address": "Àdírẹ́sì ilé-iṣẹ́",
    "Company Email": "Ìméèlì ilé-iṣẹ́",
    "Company Phone No": "Nọ́mbà fóònù ilé-iṣẹ́",
    "Total Referrals": "Àpapọ̀ ìtọ́kasí",
    "My Earnings": "Owó Tí Mo Jẹ́",
    "Your Referral ID": "Nọ́mbà Ìtọ́kasí Rẹ",
    "Share Your Unique Referral Link": "Pín Àsopọ̀ Ìtọ́kasí Aláìlẹ́gbẹ́ Rẹ",
    "How do I Refer a Friend to InstaPay?": "Báwo ni mo ṣe lè ṣe ìtọ́kasí ọ̀rẹ́ kan sí InstaPay?",
    "Step 01": "Igbese 01",
    "Share your InstaPay unique referral link with your friends.": "Pín àsopọ̀ ìtọ́kasí aláìlẹ́gbẹ́ InstaPay rẹ pẹ̀lú àwọn ọ̀rẹ́ rẹ.",
    "Step 02": "Igbese 02",
    "Earn rewards when they transact $100 or more": "Jẹ́ èrè nígbà tí wọ́n bá ṣe ìgbòwó $100 tàbí ju bẹ́ẹ̀ lọ",
    "Step 03": "Igbese 03",
    "Earn up to $0.06 for every transaction your followers make.": "Jẹ́ èrè tó tó $0.06 fún ìgbòwó kọ̀ọ̀kan tí àwọn ọmọ-ẹgbẹ́ rẹ bá ṣe.",
    "My Referrals": "Àwọn Ìtọ́kasí Mi",
    "Referral Complete": "Ìtọ́kasí Tí Parí",
    "Received Quotations": "Àwọn Ìsọtẹ́lẹ̀ Tí A Gba",
    "Sent Quotations": "Àwọn Ìsọtẹ́lẹ̀ Tí A Rán",
    "Search Pending Items": "Ṣe Àwárí Ohun Tí ń Dúró Dè",
    "Actions": "Ìgbésẹ̀",
    "Received Requests": "Àwọn Ìbéèrè Tí A Gba",
    "Sent Requests": "Àwọn Ìbéèrè Tí A Rán",
    "No Actions": "Kò sí Ìgbésẹ̀",
    "You Requested": "Ìbéèrè Rẹ",
    "Via": "Nípasẹ̀",
    "How was your experience?": "Báwo ni ìrírí rẹ ṣe rí?",
    "Leave a Review": "Fi Àtúnyẹwò Sílẹ̀",
    "Overall Ratings": "Ìdíyelé Àpapọ̀",
    "Visit Profile": "Bẹ Wo Profaili",
    "Ratings": "Ìdíyelé",
    "Reviews": "Àwọn Àtúnyẹwò",
    "No reviews available": "Kò sí àwọn àtúnyẹwò",
    "Select Your Currency": "Yan Owó Rẹ",
    "Proceed": "Tẹ̀síwájú",
    "Commision": "Kọmíṣánì",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Ṣí Ìgbòwó Láìlórúkọ pẹ̀lú InstaPay QR Code Sticker",
    "Effortless and Swift:": "Láìṣòro àti Yáráyárá:",
    "Instantly download your unique InstaPay QR code sticker.": "Lọ̀ ọ́ gba àmì QR InstaPay aláìlẹ́gbẹ́ rẹ lẹ́sẹ̀kẹsẹ̀.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Ṣe àwọn ìgbòwó láìsí owó-ọjà láìsí nílò ètò ìsẹ̀dáwó gẹ́gẹ́ bíi àwọn ẹ̀rọ ìsanwó.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Gba owó yáráyárá - kò sí nílò ìtẹ̀síwájú káàdì krẹ́dítì tàbí àwọn nọ́mbà fóònù mọ́.",
    "Each payment includes customer information for your convenience.": "Ọ̀kọ̀ọ̀kan ìgbòwó náà ni àlàyé alábára fún ìrọ̀rùn rẹ.",
    "Economically Smart:": "Ọ̀nà Òye Òǹjẹ́:",
    "Zero activation fees – start immediately without upfront costs.": "Kò sí owó ìbẹ̀rẹ̀ – bẹ̀rẹ̀ lẹ́sẹ̀kẹsẹ̀ láìsí owó ìkọ́kọ́.",
    "Forget about fixed monthly charges; pay as you transact.": "Gbagbe nípa owó ìdíyelé oṣù tó tọ́; sanwó bí o ṣe ń ṣe ìgbòwó.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Fi ohun èlò sílẹ̀; kò sí owó èlò ètò ìsanwó túmọ̀ sí ìfipamọ́ owó sí fún ọ.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Gbadùn ìdíyelé ìgbòwó kéré tó jẹ́ 0.75% fún ìgbòwó QR kọ̀ọ̀kan.",
    "We don't impose minimum commissions; you save more with each transaction.": "A kò gbé owó kọ́míṣánì tó kéré jùlọ; o máa fipamọ́ síwájú pẹ̀lú ìgbòwó kọ̀ọ̀kan.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Ṣe ìrírí ayọ̀ ìgbòwó lẹ́sẹ̀kẹsẹ̀, tí yóò ṣe àtìlẹ́yìn fún ṣíṣàn owó rẹ.",
    "Make Every Transaction Count with InstaPay": "Ṣe Kí Ìgbòwó Kọ̀ọ̀kan Kà Nípa InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Gba QR",
    "QR Code Status": "Ìpo QR Code",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Pín QR Code rẹ tàbí àsopọ̀ àdírẹ́sì ìsanwó láti gba ìgbòwó lẹ́sẹ̀kẹsẹ̀ sí àpamọ́ rẹ ní InstaPay.",
    "QR Code Title": "Àkọlé QR Code",
    "View Transactions": "Wo Àwọn Ìgbòwó",
    "My QR Codes:": "Àwọn QR Codes Mi:",
    "Load More": "Fi Diẹ̀ Síi",
    "Benefits Of Portfolio": "Àwọn Anfàní Portfolio",
    "Benefits:": "Àwọn Anfàní:",
    "Enhanced Visibility:": "Híhún Àwòkọ̀ṣe:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Gbé ìgbéraga rẹ sókè gẹ́gẹ́ bíi ẹni gbajúmọ̀. Portfolio rẹ jẹ́ àfihàn aláìléwu, tó wà lára taara láti àdírẹ́sì ìsanwó rẹ.\n",
    "Engage Your Audience:": "Ṣe ìfọwọ́sọwọ́pọ̀ pẹ̀lú Àwùjọ Rẹ:\n",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Ṣe ìbáṣepọ̀ pẹ̀lú àwọn àlejò nípa ìrìn-àjò ọjọ́gbọ́n tàbí ẹ̀dá rẹ. Jẹ́ kí iṣẹ́ rẹ sọ̀rọ̀ fún ọ, èyí yóò fa ìfẹ́ sí i ní òdò àwọn alábára tàbí àwọn \nolùàtìlẹ́yìn ti o ṣeéṣe.",
    "Integrated Social Proof:": "Ìmúdájú Àwùjọ:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Fi ìwúrí àwùjọ rẹ hàn. Àwọn aléjò lè rí ìtànran àwùjọ rẹ, tí ó ń ṣe àfikún ìgbàgbọ́ àti ìtumọ̀ sí iṣẹ́ rẹ.",
    "Seamless Transactions:": "Àwọn Ìṣòwò Aláìléwu:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Gba àwọn aléjò láàyè láti béèrè tàbí bẹ̀rẹ̀ ìṣòwò lórí ojú-òpó rẹ. Àpèjúwe rẹ kò ní fi iṣẹ́ rẹ hàn nìkan, ṣùgbọ́n ó tún rọrùn fún ìbáṣepọ̀ ìṣòwò.",
    "Personalized Storytelling:": "Ìtàn Tí A Ṣe Àdáni:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Lò abala 'Nípa Èmi' láti pin ìtàn, ìṣẹ̀ṣe tàbí èrò rẹ, tí ó ń ṣe àmúlò ìbáṣepọ̀ pẹlú àwùjọ rẹ.\n",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Àpèjúwe Mi' kì í ṣe fọ́tò àgbéléwò nìkan; ó jẹ́ òhun èlò láti kọ́ àmì rẹ, láti jẹ́ kí àwùjọ rẹ dùn ún, àti láti ṣe àwọn ìbáṣepọ̀ ìṣòwò rẹ lórí InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Fi Iṣẹ́ Rẹ Hàn, Fa Ìtànran Rẹ Síi. 'Àpèjúwe Mi' ni àgbéléwò ìlọ́nà oní nọmba rẹ lórí InstaPay. Gbé àti fi àwọn àkóónú oríṣiríṣi hàn – láti àwòrán \nìfẹnukò tó dùn ún àti àwọn fídíò sí àwọn PDF aláyè. Bóyá o jẹ́ olórin, olóyè tí kò níṣẹ́ títọ́, tàbí oníṣòwò, ẹ̀yà ara yìí gba ọ láàyè láti fi iṣẹ́ tàbí \niṣẹ́ akanṣe rẹ hàn ní ọ̀nà tí yóò dùn ún fún ojú.",
    "Add/Manage Portfolio": "Fi/Kọ́ Àpèjúwe Rẹ Sílẹ̀",
    "Add New": "Fi Tuntun Kun",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Fikun Òwò Rẹ Lórí Àwùjọ Àwùjọ Pẹ̀lú Àdírẹ́sì Ìṣòwò InstaPay Rẹ",
    "Make It Effortless for Your Audience to Support You:": "Ṣe Kí Ó Rọrùn Fún Àwùjọ Rẹ Láti Ṣe Àtìlẹ́yìn Fún Ọ:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Laísí ìdààmú, dápọ̀ mọ́ àdírẹ́sì ìṣòwò InstaPay rẹ sínú àwọn ìfiranṣẹ́ àwùjọ àwùjọ rẹ.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Laísí ìdààmú, dáàkọ àti lẹ mọ́ ònà àdírẹ́sì ìṣòwò rẹ sínú gbogbo ìfiranṣẹ́, ìtàn, tàbí ní ìpilẹ̀ṣẹ̀ lórí ojú-òpó rẹ.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "\"Yíyàra fún àwọn olóòtú àwùjọ àwùjọ, olórin, oníṣòwò, tàbí ẹnikẹ́ni tí ó ní àwùjọ tí wọ́n fẹ́ láti fi àtìlẹ́yìn wọn hàn.",
    "Instant Support, Endless Possibilities:": "Àtìlẹ́yìn Láìpẹ́, Àwọn Àǹfààní Tí Kò Lópin:",
    "Transform the way you monetize your social media content.": "Yí ọ̀nà tí o tètè jẹ́rìí sí àwọn àkóónú àwùjọ àwùjọ rẹ padà.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Gba àwọn olùgbójú rẹ lààyè láti rán ọwó sí ọ láìpẹ́ pẹ̀lú kan nìkan, tààrà láti àwùjọ àwùjọ ayanfẹ́ wọn.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Yíyàra fún àtìlẹ́yìn gígùn nígbàtí o bá ń sọ fídíò lórí ètò, gẹ́gẹ́ bí eto ìdásí fún àkóónú ẹ̀dá rẹ, tàbí fún ìfúnní fún iṣẹ́ ńlá rẹ tó ń bọ̀.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Ibi Tí O Lè Fi Àdírẹ́sì Ìṣòwò InstaPay Rẹ Sí Fún Ìtànran Tó Pọ̀jù:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Àwòrán Àwùjọ Àwùjọ: Mọ ọ́ mọ́ ìtàn-àyé rẹ tàbí abala nípa rẹ fún ìhàn gígùn.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Ìfiranṣẹ́ àti Ìkéde: Ṣàfikún rẹ sí àwọn ìfiranṣẹ́ ara ẹni rẹ, pàápàá àwọn tí o jẹ́ afihàn iṣẹ́ rẹ tàbí ìpè kan pàtó fún àtìlẹ́yìn.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Ìtàn àti Fídíò Kukuru: Ṣàgbékalẹ̀ rẹ nínú àwọn ìtàn ìbáṣepọ̀ tàbí fídíò kukuru tí o mú àyà ká láti gba àwọn àfikún láìpẹ́.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Fídíò Sísọ Gígùn: Pín rẹ nígbà àwọn ìgbà ìgbàlódé rẹ, kí o rọrùn fún àwọn olùwò láti ṣe àfikún ní àkókò gídí.",
    "Simple, Fast, and Secure:": "Rọrùn, Yára, àti Ààbò:",
    "No complex setup – get started in moments.": "Kò sí ìṣeto rírọrùn – bẹ̀rẹ̀ ní ìṣẹ́jú kan.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Gbadùn ààbò àti yára ìṣiṣẹ́ ìsanwó ìgbẹ́kẹ̀lé InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Gbà ìsanwó láti ọdọ ènìyàn kankan, níbi kankan, láìsí wíwà ní àkántì InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "bé Ìwúrí Rẹ Lórí Àwùjọ Àwùjọ pẹ̀lú Àdírẹ́sì Ìṣòwò InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Àdírẹ́sì Ìṣòwò Onírúurú)",
    "Payment Address Title": "Àkọlé Àdírẹ́sì Ìṣòwò",
    "Currency": "Owó",
    "Payment Address Description": "Àlàyé Àdírẹ́sì Ìṣòwò",
    "Update": "Ìmúdàgba",
    "Updating": "Nípa Mímúdàgba",
    "Total Transaction": "Ìgbàpadà Ìṣòwò",
    "Last 30 days": "Òṣù méjìlá sẹ́yìn",
    "Last 1 month": "Oṣù kan sẹ́yìn",
    "Last 1 year": "Ọdún kan sẹ́yìn",
    "Custom Date": "Ọjọ́ Àdáni",
    "Payment Methods": "Àwọn Ọ̀nà Ìṣòwò",
    "Payment Types": "Àwọn Ìrú Ìṣòwò",
    "Requested Amount": "Ìbéèrè Owó",
    "Quoted Amount": "Owó Ti a Sọ",
    "About Me": "Nípa Mi",
    "Followers": "Àwọn Olùgbójú",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "Gba Kóòdù QR",
    "Initiate A Payment": "Bẹ̀rẹ̀ Ìṣòwò",
    "Report this person": "Èsùn ẹni yìí",
    "Add now": "Fi kun báyìí",
    "Recent Reviews": "Àwọn Àtúnyẹ̀wò Láìpẹ́",
    "Reviews As Seller": "Àtúnyẹ̀wò Bí Olùtàjà",
    "Reviews As Buyer": "Àtúnyẹ̀wò Bí Onírajà",
    "Select Language": "Yan Èdè",
    "Profile Completion": "Píparí Ìtàn-àyé",
    "Profile Completed": "Ìtàn-àyé Ti Pari",
    "Basic Info": "Aláyé Ìpilẹ̀ṣẹ̀",
    "Username": "Orúkọ olùmúlò",
    "Gender": "Abọ",
    "Male": "Ọkùnrin",
    "Female": "Obìnrin",
    "-Select-Gender-": "-Yan-Abọ-",
    "Date Of Birth": "Ọjọ́ Ìbí",
    "Address": "Orúkọ àti ọjọ́ ìbí",
    "Edit Profile": "Ṣàtúnṣe Ìtàn-àyé",
    "Update Profile": "Ìmúdàgba Ìtàn-àyé",
    "Updating Profile": "Nípa Ìmúdàgba Ìtàn-àyé",
    "Complete Now": "Parí Ní Báyìí",
    "Password": "Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀",
    "Change Password": "Yí Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀",
    "New Password": "Ọ̀rọ̀ Ìgb",
    "Re-enter New Password": "Tún Kọ Ọ̀rọ̀ Ìgbànímọ̀ọ́rọ̀ Tuntun",
    "Please follow this guide for a strong password": "Ẹ jọ̀wọ́ tẹ̀lé ìtọ́sọ́nà yìí fún ọ̀rọ̀ ìgbànímọ̀ọ́rọ̀ tó lágbára",
    "Include at least one special character.": "Pẹ̀lú ò kéré ju ọ̀kan lára àwọn àmì pàtàkì.",
    "Minimum of 8 characters.": "Ò kéré ju ẹ̀wọn lẹ́tà mẹ́jọ.",
    "Include at least one number": "Pẹ̀lú ò kéré ju nọ́mbà kan",
    "Change it often for enhanced security.": "Yí i padà nígbà gbogbo fún ààbò tó pọ̀ síi.",
    "Security Questions": "Àwọn Ìbéèrè Ààbò",
    "Security Question": "Ìbéèrè Ààbò",
    "Select your question": "Yan ìbéèrè rẹ",
    "Answer To The Question": "Ìdáhùn sí Ìbéèrè",
    "Nominee": "Olùdíje",
    "Relationship with Nominee": "Ìbáṣepọ̀ pẹ̀lú Olùdíje",
    "Nominee Contact Number": "Nọ́mbà Olùbáṣepọ̀ Olùdíje",
    "Nominee Address": "Àdírẹ́sì Olùdíje",
    "Delete Nominee": "Pa Olùdíje rẹ",
    "Update Nominee": "Ìmúdàgba Olùdíje",
    "Details Updated Successfully!": "Àwọn Aláyé Ti Ìmúdàgba Ní Aṣeyọrí!",
    "Success": "Aṣeyọrí",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "O lè yan Olùdíje tí o fẹ́, tí ó bá jẹ́ pé kò jẹ́ ọmọdé, nípasẹ̀ pẹpẹ InstaPay fúnra rẹ. O mọ̀ pé kò sí nílò fún ìbúwọ́lù tó tutù láti yan olùdíje, àti pé \nìforúkọsílẹ̀ ayélujára yóò ka bíi èyí tí ó parí àti pé ó so mọ́. Nígbàtí oníhòòhò InstaPay Wallet bá kú, ìwọ̀ntúnwọ̀nsì nínú Wallet yóò jẹ́ gbe lọ sí \nOlùdíje tí a fọ́rúkọsílẹ̀. KEMIT KINGDOM yóò jẹ́ tú nínú gbogbo àwọn òfin rẹ̀ nígbà tí ó bá fi owó náà lé Olùdíje lọ́wọ́. Olùdíje yóò pèsè gbogbo \nàwọn ìwé tí InstaPay bèèrè, pẹ̀lú àwọn fún ìdánimọ̀ àti ẹ̀rí ikú.",
    "Add Nominee": "Fi Olùdíje Kun",
    "Two Factor Authentication": "Ìdánilójú Méjì",
    "SMS Number": "Nọ́mbà SMS",
    "Updated Successfully!": "Ti Ìmúdàgba Ní Aṣeyọrí!",
    "Social Network Accounts": "Àwọn Ìròyìn Àwùjọ Àwùjọ",
    "Here, you can set up and manage your integration settings.": "Níhìn-ín, o lè ṣeto àti ṣàkóso àwọn ètò ìdarapọ̀ mọ́ rẹ.",
    "Social Network Account": "Ìròyìn Àwùjọ Àwùjọ",
    "Activate Account": "Ṣíṣe Ìròyìn Náà Ṣíṣẹ",
    "Enter the code below in the InstaPay Chatbot.": "Tẹ kóòdù tó wà nísàlẹ̀ yìí sí ìnájàrá InstaPay.",
    "The code will expire in ": "Kóòdù yóò parí ní",
    "Notifications": "Àwọn Ìkìlọ̀",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Ṣe àdáni bí o ṣe ń gbà àwọn ìkìlọ̀. Àwọn ètò yìí kan sí àwọn iṣẹ́ tí o ń ṣe àyẹ̀wò.",
    "Activity": "Iṣẹ́",
    "Email": "Àdírẹ́sì",
    "Push": "Títẹ síi",
    "SMS": "SMS",
    "Source": "Irinṣẹ",
    "Payment Requests": "Ibèèrè ìdá",
    "Bulk Payments": "Díè wọlé",
    "Identity Verification": "Ìdánimọ̀",
    "Verify Your Identity": "Ṣe Ìdánimọ̀ Rẹ",
    "Identity Verification Status:": "Ìpò Ìdánimọ̀:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Kọ̀wé nípa ara rẹ kí o sì ṣàlàyé ìdí tí o fi ń lò InstaPay.",
    "Enter Your Message": "Kọ Ìfiranṣẹ́ Rẹ",
    "Select Occupation": "Yan Iṣẹ́",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "O gbọdọ̀ jẹ́ ìwé ìdánimọ̀ tí ìjọba fún ní àṣẹ, tí ó ní àwòrán. Gbogbo ìwé ìdánimọ̀ gbọdọ̀ kọ ní èdè Látìnì. Bí bẹ́ẹ̀ kọ́, ìtúmọ̀ tí a fọwọ́sí",
    "Accepted Documents": "jẹ́ dandan.",
    "A national identity card": "Àwọn Ìwé Tí A Gba",
    "A valid passport": "Káàdì Ìdánimọ̀ Orílẹ̀-èdè",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Pásípóòtù Tó Wúlò",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Àwọn Ìwé Àṣẹ Ìgbé Ilé (tí a gba nìkan bí o bá jẹ́ tí a fún ní àṣẹ ní orílẹ̀-èdè Yúróòpù tàbí ní Switzerland)",
    "Address Verification:": "Ìdánimọ̀",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Pèsè ìwé ìdánwò báńkì tàbí ìwé ìdánwò iṣẹ́ ìgbàlódé (tí ó kéré jù lọ ọṣù mẹ́ta) gẹ́gẹ́ bí ẹ̀rí àdírẹ́sì.",
    "Additional Documents:": "Àwọn Ìwé Afikún:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom ni ẹ̀tọ́ láti béèrè fún àwọn ìwé afikún (Ìwádìí Tó Pọ̀ Jùlọ - EDD) tàbí àlàyé mìíràn tí ó nílò fún ìwádìí tó jinlẹ́,",
    "Start Verification": "ní ìbámu pẹ̀lú àwọn òfin rẹ.",
    "Sessions": "Bẹ̀rẹ̀ Ìwádìí",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Àwọn Ìgbà Tí Ò Lò",
    "Your Withdrawal Options": "Èyí jẹ́ àtòjọ àwọn ẹ̀rọ tí wọ́n ti wọlé sí àkántì rẹ. Yọ àwọn tí o kò mọ̀ dàní.",
    "Choose your preferred method for withdrawing payments.": "Àwọn Àṣàyàn Yíyọ Kúrò",
    "No withdrawal options currently available in your country.": "Yan ọ̀nà tí o fẹ́ jùlọ fún yíyọ owó kúrò.",
    "Download Your Data": "Kò sí àṣàyàn yíyọ kúrò tí ó wà fún orílẹ̀-èdè rẹ lọ́wọ́lọ́wọ́.",
    "Request a Copy of Your Data": "Gba Dátà Rẹ",
    "Submit a request to download a copy of your data": "Bèèrè fún Dáàkọ Dátà Rẹ",
    "Which Data Should Be Included in the Download?": "Fí ìbéèrè sílẹ̀ láti gba dáàkọ dátà rẹ",
    "Select all applicable options.": "Irú Dátà Wo Ni O Yẹ Kí A Pẹ̀lú nínú Gbigba?",
    "Personal Information": "Yan gbogbo àṣàyàn tí ó yẹ.",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Aláyé Ti Ará ẹni",
    "Name and Date Of Birth": "A lò àlàyé yìí láti dáàbò bò àkántì rẹ, láti ṣe àdáni ìrírí rẹ, àti láti kan sí ọ nígbà tí ó bá yẹ.",
    "Phone number": "Ìmélì",
    "Financial Information": "Nọ́mbà fóònù",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Aláyé Òwò",
    "Bank accounts": "A lò àlàyé yìí láti jẹ́ kí ìsanwó yára àti láti rán tàbí gbà owó ní ìṣẹ́jú kan.",
    "Debit or credit cards": "Àwọn ìròyìn báńkì",
    "Credit Information": "Àwọn káàdì déb",
    "Includes your credit application information": "Pẹ̀lú àlàyé lórí ìbéèrè fún kírédítì rẹ",
    "Other Information (not included in file)": "Aláyé Mìíràn (tí kò sí nínú fáìlì)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Pẹ̀lú àlàyé lórí ẹ̀rọ rẹ, dátà lílo ìmọ̀ ẹ̀rọ, àlàyé lórí ìpò gẹ́ọ̀gráfíkì, àwọn ìfẹ́ sí títà, ìtàn ìfọwọ́sí, àti dátà tí a lò fún àwọn iṣẹ́ mìíràn bíi kírédítì,\nìdánimọ̀, ìbáṣepọ̀ pẹ̀lú PayPal, àti àwọn alágbàṣe ẹgbẹ́ kẹta.",
    "Choose file type:": "Yan ìrú fáìlì:",
    "Select file type": "Yan ìrú fáìlì",
    "PDF file can be easily opened on any computer": "Fáìlì PDF lè ṣí ní rọọrùn lórí kọ̀mpútà kankan",
    "Submit Request": "Fí ìbéèrè sílẹ̀",
    "Delete Your Account": "Pa Àkántì Rẹ",
    "Ask us to delete your data and close this account": "Béèrè fún wa láti pa dátà rẹ àti láti pa àkántì yìí",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "A lò dátà rẹ láti pèsè iṣẹ́, nítorí náà bí a bá pa dátà rẹ, a gbọdọ̀ pa àkántì rẹ.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Bí àkántì rẹ bá ní ìdíwò, lọ sí Ìbéèrè Ìyọkúrò, yan 'Lọ sí Ìdíwò Àkántì', lẹ́yìn náà tẹ 'Yanjú'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Ṣe ìdánilójú pé gbogbo ìsanwó tó ṣí kù ti jẹ́ sán. A kò ní lè pa àkántì rẹ títí tí wọn ò bá sán.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Bí o bá ní gbèsè odi, tẹ ọ̀nà Yanjú tó wà ní ẹgbẹ́ gbèsè rẹ.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Lẹ́yìn tí àkántì rẹ di mímọ́, àti bí o bá yẹ, a ó pa àkántì rẹ àti pa dátà rẹ. A nílò láti tọ́jú díẹ̀ nínú dátà rẹ fún àwọn ìdí òfin àti ìlànà. Láti mọ̀ síwájú síi, wo ",
    "privacy statement": "ìpolongo ìpamọ́",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "A ó fi ìmélì ránṣẹ́ sí ọ láti jẹ́ kí o mọ ìpò rẹ, àti bí àwọn ìgbésẹ̀ mìíràn bá wà tí o ní láti gbé.",
    "Have more questions about data deletion?": "Ṣé o ní ìbéèrè síwájú síi nípa ìparẹ́ dátà?",
    "Contact us": "Kàn sí wa",
    "I understand that account deletion is permanent and can't be reversed.": "Mo mọ̀ pé ìparẹ́ àkántì jẹ́ pípẹ́ àti pé kò lè yípadà.",
    "Are you sure you want to close your account?": "Ṣé o dájú pé o fẹ́ pa àkántì rẹ?",
    "Yes, Continue": "Bẹ́ẹ̀, Tẹ̀síwájú",
    "Logging In": "Wíwọlé",
    "Complete your profile to streamline your transaction process.": "Pári ìtàn-àyé rẹ láti jẹ́ kí ìṣòwò rẹ rọrùn.",
    "Skip": "Fòyà",
    "Complete Your Profile": "Pári Ìtàn-àyé Rẹ",
    "API Key": "Bọ́tìnì API",
    "Get API Key": "Gba Bọ́tìnì API",
    "Generating New API Key": "Nípa Ṣíṣe Bọ́tìnì API Tuntun",
    "Generate API Key": "Ṣe Bọ́tìnì API",
    "Regenerate API Key": "Tún Ṣe Bọ́tìnì API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Láti lò gbogbo àǹfààní ìṣòwò aláìléwu InstaPay, tẹ̀lé àwọn ìgbésẹ̀ yìí láti ṣe àgbékalẹ̀ bọ́tìnì API rẹ. Àkọ́kọ́, forúkọsílẹ̀ fún àkántì kan ní [URL ìforúkọsílẹ̀ rẹ] \nkí o sì wọlé sí Dáṣúbọ́ọ̀dù Olùṣèdá. Lọ sí apá 'Bọ́tìnì API', ṣe àgbékalẹ̀ bọ́tìnì tuntun, kí o sì ríi dájú pé o tọ́jú rẹ ní aàbò.",
    "Delete API Key": "Pa Bọ́tìnì API",
    "Webhook URL": "URL Webhook",
    "KYB Verification": "Ìwádìí KYB",
    "Additional Files": "Àwọn Fáìlì Afikún",
    "Business Verification": "Ìwádìí Òwò",
    "--Select-Your-Company-Type--": "--Yan-Iru-Ìlé-iṣẹ́-Rẹ--",
    "Documents Required:": "Àwọn Ìwé Tí A Nílò:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Ìwé Ìforúkọsílẹ̀ Ìlé-iṣẹ́ Osíṣẹ́ (Ìwé Ìforúkọsílẹ̀/Ìdásílẹ̀ Àjọ)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Ìwé yìí ń ṣiṣẹ́ bí káàdì ìdánimọ̀ osíṣẹ́ fún ìlé-iṣẹ́ àti pé ó yẹ kí ó wá láti inú ìforúkọsílẹ̀ òwò agbègbè. Ó ń fi hàn ìpò lọ́wọ́lọ́wọ́ ti òwò\n(tí ó ń ṣiṣẹ́ tàbí tí ó ti pa).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Ìwé yẹ kí ó jẹ́ tuntun, pẹ̀lú ọjọ́ tí wọ́n fún ní àṣẹ tí kò ju oṣù mẹ́ta lọ.",
    "The document must include the following details:": "Ìwé náà gbọdọ̀ pẹ̀lú àwọn aláyé wọ̀nyí:",
    "Legal name of the company": "Orúkọ osíṣẹ́ ti ìlé-iṣẹ́",
    "Full registered address": "Àdírẹ́ẹ̀sì ìforúkọsílẹ̀ kíkún",
    "Company registration number": "Nọ́mbà ìforúkọsílẹ̀ ìlé-iṣẹ́",
    "Date of incorporation": "Ọjọ́ ìdásílẹ̀",
    "Description of the company's activities/purpose": "Àlàyé lórí iṣẹ́/ìdí ti ìlé-iṣẹ́",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Aláyé àṣojú osíṣẹ́: orúkọ pátápátá, ọjọ́ àti ibi ìbí, àdírẹ́sì ibùgbé (ìbéèrè tó kéré jùlọ: orílẹ̀-èdè ibùgbé), àti orílẹ̀-èdè",
    "If applicable, the document should also include:": "Bí ó bá yẹ, ìwé náà lè tún ní:",
    "Brand or trading names": "Orúkọ brándì tàbí orúkọ ìṣòwò",
    "Value Added Tax (VAT) number": "Nọ́mbà Owo-ori Àfikún (VAT)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Àtòjọ Àwọn Olùjẹ Anfàní Ìkẹyìn (UBOs) - èyí jẹ́ dandan ní àwọn orílẹ̀-èdè kan, bíbẹẹ̀kọ́, a lè pèsè gẹ́gẹ́ bí ìwé tó yàtọ̀.",
    "Proof of Legal Representative's Authority": "Ìwé Ẹrí Ìṣòro Olóyè",
    "This document validates an individual's authority to represent the company": "Ìwé yìí fún ní ìdánilójú àṣẹ ènìyàn láti ṣojú ìlé-iṣẹ́",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Nígbà wọ́pọ̀, a máa ń darúkọ àṣojú osíṣẹ́ nínú ìwé ìforúkọsílẹ̀ ìlé-iṣẹ́. Síbẹ̀síbẹ̀, bí èyí kò bá rí bẹ́ẹ̀, a gbọdọ̀ gba ìwé bíi Ìwé Àṣẹ Agbára tàbí Ìdánilójú Agbára.",
    "Power of Attorney/Delegation of Powers:": "Ìwé Àṣẹ Agbára/Ìdánilójú Agbára:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Ẹni tí ó fún ní àṣẹ gbọdọ̀ ní ẹ̀tọ́ tí ó tọ́ síi láti ṣe bẹ́ẹ̀. Ẹni yìí yẹ kí o jẹ́ àkọsílẹ̀ gẹ́gẹ́ bí àṣojú osíṣẹ́ nínú ìwé ìforúkọsílẹ̀ ìlé-iṣẹ́.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Ìwé Àkọsílẹ̀ Ìṣẹ́jú àti Àwọn Olùjẹ Anfàní Ìkẹyìn (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Ẹ̀rí wí pé a ti ṣe àkọsílẹ̀ ìlé-iṣẹ́ ní ọjà ìṣura yẹ kí o wà. Èyí lè jẹ́ ojú-ìwé kan láti ojú-òpó ayélujára ọjà ìṣura tí ó fihàn àlàyé ìlé-iṣẹ́.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Bí o bá kéré jù lọ 75% tí àwọn mọ́lẹ́bí ìlé-iṣẹ́ jẹ́ ti ìjọba gbangba, gbogbo àwọn olùmọ́lẹ́bí tí wọn ni tàbí ṣàkóso jù lọ 25% àwọn mọ́lẹ́bí yóò jẹ́ kókó \nfún ìwádìí Ònínúgbègbè Anfàní Ìkẹyìn (UBO).",
    "Collection of Identification Documents": "Gbigba ti Awọn Iwe Èrí Ìdánimọ̀",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Ìwé ìdánimọ̀ gbọdọ̀ jẹ́ tí ìjọba fún ní àṣẹ, tí ó ní àwòrán ẹni náà. Gbogbo ìwé ìdánimọ̀ gbọdọ̀ kọ ní lẹ́tà Látìnì. Bí bẹ́ẹ̀ kọ́, a gbọdọ̀ ní ìtúmọ̀ tí a fọwọ́si.",
    "Accepted documents:": "Awọn ìwé tí a gbà:",
    "Valid National Identity Card": "Kaadi ìdánimọ̀ orílẹ̀-èdè tí ó wúlò",
    "Valid Passport": "Ìwé ìrìnnà tí ó wúlò",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Àṣẹ ìgbé gẹ́gẹ́ bí olùgbé - A ó gbà wọ́n nìkan tí a bá ti fún wọn ní àṣẹ láti orílẹ̀-èdè Yúróòpù.",
    "Only legal representatives are subject to the identification document collection process.": "Àwọn aṣojú òfin nìkan ni wọ́n jẹ́ kókó fún ìkópa nínú ìlànà ìkópa ìwé ìdánimọ̀.",
    "Additional Requirement:": "Ìbéèrè Afikún:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "Nínú àwọn ọ̀ràn tí ó ní ìṣe pẹ̀lú àṣẹ ìgbàgbọ́ (Power of Attorney) tàbí Fífún ní Agbára, àwọn méjèèjì (ẹni tí ó n fún ní àṣẹ àti ẹni tí ó n gbà á) \ngbọdọ̀ pèsè àdàkọ ìwé ìdánimọ̀ wọn.",
    "Additional Documents": "Àwọn ìwé Afikún",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Ìwé ìsanwó ìrọ̀rùn bíi ìsanwó gáàsì, iná, tẹlifóònù tàbí bílì fóònù alágbèéká; tàbí ìwé kan tí ìjọba fún ní àṣẹ tí ó fihàn orúkọ àti adírẹ́sì olùmúlò \n(bíi Ìwé Èrí Ibi Gbé); tàbí",
    "Bank Statement; or": "Statement banki; tàbí",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "ìwé kan tí a fun ní àṣẹ láti ilé iṣẹ́ ìjọba tí ó fihàn adírẹ́sì àti orúkọ olùmúlò (bíi Ìwé Èrí Ibi Gbé).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom ní ẹ̀tọ́ láti béèrè fún àwọn ìwé àfikún tàbí àlàyé míì tí ó jẹ́ dandan fún ìṣètò ìwádìí tító, gẹ́gẹ́ bí òfin ti la kalẹ̀.",
    "Note:": "Àkíyèsí:",
    "Partners": "Àwọn Alábàáṣepọ́",
    "Add New Partner": "Ṣe Afikún Alábàáṣepọ́ Tuntun",
    "Designation": "Orúkọ Ìpò",
    "Partner Name": "Orúkọ Alábàáṣepọ́",
    "Partner Email": "Ìméèlì Alábàáṣepọ́",
    "Partner Designation": "Orúkọ Ìpò Alábàáṣepọ́",
    "Add Now": "Ṣe Afikún Báyìí",
    "Adding": "Nípa Ṣíṣe Afikún",
    "Delete Partner": "Pa Alábàáṣepọ́ rẹ̀",
    "Delete": "Pípá",
    "Deleting": "Nípa Pípá",
    "Are you sure, you want to remove sxz as partner?": "Ṣé o dájú pé o fẹ́ yọ sxz kúrò bíi alábàáṣepọ́?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Ìwé Àfihàn Ìforúkọsílẹ̀ Ajọṣepọ́ (Ìwé Ìforúkọsílẹ̀/Ìdásílẹ̀ Ajọṣepọ́)",
    "This document should be either:": "Ìwé yìí gbọdọ̀ jẹ́:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Ẹ̀rí ìdásílẹ̀ ajọṣepọ́, tí ó n pèsè àlàyé nípa àwọn aláyé ajọṣepọ́, ọjọ́ ìdásílẹ̀ àti ìdí rẹ̀.",
    "A local registry extract:": "Àfihàn ìforúkọsílẹ̀ agbègbè:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Ó jẹ́ dandan gẹ́gẹ́ bí òfin orílẹ̀-èdè náà ṣe sọ, tí ó jẹ́ káàdì ìdánimọ̀ lọ́wọ́lọ́wọ́ fún ajọṣepọ́, tí ó n fihàn ipò rẹ̀ (tí ó ṣíṣẹ́ tàbí tí ó ti parí).",
    "If a registry extract exists, it must not be older than 3 months.": "Tí àfihàn ìforúkọsílẹ̀ agbègbè bá wà, kò gbọdọ̀ ti pẹ́ ju oṣù mẹ́ta lọ.",
    "Entity name": "Orúkọ ajọṣepọ́",
    "Registration number (if applicable, based on the country's requirements)": "Nọ́mbà ìforúkọsílẹ̀ (tí ó bá yẹ, da lórí ìbéèrè orílẹ̀-èdè náà)",
    "Date of creation": "Ọjọ́ ìdásílẹ̀",
    "Description of the entity's activities or purpose": "Alaye àwọn iṣẹ́ tàbí ìdí pàtàkì ajọṣepọ́ náà",
    "Trading names": "Orúkọ ìṣòwò",
    "Articles of Association": "Àwọn Àtúnṣe Àpilẹ̀kọ",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Àwọn Àtúnṣe Àpilẹ̀kọ jẹ́ ìwé ìlànà ajọṣepọ́ náà, tí ó ṣàlàyé àwọn òfin ìṣiṣẹ́ rẹ̀.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Kò sí àkókò àkókò lórí ìwé yìí, èyí túmọ̀ sí pé àwọn ẹni tó jẹ́ títúnṣe jùlọ ni a ń béèrè fún. Lára àwọn ilé-iṣẹ́ báyìí, wọ́n máa ń ní ìpàdé ọdọọdún kan tó lè mú \nàtúnṣe tuntun wá sí àwọn Àtúnṣe Àpilẹ̀kọ.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Àwọn àpẹẹrẹ àwọn àyípadà tí ó lè wáyé ni àwọn ìmúdójúìwọn sí orúkọ ilé-iṣẹ́, àdírẹ̀ẹ̀sì, èrò, ìgbìmọ̀ ìṣàkóso, ipa àti òjúṣe, àti bẹ́ẹ̀ bẹ́ẹ̀ lọ.",
    "The document should contain information about:": "Ìwé náà gbọdọ̀ ní àlàyé nípa:",
    "The entity itself, including its legal name, registered address, and purpose": "Ilé-iṣẹ́ náà fúnra rẹ̀, pẹ̀lú orúkọ òfin rẹ̀, àdírẹ̀ẹ̀sì fọ́ọ̀mù àti èrò",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Àwọn òfin ìṣiṣẹ́ inú rẹ̀, gẹ́gẹ́ bí ìlànà ìyànṣẹ́lódì fún àwọn ọmọ ẹgbẹ́ ìgbìmọ̀ ìṣàkóso, ipa wọn àti agbára wọn, láàárín àwọn àlàyé mìíràn. Àkójọpọ̀ yìí kò tíì pé.",
    "How the entity is funded": "Bí wọ́n ṣe ń ṣe àtìlẹ́yìn owó fún ilé-iṣẹ́ náà",
    "Appointment/Removal/Changes on Board of Management Members:": "Ìyànṣẹ́lódì/Ìyọkúrò/Àyípadà lórí Ìgbìmọ̀ Ìṣàkóso:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Àwọn ìmúdójúìwọn wọ̀nyí máa ń wáyé nítorí àwọn àníyàn ilé-iṣẹ́ tàbí bí ó ti ṣe kọ sí nínú Àwọn Ìwé Ìdásílẹ̀. Àwọn àyípadà náà ni a máa ń ṣe àkọsílẹ̀ nínú \nìṣẹ́jú ìpàdé ìgbìmọ̀ ìṣàkóso, tí a sì ti forúkọ sílẹ̀ àti fọwọ́sí láti fi hàn àtúnyẹwò àwọn ọmọ ẹgbẹ́ tuntun, bíi ààrẹ, ìgbákejì ààrẹ, olùṣàkóso owó, àti àkówe \ngbogbogbò.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Ìṣẹ́jú ìpàdé ìgbìmọ̀ ìṣàkóso tó gbẹ̀yìn, pẹ̀lú àtòkọ àwọn ọmọ ẹgbẹ́ lọ́wọ́lọ́wọ́, yóò nílò láti fi kún Àwọn Ìwé Ìdásílẹ̀, tí ó bá wà.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Àkíyèsí: Tí àtòkọ àwọn ọmọ ẹgbẹ́ ìgbìmọ̀ ìṣàkóso lọ́wọ́lọ́wọ́ kò bá wà nínú Àwọn Ìwé Ìdásílẹ̀ tó gbẹ̀yìn àti pé kò sí ìṣẹ́jú ìpàdé ìgbìmọ̀, a gbọdọ̀ pèsè àtòkọ yìí. \nO gbọdọ̀ ní ọjọ́ àti ìbúwọ́lù ẹni tó jẹ́ aṣojú òfin tí a ṣẹ̀ṣẹ̀ yàn, èyí tí a lè jẹ́rìí rẹ̀ nípasẹ̀ àwọn ìwé àjẹmọ́.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Ìwé yìí fi hàn pé ẹni kan ní àṣẹ láti ṣòro fún àjọ náà. Ìwé yìí le jẹ:",
    "The latest Articles of Association": "Àwọn Ìwé Ìṣètò Ilé-iṣẹ́ tó ṣẹ̀ṣẹ̀ jùlọ",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Àtòkọ àwọn ọmọ ẹgbẹ́ ìgbìmọ̀ ìṣàkóso lọ́wọ́lọ́wọ́ (eyí tí ó le wà nínú ìwé ìpàdé ìgbìmọ̀ ìṣàkóso)",
    "The document should specify:": "Ìwé yìí yẹ kí o sọ:",
    "Full name of the individual(s)": "Orúkọ kíkún ti ẹni náà",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Iṣẹ́ ti ẹni náà ń ṣe. A ó fi ìwé ìṣètò ilé-iṣẹ́ náà ṣe àyẹ̀wò àlàyé yìí láti ríi dájú pé iṣẹ́ ti ẹni náà ń ṣe lè fún un láṣẹ láti sojú fún àjọ náà.\nEyi pàtàkì gan-an nígbà tí ẹni náà kò jẹ́ olórí, àti pé àwọn iṣẹ́ míì ní àlàyé láti ṣojú fún àjọ náà pẹ̀lú àwọn ààlà tó ní bí ó ṣe wà nínú àwọn ìwé ìṣètò ilé-iṣẹ́ náà.",
    "Additional information about the legal representative that will be collected includes:": "Aláyé àfikún nípa aṣojú òfin tí a máa gba pẹ̀lú ni:",
    "Date and place of birth": "Ọjọ́ àti ibi ìbí",
    "Residential address (at least the country of residence should be stated)": "Adírẹ́ẹ̀sì ibùgbé (ó yẹ kí o tọ́ka sí orílẹ̀-èdè tí wọ́n ń gbé)",
    "Nationality": "Orílẹ̀-èdè",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Ètò ìní àti Àwọn Olówó Ìpìlẹ̀ Ànfàní (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Ni igbagbogbo, awọn iru awọn ajọ yii, ti a ba wo ilana ati idi wọn, kii ni awọn oniwun anfani. Sibẹsibẹ, a o ṣe idanimọ awọn ọmọ ẹgbẹ igbimọ ki a si tọju wọn \nbii ẹni pe wọn jẹ awọn oniwun anfani fun idi ti ayẹwo, gbigba ati ijẹrisi ID, ati bẹbẹ lọ.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Diẹ ninu awọn ajọ le ni awọn oniwun anfani, paapaa awọn ti n ṣe èrè. Ni iru awọn ipo bẹẹ, a o sọ alaye yii ninu Awọn Ofin Ajọ. Awọn eniyan wọnyi ni a le \nrọrun idanimọ wọn ki a si tọju wọn bii bẹẹ, papọ pẹlu awọn ọmọ ẹgbẹ igbimọ isakoso.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Àfikún Ìbéèrè: Ní àwọn ọ̀ràn tí ó níi ṣe pẹ̀lú Ìfọwọ́sí Agbára tàbí Ìyànsípò Agbára, àwọn ẹgbẹ́ méjèèjì (ẹni tí ó ń fún ni agbára àti ẹni tí ó ń gba) gbọdọ̀ \ngbé dá ìwé ìdánimọ̀ wọn kalẹ̀.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Ìwé Ìforúkọsílẹ̀ Olùṣòwò Àdánidá (Ìwé Ìforúkọsílẹ̀/Ìdásílẹ̀ Ajọ)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Ìwé yìí jẹ́ ìforúkọsílẹ̀ àgbáyé fún àwọn olùṣòwò àdánidá, tí a gbékalẹ̀ ní àwọn orílẹ̀-èdè tí irú ìforúkọsílẹ̀ yìí jẹ́ dandan. Ó jẹ́rìí sí pé ènìyàn náà jẹ́ olùṣòwò \nàdánidá ní ìṣẹ́-ọnà. Orúkọ ìwé yìí lè yàtọ̀ láti orílẹ̀-èdè kan sí èkejì.",
    "Where applicable, the document should not be older than 3 months.": "Níbi tí ó bá yẹ, ìwé náà kò gbọdọ̀ ju ọṣù mẹ́ta lọ.",
    "The document must contain:": "Ìwé náà gbọdọ̀ ní:",
    "Individual's name": "Orúkọ ènìyàn náà",
    "Residential address": "Àdírẹ́sì ibùgbé",
    "Registration number": "Nọ́mbà ìforúkọsílẹ̀",
    "The sole trader is the only individual subject to the identification document collection process.": "Oníṣòwò adánilọ́kọ ni ẹni kàn ṣoṣo tí a béèrè fún ìwé ìdánimọ̀.",
    "No Transactions": "Kò sí Ìgbòwó",
    "No Pending Items": "Kò sí Ohun Tí ń Dúró Dè",
    "No Beneficiaries": "Kò sí Àwọn Olùrànlọ́wọ́",
    "Send Money To": "Rán Owó Sí",
    "Confirmation": "Ìdánilójú",
    "You are setting": "O ń ṣètò",
    "as default.": "gẹ́gẹ́ bí ìlànà àkọ́kọ́.",
    "Previously": "Tẹ́lẹ̀",
    "was default QR.": "ni ìlànà àkọ́kọ́ QR.",
    "Category": "Ẹ̀ka",
    "-Select-From-The-List-": "-Yan-Láti-Inú-Atọ́ka-",
    "Add a note": "Fi Àkíyèsí kun",
    "Categories Added Successfully!": "Àwọn Ẹ̀ka Tí A Fi Kun Láṣeyọrí!",
    "Subscription starting date": "Ọjọ́ ìbẹ̀rẹ̀ ìforúkọsílẹ̀",
    "Until": "Títí",
    "Next Transaction": "Ìgbòwó Tókàn",
    "Transaction Method": "Ọ̀nà Ìgbòwó",
    "Accept the quotation": "Gba ìsọtẹ́lẹ̀ owó",
    "Choose Currency": "Yan Owó",
    "Accept": "Gba",
    "Bargain the quotation": "Jíròrò ìsọtẹ́lẹ̀ owó",
    "Enter Counter Amount:": "Tẹ Iye Kántá:",
    "Bargain": "Jíròrò",
    "Decline the quotation": "Fagilé ìsọtẹ́lẹ̀ owó",
    "Are you sure, you want to decline this quotation?": "Ṣé ó dá ọ lójú pé o fẹ́ kọ̀ ìsọtẹ́lẹ̀ owó yìí sílẹ̀?",
    "Decline": "Kọ̀",
    "Personal Account": "Àkọọ́lẹ́ Tìẹ̀",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Eib",
    "May": "Mei",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Ọ̀ṣù",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "Today": "Ní òní",
    "Spent In": "Pẹ̀lú ni",
    "Revised Amount": "ẹ̀bá fẹẹ̀ rẹ",
    "You spent more comparing to last month": "Ẹ ti wọle lórí ẹ̀rọ̀ kẹtẹ",
    "Last": "Kọọkan",
    "days": "Ọjọ",
    "Year": "Odu",
    "Instant Mobile Top-up Across 150+ Countries": "Ifọwọkan Foonu Alagbeka Naa Ni Ori Ilẹ-aye Ju Ọgọrun Mẹtalelọgbọn Orilẹ-ede Lọ",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Mu ayọ ba awọn ayanfẹ rẹ tabi tun foonu ara rẹ kun, laibikita ibi ti o wa! Iṣẹ Ifọwọkan Alagbeka InstaPay ngbanilaaye rẹ lati kun awọn foonu alagbeka lẹsẹkẹsẹ ni ju ọgọrun mẹtalelọgbọn orilẹ-ede lọ. O dara fun ẹbun tabi lati rii daju pe o nigbagbogbo ni asopọ, a ṣe apẹrẹ iṣẹ wa fun irọrun, iyara, ati aabo.",
    "How It Works - Step-by-Step Guide:": "Bii O ṣe n ṣiṣẹ - Itọnisọna Igbesẹ nipasẹ Igbesẹ:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Yan 'Ifọwọkan Alagbeka': Wọle si ẹya yii taara laarin ohun elo InstaPay lori awọn iru ẹrọ bii WhatsApp, Twitter, Telegram, tabi Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Yan Owo ati Iye: Yan lati ọpọlọpọ awọn owo nina (USD, GBP, EUR, bbl) ki o si pato iye ti o fẹ kun.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Tẹ Nọmba Alagbeka sii: Kọ nọmba ti o fẹ ṣe ifọwọkan—ti ara rẹ tabi ti olufẹ kan. Eto wa ṣe onigbọwọ ilana ailewu ati yarayara.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Jẹrisi ati Sanwo: Ṣayẹwo awọn alaye ifọwọkan lẹẹmeji, jẹrisi, ki o si sanwo nipa lilo ọna ti o fẹran. Ilana iṣowo naa waye lẹsẹkẹsẹ ati ni aabo.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Gba Ijerisi Lẹsẹkẹsẹ: Iwọ ati olugba naa (ti o ba yẹ) yoo gba ifitonileti ijerisi lẹhin ti ifọwọkan ba ṣaṣeyọri.",
    "Benefits:": "Awọn Anfani:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Agbara Agbaye: Firanṣẹ akoko airi ni irọrun si ju ọgọrun mẹtalelọgbọn orilẹ-ede lọ.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Ipa Lẹsẹkẹsẹ: Wo awọn anfani lẹsẹkẹsẹ—pipe fun awọn ẹbun iṣẹju to kẹhin tabi awọn ibaraẹnisọrọ pajawiri.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Aabo Kikun: Gbadun alaafia ọkan pẹlu eto isanwo wa ti o ni aabo.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Awọn Aṣayan Isanwo Oniruuru: Yan lati awọn kaadi kirẹditi, PayPal, tabi apamọwọ InstaPay rẹ fun awọn sisanwo.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Ṣetan lati tan ayọ tabi lati wa ni asopọ? Kun bayi ki o ṣe ayọ ọjọ ẹnikan—tabi tirẹ!",
    "Pick a language": "Yan ede kan",
    "French": "Faransé",
    "Spanish": "Espani",
    "Portuguese": "Pọtogí",
    "German": "Jámánì",
    "Ukrainian": "Ukranian",
    "Italian": "Itali",
    "Russian": "Rọ́ṣíà",
    "Arabic": "Arábìkì",
    "Polish": "Pólándì",
    "Dutch (Nederlands)": "Nídálándì",
    "Yoruba": "Yorùbá",
    "Indonesian": "Indonésíà",
    "Turkish": "Túrkì",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinese",
    "Select Your Preferred Language": "Yan Ẹ̀dá Àyẹ́yẹ Rẹ",
    "Please select the language you would \nlike to use for the eKYC process.": "Jọwọ yan èdè tí o fẹ́ lo fún ìlànà eKYC.",
    "Select Your Nationality": "Yan Ìdámọ̀ Orílẹ̀-èdè Rẹ",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Yan orílẹ̀-èdè rẹ láti rí àwọn ìwé ìdánimọ̀ tí a ṣe é gbé kalẹ̀ fún orílẹ̀-èdè rẹ.",
    "Grant permission to use the camera.": "Fun ìyàláàmú láti lo kámérà.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tẹ̀tàbí tẹ \"Fúnàṣẹ̀\" nígbàtí ìbéèrè bá farahàn láti fúnni ní ìyàláàmú fún ìwọlé kámérà láti gbé ìwé náà.",
    "Upload Your Documents from \nGallery/Photos": "Ìkọ́lẹ̀ àwọn Ìwé Rẹ láti inú Ìkànsí/Àwọn Awòrán",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Tí o bá ní ìṣòro pẹ̀lú kámérà rẹ tàbí tí ètò rẹ kò bá ní ọ̀kan, o lè ìkọ́lẹ̀ àwọn fọ́tò ìwé náà láti inú ìkọ́lé ìbòmíràn rẹ.",
    "Upload Your ID.": "Gbe ID rẹ sii.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Ṣe idaniloju pe ID rẹ ko ni aiṣedeede lati rii daju idaniloju yara.",
    "Valid Proof of Address": "Ẹri Adirẹsi To Wulo",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Ṣe idaniloju pe ẹri adirẹsi rẹ wa lọwọlọwọ ati pe ọjọ ifihan ti o wa laarin oṣu mẹta to koja lati ọjọ ibẹrẹ ti idaniloju eKYC rẹ.\n\n2. Ṣe idaniloju pe ẹri adirẹsi rẹ ko ni aiṣedeede lati rii daju idaniloju yara.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Ifọrọranṣẹ Pataki\n\nJọwọ ka gbogbo igbesẹ ninu itọsọna yii ṣakiyesi ṣaaju ki o to tẹsiwaju pẹlu ayewo eKYC rẹ.\n\nYago fun aṣiṣe ti o wọpọ:\n* Rii daju pe gbogbo awọn iwe aṣẹ rẹ jẹ mimọ, ka, ati imudojuiwọn.\n* Ṣayẹwo pe alaye ti o pese baamu pẹlu awọn alaye ti o wa ninu awọn iwe aṣẹ osise rẹ.\n* Tẹle awọn ilana naa pẹkipẹki lati yago fun idaduro tabi kiko.\n\nAkiyesi: \nTi ayewo eKYC rẹ ko ba ṣaṣeyọri ati pe o nilo lati tun fi silẹ, iwọ yoo sanwo afikun fun ilana ifisilẹ lẹẹkansi.\n\nNipa tẹle awọn ilana wọnyi, o le rii daju ayewo eKYC ti ko ni wahala ati aṣeyọri.\n\nFun awọn ibeere tabi iranlọwọ, jọwọ kan si ẹgbẹ atilẹyin wa ni support@insta-pay.ch",
    "Personal Transactions": "Awọn idunadura ti ara ẹni",
    "Business Transactions": "Awọn idunadura Iṣowo",
    "Shopping & Purchases": "Iṣowo & Awọn rira",
    "Bills & Utilities": "Awọn owo-ori & Awọn iṣẹ",
    "Entertainment & Leisure": "Idaraya & Iṣere",
    "Investments & Savings": "Idoko-owo & Fipamọ",
    "Health & Wellness": "Ilera & Adayeba",
    "Transportation": "Gbigbe",
    "Education": "Ẹkọ",
    "Miscellaneous": "Aṣọtọ",
    "Family Support": "Atilẹyin Ẹbi",
    "WALLET": "WALLET",
    "Add a currency": "Fi owo ṣafikun",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Nigbati o ba ṣafikun owo, gbogbo awọn sisanwo ti o gba ni owo yẹn ni yoo gba kirediti si iwọntunwọnsi InstaPay rẹ. A yoo lo owo akọkọ rẹ fun fifiranṣẹ tabi beere awọn isanwo ayafi ti a ti sọ bibẹẹkọ.",

    "balance amount ": "iye iwọntunwọnsi",
    "referral ": "ìtọkasí",
    "crypto wallet balance": "iwontunwonsi apamọwọ crypto",
    "money in": "owo ti n wọle",
    "money out": "owo ti n jade",
    "analytics": "onínọmbà",
    "see more": "wo diẹ sii",
    "manage my Wallets and see the transaction details": "ṣakoso àwọn Wallets mi ki o wo àwọn alaye ti àwọn iṣowo mi",
    "set as default Wallet": "ṣeto bi Wallet aiyipada",
    "add currency": "fi owo kun",
    "convert funds ": "iyipada awọn owo",
    "withdraw balance": "yọ iṣiro kuro",
    "chart names": "orukọ awọn aworan",
    "withdraw balance from PKR wallet": "yọ ilé lati inu apo-iwe PKR",
    "select a payout channel": "yan ikanni isanwo kan",
    "you have entered an amount below the minimum payment range": "o ti tẹ iye kan ti o wa ni isalẹ ibiti isanwo ti o kere ju",
    "change": "iyipada",
    "edit": "satunkọ",
    "moving to next step in ...": "lọ si igbesẹ ti n bọ ni ...",
    "you are withdrawing ...": "o n yọ ...",
    "where is my payment": "ibo ni owo mi wa",
    "download": "gba lati ayelujara",
    "IBAN / SWIFT Code": "IBAN tabi SWIFT/BIC koodu",
    "swift code": "SWIFT koodu",
    "account number": "nọmba iroyin",
    "branch street": "itolẹ́wọ́ ìkẹta",
    "city": "ilu",
    "province": "agbegbe",
    "postal code": "koodu ifiweranṣẹ",
    "all payout channels": "gbogbo awọn ikanni isanwo",
    "bussiness beneficiary": "olugba iṣowo",
    "no quotation": "ko si isiro iye owo",
    "no pending items": "ko si awọn ohun ti o nduro",
    "QR status": "Ipo QR",
    "Portfolio": "Apo",
    "edit whole portfolio page": "ṣatunkọ gbogbo oju-iwe apo",
    "personal account": "akọọlẹ̀ ara ẹni",
    "Chart names": "Orukọ awọn aworan",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "sì í",
    "not connected": "kò ní sopọ̀ mọ́",
    "code": "koodu",
    "ratings": "ìwádìí",
    "current password": "ọrọigbaniwọle lọwọlọwọ",
    "include at least one uppercase and lowercase": "sọkalẹ o kere ju lẹẹkan ati lẹta kekere",
    "my social network accounts": "àkọọlẹ mi ti nẹ́tìwọ́ọ̀kì àwùjọ",
    "SMS ": "SMS",
    "view identity verfication guide": "wo itọsọna ìdánimọ̀",
    "boimetric face recognition": "fẹ́lẹ́fẹ́lẹ́ oju biometriki",
    "please select a payer (withdrawl options)": "jọwọ yan olufowo (awọn aṣayan yiyọ)",
    "IBAN number ": "nọ́mbà IBAN",
    "account holder name": "orukọ dimu àkọọlẹ",
    "download data": "gbé data",
    "bank transfer": "irinsin ifowopamo",
    "mobile wallet": "apamowo alagbeka",
    "all bank names": "gbogbo oruko banki",
    "pay via card": "sanwo nipasẹ kaadi",
    "you have entered an amount that is below the payer range": "o ti tẹ iye ti o wa ni isalẹ iwọn ti o sanwo",
    "trouble sending money": "ìṣoro fifiranṣẹ owó",
    "please follow these example": "jowo tẹle awọn apẹẹrẹ wọnyi",
    "wallet ID ": "ID Wallet",
    "username": "orukọ olumulo",
    "email": "imeeli",
    "phone number": "nọmba foonu",
    "no user found": "ko si olumulo ti a rii",
    "search": "awari",
    "scan/upload QR code of the recipient": "sẹẹnì/filọ koodu QR ti olugba",
    "invite them": "pe wọn",
    "trouble quoting money": "isoro ni didenunwo owo",
    "select a country": "yan orilẹ-ede kan",
    "airtime details": "ọ̀rọ̀ ilẹ̀kùn",
    "enter the phone number": "tẹ nọmba foonu sii",
    "service type": "iru iṣẹ",
    "select a service": "yan iṣẹ kan",
    "service list": "akọ́kọ́ iṣẹ",
    "amount entered is less than the ....": "iye ti a tẹ sii kere ju ....",
    "total": "àpapọ̀",
    "total credit the receiver gets": "Gbogbo kirẹditi ti olugba n gba",
    "take ": "mu",
    "OTP has been verified , You'll be redirected......": "OTP ti jẹrisi, iwọ yoo ṣe itọsọna......",
    "Link Your Instagram Account to InstaPay": "So àkọọlẹ Instagram rẹ pọ mọ InstaPay",
    "Step 01 – From Instagram App": "Igbesẹ 01 – Lati Ohun elo Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Ṣii ohun elo Instagram, wa oju-iwe profaili \"InstaPay\", yan \"Ifiranṣẹ\" lati ṣii apoti ifọrọranṣẹ, tẹ/so \"Pẹlẹ o\" ki o tẹ fi ranṣẹ lati bẹrẹ ibaraẹnisọrọ pẹlu bot ifọrọranṣẹ InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Igbesẹ 02 – Lati Oju-iwe Profaili Instagram InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Bot ifọrọranṣẹ InstaPay yoo ṣafihan awọn bọtini iṣe meji: \"Forukọsilẹ\" ati \"So pọ\". Yan \"So pọ\" ati bot ifọrọranṣẹ yoo sọ fun ọ lati tẹ orukọ olumulo InstaPay rẹ sii.",
    "Step 03 – From InstaPay Instagram Profile Page": "Igbesẹ 03 – Lati Oju-iwe Profaili Instagram InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Bot ifọrọranṣẹ InstaPay yoo lẹhinna beere fun koodu ijẹrisi InstaPay. Tẹ koodu ti o gba lati inu awọn eto InstaPay ni isalẹ \"Awọn akọọlẹ Nẹtiwọọki Awujọ Mi\" ki o tẹ fi ranṣẹ.",
    "Step 04 – From InstaPay Instagram Profile Page": "Igbesẹ 04 – Lati Oju-iwe Profaili Instagram InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Iwọ yoo gba ifiranṣẹ ikini ti o jẹrisi pe akọọlẹ Instagram rẹ ti ni bayi ni ibamu pẹlu InstaPay. Tẹ lori \"Akojọ Akọkọ\" lati bẹrẹ lilo awọn iṣẹ InstaPay.",
    "Additional Tips": "Awọn imọran afikun",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Daabobo akọọlẹ rẹ: Rii daju pe akọọlẹ Instagram rẹ wa ni aabo nipa mu ifọrọranṣẹ meji-oluyipada ṣiṣẹ (2FA) lati daabobo awọn iṣẹ InstaPay ti o ni asopọ.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Ṣawari siwaju sii: Ni kete ti o ba sopọ, ṣawari ibiti awọn iṣẹ InstaPay nfunni, lati awọn iṣowo ailopin si awọn yiyọkuro lẹsẹkẹsẹ.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Duro imudojuiwọn: Duro ṣayẹwo awọn imudojuiwọn wa, bi a yoo ṣe atilẹyin ni laipẹ lati so awọn akọọlẹ nẹtiwọki awujọ miiran pọ lati jẹ ki iriri rẹ pọ julọ.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Iranlọwọ & Atilẹyin: Ti o ba dojukọ eyikeyi awọn ọran lakoko ilana sisopọ, kan si ẹgbẹ atilẹyin wa fun iranlọwọ.",
    "Future Enhancements": "Awọn ilọsiwaju iwaju",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Ni ọjọ iwaju, InstaPay yoo gba ọ laaye lati so ọpọlọpọ awọn akọọlẹ nẹtiwọki awujọ pọ, nfun ọ ni irọrun ati irọrun diẹ sii. Duro ṣayẹwo fun awọn imudojuiwọn igbadun!",
    "Personal Transactions": "Awọn idunadura ti ara ẹni",
    "Business Transactions": "Awọn idunadura Iṣowo",
    "Shopping & Purchases": "Iṣowo & Awọn rira",
    "Bills & Utilities": "Awọn owo-ori & Awọn iṣẹ",
    "Entertainment & Leisure": "Idaraya & Iṣere",
    "Investments & Savings": "Idoko-owo & Fipamọ",
    "Health & Wellness": "Ilera & Adayeba",
    "Transportation": "Gbigbe",
    "Education": "Ẹkọ",
    "Miscellaneous": "Aṣọtọ",
    "Family Support": "Atilẹyin Ẹbi",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Bẹrẹ Irin-ajo InstaPay rẹ loni!",
    "Enter your phone number": "Tẹ nọmba foonu rẹ sii",
    "Login with email": "Wo sinu pẹlu imeeli",
    "Don't have an account?": "Ṣe o ni akọọlẹ kan?",
    "Sign Up": "Forukọsilẹ",
    "Continue": "Tesiwaju",
    "We recommend you download and install the Google Authenticator app": "A ṣe iṣeduro fun ọ lati ṣe igbasilẹ ati fi sori ẹrọ app Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Ijẹrisi meji-idi (2FA) jẹ ọna ti o dara julọ lati daabobo ara rẹ lori ayelujara.",
    "Strong security with Google Authenticator": "Aabo to lagbara pẹlu Google Authenticator",
    "Enter your password": "Tẹ ọrọ igbaniwọle rẹ sii",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "A ṣe iṣeduro fun ọ lati ṣe igbasilẹ ati fi sori ẹrọ app Google Authenticator lati rii daju pe o gba awọn koodu ijẹrisi rẹ ni kiakia, lati yago fun eyikeyi idaduro tabi awọn iṣoro pẹlu SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Koodu Ijerisi-Meji",
    "Problem with the token?": "Isoro pẹlu itọnisọna?",
    "Verify using SMS": "Lo SMS lati ṣe ijẹrisi",
    "Verify": "Ṣayẹwo",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Ijẹrisi SMS",
    "Enter the SMS code sent to +41********4053": "Tẹ koodu SMS ti a firanṣẹ si +41********4053",
    "Verify Using Authenticator App": "Lo app Authenticator lati ṣe ijẹrisi",
    "Resend code in 120 seconds": "Tun koodu ranṣẹ ni iṣẹju-aaya 120",
    "Verify ": "Ṣayẹwo",
    "SMS - Web Mobile": null,
    "My own account": "Akọọlẹ temi",
    "Friends & Family": "Ọrẹ ati Ẹbi",
    "Aunt": "Auntie",
    "Brother-in-law": "Ẹgbẹ́ aya",
    "Cousin": "Arabinrin",
    "Daughter": "Ọmọbinrin",
    "Father": "Baba",
    "Father-in-law": "Baba oko",
    "Friend": "Ọrẹ",
    "Grandfather": "Baba ńlá",
    "Grandmother": "Mama ńlá",
    "Husband": "Ọkọ",
    "Mother": "Mama",
    "Mother-in-law": "Iya oko",
    "Nephew": "Ọmọkunrin arakunrin",
    "Niece": "Ọmọbinrin arakunrin",
    "Self (i.e. the sender, himself)": "Ararẹ (ẹni tí ń ránṣẹ́)",
    "Sister": "Arabinrin",
    "Sister-in-law": "Ẹgbẹ aya",
    "Son": "Ọmọkunrin",
    "Uncle": "Ẹ̀gbọ́n baba",
    "Wife": "Aya",
    "Others not listed": "Ẹlòmíràn tí kò sọ",
    "Merchant": "Onisowo",
    "No relationship": "Ko si ibasepọ",
    "Add Funds": "Fi Awọn owo kun",
    "Enter the Amount": "Tẹ iye naa sii:",
    "Fees": "Awọn owo iṣẹ:",
    "You Will Get": "Iwọ yoo gba:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Iye iṣowo yẹ ki o wa laarin [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Iye ti ko tọ: Ipele ijẹrisi idanimọ rẹ lọwọlọwọ ni opin iye ti o pọ julọ ti o le ṣafikun. Jọwọ ṣe imudojuiwọn ijẹrisi idanimọ rẹ lati ni anfani lati awọn aala ti o ga julọ.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Iye owo nina yipada nigbagbogbo da lori awọn ipo ọja. Jọwọ tọka si idiyele lori oju-iwe aṣẹ jẹrisi bi asọye ikẹhin rẹ.",
    "Next": "Nigbamii",
    "Upgrade Identity Verification": "Mu Ijẹrisi Idanimọ Rẹ Ṣe",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Beere owo lọwọ ẹnikẹni, nibikibi, pẹlu lori awọn iru ẹrọ nẹtiwọọki awujọ. Pẹlu InstaPay, awọn ibeere isanwo rẹ ni irọrun wiwọle lẹsẹkẹsẹ lẹhin ti o gba ati awọn owo ti wa ni firanṣẹ si Awọn akọọlẹ Gbigba rẹ. Ṣe ilọsiwaju awọn iṣowo rẹ ki o ṣakoso awọn sisanwo pẹlu irọrun ati aabo.",
    "You’ve been logged out.": "O ti jade.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Fun aabo rẹ, a ti yọ ọ kuro lẹhin iṣẹju 15 ti aini iṣẹ. Jọwọ wọle lẹẹkansi ti o ba fẹ lo InstaPay.",
    "LOGIN AGAIN": "WÓLÉ TÚN",
    "Brother": "Arakunrin",
    "Current Password": "Ọ̀rọ̀ aṣínà ìsinsin yìí",
    "The new password cannot be the same as the old one": "Ọ̀rọ̀ aṣínà tuntun kò lè jẹ́ bẹ́ẹ̀ gẹ́gẹ́ bí èyí tí ẹ ti lo tán.",
    "Minimum password length: [X] characters ": "Ẹ̀gbá kan lọ́kàntùǹka ọmọ ìwé ìníkọ́: [X] àwọn alábàákọ.",
    "Include at least one uppercase and one lowercase letter.": "Rántí láti ṣàfikún lẹ́tà aláṣájú kan àti lẹ́tà àwọ̀nka kan.",
    "Nominee Information": "Alaye Ẹni Tó Gbẹ́kalẹ̀",
    "Verification Code Preferences": "Àṣàyàn Koodu ìmúdájú",
    "How Others Can Find You": "Báwọn Míràn Ṣe Lẹ̀ Rí Ọ",
    "My Social Network Accounts": "Àwọn Àkàǹlẹ̀ Nẹ́tíwọ́ọ̀kì Awùjọ Mi",
    "Login Activity": "Ìwààsù Ọ̀rọ̀ìkọ̀nà",
    "Receiving Accounts": "Àwọn Àkàǹlẹ̀ Gba",
    "Change Your Password To Continue": "Ṣàtúnṣe Ọ̀rọ̀ Àsìnà Rẹ Látí Tẹ̀síwájú",
    "Continue with": "Tẹ̀síwájú Pẹ̀lú",
    "Back to Login": "Padà Sílẹ̀ Àkọlé",
    "Send OTP": "Fíránṣẹ́ OTP",
    "A Verification code has been sent to your email and number.": "Àkọlé kan ti a fíránṣẹ́ sí ìmèlí rẹ̀ àti nọ́mbà rẹ.",
    "Resend available in": "Àfikún ìfíranṣẹ́ Tí Yé Aṣeyọrí Nínú",
    "Create a strong Password": "Ṣẹ̀dá Ọ̀rọ̀ Àsìnà Tí Ó Lágbára",
    "Password must:": "Ọ̀rọ̀ Àsìnà yẹ:",
    "Be between 9 and 64 characters": "Ṣe láàrín 9 sí 64 àwọn alábàákọ",
    "Include at least two of the following:": "Pé Fún Àkọlé Àtàtà Bí Àwọn Wọnyí:",
    "Lowercase Character": "Àkọlé Abẹ́lẹ̀",
    "Special Character": "Àkọlé Páàtìpáàtà",
    "Password Updated Successfully!": "Ọ̀rọ̀ Àsìnà Yẹyé Kúkúrú Láti Ṣe É!",
    "Click below to login": "Tẹ níhìn-ín láti wọlé",

    "Send Money": "Firanṣẹ Owo",
    "Enter the amount": null,
    "Recent Transfer": "Gbigbe Tuntun",
    "See all": "Wo gbogbo",
    "Send again": "Tun firanṣẹ",
    "You Send": "Iwọ Firanṣẹ",
    "They Receive": "Wọn Gba",
    "Receive Method": "Ọna gbigba",
    "Select method": "Yan ọna",
    "Next": "Itele",
    "Trouble sending money?": "Isoro ni fifiranṣẹ owo?",
    "Where do you want to send money ?": "Nibo ni o fẹ lati fi owo ranṣẹ si?",
    "Search ": "Ṣawari",
    "Confirm": "Jẹrisi",
    "Bank Deposit": "Idogo Banki",
    "Cash Pick-up": "Gbigba Owo",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Kaadi Banki",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Kini Wallet alagbeka olugba naa?",
    "Transfer fees": "Owo gbigbe",
    "Total to pay": "Lapapọ lati san",
    "Choose the beneficiary!": "Yan olugba!",
    "Country": "Orilẹ-ede",
    "Receive method": "Ọna gbigba",
    "You send": "Iwọ Firanṣẹ",
    "change": "iyipada",
    "edit": "fi titun kun",
    "add new": "Awọn olugba",
    "Beneficiaries": "Ṣakoso Awọn olugba",
    "Manage Beneficiaries": "Ṣawari",
    "Search": "Itele",
    "CASH PICK-UP": "Gbigba Owo ni gbangba",
    "Where will your receiver collect the money": "Nibo ni olugba rẹ yoo gba owo naa",
    "Cash is available to collect within minutes from any branch of [name] ": "Owo wa lati gba laarin iṣẹju diẹ lati eyikeyi ẹka ti [orukọ]",
    "BANK ACCOUNT": "Ile-ifowopamọ",
    "Transfer to recipient’s bank account": "Gbigbe si akọọlẹ banki ti olugba",
    "BANK CARD": "KAADI ILE-IFOWOPAMỌ",
    "Fast transfer directly to recipient’s bank card": "Gbigbe yara taara si kaadi ile-ifowopamọ olugba",
    "CRYPTO WALLET": "Wallet crypto",
    "Fast transfer directly to a crypto wallet": "Gbigbe yara taara si Wallet crypto",
    "Review transfer": "Atunwo gbigbe",
    "Receiver's Details": "Alaye olugba",
    "Edit": "Ṣatunkọ",
    "Name": "Orukọ",
    "Reason for sending": "Idi fun fifiranṣẹ",
    "Sending": "Fifiranṣẹ",
    "Receiver gets": "Olugba gba",
    "Exchange rate": "Oṣuwọn paṣipaarọ",
    "Estimated time": "Aago asiko",
    "Select Payment Type": "Yan iru sisanwo",
    "Credit cards": "Kaadi kirẹditi",
    "Domestic cards": "Kaadi abele",
    "Mobile Wallets": "Awọn Wallet alagbeka",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KAADI KIRẸDITI",
    "Credit Card": "Kaadi kirẹditi",
    "Select card": "Yan kaadi",
    "Card Number": "Nọmba kaadi",
    "Enter the 16-digit card number on the card": "Tẹ nọmba kaadi 16-digit",
    "Expiry Date": "Ọjọ ipari",
    "Enter the expiration date of the card": "Tẹ ọjọ ipari ti kaadi naa",
    "CVV Number": "Nọmba CVV",
    "Enter the 3 or 4 digit number on the card": "Tẹ nọmba 3 tabi 4-digit lori kaadi naa",
    "INSTAPAY WALLET": "Wallet INSTAPAY",
    "Select Wallet": "Yan Wallet",
    "My Wallet": "Apo mi",
    "Conversion": "Iyipada",
    "Convert": "Iyipada",
    "Your payment is confirmed": "Ti ṣayẹwo isanwo rẹ",
    "Thanks for using InstaPay": "O ṣeun fun lilo InstaPay",
    "Status": "Ipo",
    "Share": "Pin",
    "Transfer created": "Gbigbe ṣẹda",
    "Payment received": "Sanwo gba",
    "Payment processed": "Sanwo ti ṣe ilana",
    "Transfer successful": "Gbigbe aṣeyọri",
    "It may take few hours for the funds to appear in [user name] account": "O le gba awọn wakati diẹ fun awọn owo lati han ninu akọọlẹ [orukọ olumulo]",
    "Transfer failed": "Gbigbe kuna",
    "Transaction Details": "Awọn alaye iṣowo",
    "Need help?": "Nilo iranlọwọ?",
    "Service Details": "Alaye Iṣẹ",
    "Funds Deposit Speed": "Wiwa Awọn Owo",
    "Cut-off Time": "Akoko Ige",
    "Bank holiday list": "Atokọ Awọn Isinmi Banki",
    "Threshold limits": "Awọn opin Iṣowo",
    "null": null,
    "Instant, Real Time": "Láìpé, nígbà gidi",
    "Same day, T+1": "Ní ọjọ́ kan náà, T+1",
    "Non-instant, T+1": "Kò láìpé, T+1",
    "Instant Payment, Real Time": "Ìsanwó láìpé, nígbà gidi",
    "Transaction processed before 3:00 PM Local time": "Ilana mu iṣowo ṣaaju 3:00 PM Aago agbegbe",
    "Delivered the same day": "Ti ṣe ifijiṣẹ ni ọjọ kanna",
    "Transactions processed post 3:00 PM": "Awọn iṣowo ti a ṣe ilana lẹhin 3:00 PM",
    "T+1 business days": "T+1 ọjọ iṣowo",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Awọn sisanwo ti a ṣe ni awọn ọjọ ọsẹ ṣaaju 3:00 PM yoo gba kirẹditi si akọọlẹ ile-ifowopamọ ti n gba nipasẹ idaji owurọ ti ọjọ kanna.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Awọn sisanwo ti a ṣe ni awọn ọjọ ọsẹ lẹhin 3:00 PM yoo gba kirẹditi nipasẹ idaji owurọ ọjọ keji.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Awọn sisanwo ti a ṣe ni ọjọ Satidee, ọjọ Sunday tabi isinmi gbangba yoo gba kirẹditi si akọọlẹ naa nipasẹ idaji owurọ ọjọ iṣowo akọkọ ti n bọ.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Aago Agbegbe",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Iye to kere ju fun iṣowo kọọkan:",
    "Maximum per transaction limit:": "Iye to pọ julọ fun iṣowo kọọkan:",
    "Daily threshold limit:": "Iye oṣuwọn ojoojumọ:",
    "Weekly threshold limit:": "Iye oṣuwọn ọsẹ:",
    "Monthly threshold limit:": "Iye oṣuwọn oṣooṣu:",
    "Yearly threshold limit:": "Iye oṣuwọn ọdun:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Iwe iroyin ipilẹ (A ko fọwọsi)",
    "Identity Verified": "Idanimọ ti fọwọsi",

    "Good Evening!": "Káàárọ̀!",
    "Total Balance": "Apapọ Ibalansi Akọọlẹ",
    "Quick Transfer": "Gbigbe Owo Ni Kiakia",
    "No transactions": "Ko si awọn iṣowo ti a rii",
    "Blocked balance": "Ibalansi ti a Ti Dẹkun",
    "Available balance": "Ibalansi Tí o wà ní àfihàn",
    "Insufficient Balance in PKR": "Ibalansi Owo Ni PKR Ko To",
    "Chat with us": "Bá wa sọ̀rọ̀",
    "Add Currency": "Fikun èyíkéyìí àwọn owó",
    "Education": "Ẹkọ",
    "Divers": "Onírúurú",
    "Family support": "Ìtìlẹ́yìn ẹbí",
    "Others": "Ẹyọkò Expenses",
    "Transport": "Ìrìn àjò",
    "schedule payment": "Ìsanwó Tí a Ṣétò",
    "subscription payment": "Ìsanwó Tí a ńṣe Lẹ́ẹ̀kúnrẹ́rẹ́",
    "No payment": "Ko si Ìsanwó kan tí a rí",
    "No Beneficiaries": "Ko si Àwọn Olùdájú Tí o wà",
    "Code Postal": "Kóòdù Olọ́jọ́wọ́",
    "Additional Information": "Àlàyé Àfikún",
    "Gender": "Ìbíranṣé",
    "Date Of Birth": "Ọjọ Ìbí",
    "Nationality": "Ìranṣẹ́",
    "Occupation": "Iṣẹ́ (Gbogbo àṣàyàn pẹ̀lú)",
    "ID Type": "Ìrù ID (Gbogbo àṣàyàn pẹ̀lú)",
    "ID Number": "Nọ́mbà ID",
    "Select Beneficiary Payment Channels": "Yan Àwọn Ọ̀nà ìsanwo Olùgbèsẹ",
    "wallet crypto": "Apamọwọ Crypto",
    "Add another wallet": "Fikun apamọwọ mìíràn",
    "Enterprise": "Iṣowo",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "\"Àwọn olùgbèsẹ Iṣowo ń bọ̀ súnmọ́! Ìwọ yóò lè ṣe àwọn ìṣòwò káàkiri agbègbè, ní igbẹkẹ̀lé tó ju ilẹ̀ kọjá lọ.\"",
    "Joined At": "Darapọ̀ sí",
    "Profile Link": "Ìjápọ̀ Profaili",
    "comission": "Ìwé-ẹri",
    "my withdrawls": "Àwọn Yíyọ mi",
    "My Referrals": "Àwọn Ìtọ́kasí mi",
    "search quotation": "Ṣàwárí Àwọn Èjìká",
    "no quotation": "Ko si Àwọn Èjìká ti a rí",
    "actions": "Ìgbésẹ̀",
    "search request": "Ṣàwárí ìbéèrè",
    "no pending items ": "Ko sí àwọn ohun ti a pàdé",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "Pin Kóòdù QR rẹ tàbí ìjápọ̀ ìsanwo láti gba àwọn ìsanwó ni apamọwọ InstaPay rẹ lọ́tìtọ̀",
    "Description": "Àlàyé",
    "save": "Fipamọ",
    "Share your Insta-Pay unique payment link": "Pin ìjápọ̀ ìsanwo InstaPay tí o dàgbà kan ṣoṣo rẹ",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Gbé Profaili gbangba rẹ soke. Apapọ rẹ ṣiṣẹ́ bí àfihàn ìgbéyè-gbèèká, wọlé taara láti adirẹsi ìsanwo rẹ",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "\"So pọ̀ mọ́ àwọn àgbọ́rìn rẹ̀ nípasẹ̀ ìrìn àjò oníṣẹ́ tàbí ẹ̀dá rẹ. Jẹ́ kí iṣẹ́ rẹ hàn ararẹ̀ àti kí o tù àwọn oníṣeṣẹ́ lára tàbí onígbàláà rẹ jù lọ.\"",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "\"Ṣàfihàn ìfọwọ́kan àwọn akọọ́lẹ̀ rẹ lori awujọ. Àwọn alágbẹ̀jọ́mọ́ le rí ìkan tí ìfọwọ́kan awujọ rẹ yóò jẹ́ pípín, fi gbèkan bí onípèjúwé sí apapọ rẹ.\"",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "\"Ṣe àfikún fún àwọn arìnrìnàjò láti béèrè tàbí bẹ̀rẹ̀ ìsanwo tàbí àwọn ohun tí o ń ta kíakíakí. Apapọ rẹ kò kàn jẹ́ ìfihàn ṣugbọn bákannáà ṣe wọlé fún àwọn ìsanwo tàbí àwọn ìṣe iná.\"",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Lo apakan \"Nípa Mi\" láti pin ìtàn rẹ, erongba rẹ tàbí èrò rẹ, fi ìbáṣepọ̀ pẹ̀lú awọn agbọ́rìn rẹ.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"Àpapọ̀ Mi\" jẹ́ ju àgalà lọ; ọ̀nà fún àgbéyẹ̀sẹ̀ ẹ̀rò rẹ, fí sí ìṣe pẹ̀lú àwọn agbọ́rìn rẹ, àti ṣe ayípadà ìgbègbà iná ní InstaPay.",
    "No Portfolio Items": "Ko si àwọn ohun ìtẹ̀wé tí a rí",
    "Add New Portfolio Item": "Fikun Ohun Tẹ̀wé Tuntun",
    "Title": "Akọ́lé",
    "drag/drop or upload a file(photo/video/pdf)": "Dì wọlé tàbí ṣe ìkópọ̀ fà/fifun fáìlì kan (fọ́tò/fídíò/pídìfì)",
    "discard": "Fagilé",
    "save and post ": "Fipamọ àti Forúkọsílẹ̀",
    "My Payment Address": "Adirẹsi ìsanwo mi",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Péye fún àwọn òṣèré, àwọn àgbéjáde, àwọn ìjọba, tàbí ẹnikẹ́ni tí ó fẹ́ kí ẹ̀gbọ́n wọn rò mọ́ pẹ̀lú ọ",
    "Personal Account": "Ìpinnu Àkọọlẹ Tikararẹ̀",
    "spent in total": "Gbogbo ìní",
    "not connected": "Kò Dáríígbọ́n",
    "more": "Pẹ̀lú",
    "less": "Kò tó",
    "profile reports": "Ìròyìn Profaili",
    "All notifications are in English langauge": "Gbogbo àwọn ìfọwọ́kan wa ní èdè Gẹ̀ẹ́sì",
    "timezone": "Àsìkò",
    "nominee information": "Aláṣiṣẹ́ àṣẹ",
    "full name": "Orúkọ Kikun",
    "Relation with Nominee": "Ìbáṣepọ̀ pẹlu Aláṣẹ́",
    "source": "Orísun",
    "Google Authenticator": "Olùfíhàn Google",
    "notifications": "Ìwífún",
    "login": "Wọlé",
    "View Identity Verification Guide": "Wo ìtóye Ẹ̀rí Idánilójú",
    "verified": "Tí ó ti jẹ́rìí",
    "select ": "Yan",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Ó gbọdọ̀ jẹ́ ohun ìmúlò tí a ṣé é tán láti inú ìjọba pẹlu fọ́tò kan. Gbogbo ẹ̀rí gbọ́dọ̀ wà nínú Alphabet Latin. Bí bẹ́ẹ̀kọ̀, èdá jẹ́rìí kan pàtàkì yóò jẹ́ pẹ̀lú.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom pa ètò láti bẹ̀wò àwọn àkọọlẹ̀ (Enhanced Due Diligence - EDD) tàbí èyíkéyìí ìtàn náà láti ṣe ìtẹnumọ́ pátápátá tàbí àwọn àìlòfin rẹ̀.",
    "sessions": "Àwọn Ìpamọ̀",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Ṣí Yíyọ Tí a Ṣétò: Ṣépa Àwọn Àkọọlẹ rẹ Bayii!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Dáadáa ṣàkóso ibi tí o fẹ́ gba àwọn ìsanwó nípasẹ̀ fifi àwọn aláyèè àwọn àkọọlẹ rẹ̀, apamọwọ alágbééká, apamọwọ crypto, àwọn káràdì ìsanwó, tàbí àwọn ibi gbigba owó pẹ̀lú owó. Tẹ̀lé àwọn ìgbésẹ̀ ní isàlè láti ṣèdáyò wọlé si àwọn owó rẹ́ nígbàkankan.",
    "Select the receiving channel below and setup the details.": "Yan ikanni ìgba isànwó ní isàlè yìí àti ṣètò àwọn aláyèè.",
    "Please Select A Payer": "Jòwọ́, Yan Olùsanwó",
    "Number": "Nọ́mbà",
    "Account Holder Name": "Orúkọ Olùní Akọọlẹ",
    "Province": "Agbegbe",
    "postal": "Àdírẹ̀sì Olọ́jọ́wọ́",
    "delete channel": "Paarẹ́ Ikanni",
    "set as default": "Ṣètò gẹ́gẹ́ bí Aiyẹ́nìní",
    "edit": "Ṣàtúnṣe",
    "all channels are in english language in recieving account page": "Gbogbo ikanni wa ní èdè Gẹ̀ẹ́sì lori oju ewe àkọọlẹ ìgba ìsanwó.",
    "select file type": "Yan irú fáìlì",
    "download data": "Gba Àwọn Aláyèè",
    "INTERNATIONAL TRANSFER": "ÌSÍRAJÚ ÀGBÀYÉ",
    "enter the amount": "Fọwọ́si Iye",
    "they recieve ": "Olùgba n gba",
    "trouble sending money": "Ìṣòro Nípa Rírán Owó?",
    "select destination country": "Yan Orílẹ̀ Èdè Ìlà Ọ̀nà",
    "bank transfer": "Gbigbe Banki",
    "recieving method": "Ìlà ìgba",
    "payer": "Olùsanwó",
    "The fee and the received amount may vary depending on the chosen Payment Method": "Owó iṣẹ́ àti iye tí ó gba lè yàtọ̀ gẹ́gẹ́ bí ètò ìsanwó tí a yan.",
    "search ": "Ṣàwárí",
    "show more": "Fihan Die",
    "select remark reasons": "Yan Àwọn Ìdí Fìdílé",
    "credit and debit card": "Káràdì Ìsanwó àti Gbigba",
    "Transfer Fees": "Ìyọ̀nìsìwájú Owó",
    "Estimated Time": "Àkókò Tí ó Ṣàdédé:",
    "within minutes": "Ní Ààrà",
    "total ": "Apapọ̀",
    "OTP verifications code": "Kódì Ìfáṣẹ́yẹ Àwɔn Nọ́mbà (OTP)",
    "verify using sms/email": "Ṣèyẹ̀wò pẹ̀lú SMS/Email",
    "sms/email verification code": "SMS/Email Kódì Ìfáṣẹ́yẹ",
    "Verify Using Authenticator": "Ṣèyẹ̀wò pẹ̀lú ìpèsè àṣẹ",
    "WALLET TO WALLET TRANSFER": "ÌGBÀ GBOGBO WÀ PELÚ ÀWỌN ÌSANWÓ",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Fọwọ́si ID Apamọwọ, Orúkọ Ìlànà, Ìmeèlì, tàbí Nọ́mbà Fónú Olùgba.",
    "search": "Ṣàwárí",
    "lookup": "Wá",
    "Recipient Not on InstaPay?": "Olùgba Kò Sí Lori InstaPay?",
    "invite them": "Pe wọ́n",
    "Please follow these examples": "Jọwọ̀ tẹ̀lé àwọn àpẹẹrẹ wọ̀nyí:",
    "Wallet ID: GY68J782": "ID Apamọwọ: GY68J782",
    "InstaPay/Instagram Username": "InstaPay/Instagram Orúkọ Ìlànà: instauser",
    "Email: user@email.com": "Ìmeèlì: user@email.com",
    "With Country code": "Nọ́mbà Fónú (pẹ̀lú Kóòdù Orílẹ̀ Èdè)",
    "invite friend": "Pe Ọrẹ",
    "invite via phone number": "Pe nípasẹ̀ Nọ́mbà Fónú",
    "invite via email address": "Pe nípasẹ̀ Àdírẹsì Ìmeèlì",
    "next": "Nìkan",
    "back": "Padà",
    "Personalized Message": "Ìránṣẹ́ Tí ó Níra",
    "available currency": "Owó Tí ó wà",
    "attach files": "Fìdílé Àwọn Fáìlì",
    "total amount": "Apapọ Iye",
    "instant payment": "Ìsanwó Kiakia",
    "starting from": "Bíbẹ̀rẹ̀ Lati",
    "recurring cycle": "Ìṣípadà Lẹ́ẹ̀kúnrẹ́rẹ́",
    "until I stop": "Títí tí o bá dáwọ́ Dúró",
    "start from": "Bíbẹ̀rẹ̀ Lati",
    "cycle": "Ìṣípadà",
    "total": "Apapọ̀",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "Olùgba lè gba iye tí a ṣe àyípadà díẹ̀ láti ọwọ́ iye owó pẹ̀lú ìwọ́nsílẹ̀.",
    "schedule to": "Tí a Ṣétò Sí",
    "schedule at": "Nígbà Wo",
    "Scheduled Date": "Ọjọ̀ tí a ṣètò",
    "Scheduled time": "Àkókò tí a ṣètò",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "Olùgba lè gba iye tí a ṣe àyípadà díẹ̀ láti ọwọ́ iye owó pẹ̀lú ìwọ́nsílẹ̀.",
    "QR CODE PAYMENT ": "ÌSANWÓ PẸ̀LU KÓÒDÙ QR",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Ní yíyàn, lo Kóòdù Alphanumeric InstaPay.",
    "PYAYMENT REQUEST": "ÌBÉÈRÈ ÌSANWÓ",
    "choose the beneficiary": "Yan Olùgberé",
    "trouble requesting money": "Ìṣòro Nípa Béèrè Owó?",
    "Standard Request": "Béèrè Aláìmọ́rọ",
    "Subscription Request": "Ìbéèrè Ìṣípadà",
    "Schedule Request": "Ìbéèrè Tí a Ṣètò",
    "Security Request": "Ìbéèrè Idogo Àbáyọ",
    "Instalment Request": "Ètò Ìsanwó Pàtàkì",
    "Split Request": "Gé Ìsanwó sí",
    "You recieved": "Ìwọ gba",
    "is this your location?": "Ṣe ibi yìí jẹ́ tìrẹ?",
    "yes": "Bẹẹni",
    "Authenticator Code": "Kódì Ìpèsè àṣẹ",
    "Enter a code shown in the app to make sure everything works fine.": "Fọwọ́si kóòdì tí ó hàn ninu ìwé Ìfáṣẹ́yẹ láti jẹrìí pé gbogbo nkan jẹ́ dáadáa.",
    "SEND A QUOTE": "RÁN ÌJÌKÀ",
    "Trouble Quoting Money?": "Ìṣòro Nípa Rírán Ìjìkà?",
    "Please follow these examples:": "Jọwọ̀ tẹ̀lé àwọn àpẹẹrẹ wọ̀nyí:",
    "Full Name: Muhammad Imtiaz": "Orúkọ Kikun: Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "InstaPay/Instagram Orúkọ Ìlànà: instauser",
    "Phone Number: 44795396600 (With Country Code)": "Nọ́mbà Fónú: 44795396600 (Pẹ̀lú Kóòdù Orílẹ̀ Èdè)",
    "SEND TOP-UP AND E-SIM": "RÁN ÒWÓ TOP-UP àti E-SIM",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Duro pẹ̀lú àsopọ̀ kari ayé! Ìṣẹ́ InstaPay Top-Up ń fún ọ́ láyè láti ṣe ìdáná àwọn fónu lẹsẹ̀kẹsẹ̀, rà àwọn eSIM, àwọn èròjà àmi ọjà, àwọn kárdì ẹbun, àti san àwọn owó alábọ̀ọ́lẹ̀ ní orílẹ̀-èdè tó ju 150 lọ. Ó péré fún àwọn ẹ̀bun tàbí fún múra ara rẹ̀, iṣẹ́ wa ń fi ẹ̀sọ, yíyára, àti ààbò̀ ṣáájú.",
    "MOBILE AIRTIME AND E-SIM": "AYÉRÓ FÓNÚ ALÁGBÉÉKÁ ATI E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Yan orílẹ̀-èdè fún àyeró alágbééká!",
    "Other Operator": "Àwọn alábòwọ̀ míràn",
    "airtime": "Ayéró",
    "bundle": "Àwọn àwòkọ",
    "data": "Àdata",
    "Enter custom amount": "Fọwọ́si iye aláṣepọ̀",
    "Credit & Debit Card": "Káràdì ìsanwó àti gbigba",
    "Bank Transfer": "Gbigbe Banki",
    "Mobile Wallet": "Apamọwọ Alágbééká",
    "InstaPay Wallet": "Apamọwọ InstaPay",
    "PayPal": "PayPal",
    "add card payment": "Fi káràdì ìsanwó kún",
    "save details to use next time.": "Fipamọ̀ àwọn aláyèè fún l'ọjọ iwájú",
    "Recipient Mobile Number": "Nọ́mbà Fónú Olùgba",
    "Amount": "Iye",
    "Service": "Ìṣẹ́",
    "Destination": "Ìlà Ọ̀nà",
    "Provider": "Olùfún",
    "Airtime": "Ayéró",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Within minutes": "Ní Ààrà",
    "top-up summary": "Àkíyèsí Top-Up",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR kò ṣe àtìlẹ́yìn ní PayPal, USD yóò lo gẹ́gẹ́ bí àiyẹ́nìní.",
    "Amount in USD": "Iye ní USD",
    "Send Top-up": "Rán Top-Up",
    "available sunservices": "Àwọn Iṣẹ́ Sun ti wà",
    "login with email": "Wọlé Pẹ̀lú Ìmeèlì",
    "Already Have an Account?": "Ní Àkọọlẹ̀ Tẹ́lẹ̀? Wọlé Wá",
    "profile picture": "Àwòrán Àpẹrẹ",
    "add photo/logo": "Fí àwòrán tàbí àmi kún",
    "first name": "Orúkọ Àkọ́kọ́",
    "enter your first name": "Fọwọ́si Orúkọ Àkọ́kọ́ rẹ",
    "last name": "Orúkọ ìdì",
    "enter your last name": "Fọwọ́si Orúkọ ìdì rẹ",
    "email(optional)": "Ìmeèlì (Ààyè)",
    "enter your email address": "Fọwọ́si Àdírẹ̀sì Ìmeèlì rẹ",
    "password must": "Ọ̀rọ̀ Àgbódọ̀",
    "It will be autofill, if you're signing up with invitation link": "Ìmú-rànṣẹ́ laifọwọ́si fún Forukọsilẹ̀ Pẹ̀lú Ìpèsí",
    "change number": "Ìyípadà Nọ́mbà",
    "signup error": "Ìṣòro Forukọsilẹ̀",
    "Something went wrong while sending sms!": "Ohun kan ṣẹlẹ̀ nigba rírán SMS! Jọwọ̀ gbìyànjú lẹ́yìn.",
    "Errors": "Àwọn aṣìṣe ṣẹlẹ̀. Jọwọ̀ tọ́ka sí àkọsílẹ̀ tó wà lórí fún àwọn àlàyé.",
    "Generic error": "Aṣìṣe gbogbo",
    "Trust Payments API requires the 'requests' library": "API àwọn ìsanwó Trust ń béèrè fún ìkápá 'requests' láti ṣiṣẹ́ pẹ̀lú tó tọ́.",
    "Send error": "Aṣìṣe nígbà ríránṣẹ́. Jọwọ̀ gbìyànjú lẹ́ẹ̀kan.",
    "Receive error": "Aṣìṣe nígbà gbígbà wọlé. Jọwọ̀ gbìyànjú lẹ́ẹ̀kan.",
    "Invalid credentials provided": "Àṣìṣe ìdánilójú àìtọ́. Jọwọ̀ ṣàwárí àti gbìyànjú lẹ́ẹ̀kan.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Ìṣòro kan ṣẹlẹ̀ nígbà tí a ń sopọ̀ mọ́ àwọn sèrìfà Trust Payments. Ṣayẹwo ìsopọ̀ rẹ kí o si gbìyànjú lẹ́ẹ̀kan.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Aṣìṣe airotẹlẹ nígbà tí a ń sopọ̀ mọ́ àwọn sèrìfà Trust Payments. Tí ìṣòro yìí bá tún ṣẹlẹ̀, kan sí support@trustpayments.com.",
    "Unknown error. If this persists please contact Trust Payments": "Aṣìṣe aláìmọ̀. Jọwọ̀ kan sí Trust Payments support tí ìṣòro bá tẹ̀síwájú.",
    "Incorrect usage of the Trust Payments API": "Ìlò Trust Payments API ní àìtọ́. Jọwọ̀ ṣàyẹ̀wò ìbéèrè kí o si gbìyànjú lẹ́ẹ̀kan.",
    "Invalid card details": "Aláyèè káràdì àìtọ́. Jọwọ̀ ṣayẹwo àti tẹ àwọn aláyèè tó tọ́.",
    "Invalid date": "Ọjọ́ tó pèsè jẹ́ àìtọ́.",
    "Invalid date/time": "Àkọsílẹ̀/ọjọ́-ìgbà ni àìtọ́. Jọwọ̀ fi ìwọ́nsílẹ̀ tó tọ́.",
    "Invalid details": "Aláyèè àìtọ́. Jọwọ̀ ṣàwárí àti tẹ àwọn aláyèè tó tọ́.",
    "Card number does not match card type": "Nọ́mbà káràdì kò bá irú káràdì tó yẹ.",
    "Malformed XML": "Aṣìṣe fífaradá XML. Jọwọ̀ ṣàyẹ̀wò àti ṣètúnṣe XML.",
    "XML does not match schema": "XML kò bá afama tí a fẹ́.",
    "Invalid file format": "Àkọkọ́ fáìlì àìtọ́. Jọwọ̀ lo àkọkọ́ tí a ṣe àtìlẹ́yìn.",
    "Empty file contents": "Àkọkọ́ tí ó ṣófo. Jọwọ̀ fi àkọkọ́ tó yẹ.",
    "Invalid file contents": "Àkọkọ́ fáìlì àìtọ́. Jọwọ̀ ṣàyẹ̀wò fáìlì àti gbìyànjú lẹ́ẹ̀kan.",
    "Malformed JSON": "JSON tí a kó dípò. Jọwọ̀ ṣètúnṣe orí JSON.",
    "StApi Error": "Aṣìṣe StApi",
    "Invalid fields specified in request": "Àwọn ààyèè àìtọ́ tí a pèsè ní ìbéèrè. Jọwọ̀ ṣàwárí àti ṣètúnṣe wọn.",
    "Missing parent": "Àlàyé àwọn òbí ní àìdára.",
    "Refund requires settled parent or parent that's due to settle today": "Ìdápadà ń bẹ̀rẹ̀ fún ìdàgbàlẹ̀ kan tó ti pé tàbí èyí tí ó dúró fún ìdàgbàlẹ̀ ní òní.",
    "Refund requires authorisation parent": "Ìdápadà ń bẹ̀rẹ̀ fún ìfọwọ́fún fún ìfowopamọ.",
    "Refund amount too great": "Ìdápadà iye tí ó ju àwọn tó gba yé lọ.",
    "No acquirer specified": "Àìní àwọn ìwé àyèpè fun àwọn tó gbé ìwé.",
    "Repeat amount too great": "Àwọn ìyè tí ń tún ṣe ju àwọn tí a gba yé lọ.",
    "Split amount too great": "Ìyapa iye tí ń tún ṣe ju iye tí a gba yé lọ.",
    "Cannot refund a declined transaction": "Kò lè dá pada ìgbèsẹ tó kọ.",
    "Refund requires a settled parent": "Ìdápadà ń bẹ̀rẹ̀ fún ìdàgbàlẹ̀ kan tó ti dá sílẹ̀.",
    "Reversal requires a cancelled auth parent": "Ìyípadà ń bẹ̀rẹ̀ fún àṣẹ ìfagilé tí wọ́n ti fagilé.",
    "Cannot override amount in child transaction": "Kò lè yí iye padà ní ìṣíṣẹ̀ ọmọ.",
    "Cannot override currency in child transaction": "Kò lè yí owó padà ní ìṣíṣẹ̀ ọmọ.",
    "Subscription requires RECUR account": "Ìforúkọsí nílò fún àtúnṣe ìṣéjà.",
    "Subscription requires successful parent": "Ìforúkọsí nílò fún ìṣéjà kan tó dára.",
    "Risk Decisions must have AUTH as parent": "Àwọn ìpinnu àbò̀ gbọdọ̀ jẹ́ pẹ̀lú ìfọwọ́fún ìfowopamọ.",
    "Chargebacks must have AUTH/REFUND as parent": "Àwọn ìjẹ́rìí náà gbọdọ̀ jẹ́ mọ́ ìfọwọ́fún tàbí ìdápadà.",
    "Refund amount less than Minimum allowed": "Iye ìdápadà kéré ju ìyẹn tó gba yé lọ.",
    "Refund requires paypaltransactionid": "Ìdápadà nilo ID ìgbáyegbe PayPal.",
    "Invalid split transaction": "Ìṣíṣẹ̀ ìyapa àìtọ́.",
    "Cannot reverse AUTH processed more than 48 hours ago": "Kò lè túnṣe ìfọwọ́fún tí ó ti kọjá ọjọ́ 48.",
    "Reversal requires acquirerreferencedata": "Ìyípadà nílò fún àwọn ìlà àwọn tí ó gba gbé.",
    "Cannot reverse AUTH processed by a different acquirer": "Kò lè yí ìfọwọ́fún padà tí wọ́n ti ṣe nipasẹ alábòsọ́ àtìlẹ́hì.",
    "Payment type does not support repeats": "Iru ìsanwó kò ṣe àtìlẹ́yìn fún àwọn ìṣíṣẹ̀ tí ń tún ṣe.",
    "Reversal missing required data": "Ìyípadà nílò àwọn àlàyé tí ó yẹ.",
    "Missing token": "Àlàyé èérò ní àìdára.",
    "Subscription with an accountcheck parent not supported on current acquirer": "Ìforúkọsí nílò pẹ̀lú àyèpè tí kò ṣe àtìlẹ́yìn fún olùkókó àtìlẹ́hì.",
    "Subscription cannot be used as a parent": "Ìforúkọsí kò lè wáyé bí ohun ìṣíṣẹ̀ ọmọ.",
    "Invalid parent": "Ìru ìṣíṣẹ̀ àwọn òbí ni àìtọ́.",
    "Payment type does not support refunds": "Iru ìsanwó kò ṣe àtìlẹ́yìn fún àwọn ìdápadà.",
    "Invalid incremental transaction": "Ìṣíṣẹ̀ ẹ̀ẹ̀kún jẹ́ àìtọ́.",
    "Partial reversals not supported": "Ìdápadà apá ni kò ṣe àtìlẹ́yìn.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Ìṣíṣẹ̀ òbí àti ọmọ gbọdọ̀ ni iru ìsanwó tó dọgba fún àwọn ìbéèrè 3D àbò.",
    "Payment type does not support card scheme updates": "Iru ìsanwó kò ṣe àtìlẹ́yìn fún ìtúnṣe ètò káràdì.",
    "Cannot reverse AUTH at this time, please try again": "Kò lè yí ìfọwọ́fún pada ní àkókò yìí. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "Cannot determine token": "Àìní aṣe. Jọwọ ṣàyẹ̀wò kí o si gbìyànjú lẹ́ẹ̀kan sí.",
    "Service Temporarily Disabled": "Ìṣẹ̀yàn ni iṣẹ́ ìgbékalẹ̀ nítorí ìsopọ̀ aṣìṣe.",
    "Login first request": "Wọlé jẹ́ dandan ṣáájú ṣíṣe ìbéèrè.",
    "Invalid username/password": "Orúkọ ẹni náà tàbí ọrọigbaniwọlé jẹ́ àìtọ́. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "Invalid session": "Ìdánilójú tó kọjá jẹ́ àìtọ́. Jọwọ wọlé lẹ́ẹ̀kan sí.",
    "Session has expired": "Àjọ tó pọ́n àṣìṣe, jọwọ wọlé lẹ́ẹ̀kan sí.",
    "Password expired": "Ọ̀rọ̀igbaniwọlé ti ọjọ́ rẹ kọjá. Jọwọ ṣètò tuntun.",
    "Password has been previously used": "Ọ̀rọ̀igbaniwọlé ti a ti lò ṣáájú. Jọwọ yàn tuntun kan.",
    "MyST user account has been locked": "Akọ́ọ̀lẹ̀ olùmúsa MyST ti wọ́n ti di. Jọwọ kan sí ìtìlẹ́yìn.",
    "New password does not match confirmed password": "Ọ̀rọ̀igbaniwọlé tuntun kò bá ìmújùmọ̀ fún ìjẹ́rìí.",
    "Incorrect current password": "Ọ̀rọ̀igbaniwọlé ìwòyí jẹ́ àìtọ́. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "Invalid selection": "Yíyan àìtọ́ ni a ṣe. Jọwọ ṣàwárí àṣàyàn tó tọ́.",
    "User already exists": "Olùmúsa náà ti wà tẹlẹ. Jọwọ wọlé tàbí ṣètò ọrọigbaniwọlé tuntun bí ó bá yẹ.",
    "No transaction found": "Kò sí ìṣíṣẹ̀ tó rí.",
    "Invalid selected transactions": "Ìṣíṣẹ̀ àìtọ́ ti a yàn.",
    "Data supplied has not been saved": "Àlàyé kò tí wà ní fipamọ́. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "Invalid request type": "Ìbéèrè àìtọ́.",
    "Missing request type, at least one request type must be selected": "Ìbéèrè tí kò sí ti pọ́n. Jọwọ yàn kan.",
    "Invalid payment type": "Iru ìsanwó àìtọ́.",
    "Missing payment type, at least one payment type must be selected": "Àìní iru ìsanwó. Jọwọ yàn kan.",
    "Invalid error code": "Kóòdì aṣìṣe tó ṣe àtìlẹ́yìn jẹ́ àìtọ́.",
    "Missing error code, at least one error code must be selected": "Àìní kóòdì aṣìṣe. Jọwọ ṣàwárí kan.",
    "Invalid filter description": "Àpẹẹrẹ àyẹ̀wò àìtọ́.",
    "Invalid destination description": "Àpẹẹrẹ àyèpè àìtọ́.",
    "Invalid notification type": "Ìròyìn àìtọ́ ni àṣàyàn.",
    "Invalid destination": "Àyèpè àìtọ́.",
    "Invalid field selected": "Ààyè àìtọ́ ni a yàn.",
    "Invalid email from address": "Àdírẹ́sì lẹ́tà tó ránṣẹ̀ jẹ́ àìtọ́.",
    "Invalid email subject": "Òrò ìjápọ̀ àìtọ́.",
    "Invalid email type": "Iru lẹ́tà àìtọ́.",
    "Unable to process request": "Kò le ṣíṣẹ̀ ìbéèrè náà. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "No file selected for upload": "Kò sí fáìlì tí a yàn fún gígbe.",
    "Invalid file size": "Iwọn fáìlì jẹ́ àìtọ́. Jọwọ pèsè tó jẹ́ òdògún.",
    "Invalid filename": "Orúkọ fáìlì jẹ́ àìtọ́.",
    "Invalid extension": "Àfikún fáìlì jẹ́ àìtọ́.",
    "User requires at least one site reference": "Olùmúsa nilo ìdánimọ̀ ojúlé kan.",
    "Only ST-level users can have '*' access": "Ẹnikéjì lóró àwókọ̀tọ̀ ni o lè ní * àwọn àṣẹ òdè.",
    "Request failed": "Ìbéèrè rẹ kọ́. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "Invalid File Contents": "Àwọn ohun tó wà nínú fáìlì jẹ́ àìtọ́.",
    "Maximum number of files uploaded": "Iwọn fáìlì gíga tó pọ́n ti kọjá. Jọwọ pa fáìlì kan àti gbìyànjú lẹ́ẹ̀kan sí.",
    "Insufficient gateway access privileges": "Aìní àṣẹ ipò-géètì tí ó tó.",
    "Maximum file size limit reached": "Iwọn fáìlì tó pọ́n ti kọjá.",
    "Username(s) must be a valid user(s)": "Orúkọ olùmúsa gbọdọ̀ jẹ́ olùmúsa tó yẹ.",
    "Site reference(s) must be a valid site(s)": "Ìdánimọ̀ ojúlé gbọdọ̀ jẹ́ ojúlé tó yẹ.",
    "Unable to send email, please verify the details and try again": "Kò le rán lẹ́tà. Jọwọ ṣàyẹ̀wò àwọn àlàyé kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "Negative already exists": "Iye kan tó kéré sí àṣìṣe tẹlẹ̀. Jọwọ ṣàyẹ̀wò àti ṣètúnṣe.",
    "Cannot delete a search owned by another user": "Kò le parẹ ìwádìí tí ẹni kan tó jẹ́ olùní ni.",
    "Invalid search": "Ìwádìí àìtọ́. Jọwọ gbìyànjú lẹ́ẹ̀kan pẹ̀lú àwárí tó dára.",
    "Cannot delete the specified search, the search name cannot be found": "Kò le parẹ ìwádìí tí wọ́n ti ṣe. Orúkọ ìwádìí kò rí.",
    "Search parameter is too short": "Àkọ́ọ̀lẹ̀ àwárí kéré ju. Jọwọ fi àwárí tó yẹ̀ sí.",
    "Duplicate custom fields defined": "Àwọn ààyèè tó ṣe àwókọ̀ ni ẹ̀ẹ̀kan. Jọwọ ṣètúnṣe àwọn àyípadà náà.",
    "Cannot allocate selected users, insufficient privileges": "Kò le fún àwọn olùmúsa tí wọ́n yàn ní àṣẹ. Aìní àṣẹ tó pọ̀.",
    "Allocated users have access to additional sites": "Àwọn olùmúsa tó yẹ ní àṣẹ sí àwọn ojúlé mìíràn.",
    "Allocated users have access to additional users": "Àwọn olùmúsa tó yẹ ní àṣẹ sí àwọn olùmúsa mìíràn.",
    "User with current role cannot be allocated users": "Àwọn olùmúsa pẹ̀lú ipa ti wọ́n ní lọwọ̀ kò le fún àwọn olùmúsa mííràn ní àṣẹ.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Jójúlé yìí nilo kí aṣàlò rẹ gba kọókì kí wọ́n tó wọlé.",
    "User requires at least one site reference or site group": "Olùmúsa nilo ìdánimọ̀ ojúlé kan tàbí ẹgbẹ́ ojúlé kan.",
    "Allocated users have access to additional site groups": "Àwọn olùmúsa tó yẹ ní àṣẹ sí àwọn ẹgbẹ́ ojúlé mìíràn.",
    "No statement found": "Kò sí ìjábọ́ tó rí.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "Àwọn àlàyé tí wọ́n ṣàgbéjáde kò tí sí ní àtúnṣe nínú MobilePay ẹnikéjì.",
    "Bypass": "Ṣíṣe irínà.",
    "Insufficient access privileges": "Aìní àṣẹ tó yẹ.",
    "Coding error": "Àsìṣe kóòdì ìkọ̀.",
    "Insufficient privileges": "Àìní àṣẹ láti parí ìgbésẹ̀ yìí.",
    "Invalid request": "Ìbéèrè àìtọ́. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "Invalid field": "Ààyè àìtọ́ ni a tẹ. Jọwọ ṣàyẹ̀wò àti ṣètúnṣe ìwé àyè náà.",
    "Unknown site": "Ojúlé àìmọ̀.",
    "Banned card": "Káràdì tó ni ìdènà.",
    "XML element parse error": "Aṣìṣe nígbà fífaradá XML. Jọwọ ṣàyẹ̀wò ètò XML.",
    "Maestro must use SecureCode": "Ìṣíṣẹ̀ Maestro gbọdọ̀ lo SecureCode fún ìjẹ́rìí.",
    "Multiple email addresses must be separated with , or ;": "Àwọn àdírẹ́sì lẹ́tà ní ẹ̀ẹ̀kan gbọdọ̀ yà sọ́tọ̀ pẹ̀lú àpá (,) tàbí pẹ̀lú àmì òpin (;).",
    "Invalid site reference for alias": "Ìdánimọ̀ ojúlé àìtọ́ fún òrùkọ.",
    "Invalid version number": "Nọ́mbà àtúnṣe àìtọ́. Jọwọ tẹ nọ́mbà àtúnṣe tó yẹ.",
    "Unknown user": "Olùmúsa àìmọ̀. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "Cannot determine account": "Kò le ṣàyẹ̀wò àkọ́ọ̀lẹ̀.",
    "JSON element parse error": "Aṣìṣe nígbà fíparadá JSON. Jọwọ ṣàyẹ̀wò ètò JSON.",
    "Wallet type configuration error": "Aṣàlò irú àkọ́ọ̀lẹ̀.",
    "Wallet type not supported on this request": "Irú àkọ́ọ̀lẹ̀ kò ṣe àtìlẹ́yìn fún ìbéèrè yìí.",
    "The card number you have provided is incorrect, please verify your details and try again": "Nọ́mbà káràdì tí wọ́n pèsè jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò àwọn aláyèè kí o si gbìyànjú lẹ́ẹ̀kan sí.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "Nọ́mbà ààbọ̀ (CVV2) tí wọ́n pèsè jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "Ọjọ́ ìgbàpin náà jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò àwọn aláyèè kí o si gbìyànjú lẹ́ẹ̀kan sí.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "Ọ̀sẹ̀ ti ọjọ́ ìgbàpin jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "Ọdún ìgbàpin jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Kò le ṣe àtìlẹ́yìn fún ìsanwó rẹ nítorí àwọn ìsopọ̀ aṣìṣe. ID ìbéèrè kò dọgba. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "The issue number you have provided is incorrect, please verify your details and try again": "Nọ́mbà isọ̀rí tí wọ́n pèsè jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "The payment type you have provided is incorrect, please verify your details and try again": "Iru ìsanwó tí wọ́n pèsè jẹ́ àìtọ́. Jọwọ ṣàyẹ̀wò kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "Unable to process your payment, please contact the website": "Kò le ṣe àtìlẹ́yìn fún ìsanwó rẹ. Jọwọ kan sí ojúlé aláìbáwí fún ìtìlẹ́yìn.",
    "There are errors with these fields: {0}": "Àwọn aṣìṣe wà pẹ̀lú àwọn ààyè wọ̀nyí: {0}",
    "No account found": "Kò sí àkọ́ọ̀lẹ̀ tó rí.",
    "Refund cannot be processed": "Ìdápadà kò le ṣe.",
    "Transaction de-activated": "Ìṣíṣẹ̀ pààrí.",
    "Socket receive error": "Aṣìṣe gbígbà ọwọ́.",
    "Socket connection error": "Aṣìṣe ìsopọ̀.",
    "Socket closed": "Ìmọ̀ ìsopọ̀ tó ti kó.",
    "Invalid data received": "Àlàyé àìtọ́ tí wọ́n gbà.",
    "Invalid SQL": "Ìbéèrè SQL àìtọ́.",
    "Timeout": "Ìgbẹ̀dánwò ṣẹlẹ̀.",
    "Invalid acquirer": "Àlàyé alábòsọ́ jẹ́ àìtọ́.",
    "Unable to connect to acquirer": "Kò le sopọ̀ mọ́ alábòsọ́.",
    "Invalid response from acquirer": "Àlàyé àìtọ́ láti ọdọ̀ alábòsọ́.",
    "No available transport": "Kò sí ìsọdọ̀sọ̀ tí wọ́n rí.",
    "File size too large": "Iwọn fáìlì tó pọ̀ jù.",
    "Socket send error": "Aṣìṣe gbígbé fáìlì.",
    "Communication error": "Ìsopọ̀ aṣìṣe.",
    "Proxy error": "Aṣìṣe aṣàlò.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Kò le ṣe àtìlẹ́yìn fún ìsanwó rẹ nítorí ìsopọ̀ aṣìṣe. Jọwọ ṣàyẹ̀wò àwọn aláyèè kí o gbìyànjú lẹ́ẹ̀kan sí ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Kò le ṣe àtìlẹ́yìn fún ìsanwó rẹ nítorí ìsopọ̀ aṣìṣe (ìfihàn ìyàtọ̀ HTTP {0}). Jọwọ ṣàyẹ̀wò àwọn aláyèè kí o gbìyànjú lẹ́ẹ̀kan sí ({1}).",
    "Wrong number of emails": "Nọ́mbà àdírẹ́sì lẹ́tà tí kò tọ́ ni wọ́n pèsè.",
    "Bank System Error": "Àṣìṣe sístẹ̀mù ilé ìfowopamọ́.",
    "Wrong number of transactions": "Nọ́mbà ìṣíṣẹ̀ tí kò tọ́ ni wọ́n pèsè.",
    "Invalid transport configuration": "Ìṣètò ìsọdọ̀sọ̀ àìtọ́.",
    "No valid updates specified": "Kò sí àtúnṣe tó tọ́ tí wọ́n pèsè.",
    "Transaction reference not found": "Ìtọ́kasí ìṣíṣẹ̀ kò rí.",
    "Settlebaseamount too large": "Ìye ìsọdọ̀sọ̀ tó pọ̀ jù.",
    "Transaction not updatable": "Ìṣíṣẹ̀ kò ṣe ìtúnṣe.",
    "No searchable filter specified": "Kò sí àfáradà fún àwárí.",
    "Timeout Error": "Ìsẹ̀lẹ̀ ìgbọ́kadì ṣẹlẹ̀. Jọwọ gbìyànjú lẹ́ẹ̀kan sí.",
    "3-D Secure Transport Error": "Ìsopọ̀ 3-D àbò̀ ṣẹlẹ̀ aṣìṣe.",
    "Unauthenticated": "Ìbéèrè tí kò jẹ́ ìfẹ́sọ́nà.",
    "Site Suspended": "Ojúlé ti gbé mì.",
    "No updates performed": "Kò sí ìtúnṣe tó ṣe.",
    "Invalid Request": "Ìbéèrè àìtọ́.",
    "Invalid Response": "Àfáradà àìtọ́.",
    "Invalid Acquirer": "Àlàyé alábòsọ́ àìtọ́.",
    "Invalid account data": "Àlàyé àkọ́ọ̀lẹ̀ àìtọ́.",
    "Missing": "Àìní aláyèè.",
    "Payment Error": "Ìsanwó tó ṣẹlẹ̀.",
    "Invalid acquirer for 3-D Secure": "Alábòsọ́ fún 3-D àbò̀ ni àìtọ́.",
    "Invalid payment type for 3-D Secure": "Ìru ìsanwó fún 3-D àbò̀ ni àìtọ́.",
    "Invalid updates specified": "Àtúnṣe àìtọ́ ti wọ́n ṣàpèjúwe.",
    "Manual investigation required": "Ìwádìí afẹ́sẹ̀yàn jẹ́ dandan.",
    "Invalid headers": "Ìkọ̀ àkọsílẹ̀ jẹ́ àìtọ́.",
    "Max fraudscore exceeded": "Ìyè ọjọ́jú ìwà ọlẹ pọ̀ ju lọ.",
    "Invalid filters": "Àwọn àfàyèwò tó pèsè jẹ́ àìtọ́.",
    "Merchant System Error": "Àṣìṣe sístẹ̀mù olùtajà.",
    "Your payment is being processed. Please wait...": "Ìsanwó rẹ ń ṣíṣẹ̀. Jọwọ dúró sí.",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Kò le ṣàpèjúwe 'ìru ìbéèrè àlàyé' àti 'ìru àwọn àlàyé ìbéèrè' nínú ìbéèrè kan ṣoṣo.",
    "Acquirer missing original transaction data": "Alábòsọ́ àìní àwọn aláyèè ìṣíṣẹ̀ àkọ́kọ́.",
    "Insufficient funds": "Aìní owó tó tọ́.",
    "Unable to process due to scheme restrictions": "Kò le ṣe àtìlẹ́yìn nítorí àwọn ìlànà àpapọ̀.",
    "Failed Screening": "Àyẹ̀wò kọjá.",
    "Unable to process due to restrictions": "Kò le ṣe àtìlẹ́yìn nítorí àwọn ìdènà.",
    "Invalid process": "Ìṣíṣẹ̀ àìtọ́.",
    "Risk Referral": "Ìsọfò àfihàn ìrìnà.",
    "Name Pick required": "Orúkọ jẹ́ pàtàkì fún yíyàn.",
    "Address Pick required": "Àdírẹ́sì jẹ́ pàtàkì fún yíyàn.",
    "IP not in range": "Àdírẹ́sì IP kò sí nínú ààrò.",
    "Invalid button configuration": "Ìṣètò bútìn àìtọ́.",
    "Unrecognised response from acquirer": "Ìdáhùn láti ọdọ̀ alábòsọ́ ni kò mọ̀.",
    "Decline": "Ìkọ̀.",
    "Uncertain result": "Ìpèpé ẹ̀dá ìgbàkọ̀.",
    "Soft Decline": "Ìgbéjáde tí rọrùn.",
    "Refer to Issuer": "Tọka sí ẹnikéjì fún àlàyé síwájú.",
    "Request is queued please check the transaction later for the status": "Ìbéèrè wà nínú ìṣẹ́lẹ̀. Jọwọ ṣàyẹ̀wò ìpò ìṣíṣẹ̀ náà nígbà mìíràn.",
    "Generic Retry": "Ìdáhùn gbogbo.",
    "Soft Decline retry": "Ìdáhùn ìgbéjáde tí rọrùn.",
    "There has been a problem with your payment, please verify your details and try again": "Ìṣòro kan wà pẹ̀lú ìsanwó rẹ. Jọwọ ṣàyẹ̀wò àwọn aláyèè kí o gbìyànjú lẹ́ẹ̀kan sí.",
    "Unknown error": "Aṣìṣe àìmọ̀.",

    "Login": "Wo wọlé",
    "Enter Your Email": "Tẹ adirẹsi imeeli rẹ sii",
    "Login With Phone Number": "Wo wọlé pẹlu nọmba foonu rẹ",
    "Verify Using Sms/Email": "Ṣayẹwo Pẹlu SMS/Email",
    "Resend Code In 100 Seconds": "Tun ranṣẹ koodu lẹẹkansii ni iṣẹju 100",
    "Verify": "Ṣayẹwo",
    "Verifying": "Ṣayẹwo lọ lọwọ",
    "Last Week": "Ọsẹ to kọja",
    "Older Transaction": "Iṣowo atijọ",
    "Total": "Gbogbo",
    "Conversion Confirmed": "Iyipada ti jẹrisi",
    "User Account Limit": "Ofin iroyin olumulo",
    "Monthly Balance Limit": "Ofin iwontunwonsi oṣooṣu",
    "Total Limit": "Ofin lapapọ",
    "Used": "Lo",
    "Remaining": "Ku silẹ",
    "Allowed Minimum Topup Amount": "Owo to kere ti a gba laaye lati kun",
    "Allowed Maximum Topup Amount": "Owo to pọ ju ti a gba laaye lati kun",
    "Incoming Limit": "Ofin owo ti nwọle",
    "Daily limit": "Ofin ojoojumọ",
    "Monthly limit": "Ofin oṣooṣu",
    "Yearly limit": "Ofin ọdun kan",
    "Sending Limit": "Ofin fifiranṣẹ",
    "Allowed Number Of Transactions": "Nọmba awọn iṣowo ti a gba laaye",
    "Total Amount": "Gbogbo Iye",
    "Amount To Send": "Iye lati fi ranṣẹ",
    "Destination Amount": "Iye ibi-afẹde",
    "Note": "Akọsilẹ",
    "Revised Amount": "Iye ti a tunwo",
    "Save": "Fipamọ",
    "Date": "Ọjọ",
    "Action": "Iṣe",
    "Payment Scheduled At": "Isanwo ti ṣeto ni",
    "Transaction Fee": "Ọya iṣowo",
    "Number Of Cycle": "Nọmba awọn iyipo",
    "Subscription Starting Date": "Ọjọ ibẹrẹ iforukọsilẹ",
    "Until": "Titi",
    "Next Transaction": "Iṣowo atẹle",
    "Pay Now": "Sanwo bayi",
    "Transaction Method": "Ọna isanwo",
    "Mobile Money Provider": "Olupese owo alagbeka",
    "Wallet Name": "Orukọ apamọwọ",
    "Wallet Number": "Nọmba apamọwọ",
    "Crypto Currency": "Owo foju",
    "Wallet Address": "Adirẹsi apamọwọ",
    "Select Document Type": "Yan iru iwe aṣẹ",
    "Edit Document Number": "Ṣatunkọ nọmba iwe aṣẹ",
    "My Withdrawals": "Awọn owo ti Mo gba",
    "Bargainable": "Ni anfani lati jiroro",
    "Description": "Apejuwe",
    "Attached Files": "Awọn faili ti a so pọ",
    "Sent": "Ti a firanṣẹ",
    "Accepted": "Ti gba",
    "Decline": "Kọ",
    "Pending": "Nṣiṣẹ",
    "Overall Rating": "Atunwo gbogbogbo",
    "No Review": "Ko si atunyẹwo",
    "0 Rating": "Atunwo 0",
    "Transaction Map": "Maapu iṣowo",
    "Send Money": "Firanṣẹ owo",
    "Sending Method": "Ọna fifiranṣẹ",
    "Trouble Sending Money": "Isoro Pese Owo",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Iwọn owo ati iye ti a gba le yato da lori ọna isanwo ti a yan.",
    "Select Method": "Yan Ọna",
    "Completed": "Ti pari",
    "How Was Your Experience?": "Bawo ni iriri rẹ?",
    "Leave A Review": "Fi atunyẹwo silẹ",
    "Review History": "Itan-akọọlẹ atunyẹwo",
    "Write A Review": "Kọ atunyẹwo",
    "Send And Post": "Firan ati Ṣe ifiweranṣẹ",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Iyipada Crypto si Fiat jẹ ojutu to lagbara ati ailewu ti o yipada ni irọrun awọn owo-owo crypto si awọn owo fiat. O ṣe iranwọ si awọn aini oriṣiriṣi ti awọn olumulo, nfunni ni ọpọlọpọ awọn ikanni isanwo ti a ṣe adani si awọn ayanfẹ kọọkan ati awọn ipo.\"",
    "Review Created": "Atunwo ti a ṣẹda",
    "Transactions": "Awọn iṣowo",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Fa/juu tabi gbe faili kan (Fọto/Vidio/PDF)",
    "Max Size 1GB": "Iwọn Max 1GB",
    "Title Description": "Akojọ Apejuwe",
    "Add New": "Fi Tuntun Kun",
    "Discard": "Pa",
    "Save And Post": "Fipamọ ati Ṣe ifiweranṣẹ",
    "Personal Account": "Iwe Akọọlẹ Ti ara ẹni",
    "Conversion": "Iyipada",
    "Spent In Total": "Gbogbo Tita",
    "International": "Kariaye",
    "Recharge": "Recharge",
    "Top Up Wallet To Wallet": "Ṣafikun Wallet si Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Ko ni Asopọ",
    "More": "Siwaju sii",
    "Less": "Din diẹ",
    "Profile Reports": "Iroyin Profaili",
    "Timezone": "Akoko Aago",
    "Cancel": "Fagile",
    "Full Name": "Orukọ Pataki",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Awọn iwe aṣẹ afikun",
    "Profile Complete": "Profaili Parí",
    "Verified": "Ti jẹrisi",
    "Bank Account": "Iwe Iroyin Banki",
    "Mobile Wallet": "Mobile Wallet",
    "Cash Pickup": "Gbigba Owo",
    "Add": "Fi Kun",
    "Configured": "Ti ṣeto",
    "Email": "Imeeli",
    "Download Data": "Gba Awọn data",
    "Enter The Amount": "Tẹ Iye naa",
    "They Receive": "Wọn gba",
    "Where Do You Want To Send Money": "Nibo ni o fẹ fi Owo ranṣẹ",
    "Bank Transfer": "Owo si Owo Banki",
    "Receiving Method": "Ọna Gbigba",
    "Payer": "Olupese",
    "Minimum Amount": "Iye Kekere",
    "Maximum Amount": "Iye ti o pọju",
    "Search": "Ṣawari",
    "Show More": "Fi siwaju",
    "Select Remark / Reasons (All)": "Yan Akojọ / Idi (Gbogbo)",
    "Attach Files": "So awọn faili pọ",
    "Holiday List": "Akọsilẹ Iṣere",
    "No Data To Show": "Ko si data lati fi han",
    "Threshold Limits": "Awọn opin iyipo",
    "Amount Limits": "Awọn opin iye",
    "OTP Verification Code": "Koodu Idanwo OTP",
    "Verify Using Authenticator": "Ṣayẹwo Pẹlu Authenticator",
    "Sms/Email Verification Code": "Koodu Ṣayẹwo SMS/Email",
    "Invite Them": "Pe Wọn",
    "Lookup": "Ṣayẹwo",
    "Invite Friend": "Pe Ọrẹ",
    "Invite Via Phone Number": "Pe Nípa Nọmba Foonu",
    "Invite Via Email Address": "Pe Nípa Adirẹsi Imeeli",
    "Next": "Tẹle",
    "Back": "Pada",
    "Invite Via Email": "Pe Nípa Imeeli",
    "Available Currency": "Iṣuna Ti o wa",
    "Add Remarks And Comments": "Fi awọn akọsilẹ ati awọn asọye kun",
    "Recipient Details": "Awọn alaye Olugba",
    "Go Back": "Pada siwaju",
    "Trouble Requesting Money": "Iṣoro n ṣe ibeere owo",
    "Instant": "Ni kiakia",
    "Subscription": "Iforukọsilẹ",
    "Schedule": "Akoko eto",
    "Choose Request Type": "Yan Iru ibeere",
    "Mobile Airtime": "Waya Airtime",
    "Incorrect Phone Number": "Nọmba foonu ti ko tọ",
    "Make Sure You Have Entered A Correct Phone Number.": "Rii daju pe o ti tẹ nọmba foonu to tọ.",
    "Close": "Pa",
    "Operator": "Olupese",
    "Recipient Mobile Number": "Nọmba foonu Olugba",
    "Amount": "Iye",
    "Service": "Iṣẹ",
    "Destination": "Ibi-afẹde",
    "Top Up Summary": "Atokọ Atunyẹwo Top-Up",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Aala Ifijiṣẹ Ojo kan ti kọja",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Firanṣẹ aṣeyọri idiyele alaye si onibara rẹ. Ṣafihan iṣẹ rẹ, awọn ọja, tabi awọn ipese rẹ ni ọna ọjọgbọn pẹlu asọye ti ara ẹni.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Awọn sisanwo ti a ṣe ni awọn ọjọ iṣẹ ṣaaju 3:00 PM yoo gba owo si akọọlẹ banki ti o gba nipasẹ alẹ ọjọ kanna.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Ṣeto awọn akọọlẹ gbigba rẹ ni awọn orilẹ-ede mẹta ati ni iyara jade awọn owo lati awọn ibeere sisanwo ti a fọwọsi si awọn ikanni isanwo ti a muu ṣiṣẹ.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Ilé-èkó Kemit ni ẹtọ lati béèrè fún àwọn àfikún ìwé-ìmọ̀ (Ìfọwọ́sowọpọ̀ Lákọ̀ọ́kọ̀ - EDD) tàbí ìnforúmẹ́ṣọ̀n tó jẹ́ kíkún fún ìmọ̀ ìdánilójú tó péye, gẹ́gẹ́ bí ìdájọ́ ìwé-láti-ọwọ́ rẹ.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "O gbọdọ jẹ ìwé-ìdánimọ̀ tí ìjọba kọ́ láti kó àwòrán rẹ. Gbogbo àwọn ìwé-ìdánimọ̀ gbọdọ jẹ́ kíkọ sílẹ̀ ní àlàfíà Latìn. Bí kò bá ṣe bẹ́ẹ̀, a nílò ìtúmọ̀ tó jẹ́rìí.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Ṣe àfihàn iṣẹ́ rẹ, fa àgbáyè rẹ sí i. 'Portfolio Mi' jẹ́ pẹpẹ rẹ tó ní gbogbo nkan lórí Instapay. Gbe wọlé kí o sì fi ẹ̀dá ìmọ̀ jùlọ - láti àwọn àwòrán àti fidio tó jẹ́kàtàrà sí PDF tó wúlò. Bí o ṣe jẹ́ olórin, àjọsọpọ̀ ẹni tàbí oníṣe ilé-iṣẹ, ẹ̀yà yìí ń jẹ́ kó o lè fi iṣẹ́ rẹ tàbí ìjọsìn rẹ hàn nínú ọ̀nà tó ni ìdákẹ́jẹ ẹlẹ́rọ́kò.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Pín QR kóòdù tàbí ìjápọ̀ owó rẹ láti gba owó láìpé ní Instapay Wallet rẹ.",
    "Afghanistan": "Àfùgànístánì",
    "Aland Islands": "Àwọn Erékùṣù Aland",
    "Albania": "Àlùbéníà",
    "Algeria": "Àlùgéríà",
    "American Samoa": "Sàmóà Amẹ́ríkà",
    "Andorra": "Àǹdòrà",
    "Angola": "Ààngólà",
    "Anguilla": "Àngúlílà",
    "Antigua and Barbuda": "Àntígúà àti Barbúdà",
    "Argentina": "Agentínà",
    "Armenia": "Àmẹ́níà",
    "Aruba": "Àrúbà",
    "Australia": "Àstràlìá",
    "Austria": "Àstríà",
    "Azerbaijan": "Àsẹ́bájánì",
    "Bahamas": "Bàhámásì",
    "Bahrain": "Bàhéréìnì",
    "Bangladesh": "Bángáládésì",
    "Barbados": "Bàrbádósì",
    "Belarus": "Bẹ̀lárùsì",
    "Belgium": "Bẹ́lìjámù",
    "Belize": "Bẹ̀lísè",
    "Benin": "Bẹ̀nẹ̀",
    "Bermuda": "Bẹ̀múdà",
    "Bhutan": "Bútánì",
    "Bolivia (Plurinational State of)": "Bòlífíà",
    "Bonaire - Sint Eustatius and Saba": "Bònẹ́ẹ̀ - Sìnt Ẹ̀ústátíùsì àti Sábà",
    "Bosnia and Herzegovina": "Bọ́síníà àti Hẹ̀zẹ̀gòfínà",
    "Botswana": "Bọ̀tìsúwánà",
    "Brazil": "Bùrázílì",
    "British Indian Ocean Territory": "Ilẹ̀ Òkun Índíánì Ìjọba Gẹ̀ẹ́sì",
    "Brunei Darussalam": "Brúnèì Darùsálámù",
    "Bulgaria": "Bùlgáríà",
    "Burkina Faso": "Bùrkíná Fasò",
    "Burundi": "Bùrùndì",
    "Cabo Verde": "Kábò Féridé",
    "Cambodia": "Kàmùbódíà",
    "Cameroon": "Kàmẹ̀rúnì",
    "Canada": "Kánádà",
    "Cayman Islands": "Àwọn Erékùṣù Cayman",
    "Central African Republic": "Orílẹ̀-èdè Àrin Áfíríkà",
    "Chad": "Ṣáàdì",
    "Chile": "Ṣílè",
    "China": "Ṣáínà",
    "Christmas Island": "Erékùṣù Kírísìmasì",
    "Cocos (Keeling) Islands": "Àwọn Erékùṣù Kòkósì",
    "Colombia": "Kòlómbíà",
    "Comoros": "Kòmòrósì",
    "Congo": "Kóńgò",
    "Congo - Democratic Republic of the": "Kóńgò - Orílẹ̀-èdè Olómìnira",
    "Cook Islands": "Àwọn Erékùṣù Kúkù",
    "Costa Rica": "Kọ́sítà Ríkà",
    "Cote d'Ivoire": "Orílẹ̀-èdè Côte d'Ivoire",
    "Croatia": "Kòrósíà",
    "Cuba": "Kúbà",
    "Curacao": "Kuraṣo",
    "Cyprus": "Ṣípírọ̀sì",
    "Czech Republic": "Ilẹ̀ Ìjọba Czech",
    "Denmark": "Dẹ́nmáàkì",
    "Djibouti": "Jìbútì",
    "Dominica": "Dòmíníkà",
    "Dominican Republic": "Orílẹ̀-èdè Dòmíníkà",
    "Ecuador": "Èkwádọ̀",
    "Egypt": "Égipítì",
    "El Salvador": "Ẹlì Sàlvádọ̀",
    "Equatorial Guinea": "Guinée Àrín-ìwọ̀-oòrùn",
    "Eritrea": "Ẹritírà",
    "Estonia": "Ẹstóníà",
    "Eswatini": "Eswatini",
    "Ethiopia": "Etopia",
    "Falkland Islands (Malvinas)": "Àwọn Erékùṣù Falkland",
    "Faroe Islands": "Àwọn Erékùṣù Faroe",
    "Fiji": "Fiji",
    "Finland": "Finilandi",
    "France": "Faransé",
    "French Polynesia": "Polinesia Faranse",
    "French Southern Territories": "Àwọn ilẹ̀ Gúúsù àti Antarctic ilẹ̀ Faranse",
    "Gabon": "Gábónì",
    "Gambia": "Gámbíà",
    "Georgia": "Georgia",
    "Germany": "Jámánì",
    "Ghana": "Gana",
    "Gibraltar": "Gibraltar",
    "Greece": "Gíríìsì",
    "Greenland": "Grínílándì",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Gíní",
    "Guinea-Bissau": "Gíní-Bísàù",
    "Guyana": "Gúyánà",
    "Haiti": "Haítì",
    "Heard Island and McDonald Islands": "Àwọn Erékùṣù Heard àti McDonald",
    "Holy See": "Ìlú Vatican",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungary",
    "Iceland": "Ìsílándì",
    "India": "Índíà",
    "Indonesia": "Indonesia",
    "Iran (Islamic Republic of)": "Íránì",
    "Iraq": "Ìrākì",
    "Ireland": "Ìrèlándì",
    "Isle of Man": "Erékùṣù Man",
    "Israel": "Ísírẹ́lì",
    "Italy": "Ìtálì",
    "Jamaica": "Jàmáíkà",
    "Japan": "Japani",
    "Jersey": "Jẹ́sẹ́",
    "Jordan": "Jọ́dánì",
    "Kazakhstan": "Kàzàkìstánì",
    "Kenya": "Kẹ́nyà",
    "Kiribati": "Kiribatì",
    "Korea (Democratic People's Republic of)": "Kòríà Ìwọ̀òòrùn",
    "Korea - Republic of": "Kòríà Ìlà Oòrùn",
    "Kuwait": "Kuwetì",
    "Kyrgyzstan": "Kírgízià",
    "Lao People's Democratic Republic": "Laósì",
    "Latvia": "Látífià",
    "Lebanon": "Lẹ́bánọn",
    "Lesotho": "Lẹ́sótò",
    "Liberia": "Láìbéríà",
    "Libya": "Líbíà",
    "Liechtenstein": "Líktẹ́nsítàìnì",
    "Lithuania": "Lítúáníà",
    "Luxembourg": "Lùksẹ́mbọ̀ọ̀gì",
    "Macao": "Makáò",
    "Madagascar": "Madagasikari",
    "Malawi": "Malawi",
    "Malaysia": "Malasia",
    "Maldives": "Maladifi",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Àwọn Erékùṣù Marshall",
    "Mauritania": "Mauritania",
    "Mauritius": "Mauritius",
    "Mexico": "Mẹ́síkò",
    "Micronesia (Federated States of)": "Àwọn Orílẹ̀-èdè Àpapọ̀ Micronesia",
    "Moldova - Republic of": "Moldova",
    "Monaco": "Monako",
    "Mongolia": "Mongolia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Moroko",
    "Mozambique": "Mosambiki",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepali",
    "Netherlands": "Họ́láńdì",
    "New Caledonia": "Kaledonia Tuntun",
    "New Zealand": "Ziland Tuntun",
    "Nicaragua": "Nikaragwa",
    "Niger": "Nijia",
    "Nigeria": "Nàìjíríà",
    "Niue": "Niwe",
    "Norfolk Island": "Ile Norfolk",
    "North Macedonia": "Masedonia Ariwa",
    "Northern Mariana Islands": "Eyin Mariana Ariwa",
    "Norway": "Norwe",
    "Oman": "Omani",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Palestine - Ipinle",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Guinea Titun",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipins",
    "Pitcairn": "Pitcairn",
    "Poland": "Poland",
    "Portugal": "Potugali",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Katar",
    "Romania": "Romania",
    "Russian Federation": "Ipinle Rọsia",
    "Rwanda": "Rwanda",
    "Saint Barth\u00e9lemy": "Saint Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena - Ascension ati Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts ati Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (apa Faranse)",
    "Saint Vincent and the Grenadines": "Saint Vincent ati awọn Grenadines",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé ati Príncipe",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Sint Maarten (Dutch part)": "Sint Maarten (apa Dutch)",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Solomon Islands": "Solomon Islands",
    "Somalia": "Somalia",
    "South Africa": "South Africa",
    "South Georgia and the South Sandwich Islands": "South Georgia ati South Sandwich Islands",
    "South Sudan": "South Sudan",
    "Spain": "Spain",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard ati Jan Mayen",
    "Sweden": "Sweden",
    "Switzerland": "Switzerland",
    "Syrian Arab Republic": "Syrian Arab Republic",
    "Tajikistan": "Tajikistan",
    "United Republic of Tanzania": "Orilẹ-ede Tanzania",
    "Thailand": "Thailand",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad ati Tobago",
    "Tunisia": "Tunisia",
    "Turkey": "Turkey",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks ati Caicos Islands",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland": "Orílẹ̀-èdè Gẹ̀ẹ́sì",
    "United States of America": "Orílẹ̀-èdè Amẹ́ríkà",
    "United States Minor Outlying Islands": "United States Minor Outlying Islands",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Republic of Bolivarian)",
    "Viet Nam": "Viet Nam",
    "Virgin Islands (British)": "Virgin Islands (Britania)",
    "Virgin Islands (U.S.)": "Virgin Islands (Amẹ́ríkà)",
    "Wallis and Futuna": "Wallis ati Futuna",
    "Yemen": "Yemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Taiwan": "Taiwan",
    "Palestine": "Palestine",
    "Western Sahara": "Ilu Sahara Iwọ-Oorun",
    "Kosovo": "Kosovo",
    "South Ossetia": "South Ossetia",
    "Abkhazia": "Abkhazia",
    "Nagorno-Karabakh (Artsakh)": "Nagorno-Karabakh (Artsakh)",
    "Transnistria": "Transnistria",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Northern Cyprus",
    "Saudi Arabia": "Saudi Arabia",
    "Yugoslavia": "Yugoslavia",

    "Login": "Wo wọlé",
    "Enter Your Email": "Tẹ adirẹsi imeeli rẹ",
    "Login With Phone Number": "Wo wọlé pẹlu nọmba foonu rẹ",
    "Verify Using Sms/Email": "Ṣayẹwo Pẹlu SMS/Email",
    "Resend Code In 100 Seconds": "Tun ranṣẹ koodu lẹẹkansii ni iṣẹju 100",
    "Verify": "Ṣayẹwo",
    "Verifying": "Ṣayẹwo lọ lọwọ",
    "Last Week": "Ọsẹ to kọja",
    "Older Transaction": "Iṣowo atijọ",
    "Total": "Gbogbo",
    "Conversion Confirmed": "Iyipada ti jẹrisi",
    "User Account Limit": "Ofin iroyin olumulo",
    "Monthly Balance Limit": "Ofin iwontunwonsi oṣooṣu",
    "Total Limit": "Ofin lapapọ",
    "Used": "Lo",
    "Remaining": "Ku silẹ",
    "Allowed Minimum Topup Amount": "Owo to kere ti a gba laaye lati kun",
    "Allowed Maximum Topup Amount": "Owo to pọ ju ti a gba laaye lati kun",
    "Incoming Limit": "Ofin owo ti nwọle",
    "Daily Limit": "Ofin ojoojumọ",
    "Monthly Limit": "Ofin oṣooṣu",
    "Yearly Limit": "Ofin ọdun kan",
    "Sending Limit": "Ofin fifiranṣẹ",
    "Allowed Number Of Transactions": "Nọmba awọn iṣowo ti a gba laaye",
    "Total Amount": "Gbogbo Iye",
    "Amount To Send": "Iye lati fi ranṣẹ",
    "Destination Amount": "Iye ibi-afẹde",
    "Note": "Akọsilẹ",
    "Revised Amount": "Iye ti a tunwo",
    "Save": "Fipamọ",
    "Date": "Ọjọ",
    "Action": "Iṣe",
    "Payment Scheduled At": "Isanwo ti ṣeto ni",
    "Transaction Fee": "Ọya iṣowo",
    "Number Of Cycle": "Nọmba awọn iyipo",
    "Subscription Starting Date": "Ọjọ ibẹrẹ iforukọsilẹ",
    "Until": "Titi",
    "Next Transaction": "Iṣowo atẹle",
    "Pay Now": "Sanwo bayi",
    "Transaction Method": "Ọna isanwo",
    "Mobile Money Provider": "Olupese owo alagbeka",
    "Wallet Name": "Orukọ apamọwọ",
    "Wallet Number": "Nọmba apamọwọ",
    "Crypto Currency": "Owo foju",
    "Wallet Address": "Adirẹsi apamọwọ",
    "Select Document Type": "Yan iru iwe aṣẹ",
    "Edit Document Number": "Ṣatunkọ nọmba iwe aṣẹ",
    "My Withdrawals": "Awọn owo ti Mo gba",
    "Bargainable": "Ni anfani lati jiroro",
    "Description": "Apejuwe",
    "Attached Files": "Awọn faili ti a so pọ",
    "Sent": "Ti a firanṣẹ",
    "Accepted": "Ti gba",
    "Decline": "Kọ",
    "Pending": "Nṣiṣẹ",
    "Overall Rating": "Atunwo gbogbogbo",
    "No Review": "Ko si atunyẹwo",
    "0 Rating": "Atunwo 0",
    "Transaction Map": "Maapu iṣowo",
    "Send Money": "Firanṣẹ owo",
    "Sending Method": "Ọna fifiranṣẹ",
    "Trouble Sending Money": "Isoro Pese Owo",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Iwọn owo ati iye ti a gba le yato da lori ọna isanwo ti a yan.",
    "Select Method": "Yan Ọna",
    "Completed": "Ti pari",
    "How Was Your Experience?": "Bawo ni iriri rẹ?",
    "Leave A Review": "Fi atunyẹwo silẹ",
    "Review History": "Itan-akọọlẹ atunyẹwo",
    "Write A Review": "Kọ atunyẹwo",
    "Send And Post": "Firan ati Ṣe ifiweranṣẹ",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Iyipada Crypto si Fiat jẹ ojutu to lagbara ati ailewu ti o yipada ni irọrun awọn owo-owo crypto si awọn owo fiat. O ṣe iranwọ si awọn aini oriṣiriṣi ti awọn olumulo, nfunni ni ọpọlọpọ awọn ikanni isanwo ti a ṣe adani si awọn ayanfẹ kọọkan ati awọn ipo.\"",
    "Review Created": "Atunwo ti a ṣẹda",
    "Transactions": "Awọn iṣowo",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Fa/juu tabi gbe faili kan (Fọto/Vidio/PDF)",
    "Max Size 1GB": "Iwọn Max 1GB",
    "Title Description": "Akojọ Apejuwe",
    "Add New": "Fi Tuntun Kun",
    "Discard": "Pa",
    "Save And Post": "Fipamọ ati Ṣe ifiweranṣẹ",
    "Personal Account": "Iwe Akọọlẹ Ti ara ẹni",
    "Conversion": "Iyipada",
    "Spent In Total": "Gbogbo Tita",
    "International": "Kariaye",
    "Recharge": "Recharge",
    "Top Up Wallet To Wallet": "Ṣafikun Wallet si Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Ko ni Asopọ",
    "More": "Siwaju sii",
    "Less": "Din diẹ",
    "Profile Reports": "Iroyin Profaili",
    "Timezone": "Akoko Aago",
    "Cancel": "Fagile",
    "Full Name": "Orukọ Pataki",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Awọn iwe aṣẹ afikun",
    "Profile Complete": "Profaili Parí",
    "Verified": "Ti jẹrisi",
    "Bank Account": "Iwe Iroyin Banki",
    "Mobile Wallet": "Mobile Wallet",
    "Cash Pickup": "Gbigba Owo",
    "Add": "Fi Kun",
    "Configured": "Ti ṣeto",
    "Email": "Imeeli",
    "Download Data": "Gba Awọn data",
    "Enter The Amount": "Tẹ Iye naa",
    "They Receive": "Wọn gba",
    "Where Do You Want To Send Money": "Nibo ni o fẹ fi Owo ranṣẹ",
    "Bank Transfer": "Owo si Owo Banki",
    "Receiving Method": "Ọna Gbigba",
    "Payer": "Olupese",
    "Minimum Amount": "Iye Kekere",
    "Maximum Amount": "Iye ti o pọju",
    "Search": "Ṣawari",
    "Show More": "Fi siwaju",
    "Select Remark / Reasons (All)": "Yan Akojọ / Idi (Gbogbo)",
    "Attach Files": "So awọn faili pọ",
    "Holiday List": "Akọsilẹ Iṣere",
    "No Data To Show": "Ko si data lati fi han",
    "Threshold Limits": "Awọn opin iyipo",
    "Amount Limits": "Awọn opin iye",
    "OTP Verification Code": "Koodu Idanwo OTP",
    "Verify Using Authenticator": "Ṣayẹwo Pẹlu Authenticator",
    "Sms/Email Verification Code": "Koodu Ṣayẹwo SMS/Email",
    "Invite Them": "Pe Wọn",
    "Lookup": "Ṣayẹwo",
    "Invite Friend": "Pe Ọrẹ",
    "Invite Via Phone Number": "Pe Nípa Nọmba Foonu",
    "Invite Via Email Address": "Pe Nípa Adirẹsi Imeeli",
    "Next": "Tẹle",
    "Back": "Pada",
    "Invite Via Email": "Pe Nípa Imeeli",
    "Available Currency": "Iṣuna Ti o wa",
    "Add Remarks And Comments": "Fi awọn akọsilẹ ati awọn asọye kun",
    "Recipient Details": "Awọn alaye Olugba",
    "Go Back": "Pada siwaju",
    "Trouble Requesting Money": "Iṣoro n ṣe ibeere owo",
    "Instant": "Ni kiakia",
    "Subscription": "Iforukọsilẹ",
    "Schedule": "Akoko eto",
    "Choose Request Type": "Yan Iru ibeere",
    "Mobile Airtime": "Waya Airtime",
    "Incorrect Phone Number": "Nọmba foonu ti ko tọ",
    "Make Sure You Have Entered A Correct Phone Number.": "Rii daju pe o ti tẹ nọmba foonu to tọ.",
    "Close": "Pa",
    "Operator": "Olupese",
    "Recipient Mobile Number": "Nọmba foonu Olugba",
    "Amount": "Iye",
    "Service": "Iṣẹ",
    "Destination": "Ibi-afẹde",
    "Top Up Summary": "Atokọ Atunyẹwo Top-Up",
    "Daily Sending Limit Exceeded": "Aala Ifijiṣẹ Ojo kan ti kọja",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Firanṣẹ aṣeyọri idiyele alaye si onibara rẹ. Ṣafihan iṣẹ rẹ, awọn ọja, tabi awọn ipese rẹ ni ọna ọjọgbọn pẹlu asọye ti ara ẹni.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Awọn sisanwo ti a ṣe ni awọn ọjọ iṣẹ ṣaaju 3:00 PM yoo gba owo si akọọlẹ banki ti o gba nipasẹ alẹ ọjọ kanna.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Ṣeto awọn akọọlẹ gbigba rẹ ni awọn orilẹ-ede mẹta ati ni iyara jade awọn owo lati awọn ibeere sisanwo ti a fọwọsi si awọn ikanni isanwo ti a muu ṣiṣẹ.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Ilé-èkó Kemit ni ẹtọ lati béèrè fún àwọn àfikún ìwé-ìmọ̀ (Ìfọwọ́sowọpọ̀ Lákọ̀ọ́kọ̀ - EDD) tàbí ìnforúmẹ́ṣọ̀n tó jẹ́ kíkún fún ìmọ̀ ìdánilójú tó péye, gẹ́gẹ́ bí ìdájọ́ ìwé-láti-ọwọ́ rẹ.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "O gbọdọ jẹ ìwé-ìdánimọ̀ tí ìjọba kọ́ láti kó àwòrán rẹ. Gbogbo àwọn ìwé-ìdánimọ̀ gbọdọ jẹ́ kíkọ sílẹ̀ ní àlàfíà Latìn. Bí kò bá ṣe bẹ́ẹ̀, a nílò ìtúmọ̀ tó jẹ́rìí.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Ṣe àfihàn iṣẹ́ rẹ, fa àgbáyè rẹ sí i. 'Portfolio Mi' jẹ́ pẹpẹ rẹ tó ní gbogbo nkan lórí Instapay. Gbe wọlé kí o sì fi ẹ̀dá ìmọ̀ jùlọ - láti àwọn àwòrán àti fidio tó jẹ́kàtàrà sí PDF tó wúlò. Bí o ṣe jẹ́ olórin, àjọsọpọ̀ ẹni tàbí oníṣe ilé-iṣẹ, ẹ̀yà yìí ń jẹ́ kó o lè fi iṣẹ́ rẹ tàbí ìjọsìn rẹ hàn nínú ọ̀nà tó ni ìdákẹ́jẹ ẹlẹ́rọ́kò.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Pín QR kóòdù tàbí ìjápọ̀ owó rẹ láti gba owó láìpé ní Instapay Wallet rẹ.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "O ní iṣẹju 15 láti pari iṣowo naa.",
    "Time Remaining": "Jọwọ pari laarin akokò tó fi lélẹ láti yàrá URL kuro.",
    "Please Process The Payment Via Your Selected Payment Type": "Akoko tó kù",
    "Payment Type": "Jọwọ ṣe isẹ sisanwo rẹ nípa irú sisanwo tí o yan.",
    "Top-Up Wallet": "Irú sisanwo",
    "Confirm Your Payment": "SEPU/APAPO ÀPO",
    "You Will Get": "Jẹrisi sisanwo rẹ",
    "Amount To Pay": "O máa gba",
    "Proceed To Payment": "Iye lati san",
    "Load/Deposit Money To The Wallet": "Tẹsiwaju sí sisanwo",
    "Withdraw": "Fi/fi owo kun àpo rẹ",
    "Elevate Your Business With InstaPay": "Yọ",
    "Higher Balances & Transaction Limits": "Gbé ilé-iṣẹ rẹ soke pẹlu InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Àkópọ owó tó ga àti ààbò ìṣowo",
    "Full Feature Access": "Ṣii àyè láti ṣakoso owó púpọ̀ àti sisẹ àwọn ààlà ìṣowo lárin ayedè tó InstaPay n pese.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Àkópọ ìlọ́síwájú iṣẹ gbogbo.",
    "Global Reach": "Iṣe Agbaye",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Fa iṣowo rẹ siwaju laisi awọn aala. InstaPay sopọ mọ ọ pẹlu awọn onibara ati awọn alabaṣepọ kakiri agbaye, ṣiṣe awọn iṣowo agbaye rọrun ju ti iṣaaju lọ.",
    "Advanced Security": "Aabo To Ti ni Ilọsiwaju",
    "Business Registration Available Soon\n": "Iforukọsilẹ iṣowo yoo wa laipẹ.",
    "Amount After Fee": "Owo Lẹhin Owo",
    "Allowed Maximum Amount Per Transaction": "Owo Giga ti a Fọwọsi fun Iṣowo Kọọkan",
    "Transfer": "Gbe lọ",
    "Uncategorized": "Aikọkọ",
    "Currency ISO Code & Amount": "Koodu ISO Owo & Oye",
    "Choose The Top-Up Channel": "Yan ikanni Top-Up",
    "You Selected": "ti o yan",
    "Payout Channel": "ikanni isanwo",
    "Your Details": "àlàyé rẹ",
    "Detailed Confirmed": "apejuwe ti a fidi rẹ mulẹ",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Mu agbara owo rẹ pọ si nipa ṣiṣe igbesoke si InstaPay Business Account kan. Ni iriri agbara InstaPay ni kikun pẹlu awọn ẹya ti o ni ilọsiwaju ti a ṣe apẹrẹ ni pataki fun idagbasoke iṣowo.",
    "Profile Picture": "Aworan àwòkọ́silẹ̀",
    "Add Photo Or Logo": "Ṣàfikún Aworan tàbí Àṣà",
    "First Name": "Orúkọ àkọ́kọ́",
    "Last Name": "Orúkọ ìkẹyìn",
    "Enter Your First Name": "Tẹ orúkọ àkọ́kọ́ rẹ",
    "Enter Your Last Name": "Tẹ orúkọ ìkẹyìn rẹ",
    "Email (Optional)": "Adirẹsi imeeli (àwọn yàn)",
    "Password Must": "Òrò àbò gbọdọ̀",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Yóò dáàbò bo laifọwọyi tí o bá n forúkọ sílẹ̀ pẹ̀lú ìjápọ̀ ìpé.",
    "Change Number": "Yíye nọmba padà",
    "Search Country": "Ṣàwárí orílẹ̀-èdè",
    "Select From The List": "Yan láti àtòjọ̀",
    "Received Amount": "Ìwọn tí a gba",

    "null": null,
    "Processing": "Ṣíṣe",
    "Verify Using Sms/Email": "Ṣàyẹ̀wò pẹ̀lú SMS/Email",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Tí ó ti wà",
    "You Can Have Only 8 Wallets": "O le ní àwọn apamọ́ owó 8 nìkan",
    "Save": "Fipamọ̀",
    "0 File": "0 fáìlì",
    "Search From The List": "Wá láti inú àtòjọ",
    "Amount To Send": "Ìwọn owó láti rán",
    "Wallet To Wallet": "Lati Wallet si Wallet",
    "TOP-UP WALLET ": "Ṣíkọ̀lọpọ̀ Apamọ́",
    "Enter The Amount": "Tẹ iye",
    "Load/Deposit Money To The Wallet": "Fipamọ́/Síkọ̀lọpọ̀ owó sí apamọ́",
    "Choose The Top-Up Channel": "Yan ikanni tó dára fún ṣíkọ̀lọpọ̀",
    "Paypal": "Paypal",
    "You Will Get": "Iwọ yóò gba",
    "Amount To Pay": "Ìwọn owó tó yáwọn",
    "Card Holder Name ": "Orúkọ oníkaárì",
    "Card Number": "Nọ́mbà káàdì",
    "CVC": "CVC",
    "MM/YY": "Ọ̀sẹ̀/Bí",
    "Your Details ": "Àlàyé rẹ",
    "You Selected": "Ìwọ yàn",
    "Detailed Confirmed": "Àtòjọ tí wọ́n fún ní ìmúdájú",
    "Transaction Failed": "Ìgbèrò yáwọn kò ṣẹ",
    "Something Went Wrong. Try Again.": "Ohunkan kùnà. Gbìyànjú lẹ́ẹ̀kan sí.",
    "Convert Funds": "Yí owó padà",
    "How Would You Like To Cashout?:": "Báwo ni ó fẹ́ pèsè owó rẹ?:",
    "Default Receiving Account": "Àkọsílẹ̀ gbigba aiyipada",
    "Specify Receiving Account": "Ṣàlàyé àkọsílẹ̀ gbigba",
    "Which Receiving Account Would You Want Use?:": "Èwo ni ìwọ fẹ́ lo bí àkọsílẹ̀ gbigba?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Banki alágbéèká",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Rárá",
    "Request": "Ẹ́bẹ̀",
    "Subscription Payment": "Iṣeduro owo osuwọn",
    "Is not supported by Paypal, USD will be used as the default currency.": "Kò se ṣègbéyè gbà PayPal, USD ni yóò jẹ́ owó aiyipada.",
    "Review Payment": "Ṣàyẹ̀wò Ìsanwó",
    "Google Authenticator": "google authenticator",
    "Scheduled Has Been Cancelled": "Eto ní ṣíṣe ti fagilé",
    "Select Country First": "Yan orílẹ̀-èdè kíní",
    "Select Gender": "Yan ìbálò",
    "Select Occupation": "Yan iṣẹ́",
    "IBAN and SWIFT/BIC": "IBAN àti SWIFT/BIC",
    "Mobile Money Provider": "Olùpèsè owó alágbèéká",
    "Wallet Name": "Orúkọ àpò̀wẹ́",
    "Wallet Number ": "Nọ́mbà àpò̀wẹ́",
    "Crypto Currency": "Owó nàjìá",
    "Wallet Address ": "Àdírẹ́sì àpò̀wẹ́",
    "Select Document Type ": "Yan irú ìwé ìdánimọ̀",
    "Enter Document Number": "Fọwọ́ sí nọ́mbà ìwé",
    "Msisdn": "Msisdn",
    "Edit ": "Ṣàtúnṣe",
    "My Withdrawls": "Àtìjókò",
    "Declined": "Ẹwọ̀n",
    "Accepted": "Fàgbà",
    "Overall Ratings": "Àwọn ìdámọ̀pọ̀",
    "0 Ratings": "0 ìdámọ̀",
    "No Reviews": "Kò sí àyẹ̀wò",
    "Send Money": "Rán owó",
    "Trouble Sending Money?": "Ìṣòro pẹ̀lú rírán owó?",
    "Payment Request": "Ìbéèrè ìsanwó",
    "How Was Your Experience?": "Bawo ni irírí rẹ?",
    "Leave A Review": "Fi ìdámọ̀ silẹ̀",
    "Write Review Message": "Kọ sí ìfọ̀rọ̀wérọ̀",
    "Send And Post": "Rán àti fi lé lórí",
    "You Rated": "O ṣe ìdámọ̀",
    "Review History": "Ìtàn ìfọ̀rọ̀wérọ̀",
    "Max Size 1GB": "Ìwọn tó pẹ̀lú 1GB",
    "Title": "Àkọlé",
    "Description": "Àpèjúwe",
    "Add New": "Fikun un tuntun",
    "My Payment Address": "Àdírẹ́sì ìsanwó mi",
    "Personal Account": "Àkọ́kọ́ àdámọ̀ ẹni",
    "Updated Successfully": "Ti ṣe àtúnṣe pẹ̀lú àṣeyọrí",
    "Spent In": "Lo nínú",
    "TOP UP": "Tún fìlàṣẹ sílẹ̀",
    "Quotation": "Ìpè",
    "Amount": "Iye",
    "Not Connected": "Ko sopọ",
    "More": "Siwaju",
    "Less": "Kuru",
    "Reports": "Iroyin",
    "User Profile": "Aami olumulo",
    "Cancel": "Fagilee",
    "Saving": "Fipamọ",
    "Confirm ": "jẹrisi",
    "Verify ": "Ṣayẹwo",
    "Delete Country": "Pa orilẹ-ede",
    "Bank Account": "Iṣowo banki",
    "Cash Pickup": "Gbigba Owo",
    "Mobile Wallet ": "Ẹrọ wallet alagbeka",
    "Delete Country Withdrawals": "Pa awọn yiyọ orilẹ-ede",
    "Yes , Delete It": "Bẹẹni, pa a",
    "E-sim": "E-sim",
    "Utilities": "Awọn iṣẹ",
    "Searching": "Wiwa",
    "Recipient Mobile Number": "Nọmba foonu olugba",
    "TOP-UP SUMMARY": "Akopọ afikun",
    "Trouble Requesting Money?": "Isoro ninu Beere Owo?",
    "Choose The Beneficiary": "Yan Olugbọ",
    "Enter The amount": "Tẹ iye",
    "You selected": "O ti yan",
    "Timezone": "Aago agbegbe",
    "Starting From": "Bibẹrẹ lati",
    "Recurring Cycle": "Iyipo atunwi",
    "Until I stop": "Titi emi o fi da duro",
    "Schedule To": "Ṣeto si",
    "Schedule at": "Ṣeto ni",
    "Selected time must be at least 5 minutes from now.": "Aago ti a yan gbọdọ jẹ o kere ju iṣẹju marun lati bayi.",
    "Is this your Location?": "Ṣe eyi ni ipo rẹ?",
    "Yes ": "Bẹẹni",
    "Select Remark Reason": "Yan idi ti akọsilẹ",
    "Attach File": "So awọn faili pọ",
    "Invite via Phone Number": "Pe nipasẹ Nọmba foonu",
    "Invite Friend": "Pe ọrẹ",
    "Invite via Email Address": "Pe nipasẹ Adirẹsi imeeli",
    "Invite via": "Pe nipasẹ",
    "Phone": "Foonu:",
    "Message": "Ifiranṣẹ:",
    "Send": "Firanṣẹ",
    "Invited Succesfully!": "Pe ni aṣeyọri!",
    "Email": "Imeeli",
    "Bank Transfer": "Gbigbe Banki",
    "Mobile Wallet": "Apamọwọ alagbeka",
    "Select The Bank": "Yan banki",
    "Select The Payer": "Yan ol paying",
    "Min Amount:": "Iye Iṣowo Kekere:",
    "Max Amount": "Iye Iṣowo Tọkasi",
    "Other Beneficiaries:": "Awọn olugba miiran:",
    "Back ": "Pada",
    "Next": "Tẹsiwaju",
    "Lookup": "Ṣawari",
    "Invite Them": "Pe wọn",
    "Delete Channel ": "Pa ikanni",
    "Set As Default": "Ṣeto gẹgẹbi aiyipada",
    "See More": "Wo diẹ sii",
    "Session Details": "Alaye ipade",
    "Platform": "Syeed",
    "Windows": "Windows",
    "Browser Name": "Orukọ aṣawakiri",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Ẹya aṣawakiri",
    "IP Address": "Adirẹsi IP",
    "Karachi": "Karachi",
    "State": "Ipinle",
    "Sindh": "Sindh",
    "Active": "Gba",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Apamọwọ yii ti owo ni a ti dina/tabi ko ṣiṣẹ lọwọlọwọ, ati pe a ko le fi owo kun.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Apamọwọ yii ti owo ni a ti dina/tabi ko ṣiṣẹ lọwọlọwọ, ati pe a ko le yi owo pada.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Apamọwọ yii ti owo ni a ti dina/tabi ko ṣiṣẹ lọwọlọwọ, ati pe o ko le fa owo silẹ.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Apamọwọ yii ti owo ni a ti dina/tabi ko ṣiṣẹ lọwọlọwọ, ati pe o ko le gba iwe ìjábọ.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Apamọwọ yii ti owo ni a ti ṣeto gẹgẹ bi ti aiyipada rẹ ati pe a ko le dina rẹ. Lati tẹsiwaju, jọwọ ṣeto apamọwọ owo miiran gẹgẹ bi ti aiyipada rẹ ni akọkọ.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Apamọwọ yii ti owo ni a ti ṣeto gẹgẹ bi ti aiyipada rẹ ati pe a ko le da ṣiṣẹ rẹ duro. Lati tẹsiwaju, jọwọ ṣeto apamọwọ owo miiran gẹgẹ bi ti aiyipada rẹ ni akọkọ.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Ṣe o da lori pe o fẹ paarẹ awọn alaye iforukọsilẹ lati orilẹ-ede yii?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Ṣeto awọn akọọlẹ gbigba rẹ ni awọn orilẹ-ede mẹta ati ki o yọ awọn owo lori ibeere isanwo ti o fọwọsi si awọn ikanni isanwo ti a mu ṣiṣẹ.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Ko si olugba ti o wa lati Pakistan pẹlu awọn alaye Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "Ifiranṣẹ ìkìlọ ti a fi ranṣẹ ni aṣeyọri si",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Owo ati iye ti a gba le yato si da lori ọna isanwo ti a yan.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Pín koodu QR rẹ tabi ọna asopọ isanwo rẹ lati gba owo lẹsẹkẹsẹ ni apamọwọ Insta rẹ.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Fa/Fi silẹ tabi gbe faili kan (Fọto/Vidio/PDF)",
    "Login Again": "tun wọle",
    "Unblock The Wallet": "ṣe ìdánilójú wallet",
    "Invalid Amount": "iye ti ko tọ",
    "Add Payment Card": "fi kaadi sisanwo kun",
    "Save Details To Use Next Time": "fipamọ alaye fun lilo nigbamii",
    "Paid": "ti san",
    "Started On": "bẹrẹ lori",
    "Num Of Cycles": "nọmba awọn iyipo",
    "Ends On": "pari lori",
    "Next Payment At": "isanwo to nbọ ni",
    "Beneficiary": "alabaṣepọ",
    "Recieved Quotation": "iwe ẹtọ ti a gba",
    "Sent Quotation": "iwe ẹtọ ti a fi ranṣẹ",
    "Decline Offer": "kọ ẹbọ",
    "Are You Sure That You Want To Decline The Offer?": "ṣe o dajudaju pe o fẹ kọ ẹbọ naa?",
    "Decline The Offer": "kọ ẹbọ naa",
    "Bargain Offer": "ìpèro ẹbọ",
    "Enter Counter Amount": "tẹ iye ọrọ-ọrọ",
    "Custom Amount": "iye adani",
    "Send The Offer": "fi ẹbọ ranṣẹ",
    "The Offer Is Sent": "ẹbọ ti fi ranṣẹ",
    "Countered Offer": "ẹbọ ọrọ-ọrọ",
    "Applied": "ti lo",
    "Accept Quotation": "gba iwe ẹtọ",
    "Quotation Not Found Or Already Exist": "Iwe ẹtọ ko ri tabi tẹlẹ wa",
    "Recieved Request": "ibeere ti a gba",
    "Sent Request": "ibeere ti a fi ranṣẹ",
    "Search Request": "wa ibeere",
    "Search Quotation": "wa iwe ẹtọ",
    "QR Status": "ipo QR",
    "QR Code Description": "apejuwe koodu QR",
    "Download Your QR Code": "Gba koodu QR rẹ",
    "Your QR Code": "koodu QR rẹ",
    "Your QR Code Poster": "aṣọ koodu QR rẹ",
    "Download  ": "gba",
    "Select Relation": "yan ibatan",
    "Nominee Permanent Address": "adirẹsi ti o wa titi fun alabaṣepọ",
    "Enter Your Message": "tẹ ifiranṣẹ rẹ",
    "Select": "yan",
    "Select File Type": "yan iru faili",
    "Send TOP-UP And E-Sim": "Fi owo-ṣaaju ati E-sim ranṣẹ",
    "Search Users": "wa awọn olumulo",
    "Select Payment Request Type": "yan iru ibeere sisanwo",
    "Wallet To Wallet Transfer": "gbigbe owo-ṣaaju si owo-ṣaaju",
    "Holiday List": "atokọ isinmi",
    "No Data To Show": "ko si data lati fi han",
    "They Recieve": "wọn gba",
    "You Will Be Logged Out In 60 Seconds": "A o yọ rẹ kuro ni iṣẹ ni iṣẹju 60",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Gbe asin rẹ tabi tẹ bọtini eyikeyi lori bọtini rẹ lati tun eto aago pada si iṣẹju 15",
    "You've Been Logged Out": "O ti yọ kuro",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Fun aabo rẹ, a ti yọ rẹ kuro lẹhin iṣẹju 15 ti aiṣeṣe. Jọwọ buwọle lẹẹkansi ti o ba fẹ lo InstaPay.",
    "Request Times Out. Please Try Again!": "Akoko ibeere ti pari. Jọwọ gbiyanju lẹẹkansi!",
    "Resend Code In 100 Seconds": "Tun ranṣẹ koodu lẹẹkansii ni iṣẹju 100",
    "Computer Service": "Iṣẹ kọmputa",
    "Family Support": "Atilẹyin ẹbi",
    "Education": "Eko",
    "Gift And Other Donations": "Ẹbun ati awọn ẹbun miiran",
    "Medical Treatment": "Itọju ilera",
    "Maintenance Or Other Expenses": "Itọju tabi awọn inawo miiran",
    "Travel": "Irinna",
    "Small Value Remittance": "Ìfiránṣẹ́ iye kekere",
    "Liberalized Remittance": "Ìfiránṣẹ́ tó yá",
    "Construction Expenses": "Inawo ikole",
    "Hotel Accommodation": "Ibi-ibèjì hotel",
    "Advertising And/Or Public Relations Related Expenses": "Inawo ti o ni ibatan si ipolongo ati/tabi ibasepọ pẹlu awọn eniyan",
    "Fees For Advisory Or Consulting Service": "Owo fun awọn iṣẹ imọran tabi olutọju",
    "Business Related Insurance Payment": "Isanwo iṣeduro ti o ni ibatan si iṣowo",
    "Insurance Claims Payment": "Isanwo ẹtọ iṣeduro",
    "Delivery Fees": "Owo ifijiṣẹ",
    "Payments For Exported Goods": "Sanwo fun awọn ọja ti a ta",
    "Payment For Services": "Sanwo fun awọn iṣẹ",
    "Payment Of Loans": "Sanwo awọn awin",
    "Office Expenses": "Inawo ọfiisi",
    "Residential Property Purchase": "Ra ohun-ini ibugbe",
    "Property Rental Payment": "Sanwo iyalo ohun-ini",
    "Royalty, Trademark, Patent And Copyright Fees": "Owo fun awọn ẹtọ onipindoje, aami iṣowo, awọn ẹtọ ohun-ini ati awọn owo imulo",
    "Investment In Shares": "Idoko-owo ni awọn mọlẹbi",
    "Fund Investment": "Idoko-owo ninu awọn owo-ori",
    "Tax Payment": "Sanwo owo-ori",
    "Transportation Fees": "Owo irin-ajo",
    "Utility Bills": "Iwe owo agbara",
    "Personal Transfer": "Ìfiránṣẹ́ ẹni-kọọkan",
    "Payment Of Salary": "Sanwo oya",
    "Payment Of Rewards": "Sanwo awọn ẹsan",
    "Payment Of Influencer": "Sanwo fun awọn oludari",
    "Broker, Commitment, Guarantee And Other Fees": "Awọn owo asojú, ẹjọ, ẹri ati awọn owo miiran",
    "Other Purposes": "Ero miiran",
    "Aunt": "Iya",
    "Brother": "Arakunrin",
    "Brother-In-Law": "Arabinrin",
    "Cousin": "Baba",
    "Daughter": "Obirin",
    "Father": "Baba",
    "Father-In-Law": "Iya obinrin",
    "Friend": "Oreo",
    "Grandfather": "Baba",
    "Grandmother": "Iya",
    "Husband": "Auntie",
    "Mother": "Akowe",
    "Mother-In-Law": "Atilẹyin",
    "Nephew": "Ara rẹ (itumo ẹni ti o fi ranṣẹ)",
    "Niece": "Aburo",
    "Self (i.e. The Sender, Himself)": "Iya Obirin",
    "Sister": "Ọmọ",
    "Sister-In-Law": "Iyawo arakunrin",
    "Son": "Ọmọkùnrin",
    "Uncle": "Uncle",
    "Wife": "Iyawo",
    "Others Not Listed": "Ẹlomiiran ti ko ṣe atokọ",
    "Passport": "Pasipọ́tì",
    "National Identification Card": "Kaadi Idanimọ Orílẹ̀-èdè",
    "Driving License": "Iwe-aṣẹ awakọ",
    "Social Security Card/Number": "Kaadi/ Nọ́ńbà Atilẹ́yìn ààbò",
    "Tax Payer Identification Card/Number": "Kaadi/ Nọ́ńbà Idanimọ Oṣùpá",
    "Senior Citizen Identification Card": "Kaadi Idanimọ Ọkàn-àyà",
    "Birth Certificate": "Iwe-ẹri Ìbí",
    "Village Elder Identification Card": "Kaadi Idanimọ Agba Ilu",
    "Permanent Residency Identification Card": "Kaadi Idanimọ Ijọba Idaduro Aaye",
    "Alien Registration Certificate/Card": "Kaadi Iwe-ẹri/Iforukọsilẹ Agbẹjọro",
    "PAN Card": "Kaadi PAN",
    "Voter’s Identification Card": "Kaadi Idanimọ Oludibo",
    "Health Insurance Card/Number": "Kaadi/Ọ̀nà Iṣeduro Ilera",
    "Employer Identification Card": "Kaadi Idanimọ Agbẹjọro Iṣẹ",
    "Others Not Listed": "Awọn miiran ti ko ṣe atokọ",
    "Bank Account": "Iṣiro Banki",
    "Cash Pickup": "Gbigba Owo",
    "Card": "Kaadi",
    "Arts & Entertainment": "Awọn ọna ati Ẹkọ-ara",
    "Banking & Finance": "Iṣowo ati Awọn owo",
    "Education & Research": "Ẹkọ ati Iwadi",
    "Engineering & Construction": "Imọ-ẹrọ ati Ikole",
    "Healthcare & Medicine": "Ilera ati Iṣoogun",
    "Information Technology & Services": "Imọ-ẹrọ alaye ati Awọn iṣẹ",
    "Legal & Compliance": "Ofin ati Iṣe-ṣiṣe",
    "Manufacturing & Production": "Ṣiṣejade ati Ṣiṣelọpọ",
    "Marketing & Sales": "Tita ati Tita ọja",
    "Non-profit & Charity": "Ẹgbẹ Ọjọgbọn & Ẹbun",
    "Real Estate & Property": "Ilẹ-ini & Ohun-ini",
    "Retail & Wholesale": "Tita & Tita fun gbogbo eniyan",
    "Science & Pharmaceuticals": "Imọ & Oògùn",
    "Transport & Logistics": "Irin-ajo & Ilana gbigbe",
    "Travel & Tourism": "Irin-ajo & Ìrìnlẹ ti ilẹ",
    "Unemployed": "Aini iṣẹ",
    "Other": "Ẹlòmíì",
    "Cash": "Owo",
    "Business": "Iṣowo",
    "Gift": "Ẹbun",
    "Salary": "Owo oṣu",
    "Lottery": "Ẹbun owó",
    "Savings": "Iṣunmọ",
    "Good Morning": "E kaaro",
    "Good Evening": "E ku evening",
    "Completed": "Ti pari",
    "Load/Deposit Money To The Wallet": "Tẹsiwaju sí sisanwo",
    "Enter The Amount": "Tẹ ìwọn owó náà",
    "Choose The Top-Up Channel": "Yan ikanni Top-Up",
    "Good Night!": "Ọdọ alẹ!",
    "You Will Get": "Jẹrisi sisanwo rẹ",
    "Amount To Pay": "O máa gba",
    "Top-Up Wallet": "Irú sisanwo",
    "Card Holder Name ": "Orúkọ oníkaárì",
    "Card Number": "Nọ́mbà káàdì",
    "CVC": "CVC",
    "MM/YY": "Ọ̀sẹ̀/Bí",
    "Save Details To Use Next Time": "fipamọ alaye fun lilo nigbamii",
    "Select Or Add Payment Card": "Yan tabi Fi Kaadi Isanwo kun",
    "Your Details ": "Àlàyé rẹ",
    "You Selected": "Ìwọ yàn",
    "Detailed Confirmed": "Àtòjọ tí wọ́n fún ní ìmúdájú",
    "Transaction Failed": "Ìgbèrò yáwọn kò ṣẹ",
    "Something Went Wrong. Try Again.": "Ohunkan kùnà. Gbìyànjú lẹ́ẹ̀kan sí.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Kuna",
    "Initiated": "Bẹrẹ",
    "Revise": "Tun wo",
    "Declined": "Ẹgbẹgbẹ",
    "Bargain-Accepted": "Gbigba iṣowo",
    "Accepted": "Ti gba",
    "Decline The Offer": "Kọ ipese naa",
    "Pending": "Nduro",
    "Cancelled": "Fagile",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Firanṣẹ aṣeyọri idiyele alaye si onibara rẹ. Ṣafihan iṣẹ rẹ, awọn ọja, tabi awọn ipese rẹ ni ọna ọjọgbọn pẹlu asọye ti ara ẹni.",
    "Wallet to wallet": "Apamọwọ si apamọwọ",
    "Please follow these examples:": "Jọwọ tẹle awọn apẹẹrẹ wọnyi:",
    "👤 InstaPay/Instagram Username: instauser": "👤 Orukọ olumulo InstaPay/Instagram: instauser",
    "📧 Email: user@email.com": "📧 Imeeli: user@email.com",
    "Full Name": "Orukọ Pataki",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Kaabo si InstaPay! Lati rii daju pe o le fa owo rẹ jade lẹsẹkẹsẹ lati awọn owo ti o gba, o nilo lati ṣeto awọn akọọlẹ gbigba rẹ ninu awọn eto. Eyi yoo gba ọ laaye lati gbe owo lọ si awọn akọọlẹ banki ayanfẹ rẹ, awọn apamọwọ alagbeka, awọn apamọwọ crypto, tabi awọn ipo gbigba owo ni irọrun.",
    "You Have No Beneficiary": "O ko ni anfaani kan",
    "Add Now": "Fi kun ni bayi",
    "Wallet-Status": "Ipo Apamọwọ",
    "Select Payment Card": "Yan Kaadi Sisanwo",
    "Your funds have been credited to your wallet.": "Awọn owo rẹ ti fi sii sinu apamọwọ rẹ.",
    "Thanks for using InstaPay": "E dupe fun lilo InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Gba owo lẹsẹkẹsẹ: Ṣeto awọn akọọlẹ gbigba rẹ bayi!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Tẹle awọn igbesẹ ti o rọrun wọnyi lati ṣeto awọn akọọlẹ gbigba rẹ ki o rii daju pe o ti ṣetan nigbagbogbo lati wọle si awọn owo rẹ:",
    "STEP 01": "Igbesẹ 01",
    "Go to Settings": "Lọ si Eto",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Ṣii ohun elo InstaPay rẹ ki o lọ si apakan \"Eto\".",
    "STEP 02": "Igbesẹ 02",
    "Select Receiving Accounts": "Yan Awọn Akọọlẹ Gbigba",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Tẹ lori \"Awọn Akọọlẹ Gbigba\" lati bẹrẹ fifi awọn alaye rẹ kun.",
    "Start Adding Accounts": "Bẹrẹ Fifi Awọn Akọọlẹ Kun",
    "Phone Number: 44795396600 (With Country Code)": "Nọmba foonu: 44795396600 (Pẹlu Koodu Orilẹ-ede)",
    "Select Country First": "Yan orílẹ̀-èdè kíní",
    "First Name": "Orúkọ àkọ́kọ́",
    "Last Name": "Orúkọ ìkẹyìn",
    "Address Line": "Laini Adirẹsi",
    "Email Address": "Adirẹsi imeeli",
    "Select City": "Yan Ilu",
    "Select Gender": "Yan ìbálò",
    "Select Occupation": "Yan iṣẹ́",
    "Male": "Ọkunrin",
    "Female": "Obinrin",
    "Non-Binary": "Non-Binary",
    "Prefer Not To Say'": "Mo fẹ ko sọ",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Ko si Olumulo Ti a Ri",
    "Email Address ": "Adirẹsi Imeeli",
    "Select Document": "Yan Iwe",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Ṣe àfihàn iṣẹ́ rẹ, fa àgbáyè rẹ sí i. 'Portfolio Mi' jẹ́ pẹpẹ rẹ tó ní gbogbo nkan lórí Instapay. Gbe wọlé kí o sì fi ẹ̀dá ìmọ̀ jùlọ - láti àwọn àwòrán àti fidio tó jẹ́kàtàrà sí PDF tó wúlò. Bí o ṣe jẹ́ olórin, àjọsọpọ̀ ẹni tàbí oníṣe ilé-iṣẹ, ẹ̀yà yìí ń jẹ́ kó o lè fi iṣẹ́ rẹ tàbí ìjọsìn rẹ hàn nínú ọ̀nà tó ni ìdákẹ́jẹ ẹlẹ́rọ́kò.",
    "Add New": "Fi tuntun kun",
    "Money Out": "Owo jade",
    "Browsername": "Orukọ aṣawakiri",
    "Browserversion": "Ẹya aṣawakiri",
    "Credit": "Kirẹditi",
    "Debit": "Gbese",
    "Top Up": "Tun kun",
    "Wallet To Wallet": "Apamowo si apamowo",
    "Accountant": "Onimọwe",
    "Architect": "Ayaworan",
    "Artist": "Onise",
    "Banker": "Olùṣowo ilé ifowopamọ",
    "Business Owner": "Aláyọjà",
    "Consultant": "Olùkèékà",
    "Doctor": "Dókítà",
    "Engineer": "Ẹnjinia",
    "Farmer": "Agbẹ",
    "Government Employee": "Oṣiṣẹ ìjọba",
    "IT Professional": "Amoye IT",
    "Lawyer": "Amofin",
    "Nurse": "Nọsi",
    "Retailer": "Onisowo kekere",
    "Salesperson": "Agbọrọsọ",
    "Student": "Akẹkọ",
    "Teacher": "Olùkọ́",
    "Prefer Not to Say": "Dára ki n má sọ",
    "Timezone": "Aago agbegbe",
    "Subscription Payment": "Iṣeduro owo osuwọn",
    "Starting From": "Bibẹrẹ lati",
    "Recurring Cycle": "Iyipo atunwi",
    "Until I stop": "Titi emi o fi da duro",
    "Schedule To": "Ṣeto si",
    "Schedule at": "Ṣeto ni",
    "Selected time must be at least 5 minutes from now.": "Aago ti a yan gbọdọ jẹ o kere ju iṣẹju marun lati bayi.",
    "They Recieve": "wọn gba",
    "Attach Files": "So awọn faili pọ",
    "Wallet Selected": "A ti yan Wallet",
    "You Have Selected PKR Currency Wallet For This Transaction": "O ti yan Apoowo Owo PKR fun iṣẹ yii",
    "Instant": "Ni kiakia",
    "Subscription": "Iforukọsilẹ",
    "Schedule": "Akoko eto",
    "Sms/Email Verification Code": "Koodu Ṣayẹwo SMS/Email",
    "Confirm Your Password": "Jẹrisi Ọrọigbaniwọle Rẹ",
    "This Security Feature Requires Password Confirmation!": "Ẹya aabo yii nilo ijẹrisi ọrọigbaniwọle!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Lo ohun elo lati ṣe atẹle koodu QR tabi lo koodu aṣiri rẹ lati mu IwUlẹ Meji Google ṣiṣẹ",
    "OR": "TABI",
    "Paste This Key In The Googe Authenticator App": "Fi bọtini yii sinu Google Authenticator App",
    "Show Secret Key": "Fi Han Koodu Aṣiri",
    "Enter The Six-Digit Code From The Application": "Tẹ koodu mẹfa lati inu ohun elo naa",
    "6 Digit Code": "Koodu Digit mẹfa",
    "Enable": "Mu ṣiṣẹ",
    "Disable": "Pa",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Lẹhin ti o ṣe atẹjade aworan koodu ila, ohun elo naa yoo fi koodu mẹfa han ti o le tẹ si isalẹ",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "IwUlẹ Meji Google ti ni asopọ bayi si akọọlẹ Instapay rẹ",
    "Min Amount:": "Iye Iṣowo Kekere:",
    "Minimum Amount": "Iye Kekere",
    "Maximum Amount": "Iye ti o pọju",
    "Identity Verification Required": "A nilo ayẹwo idanimọ",
    "Select Your Wallet Currency": "Yan owo rẹ fun apamọwọ",
    "Setup Authenticator": "Ṣeto aṣawakiri",
    "Note": "Akọsilẹ",
    "This Is A Test Notice": "Eyi jẹ Ikilọ idanwo",
    "Session Expired": "Ijọpọ pari",
    "You have been logged out for security reasons, please login again to continue.": "A ti fọwọsi kuro fun idi aabo, jọwọ wọle lẹẹkansi lati tẹsiwaju.",
    "Redirecting You To Login Page In 5 Seconds": "A yoo ṣe itọsọna ọ si oju-iwe wiwọle ni awọn iṣẹju-aaya marun",
    "Searching": "Wiwa",
    "Recipient Mobile Number": "Nọmba foonu olugba",
    "TOP-UP SUMMARY": "Akopọ afikun",
    "Amount": "Iye",
    "Something went wrong while getting countries.": "Ohun kan ṣẹlẹ nigba ti n gba awọn orilẹ-ede.",
    "Select Destination Country": "Yan orilẹ-ede ibi-afẹde.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR ko ṣe atilẹyin nipasẹ Paypal, USD ni yoo lo gẹgẹbi owo aiyipada.",
    "Select A Date": "Yan Ọjọ kan",
    "Select A Timezone": "Yan Aago Agbegbe kan",
    "Proceed": "Tẹsiwaju",
    "Proceeding": "Nlọ lọwọ",
    "You Can Now Close This Webpage And Go Back To Instagram": "O le bayi tii oju opo wẹẹbu yii ki o pada si Instagram",
    "Select A Time": "Yan Aago kan",
    "Location Permission Denied": "Aṣayan Ibi Ti kọ",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Tẹle awọn itọnisọna ni isalẹ lati muu awọn iṣẹ ipo ṣiṣẹ ni aṣawakiri rẹ lẹhin ti o kọ aṣayan tẹlẹ:",
    "1: Chrome Browser": "1: Atẹ Browser Chrome",
    "Open the Chrome app.": "Ṣii ohun elo Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Tẹ akojọ awọn dot mẹta (apa ọtun oke) ki o yan Eto.",
    "Scroll down to Site Settings > Location.": "Yi lọ si isalẹ si Eto Aaye > Ipo.",
    "Locate the website in the list or search for it.": "Wa aaye ayelujara naa ninu akojọ tabi wa fun un.",
    "Tap the website and select Allow under Location Access.": "Tẹ aaye ayelujara naa ki o yan Gba laaye labẹ Iwọle Ipo.",
    "2: Firefox Browser": "2: Firefox Browser",
    "Open the Firefox app.": "Ṣii ohun elo Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Tẹ akojọ awọn ila mẹta (apa ọtun isalẹ) ki o lọ si Eto.",
    "Select Privacy & Security > Site Permissions > Location.": "Yan Asiri & Aabo > Awọn Aṣẹ Aaye > Ipo.",
    "Find the website in the list and set the permission to Allow.": "Wa aaye ayelujara naa ninu akojọ ki o ṣeto igbanilaaye si Gba laaye.",
    "3: Samsung Internet Browser": "3: Samsung Internet Browser",
    "Open the Samsung Internet app.": "Ṣii ohun elo Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Tẹ akojọ awọn ila mẹta (apa ọtun isalẹ) ki o yan Eto.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Lọ si Awọn aaye ati Awọn igbasilẹ > Awọn Aṣẹ Aaye > Ipo.",
    "Find the website and change the permission to Allow.": "Wa aaye ayelujara naa ki o yi igbanilaaye pada si Gba laaye.",
    "4: Safari Browser": "4: Asopọ Safari",
    "Open the Settings app.": "Ṣii ohun elo Eto.",
    "Scroll down and select Safari.": "Yi lọ si isalẹ ki o yan Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Tẹ ipo ki o ṣeto si Beere Ni Akoko T’okan Tabi Gba Laye Nigba Ti Ohun Elo Nlo.",
    "Revisit the website and when prompt, allow location access": "Tun ṣabẹwo si oju opo wẹẹbu naa ki o gba laaye wiwọle ipo nigba ti o ba beere.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Imọran: Ti oju opo wẹẹbu ko ba han ninu awọn eto aṣawakiri, nu kaṣi ti aṣawakiri tabi itan rẹ ki o gbiyanju lẹẹkansi.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Lati firanṣẹ ibeere isanwo kan, jọwọ pin ipo rẹ—eyi ṣe iranlọwọ fun wa lati mu aabo pọ si ati kọ igbẹkẹle fun iriri ti o rọrun diẹ sii!",
    "Decline": "Kataa",
    "Share Location": "Pín ipo"
}